import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { ServiceController } from "@rmp/counterparty/api/service";
import { DocumentsState, useDocumentsStore } from "@rmp/counterparty/stores/composables/baseDocuments";
import { ServiceDocument, ServiceDocumentMapper } from "@rmp/core/types/services/serviceDocument";
import { useGeodeticHydrographicServiceDocumentsBreadcrumb } from "@rmp/counterparty/stores/serviceDetails/geodeticHydrographic/documents/composables/useGeodeticHydrographicServiceDocumentsBreadcrumb";
import { useGeodeticHydrographicServiceStore } from "@rmp/counterparty/stores/serviceDetails/geodeticHydrographic";

const abortService = new AbortService();
const page = usePageStore(abortService);
const serviceController = new ServiceController(abortService);

const documentsStore = useDocumentsStore({ controller: serviceController, mapper: ServiceDocumentMapper });

export interface GeodeticHydrographicServiceDocumentsState extends PageState, DocumentsState<ServiceDocument> {
}

const getDefaultState = (): GeodeticHydrographicServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...documentsStore.getDefaultState()
	};
};

export const useGeodeticHydrographicServiceDocumentsStore = defineStore({
	id: "geodetic-hydrographic-service-documents",
	state: (): GeodeticHydrographicServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...documentsStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useGeodeticHydrographicServiceStore();
			return [...breadcrumbs, useGeodeticHydrographicServiceDocumentsBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...documentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			const { title } = useGeodeticHydrographicServiceStore();
			
			this.id = id;
			
			await this.initializeDocumentsStore(title);
		}
	}
});
