import { HistoryBaseMapper } from "@rmp/core/stores/composables/history";
import { ApiAgreementHistoryEntryPersisted } from "@rmp/core/api/types/agreement/apiAgreementHistoryEntryPersisted";
import { parseISO } from "date-fns";
import { formatFullNameWithPosition } from "@rmp/core/utils/formatting";

export interface AgreementHistoryEntry {
	id: string;
	agreementId: string;
	action: string;
	actionDateTime: Date;
	createdAt: Date;
	employeeName: string;
}

export default class AgreementHistoryMapperProfile implements HistoryBaseMapper<AgreementHistoryEntry> {
	constructor() {
	}
	
	mapToHistoryEntry(source: ApiAgreementHistoryEntryPersisted): AgreementHistoryEntry {
		return {
			...source.agreementHistoryEntry,
			employeeName: formatFullNameWithPosition(source.agreementHistoryEntry),
			actionDateTime: parseISO(source.agreementHistoryEntry.actionDateTime),
			createdAt: parseISO(source.agreementHistoryEntry.createdAt)
		};
	}
}
