import { stringify, parse } from "qs";
import { Dictionary } from "vue-router/types/router";
import { isArray, isNil, isString } from "lodash";

export const stringifyQuery = (query: Dictionary<string>) => {
	return stringify(query, { encode: false, arrayFormat: "comma" });
};

export const parseQuery = (query: string) => {
	return parse(query, { comma: true });
};

export const parseArrayParameter = (value: string | string[], defaultValues?: string[]) => {
	if(isArray(value))
		return value;

	return value ? value.split(",") : defaultValues || [];
};
