import { Ref, onBeforeMount, onBeforeUnmount } from "vue";
import { onBeforeRouteLeave } from "vue-router/composables";
import { useI18n } from "vue-i18n-composable";

export function useUnsavedChangesLeaveCheck(containsUnsavedChanges: Ref<boolean>) {
	const {
		tc
	} = useI18n();
	
	const confirmLeave = () => {
		return window.confirm(tc("dialogs.confirmOperationWithUnsavedChanges.description"))
	};
	
	// Переход внутри SPA без смены оригинальной страницы браузера
	onBeforeRouteLeave((to, from, next) => {
		if(containsUnsavedChanges.value && !confirmLeave()) {
			next(false);
		} else {
			next();
		}
	});
		
	// Смена оригинальной страницы браузера
	const beforeWindowUnload = (e: BeforeUnloadEvent) => {
		if(containsUnsavedChanges.value && !confirmLeave()) {
			e.preventDefault();
			e.returnValue = "";
		}
	};
	
	onBeforeMount(() => {
		window.addEventListener("beforeunload", beforeWindowUnload);
	});
	
	onBeforeUnmount(() => {
		window.removeEventListener("beforeunload", beforeWindowUnload);
	});
}
