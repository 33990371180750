import { defineStore } from "pinia";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import { ConfirmPhoneModeType } from "@rmp/core/stores/confirmation/phone/types/confirmPhoneModeType";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useUserStore } from "@rmp/core/stores/user";
import NotDefinedException from "@rmp/core/exceptions/notDefinedException";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { AuthorizationController } from "@rmp/core/api/authorization";
import { useUser } from "@rmp/core/composables/useUser";
import { i18n } from "@rmp/core/plugins";

const abortService = new AbortService();
const authorizationController = new AuthorizationController(abortService);

const page = usePageStore(abortService);
const form = useFormStore();

export interface ConfirmPhoneState extends PageState, FormState {
	phone: string;
	code: string;
	codeError: string;
	mode: ConfirmPhoneModeType;
}

const getDefaultState = (): ConfirmPhoneState => {
	return {
		...page.getDefaultPageState(),
		...form.getDefaultState(),
		phone: "",
		code: "",
		codeError: "",
		mode: ConfirmPhoneModeType.INITIAL
	};
};

export const useConfirmPhoneStore = defineStore({
	id: "confirm-phone",
	state: (): ConfirmPhoneState => getDefaultState(),
	getters: {
		...page.getters,
		...form.getters
	},
	actions: {
		...page.actions,
		...form.actions,
		async beforeInitialized() {
			const { profile } = useUserStore();
			
			if(!profile)
				throw new NotDefinedException("profile");
			
			if(profile.phone && !profile.isPhoneConfirmed)
				await this.getNewCode();
			else
				throw new Error(i18n.tc("common.phoneConfirmationError"));
		},
		async confirmPhone() {
			this.formSaving = true;
			
			try {
				const { signInSilent } = useUser();
				
				await authorizationController.confirmPhone(this.code);
				
				this.mode = ConfirmPhoneModeType.SUCCESS;
				
				await signInSilent();
			} catch (ex) {
				console.error(ex);
				this.codeError = i18n.tc("common.wrongCode");
			} finally {
				this.formSaving = false;
			}
		},
		async getNewCode() {
			try {
				await authorizationController.getPhoneConfirmationCode();
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
			}
		}
	}
});

