import { ApiAccountProfileBase } from "@rmp/core/api/types/account/profile/apiAccountProfileBase";
import { ApiAccount } from "@rmp/core/api/types/account/apiAccount";

export default class User {
	accessToken: string;
	profile: ApiAccountProfileBase;
	account: ApiAccount;
	isTemporaryPassword: boolean;
	passwordExpiresAt: number;
	
	constructor(accessToken: string,
		profile: ApiAccountProfileBase,
		account: ApiAccount,
		isTemporaryPassword: boolean,
		passwordExpiresAt: number)
	{
		this.accessToken = accessToken;
		this.profile = profile;
		this.account = account;
		this.isTemporaryPassword = isTemporaryPassword;
		this.passwordExpiresAt = passwordExpiresAt;
	}
}
