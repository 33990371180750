import { Store } from "pinia";
import { cloneDeep, isEqual, pick, isUndefined, omit } from "lodash";
import NotDefinedOrNullException from "@rmp/core/exceptions/notDefinedOrNullException";
import { diff } from "deep-object-diff";
import { deepClonePlain } from "@rmp/core/utils/object";

class StateSnapshotService {
	values: any;
	
	constructor() {
		this.values = {};
	}
	
	get(key: string) {
		return cloneDeep(this.values[key]);
	}
	
	prepareSnapshot(state: any, fields: any) {
		return pick(state, fields);
	}
	
	set(key: string | number, value: any) {
		this.values[key] = cloneDeep(value);
	}
}

export enum SnapshotKeysEnum {
	LAST_SAVED = "LAST_SAVED"
}

export interface SnapshotState {
	snapshot: Record<SnapshotKeysEnum, {
		updateDateTime: Date
	}>;
}

export interface SnapshotOptions {
	key: SnapshotKeysEnum;
	/** Поля, которые будут сохранены в слепке */
	fields: string[];
}

export type SnapshotGetters = {
	stateContainsChangesSinceLastSaved(this: SnapshotStore, state: { [key: string]: any } & SnapshotState): boolean,
	/** Перечень полей, которые были изменены с последнего слепка. Возвращает массив ключей. */
	changedSinceLastSavedFields(this: SnapshotStore, state: { [key: string]: any } & SnapshotState): string[]
}

export type SnapshotActions = {
	[key: string]: any,
	cancelChanges(this: SnapshotStore, key?: SnapshotKeysEnum): void,
	setStateSnapshot(this: SnapshotStore, key?: SnapshotKeysEnum): void,
	cancelChangesBase(this: SnapshotStore, key: SnapshotKeysEnum): void,
	rollbackState(this: SnapshotStore, key?: SnapshotKeysEnum): void
};

type SnapshotStore = Store<string, SnapshotState, SnapshotGetters, SnapshotActions>;

const stateSnapshotService = new StateSnapshotService();


export const useSnapshotStore = (config: SnapshotOptions[]) => {
	const getOptions = () => {
		return Object.assign({}, ...config.map(item => ({
			[item.key]: {
				fields: item.fields,
				updateDateTime: Date.now()
			}
		})));
	};
	
	const options = getOptions();
	
	
	const getDefaultState = (): SnapshotState => {
		return {
			snapshot: {
				...getOptions()
			}
		};
	};
	
	const getters: SnapshotGetters = {
		stateContainsChangesSinceLastSaved(state) {
			return !!this.changedSinceLastSavedFields.length;
		},
		changedSinceLastSavedFields(state) {
			if(!state.initialized)
				return [];
			
			JSON.stringify(state.snapshot[SnapshotKeysEnum.LAST_SAVED]); // Необходимо для пересчета значения геттера
			
			let { fields } = options[SnapshotKeysEnum.LAST_SAVED];
			const currentState = stateSnapshotService.prepareSnapshot(state, fields);
			if(!currentState) throw new NotDefinedOrNullException("currentState");
			
			const lastSavedState = stateSnapshotService.get(SnapshotKeysEnum.LAST_SAVED);
			if(!lastSavedState) throw new NotDefinedOrNullException("lastSavedState");
			
			// Используется deepClonePlain так как он фильтрует undefined поля. Сейчас если в стейте объявить пустой obj {}
			// типа Type { nested: number }. А после обратиться к нему через v-model.number, vue автоматически при заходе на форму
			// будет ставить этому полю значение undefined, хотя у нас в стейте его не было.
			let differenceResult = diff(deepClonePlain(lastSavedState), deepClonePlain(currentState));
			
			return Object.keys(differenceResult);
		}
	};
	
	const actions: SnapshotActions = {
		setStateSnapshot(key = SnapshotKeysEnum.LAST_SAVED) {
			let { fields } = options[key];
			this.snapshot[key].updateDateTime = new Date();
			
			const snapshot = pick(this, fields);
			stateSnapshotService.set(key, snapshot);
		},
		rollbackState(key = SnapshotKeysEnum.LAST_SAVED) {
			const snapshot = stateSnapshotService.get(key);
			
			this.$patch(snapshot);
		},
		cancelChanges(key = SnapshotKeysEnum.LAST_SAVED) {
			this.cancelChangesBase(key);
		},
		cancelChangesBase(key) {
			this.rollbackState(key);
		}
	};
	
	return {
		actions,
		getters,
		getDefaultState
	};
};
