import AbortService from "@rmp/core/services/abortService";
import { urls } from "@rmp/counterparty/api/config/urls";
import BaseCounterpartyController from "@rmp/counterparty/api/base/baseCounterpartyController";
import { ApiPortDestinationPersisted } from "@rmp/core/api/types/divisions/port/destination/apiPortDestinationPersisted";
import ApiGetPortDestinationsParameters from "@rmp/core/api/types/divisions/port/destination/apiGetPortDestinationsParameters";

export class PortDestinationController extends BaseCounterpartyController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getDestinations = async (portId: string, parameters: ApiGetPortDestinationsParameters) => {
		return await this.get<ApiPortDestinationPersisted[]>(urls.enterprise.division.port.$id.destination, { id: portId, ...parameters });
	};
	
	getDestination = async (portId: string, destinationId: string) => {
		return await this.get<ApiPortDestinationPersisted>(urls.enterprise.division.port.$id.destination.$destinationId,
			{ portId, destinationId });
	};
}
