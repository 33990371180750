export interface RegisterSortingState<T> {
	sorting: {
		type: T;
		order: T;
	};
}

export function useRegisterSortingStore() {
	const actions = {
		setSortingOrder(this: RegisterSortingState<any>, value: string) {
			this.sorting.order = value;
		},
		setSortingType(this: RegisterSortingState<any>, value: string) {
			this.sorting.type = value;
		}
	};

	return {
		actions
	};
}
