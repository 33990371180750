import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { ChatState, useChatStore } from "@rmp/core/stores/composables/chat";
import { BoatApplicationController } from "@rmp/counterparty/api/boatApplication";
import { useBoatApplicationDetailsChatBreadcrumb } from "@rmp/counterparty/stores/boatApplicationDetails/chat/composables/useBoatApplicationDetailsChatBreadcrumb";
import { useBoatApplicationDetailsStore } from "@rmp/counterparty/stores/boatApplicationDetails";

const abortService = new AbortService();
const page = usePageStore(abortService);

const boatApplicationController = new BoatApplicationController(abortService);
const chatStore = useChatStore({ controller: boatApplicationController });

export interface BoatApplicationDetailsChatState extends PageState, ChatState {
	id: string | null;
}

const getDefaultState = (): BoatApplicationDetailsChatState => {
	return {
		...page.getDefaultPageState(),
		...chatStore.getDefaultState(),
		id: null
	};
};

export const useBoatApplicationDetailsChatStore = defineStore({
	id: "boat-application-details-chat",
	state: (): BoatApplicationDetailsChatState => getDefaultState(),
	getters: {
		...page.getters,
		...chatStore.getters,
		breadcrumbs() {
			// @ts-ignore
			const { breadcrumbs } = useBoatApplicationDetailsStore();
			return [...breadcrumbs, useBoatApplicationDetailsChatBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...chatStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.initializeChatStore(id);
		}
	}
});
