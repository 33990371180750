import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useHistoryStore } from "@rmp/core/stores/composables/history";
import { BoatApplicationController } from "@rmp/counterparty/api/boatApplication";
import BoatApplicationHistoryMapperProfile from "@rmp/counterparty/stores/boatApplicationDetails/history/mapper";
import { useBoatApplicationDetailsStore } from "@rmp/counterparty/stores/boatApplicationDetails";
import { useBoatApplicationHistoryBreadcrumb } from "@rmp/counterparty/stores/boatApplicationDetails/history/composables/useBoatApplicationDetailsHistoryBreadcrumb";
import { BoatApplicationHistoryEntry } from "@rmp/counterparty/stores/boatApplicationDetails/history/types/boatApplicationHistoryEntry";

const abortService = new AbortService();
const page = usePageStore(abortService);

const boatApplicationController = new BoatApplicationController(abortService);

const mapper = new BoatApplicationHistoryMapperProfile();
const historyStore = useHistoryStore({ controller: boatApplicationController, mapper });

export interface BoatApplicationDetailsHistoryState extends PageState {
	id: string | null;
	history: BoatApplicationHistoryEntry[];
}

const getDefaultState = (): BoatApplicationDetailsHistoryState => {
	return {
		...page.getDefaultPageState(),
		...historyStore.getDefaultState(),
		id: null,
		history: []
	};
};

export const useBoatApplicationDetailsHistoryStore = defineStore({
	id: "boat-application-details-history",
	state: (): BoatApplicationDetailsHistoryState => getDefaultState(),
	getters: {
		...page.getters,
		...historyStore.getters,
		breadcrumbs() {
			// @ts-ignore
			const { breadcrumbs } = useBoatApplicationDetailsStore();
			return [...breadcrumbs, useBoatApplicationHistoryBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...historyStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.initializeHistoryStore();
		}
	}
});
