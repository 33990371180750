import { ServiceApplicationStepState } from "@rmp/counterparty/stores/serviceApplication/steps/shared/serviceApplicationState";
import { defineStore } from "pinia";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import AbortService from "@rmp/core/services/abortService";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useDepartmentStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/department";
import { useServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication";
import { NewServiceApplicationTariffMapper } from "@rmp/core/types/services/newServiceApplicationTariff";
import { ServiceApplicationInformationTariff } from "@rmp/counterparty/stores/serviceApplication/steps/informationTariff/types/serviceApplicationInformationTariff";
import { InformationServiceController } from "@rmp/counterparty/api/service/information";
import { ApiCreateInformationRequestedTariff } from "@rmp/core/api/types/service/information/apiCreateInformationRequestedTariff";
import { InformationServiceApplication } from "@rmp/core/types/services/information/informationServiceApplication";
import {
	ServiceApplicationTariff
} from "@rmp/counterparty/stores/serviceApplication/steps/informationTariff/types/serviceApplicationTariff";

const abortService = new AbortService();
const page = usePageStore(abortService);

const informationServiceController = new InformationServiceController(abortService);

export interface InformationTariffStepState extends ServiceApplicationStepState, PageState {
	formValid: boolean;
	tariffs: ServiceApplicationInformationTariff[];
}

const getDefaultState = (): InformationTariffStepState => {
	return {
		...page.getDefaultPageState(),
		formValid: true,
		stepType: ServiceApplicationStepTypeEnum.INFORMATION_TARIFF,
		tariffs: []
	};
};

export const useInformationTariffStepStore = defineStore({
	id: "information-tariff-step",
	state: (): InformationTariffStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return state.initialized;
		},
		requestedTariff(): ApiCreateInformationRequestedTariff | undefined {
			if(!this.selectedTariff) return;
			
			return {
				quantity: +this.selectedTariff.value,
				tariffId: this.selectedTariff.id
			};
		},
		selectedTariff(state): ServiceApplicationTariff | undefined {
			if(!state.tariffs.length) return;
			
			const [{ selectedTariffId, tariffs }] = state.tariffs;
			
			if(selectedTariffId)
				return tariffs.find(x => x.id === selectedTariffId);
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			await this.fetch();
			
			this.selectDefaultTariffs();
		},
		async fetch() {
			try {
				const { selectedDepartmentId } = useDepartmentStepStore();
				
				const apiTariffs = await informationServiceController.getInformationTariffs(selectedDepartmentId);
				const tariffs = apiTariffs.map(x => NewServiceApplicationTariffMapper.map(x));
				
				this.tariffs.push({
					selectedTariffId: "",
					tariffs
				});
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		selectDefaultTariffs() {
			const { copiedService } = useServiceApplicationStore();
						
			this.tariffs.forEach((x) => {
				if(!x.tariffs.length) return;
				
				let copiedTariff = (copiedService as InformationServiceApplication).requestedTariff;
				
				if(copiedTariff) {
					x.selectedTariffId = copiedTariff.tariffId;
				} else {
					let [firstTariff] = x.tariffs;
					x.selectedTariffId = firstTariff.id;
				}
			});
		}
	}
});
