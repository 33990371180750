import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useServicesBreadcrumb } from "@rmp/counterparty/stores/services/composables/useServicesBreadcrumb";
import { useCrewboatServiceBreadcrumb } from "@rmp/counterparty/stores/serviceDetails/crewboat/composables/useCrewboatServiceBreadcrumb";
import {
	useUploadCrewboatServiceDocumentsBreadcrumb
} from "@rmp/counterparty/stores/uploadServiceDocuments/crewboat/composables/useUploadCrewboatServiceDocumentsBreadcrumb";
import { CrewboatServiceController } from "@rmp/counterparty/api/service/crewboat";
import { ServiceWorkflowStateType } from "@rmp/core/types/services/ServiceWorkflowStateType";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/counterparty/router";
import { RouteNames } from "@rmp/counterparty/router/routes";
import { CrewboatServiceApplication, CrewboatServiceApplicationMapper } from "@rmp/core/types/services/crewboat/crewboatServiceApplication";
import CrewboatServiceApplicationWorkflowService from "@rmp/core/services/serviceApplication/crewboatServiceApplicationWorkflowService";
import { ApiPropertyServiceTransitionCodeEnum } from "@rmp/core/api/types/service/ApiPropertyServiceTransitionCodeEnum";
import {
	BaseSignServiceDocumentsState,
	useBaseSignServiceDocumentsStore
} from "@rmp/counterparty/stores/composables/baseSignServiceDocuments";
import { formatServiceTitle } from "@rmp/core/utils/formatting";
import { ServiceDocument, ServiceDocumentMapper } from "@rmp/core/types/services/serviceDocument";
import { ServiceController } from "@rmp/counterparty/api/service";

const abortService = new AbortService();
const page = usePageStore(abortService);

const crewboatServiceController = new CrewboatServiceController(abortService);
const serviceController = new ServiceController(abortService);

const baseSignServiceDocumentsStore = useBaseSignServiceDocumentsStore<ServiceDocument>({
	abortService,
	controller: serviceController,
	mapper: ServiceDocumentMapper
});

const crewboatWorkflowService = new CrewboatServiceApplicationWorkflowService();

export interface UploadCrewboatServiceDocumentsState extends PageState, BaseSignServiceDocumentsState<ServiceDocument> {
	service: CrewboatServiceApplication;
}

const getDefaultState = (): UploadCrewboatServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...baseSignServiceDocumentsStore.getDefaultState(),
		service: {} as CrewboatServiceApplication
	};
};

export const useUploadCrewboatServiceDocumentsStore = defineStore({
	id: "upload-crewboat-service-documents",
	state: (): UploadCrewboatServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...baseSignServiceDocumentsStore.getters,
		breadcrumbs(state: UploadCrewboatServiceDocumentsState) {
			return [
				useServicesBreadcrumb(),
				useCrewboatServiceBreadcrumb(state.title, { id: state.service.id }),
				useUploadCrewboatServiceDocumentsBreadcrumb()
			];
		}
	},
	actions: {
		...page.actions,
		...baseSignServiceDocumentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			
			await Promise.all([
				this.fetchServiceApplication(id),
				this.fetchDocuments(id),
				this.verifySigningAuthority()
			]);
		},
		async fetchServiceApplication(serviceId: string) {
			try {
				let serviceApplication = await crewboatServiceController.getService(serviceId);
				
				this.service = CrewboatServiceApplicationMapper.map(serviceApplication);
				this.title = formatServiceTitle(this.service.type, this.service.applicationNumber);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async approve() {
			this.approving = true;
			
			try {
				await crewboatServiceController.updateStatusByTransition(this.id!,
					crewboatWorkflowService.getTransition(this.service.workflowState.alias,
						ServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY) as ApiPropertyServiceTransitionCodeEnum);
				
				this.service.workflowState.alias = ServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY;
				
				alertService.addSuccess(AlertKeys.DOCUMENTS_SENT);
				
				await router.push({ name: RouteNames.CREWBOAT_SERVICE, params: { id: this.id } });
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.approving = false;
			}
		}
	}
});
