import ApiCounterpartyBase from "@rmp/core/api/types/counterparty/apiCounterpartyBase";
import { CounterpartyTypeEnum } from "@rmp/core/types/CounterpartyTypeEnum";

export default class ApiForeignOrganizationCounterparty extends ApiCounterpartyBase {
	longName: string;
	shortName: string;
	countryCode: string;
	legalAddress: string;
	description: string;

	constructor(
		type: CounterpartyTypeEnum = CounterpartyTypeEnum.FOREIGN_ORGANIZATION,
		longName: string = "",
		shortName: string = "",
		countryCode: string = "",
		legalAddress: string = "",
		description: string = ""
	)
	{
		super(type);

		this.longName = longName;
		this.shortName = shortName;
		this.countryCode = countryCode;
		this.legalAddress = legalAddress;
		this.description = description;
	}
}
