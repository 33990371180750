import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useServicesBreadcrumb } from "@rmp/counterparty/stores/services/composables/useServicesBreadcrumb";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/counterparty/router";
import { RouteNames } from "@rmp/counterparty/router/routes";
import { GeodeticHydrographicServiceController } from "@rmp/counterparty/api/service/geodeticHydrographic";
import { useSignGeodeticHydrographicServiceDocumentsBreadcrumb } from "@rmp/counterparty/stores/uploadServiceDocuments/geodeticHydrographic/composables/useSignGeodeticHydrographicServiceDocumentsBreadcrumb";
import { GeodeticHydrographicServiceApplication, GeodeticHydrographicServiceApplicationMapper } from "@rmp/core/types/services/geodeticHydrographic/geodeticHydrographicServiceApplication";
import { useGeodeticHydrographicServiceBreadcrumb } from "@rmp/counterparty/stores/serviceDetails/geodeticHydrographic/composables/useGeodeticHydrographicServiceBreadcrumb";
import { GeodeticHydrographicServiceWorkflowStateType } from "@rmp/core/types/services/geodeticHydrographic/GeodeticHydrographicServiceWorkflowStateType";
import GeodeticHydrographicServiceApplicationWorkflowService
	from "@rmp/core/services/serviceApplication/geodeticHydrographicServiceApplicationWorkflowService";
import {
	ApiGeodeticHydrographicServiceTransitionCodeEnum
} from "@rmp/core/api/types/service/geodeticHydrographic/ApiGeodeticHydrographicServiceTransitionCodeEnum";
import {
	BaseSignServiceDocumentsState,
	useBaseSignServiceDocumentsStore
} from "@rmp/counterparty/stores/composables/baseSignServiceDocuments";
import { formatServiceTitle } from "@rmp/core/utils/formatting";
import { ServiceController } from "@rmp/counterparty/api/service";
import { ServiceDocument, ServiceDocumentMapper } from "@rmp/core/types/services/serviceDocument";

const abortService = new AbortService();
const page = usePageStore(abortService);

const geodeticHydrographicServiceController = new GeodeticHydrographicServiceController(abortService);
const serviceController = new ServiceController(abortService);

const baseSignServiceDocumentsStore = useBaseSignServiceDocumentsStore<ServiceDocument>({
	abortService,
	controller: serviceController,
	mapper: ServiceDocumentMapper
});

const geodeticHydrographicWorkflowService = new GeodeticHydrographicServiceApplicationWorkflowService();

export interface SignGeodeticHydrographicServiceDocumentsState extends PageState, BaseSignServiceDocumentsState<ServiceDocument> {
	service: GeodeticHydrographicServiceApplication;
}

const getDefaultState = (): SignGeodeticHydrographicServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...baseSignServiceDocumentsStore.getDefaultState(),
		service: {} as GeodeticHydrographicServiceApplication
	};
};

export const useSignGeodeticHydrographicServiceDocumentsStore = defineStore({
	id: "sign-geodetic-hydrographic-service-documents",
	state: (): SignGeodeticHydrographicServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...baseSignServiceDocumentsStore.getters,
		breadcrumbs(state: SignGeodeticHydrographicServiceDocumentsState) {
			return [
				useServicesBreadcrumb(),
				useGeodeticHydrographicServiceBreadcrumb(state.title, { id: state.service.id }),
				useSignGeodeticHydrographicServiceDocumentsBreadcrumb()
			];
		},
		targetState(state: SignGeodeticHydrographicServiceDocumentsState) {
			if(!state.service.workflowState) return;
			
			switch (state.service.workflowState.alias) {
				case GeodeticHydrographicServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE:
				 return GeodeticHydrographicServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY;
				case GeodeticHydrographicServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE:
					return GeodeticHydrographicServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY;
				case GeodeticHydrographicServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY:
				case GeodeticHydrographicServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY:
					return;
				default:
					throw new Error();
			}
		}
	},
	actions: {
		...page.actions,
		...baseSignServiceDocumentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			
			await Promise.all([
				this.fetchServiceApplication(id),
				this.fetchDocuments(id),
				this.verifySigningAuthority()
			]);
		},
		async fetchServiceApplication(serviceId: string) {
			try {
				let serviceApplication = await geodeticHydrographicServiceController.getService(serviceId);
				
				this.service = GeodeticHydrographicServiceApplicationMapper.map(serviceApplication);
				this.title = formatServiceTitle(this.service.type, this.service.applicationNumber);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async approve() {
			this.approving = true;
			
			try {
				await geodeticHydrographicServiceController.updateStatusByTransition(this.id!,
					geodeticHydrographicWorkflowService.getTransition(this.service.workflowState.alias,
						this.targetState!) as ApiGeodeticHydrographicServiceTransitionCodeEnum);

				this.service.workflowState.alias = this.targetState!;
				
				alertService.addSuccess(AlertKeys.DOCUMENTS_SENT);
				
				await router.push({ name: RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE, params: { id: this.id } });
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.approving = false;
			}
		}
	}
});
