import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { ServiceController } from "@rmp/counterparty/api/service";
import {
	useTugboatServiceHistoryBreadcrumb
} from "@rmp/counterparty/stores/serviceDetails/tugboat/history/composables/useTugboatServiceHistoryBreadcrumb";
import { useTugboatServiceStore } from "@rmp/counterparty/stores/serviceDetails/tugboat";
import ServiceHistoryMapperProfile, { ServiceHistoryEntry } from "@rmp/core/types/services/serviceHistoryEntry";
import { useHistoryStore } from "@rmp/core/stores/composables/history";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceController = new ServiceController(abortService);

const mapper = new ServiceHistoryMapperProfile();
const historyStore = useHistoryStore({ controller: serviceController, mapper });

export interface TugboatServiceHistoryState extends PageState {
	id: string | null;
	history: ServiceHistoryEntry[];
}

const getDefaultState = (): TugboatServiceHistoryState => {
	return {
		...page.getDefaultPageState(),
		...historyStore.getDefaultState(),
		id: null,
		history: []
	};
};

export const useTugboatServiceHistoryStore = defineStore({
	id: "tugboat-service-history",
	state: (): TugboatServiceHistoryState => getDefaultState(),
	getters: {
		...page.getters,
		...historyStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useTugboatServiceStore();
			return [...breadcrumbs, useTugboatServiceHistoryBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...historyStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.initializeHistoryStore();
		}
	}
});
