import { parseISO } from "date-fns";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";
import { ServiceApplicationBase } from "@rmp/core/types/services/base/serviceApplicationBase";
import { ApiGeodeticHydrographicServiceWorkflowState } from "@rmp/core/api/types/service/geodeticHydrographic/apiGeodeticHydrographicServiceWorkflowState";
import { ApiGeodeticHydrographicServiceApplication } from "@rmp/core/api/types/service/geodeticHydrographic/apiGeodeticHydrographicServiceApplication";

export interface GeodeticHydrographicServiceApplication extends ServiceApplicationBase {
	workflowState: ApiGeodeticHydrographicServiceWorkflowState;
}

export class GeodeticHydrographicServiceApplicationMapper {
	static map(source: ApiGeodeticHydrographicServiceApplication): GeodeticHydrographicServiceApplication {
		return {
			...source,
			createdAt: parseISO(source.createdAt),
			updatedAt: parseISO(source.updatedAt),
			type: ServiceMapperProfile.mapServiceType(source.type)
		};
	}
}
