export enum ServiceApplicationStepTypeEnum {
	SERVICE = "SERVICE",
	DEPARTMENT = "DEPARTMENT",
	PORT = "PORT",
	AGREEMENT = "AGREEMENT",
	BOAT = "BOAT",
	TUGBOAT = "TUGBOAT",
	CREWBOAT = "CREWBOAT",
	PIER = "PIER",
	FLOATING_CRANE = "FLOATING_CRANE",
	TARIFF = "TARIFF",
	INFORMATION_TARIFF = "INFORMATION_TARIFF",
	ROUTE = "ROUTE",
	TIME = "TIME",
	PROPERTY_COST = "PROPERTY_COST",
	INFORMATION_COST = "INFORMATION_COST",
	PROPERTY_CONFIRMATION = "PROPERTY_CONFIRMATION",
	INFORMATION_CONFIRMATION = "INFORMATION_CONFIRMATION",
	DOCUMENTS = "DOCUMENTS",
	CONFIRMATION = "CONFIRMATION"
}
