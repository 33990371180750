import { ApiPierPersisted } from "@rmp/core/api/types/enterpriseProperty/apiPier";

export interface ServiceApplicationPier {
	id: string;
	name: string;
	depth: string;
	length: string;
	status: string;
	isArchived: boolean;
}

export class ServiceApplicationPierMapper {
	static map(source: ApiPierPersisted): ServiceApplicationPier {
		return {
			...source,
			...source.pier
		};
	}
}
