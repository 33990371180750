import { useRouter } from "vue-router/composables";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import { RouteNames } from "@rmp/counterparty/router/routes";
import { useI18n } from "vue-i18n-composable";
import { ApiAllServiceWorkflowStateTypeEnum } from "@rmp/core/api/types/service/base/ApiAllServiceWorkflowStateTypeEnum";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";

export const useServicesRegister = () => {
	const router = useRouter();
	
	const handleItemSelect = async ({ id = "", type }: { id: string, type: ServiceTypeEnum }) => {
		switch (type) {
			case ServiceTypeEnum.TUGBOAT_SERVICE:
				return await router.push({
					name: RouteNames.TUGBOAT_SERVICE,
					params: { id }
				});
			case ServiceTypeEnum.CREWBOAT_SERVICE:
				return await router.push({
					name: RouteNames.CREWBOAT_SERVICE,
					params: { id }
				});
			case ServiceTypeEnum.PIER_SERVICE:
				return await router.push({
					name: RouteNames.PIER_SERVICE,
					params: { id }
				});
			case ServiceTypeEnum.DREDGING_WORKS_SERVICE:
				return await router.push({
					name: RouteNames.DREDGING_SERVICE,
					params: { id }
				});
			case ServiceTypeEnum.SNO_INSTALLATION_SERVICE:
				return await router.push({
					name: RouteNames.SNO_INSTALLATION_SERVICE,
					params: { id }
				});
			case ServiceTypeEnum.GEODETIC_HYDROGRAPHIC_SERVICE:
				return await router.push({
					name: RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE,
					params: { id }
				});
			case ServiceTypeEnum.INFORMATION_SERVICE:
				return await router.push({
					name: RouteNames.INFORMATION_SERVICE,
					params: { id }
				});
			case ServiceTypeEnum.FLOATING_CRANE_SERVICE:
				return await router.push({
					name: RouteNames.FLOATING_CRANE_SERVICE,
					params: { id }
				});
		}
	};
	
	const {
		t
	} = useI18n();
	
	const workflowStateFilterItems = Object.values(ApiAllServiceWorkflowStateTypeEnum)
										   .map(x => ({ text: t(`service.workflowState.${x}`), value: x }));
	const serviceTypesFilterItems = Object.values(ServiceTypeEnum)
										  .map(x => ({ text: t(`aliases.service.${x}`), value: ServiceMapperProfile.mapApiServiceType(x as ServiceTypeEnum) }));
	
	return { handleItemSelect, workflowStateFilterItems, serviceTypesFilterItems };
};
