import AbortService from "@rmp/core/services/abortService";
import { urls } from "@rmp/counterparty/api/config/urls";
import { ApiPierServiceApplication } from "@rmp/core/api/types/service/pier/apiPierServiceApplication";
import BaseCounterpartyController from "@rmp/counterparty/api/base/baseCounterpartyController";
import { ApiAgreementPersisted } from "@rmp/core/api/types/agreement/apiAgreement";
import { ApiDepartmentPersisted } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { ApiPortPersisted } from "@rmp/core/api/types/divisions/port/apiPort";
import { ApiTariff } from "@rmp/core/api/types/service/tariffs/apiTariff";
import { ApiGetTariffTotalAmountRequest } from "@rmp/core/api/types/service/apiGetTariffTotalAmountRequest";
import { ApiTariffTotalAmount } from "@rmp/core/api/types/service/apiTariffTotalAmount";
import { ApiCreatePropertyServiceApplicationBase } from "@rmp/core/api/types/service/base/apiCreatePropertyServiceApplicationBase";
import { ApiPierPersisted } from "@rmp/core/api/types/enterpriseProperty/apiPier";
import { ServiceControllerBase } from "@rmp/counterparty/api/service/serviceControllerBase";
import { ApiExpectedCost } from "@rmp/core/api/types/service/apiExpectedCost";
import { ApiPierExpectedCostRequest } from "@rmp/core/api/types/service/pier/apiPierExpectedCostRequest";
import { ApiPropertyServiceTransitionCodeEnum } from "@rmp/core/api/types/service/ApiPropertyServiceTransitionCodeEnum";
import NotImplementedException from "@rmp/core/exceptions/notImplementedException";

export class PierServiceController extends BaseCounterpartyController implements ServiceControllerBase {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getService = async (pierServiceId: string) => {
		const { serviceApplication } = await this.get<{ serviceApplication: ApiPierServiceApplication }>(urls.service.pier.$pierServiceId,
			{ pierServiceId });
		return serviceApplication;
	};
	
	getAgreements = async (departmentId: string) => {
		return await this.get<ApiAgreementPersisted[]>(urls.service.pier.department.$departmentId.agreement, { departmentId });
	};
	
	getDepartments = async () => {
		return await this.get<ApiDepartmentPersisted[]>(urls.service.pier.department);
	};
	
	getDepartmentPorts = async (departmentId: string) => {
		return await this.get<ApiPortPersisted[]>(urls.service.pier.department.$departmentId.port, { departmentId });
	};
	
	getPiers = async (departmentId: string) => {
		return await this.get<ApiPierPersisted[]>(urls.service.pier.department.$departmentId.pier, { departmentId });
	};
	
	getTariffs = async (departmentId: string, portId: string, pierId: string) => {
		return await this.get<ApiTariff[]>(urls.service.pier.department.$departmentId.pier.$pierId.tariff,
			{ departmentId, portId, pierId });
	};
	
	calculateTariffCost = async (departmentId: string,
		portId: string,
		pierId: string,
		tariffId: string,
		payload: ApiGetTariffTotalAmountRequest) => {
		// TODO: массив временный
		const [data] = await this.post<ApiTariffTotalAmount[]>(urls.service.pier.department.$departmentId.pier.$pierId.tariff.$tariffId.calculate,
			{ departmentId, portId, pierId, tariffId }, payload);
		return data;
	};
	
	createApplication = async (request: ApiCreatePropertyServiceApplicationBase) => {
		return await this.post<{ id: string }>(urls.service.pier, {}, request);
	};
	
	createDraftApplication = async (request: ApiCreatePropertyServiceApplicationBase) => {
		return await this.post<{ id: string }>(urls.service.pier.draft, {}, request);
	};
	
	calculateExpectedCost = async (payload: ApiPierExpectedCostRequest) => {
		return await this.post<ApiExpectedCost>(urls.service.pier.expectedCost.calculate, {}, payload);
	};
	
	updateStatusByTransition = async (pierServiceId: string, code: ApiPropertyServiceTransitionCodeEnum, reason?: string) => {
		return await this.post<void>(urls.service.pier.$pierServiceId.$code,
			{ pierServiceId, code },
			{ rejectionTransition: { reason } });
	};
}
