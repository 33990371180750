import { render, staticRenderFns } from "./RmpSpinnerLoader.vue?vue&type=template&id=49c84390&scoped=true&"
import script from "./RmpSpinnerLoader.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RmpSpinnerLoader.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49c84390",
  null
  
)

export default component.exports