import { isEmpty } from "lodash";
import RouteQueryService from "@rmp/core/services/route/routeQueryService";
import { Location, Route, VueRouter } from "vue-router/types/router";
import { defineStore, Store } from "pinia";
import { RegisterItemsState, useRegisterItemsStore } from "@rmp/core/stores/composables/register/useRegisterItemsStore";
import { RegisterPaginationState, useRegisterPaginationStore } from "@rmp/core/stores/composables/register/useRegisterPaginationStore";
import { RegisterSortingState, useRegisterSortingStore } from "@rmp/core/stores/composables/register/useRegisterSortingStore";
import { RegisterSearchState, useRegisterSearchStore } from "@rmp/core/stores/composables/register/useRegisterSearchStore";
import RegisterRouteQuery from "@rmp/core/stores/composables/register/types/registerRouteQuery";
import { watch } from "vue";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { RegisterFilterState, useRegisterFilterStore } from "@rmp/core/stores/composables/register/useRegisterFilterStore";
import { useAppStore } from "@rmp/core/stores/appStore";
import { cloneDeep } from "lodash";


const paging = useRegisterPaginationStore();
const sorting = useRegisterSortingStore();
const search = useRegisterSearchStore();
const filter = useRegisterFilterStore();
const items = useRegisterItemsStore();

export interface RegisterState<ItemType, FilterType = any> extends RegisterItemsState<ItemType>, RegisterPaginationState,
	RegisterSortingState<string>, RegisterSearchState, RegisterFilterState<FilterType>
{
}

export interface RegisterStoreOptions {
	routeQueryService?: RouteQueryService,
	routeQuery: RegisterRouteQuery,
	getRouter: () => VueRouter,
	routeName: string,
	itemsKey?: string
}

export function useRegisterStore<ItemType, FilterType = any>(options: RegisterStoreOptions) {
	type RegisterStore = Store<string, RegisterState<ItemType, FilterType>, {}, { [key: string]: any, patchStateWithRouteQuery(query: { [key: string]: any }): void }>;
	
	const getDefaultState = (routeQuery: RegisterRouteQuery): RegisterState<ItemType, FilterType> => {
		return {
			listing: {
				items: [],
				loading: false
			},
			paging: {
				total: 0,
				page: routeQuery.page,
				pageSize: 25
			},
			sorting: {
				type: routeQuery.sort,
				order: routeQuery.sortDirection
			},
			search: {
				query: routeQuery.query
			},
			filter: {} as FilterType
		};
	};
	
	const { routeQueryService, routeName } = options;
	
	const getters = {
		...items.getters
	};
	
	const actions = {
		...paging.actions,
		...sorting.actions,
		...search.actions,
		...items.actions,
		async initializeRegister(this: RegisterStore) {
			await this.syncStateWithRoute();
			await this.syncRouteWithState();
			await this.updateListingItems();
			this.listenStateChanges();
		},
		listenStateChanges(this: RegisterStore) {
			// TODO https://jira-rmp.griser.ru/browse/RMP-1922
			const source = {
				paging: this.paging,
				sorting: this.sorting,
				search: this.search,
				filter: this.filter
			};
			watch(() => cloneDeep(source), async (newVal, oldVal) => {
				if(newVal.paging.page === oldVal.paging.page)
					this.$patch((state) => state.paging.page = 1);
				
				await this.syncRouteWithState(false);
				await this.updateListingItems();
			},
			{ deep: true });
		},
		async syncStateWithRoute(this: RegisterStore) {
			const router = options.getRouter();
			
			let routeQuery = await routeQueryService!.resolveRouteQuery(router.currentRoute.query);
			
			let diff = await routeQueryService!.hasRouteChanges(this, routeQuery);
			
			if(!isEmpty(diff)) {
				this.patchStateWithRouteQuery(routeQuery);
			}
		},
		patchStateWithRouteQuery(this: RegisterStore, query: RegisterRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
		},
		patchRegisterStateWithRouteQuery(this: RegisterStore, query: RegisterRouteQuery) {
			this.$patch((state) => {
				state.paging.page = query.page;
				state.sorting.order = query.sortDirection;
				state.sorting.type = query.sort;
				state.search.query = query.query;
			});
		},
		async updateListingItems(this: RegisterStore) {
			this.setListingLoading(true);
			
			try {
				let { [options.itemsKey || "items"]: items, totalCount } = await this.fetchRegisterItems(this);
				
				this.setListingItems(items);
				this.setPagingTotal(totalCount);
			} catch (ex) {
				console.error(ex);
				AlertHelper.handleGeneralRequestErrors(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
			} finally {
				this.setListingLoading(false);
			}
		},
		async syncRouteWithState(replace: boolean = true) {
			const router = options.getRouter();
			
			const to: Location = {
				name: routeName,
				query: routeQueryService!.resolveRouteQueryDictionary(this)
			};
			
			if(replace) {
				await router.replace(to).catch(() => {
				});
			} else {
				if(isEmpty(to.query) && isEmpty(router.currentRoute.query)) return;
				
				await router.push(to).catch(() => {
				});
			}
		}
	};
	
	
	function getDefaultRegisterState() {
		return getDefaultState(options.routeQuery);
	}
	
	return { getters, actions, getDefaultRegisterState };
}

let storeComposables: ReturnType<typeof useRegisterStore>;
export type RegisterStoreDefinition =
	ReturnType<typeof defineStore<string, RegisterState<any>, typeof storeComposables.getters, typeof storeComposables.actions & { [key: string]: any }>> | any;
