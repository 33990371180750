import { OptionsItem } from "@rmp/core/types/options/optionsItem";

export interface Options {
	loading: boolean;
	items: OptionsItem[];
}

export interface OptionsState {
	initialized: boolean;
	options: Options;
}

export class OptionsHelper {
	static getEmpty(): Options {
		return {
			loading: false,
			items: []
		};
	}
}
