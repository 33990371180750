import { HistoryBaseMapper } from "@rmp/core/stores/composables/history";
import { ApiServiceHistoryEntryPersisted } from "@rmp/core/api/types/service/apiServiceHistoryEntry";
import { convertToTimestamp } from "@rmp/core/utils/dates";
import { formatFullNameWithPosition } from "@rmp/core/utils/formatting";

export interface ServiceHistoryEntry {
	employeeName: string;
	action: string;
	actionDateTime: number;
}

export default class ServiceHistoryMapperProfile implements HistoryBaseMapper<ServiceHistoryEntry> {
	constructor() {
	}
	
	mapToHistoryEntry(source: ApiServiceHistoryEntryPersisted): ServiceHistoryEntry {
		return {
			...source.serviceApplicationHistoryEntry,
			employeeName: formatFullNameWithPosition(source.serviceApplicationHistoryEntry),
			actionDateTime: convertToTimestamp(source.serviceApplicationHistoryEntry.actionDateTime) as number
		};
	}
}
