import { computed, reactive, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { cloneDeep, isEqual } from "lodash";
import { RegisterStoreDefinition } from "@rmp/core/stores/composables/register/useRegisterStore";
import { fil } from "date-fns/locale";

export interface FilterOptions<FilterType> {
	useStore: RegisterStoreDefinition;
	selectedParametersBadgeConfig?: { excludedParameters: string[] };
}

export function useRegisterFilter<FilterType>(options: FilterOptions<FilterType>) {
	const store = options.useStore();
	const { filter, filteredItems } = storeToRefs(store);
	
	const isFilterOpened = ref(false);
	const defaultFilter = cloneDeep(filter.value);
	let unappliedFilter = reactive(cloneDeep(defaultFilter));
	
	const resetUnappliedFilter = () => {
		if(isFilterChanged.value)
			unappliedFilter = Object.assign(unappliedFilter, cloneDeep(filter.value));
	};
	
	const applyFilter = () => {
		Object.assign(filter.value, unappliedFilter);
		isFilterOpened.value = false;
	};
	
	const resetFilter = () => {
		Object.assign(filter.value, cloneDeep(defaultFilter));
		Object.assign(unappliedFilter, cloneDeep(defaultFilter));
		isFilterOpened.value = false;
	};
	
	const isFilterChanged = computed(() => {
		return !isEqual(unappliedFilter, filter.value);
	});
	
	const isFilterEmpty = computed(() => {
		return isEqual(defaultFilter, filter.value);
	});
	
	const selectedFilteringParametersCount = computed(() => {
		let values = filter.value;
		
		if(options.selectedParametersBadgeConfig) {
			const excludedParameters = options.selectedParametersBadgeConfig.excludedParameters;
			
			if(excludedParameters && excludedParameters.length) {
				values = Object.fromEntries(
					Object.entries(values).filter(([key]) => !excludedParameters.includes(key))
				)
			}
		}
		
		return Object.values(values).flat().length;
	});
	
	watch(filter, () => {
		resetUnappliedFilter();
	}, { deep: true });
	
	watch(isFilterOpened, () => {
		resetUnappliedFilter();
	});
	
	// Для мгновенного применения фильтра использовать filter, для применения через applyFilter использовать unappliedFilter
	return {
		filteredItems,
		isFilterOpened,
		isFilterEmpty,
		isFilterChanged,
		filter,
		unappliedFilter,
		selectedFilteringParametersCount,
		applyFilter,
		resetFilter
	};
}
