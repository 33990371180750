import { ServiceApplicationStepState } from "@rmp/counterparty/stores/serviceApplication/steps/shared/serviceApplicationState";
import { defineStore } from "pinia";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import AbortService from "@rmp/core/services/abortService";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import ServiceApplicationDepartmentMapper from "@rmp/counterparty/stores/serviceApplication/steps/department/mapper";
import {
	ServiceApplicationDepartment
} from "@rmp/counterparty/stores/serviceApplication/steps/department/types/serviceApplicationDepartment";
import ServiceApplicationApiFacade from "@rmp/counterparty/api/service/facade";
import { useServiceStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/service";
import { useServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication";
import { AgreementWorkflowStateType } from "@rmp/core/types/agreement/AgreementWorkflowStateType";
import {
	ServiceApplicationCounterpartyBoat
} from "@rmp/counterparty/stores/serviceApplication/steps/boat/types/serviceApplicationCounterpartyBoat";
import { CounterpartyBoatController } from "@rmp/counterparty/api/boat";
import ServiceApplicationCounterpartyBoatMapper from "@rmp/counterparty/stores/serviceApplication/steps/boat/mapper";
import { ApiDepartmentPersisted } from "@rmp/core/api/types/divisions/department/apiDepartment";


const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceApplicationApiFacade = new ServiceApplicationApiFacade(abortService);
const counterpartyBoatController = new CounterpartyBoatController(abortService);

const mapper = new ServiceApplicationDepartmentMapper();
const boatMapper = new ServiceApplicationCounterpartyBoatMapper();

export interface DepartmentStepState extends ServiceApplicationStepState, PageState {
	departments: ServiceApplicationDepartment[];
	boats: ServiceApplicationCounterpartyBoat[];
	selectedDepartmentId: string;
}

const getDefaultState = (): DepartmentStepState => {
	return {
		...page.getDefaultPageState(),
		selectedDepartmentId: "",
		departments: [],
		boats: [],
		stepType: ServiceApplicationStepTypeEnum.DEPARTMENT
	};
};

export const useDepartmentStepStore = defineStore({
	id: "department-step",
	state: (): DepartmentStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state): boolean {
			const { agreementRequired } = useServiceStepStore();
			
			if(agreementRequired)
				return !!(state.selectedDepartmentId && this.selectedDepartment!.agreementsNumber);
			else
				return !!state.selectedDepartmentId;
		},
		selectedDepartment(state): ServiceApplicationDepartment | undefined {
			return state.departments.find(x => x.id === state.selectedDepartmentId);
		},
		serviceRequirementsMet(state): boolean {
			const { agreementRequired, boatRequired } = useServiceStepStore();
			
			const requirements = [];
			
			// Договор при необходимости должен быть заключен хотя бы с одним управлением
			if(agreementRequired)
				requirements.push(state.departments.some(x => !!x.agreementsNumber));
			
			// У контрагента при необходимости должно быть хотя бы одно судно
			if(boatRequired)
				requirements.push(!!state.boats.length);
			
			return requirements.every(x => x);
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			const { selectedServiceType } = useServiceStepStore();
			serviceApplicationApiFacade.configure(selectedServiceType);
			
			await this.fetch();
			this.selectDefaultDepartment();
		},
		async fetch() {
			try {
				const tasks = [];
				const { steps } = useServiceApplicationStore();
				
				let departments = await serviceApplicationApiFacade.getDepartments();
				
				if(steps.includes(ServiceApplicationStepTypeEnum.AGREEMENT))
					tasks.push(this.fetchAgreements(departments));
				else
					this.departments = departments.map((x, i) => mapper.mapDepartment(x, 0));
				
				if(steps.includes(ServiceApplicationStepTypeEnum.BOAT))
					tasks.push(this.fetchBoats());
				
				await Promise.all(tasks);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		async fetchAgreements(departments: ApiDepartmentPersisted[]) {
			try {
				const tasks = departments.map(x => serviceApplicationApiFacade.getAgreements(x.id));
				let agreementsByDepartment = await Promise.all(tasks);
				
				this.departments = departments.map((x, i) => mapper.mapDepartment(x, agreementsByDepartment[i].filter(y => [
					AgreementWorkflowStateType.SENT,
					AgreementWorkflowStateType.NEGOTIATED,
					AgreementWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE,
					AgreementWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY,
					AgreementWorkflowStateType.ACTIVE
				].includes(y.agreement.state.code)).length));
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		async fetchBoats() {
			try {
				let { counterpartyBoatsPersisted: apiBoats } = await counterpartyBoatController.getBoats();
				
				this.boats = apiBoats.map((x, i) => boatMapper.mapBoat(x));
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		selectDefaultDepartment() {
			if(!this.departments.length) return;
			
			const { copiedService } = useServiceApplicationStore();
			
			if(copiedService.divisionId) {
				this.selectedDepartmentId = copiedService.divisionId;
			} else {
				let [department] = this.departments;
				
				this.selectedDepartmentId = department.id;
			}
		}
	}
});
