import { LegalEntityCounterparty, LegalEntityCounterpartyMapper } from "@rmp/core/types/counterparty/legalEntity/legalEntityCounterparty";
import {
    AgreementLegalEntityCounterpartyHead, AgreementLegalEntityCounterpartyHeadMapper
} from "@rmp/counterparty/stores/agreementApplication/steps/counterparty/types/agreementLegalEntityCounterpartyHead";
import ApiLegalEntityCounterparty from "@rmp/core/api/types/counterparty/apiLegalEntityCounterparty";
import { parseISODate } from "@rmp/core/utils/dates";

//TODO: разобраться потом с данным типом, т.к. heads в LegalEntityCounterparty в другом типе

export interface AgreementLegalEntityCounterparty extends Omit<LegalEntityCounterparty, "heads"> {
    heads: AgreementLegalEntityCounterpartyHead[];
}

export class AgreementLegalEntityCounterpartyMapper {
    static map(source: ApiLegalEntityCounterparty): AgreementLegalEntityCounterparty {
        return {
            ...source,
            registrationDate: parseISODate(source.registrationDate),
            heads: source.heads.map(x => AgreementLegalEntityCounterpartyHeadMapper.map(x))
        };
    }
}
