import { AgreementWorkflowStateType } from "@rmp/core/types/agreement/AgreementWorkflowStateType";
import { ApiAgreementTransitionCodeType } from "@rmp/core/api/types/agreement/ApiAgreementTransitionCodeType";

export default class AgreementWorkflowService {
	transitions = new Map<AgreementWorkflowStateType, AgreementWorkflowStateType[]>([
		[AgreementWorkflowStateType.NEW, [AgreementWorkflowStateType.DRAFT]],
		[AgreementWorkflowStateType.DRAFT, [AgreementWorkflowStateType.SENT, AgreementWorkflowStateType.CANCELED]],
		[AgreementWorkflowStateType.SENT, [AgreementWorkflowStateType.NEGOTIATED, AgreementWorkflowStateType.REJECTED]],
		[AgreementWorkflowStateType.NEGOTIATED, [AgreementWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE]],
		[AgreementWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE, [AgreementWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY, AgreementWorkflowStateType.REJECTED]],
		[AgreementWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY, [AgreementWorkflowStateType.ACTIVE]],
		[AgreementWorkflowStateType.ACTIVE, [AgreementWorkflowStateType.NOT_ACTIVE]]
	]);
	
	transitionNames = new Map<string, ApiAgreementTransitionCodeType>([
		[[AgreementWorkflowStateType.NEW, AgreementWorkflowStateType.DRAFT].join(), ApiAgreementTransitionCodeType.CREATE],
		[[AgreementWorkflowStateType.DRAFT, AgreementWorkflowStateType.SENT].join(), ApiAgreementTransitionCodeType.SEND],
		[[AgreementWorkflowStateType.SENT, AgreementWorkflowStateType.NEGOTIATED].join(),
			ApiAgreementTransitionCodeType.NEGOTIATE],
		[[AgreementWorkflowStateType.NEGOTIATED, AgreementWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE].join(),
			ApiAgreementTransitionCodeType.FINALLY_SIGN],
		[[AgreementWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE, AgreementWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY].join(),
			ApiAgreementTransitionCodeType.FINALLY_SIGN],
		[[AgreementWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY, AgreementWorkflowStateType.ACTIVE].join(), ApiAgreementTransitionCodeType.START],
		[[AgreementWorkflowStateType.ACTIVE, AgreementWorkflowStateType.NOT_ACTIVE].join(), ApiAgreementTransitionCodeType.FINISH],
		[[AgreementWorkflowStateType.DRAFT, AgreementWorkflowStateType.CANCELED].join(), ApiAgreementTransitionCodeType.CANCEL],
		[[AgreementWorkflowStateType.SENT, AgreementWorkflowStateType.REJECTED].join(), ApiAgreementTransitionCodeType.REJECT],
		[[AgreementWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE, AgreementWorkflowStateType.REJECTED].join(), ApiAgreementTransitionCodeType.REJECT]
	]);
	
	
	
	checkTransition(currentStatus: AgreementWorkflowStateType, target: AgreementWorkflowStateType[] | AgreementWorkflowStateType) {
		const allowedStates = this.transitions.get(currentStatus);
		
		if(!allowedStates)
			return false;
		
		if(Array.isArray(target))
			return target.some(x => allowedStates.includes(x));
		else
			return allowedStates.includes(target);
	}
	
	getTransition(from: AgreementWorkflowStateType, to: AgreementWorkflowStateType) {
		const transition = this.transitionNames.get([from, to].join());
		
		if(!transition)
			throw new Error(`Невозможен переход из статуса ${from} в ${to}`);
		
		return transition;
	}
}
