import { computed } from "vue";
import { StoreDefinition, storeToRefs } from "pinia";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { RegisterStoreDefinition } from "@rmp/core/stores/composables/register/useRegisterStore";

export interface SortingOptions {
	useStore: RegisterStoreDefinition;
}


export function useRegisterSorting(options: SortingOptions) {
	const store = options.useStore();
	
	const { sorting, filteredItems } = storeToRefs(store);
	
	const isMultiSort = computed(() => {
		return Array.isArray(sorting.value.type);
	});
	
	const sortItems = function (_: any, sortBy: string | any[], sortDesc: string | any[]) {
		let sortingType = sortBy.length ? sortBy[0] : null;
		let sortingOrder = sortDesc.length ? (sortDesc[0] ? SortingOrderTypeEnum.DESCENDING : SortingOrderTypeEnum.ASCENDING) : null;
		
		if(sorting.value.type !== sortingType)
			store.setSortingType(sortingType);
		
		if(sorting.value.order !== sortingOrder)
			store.setSortingOrder(sortingOrder || "");
		
		return filteredItems.value;
	};
	
	
	const tableOptions = computed(() => {
		return {
			multiSort: isMultiSort.value,
			sortBy: isMultiSort.value ? sorting.value.type : [sorting.value.type],
			sortDesc: isMultiSort.value
				// @ts-ignore
				? sorting.value.order.map((x: any) => x === SortingOrderTypeEnum.DESCENDING)
				: [sorting.value.order === SortingOrderTypeEnum.DESCENDING]
		};
	});
	
	const headerProps = {
		"sort-icon": "mdi-sort-variant"
	};
	
	
	return {
		sorting, isMultiSort, sortItems, tableOptions, headerProps
	};
}
