import vuetify from "@rmp/core/plugins/vuetify";
import "@rmp/core/plugins/vue2Dropzone";
import "@rmp/core/plugins/vueTheMask";
import "@rmp/core/plugins/vueMeta";
import "@rmp/core/plugins/lottiePlayer";
import createVueI18nInstance from "@rmp/core/plugins/i18n";

const i18n = createVueI18nInstance({});
export {
	vuetify,
	i18n
};
