import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useServicesBreadcrumb } from "@rmp/counterparty/stores/services/composables/useServicesBreadcrumb";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/counterparty/router";
import { RouteNames } from "@rmp/counterparty/router/routes";
import {
	SnoInstallationServiceApplication,
	SnoInstallationServiceApplicationMapper
} from "@rmp/core/types/services/snoInstallation/snoInstallationServiceApplication";
import { SnoInstallationServiceController } from "@rmp/counterparty/api/service/snoInstallation";
import {
	useSnoInstallationServiceBreadcrumb
} from "@rmp/counterparty/stores/serviceDetails/snoInstallation/composables/useSnoInstallationServiceBreadcrumb";
import {
	useSignSnoInstallationServiceDocumentsBreadcrumb
} from "@rmp/counterparty/stores/uploadServiceDocuments/snoInstallation/composables/useSignSnoInstallationServiceDocumentsBreadcrumb";
import { SnoInstallationServiceWorkflowStateType } from "@rmp/core/types/services/snoInstallation/SnoInstallationServiceWorkflowStateType";
import SnoInstallationServiceApplicationWorkflowService
	from "@rmp/core/services/serviceApplication/snoInstallationServiceApplicationWorkflowService";
import {
	ApiSnoInstallationServiceTransitionCodeEnum
} from "@rmp/core/api/types/service/snoIntallation/ApiSnoInstallationServiceTransitionCodeEnum";
import {
	BaseSignServiceDocumentsState,
	useBaseSignServiceDocumentsStore
} from "@rmp/counterparty/stores/composables/baseSignServiceDocuments";
import { formatServiceTitle } from "@rmp/core/utils/formatting";
import { ServiceController } from "@rmp/counterparty/api/service";
import { ServiceDocument, ServiceDocumentMapper } from "@rmp/core/types/services/serviceDocument";

const abortService = new AbortService();
const page = usePageStore(abortService);

const snoInstallationServiceController = new SnoInstallationServiceController(abortService);
const serviceController = new ServiceController(abortService);

const baseSignServiceDocumentsStore = useBaseSignServiceDocumentsStore<ServiceDocument>({
	abortService,
	controller: serviceController,
	mapper: ServiceDocumentMapper
});

const snoInstallationWorkflowService = new SnoInstallationServiceApplicationWorkflowService();

export interface SignSnoInstallationServiceDocumentsState extends PageState, BaseSignServiceDocumentsState<ServiceDocument> {
	service: SnoInstallationServiceApplication;
}

const getDefaultState = (): SignSnoInstallationServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...baseSignServiceDocumentsStore.getDefaultState(),
		service: {} as SnoInstallationServiceApplication
	};
};

export const useSignSnoInstallationServiceDocumentsStore = defineStore({
	id: "sign-sno-installation-service-documents",
	state: (): SignSnoInstallationServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...baseSignServiceDocumentsStore.getters,
		breadcrumbs(state: SignSnoInstallationServiceDocumentsState) {
			return [
				useServicesBreadcrumb(),
				useSnoInstallationServiceBreadcrumb(state.title, { id: state.service.id }),
				useSignSnoInstallationServiceDocumentsBreadcrumb()
			];
		},
		targetState(state: SignSnoInstallationServiceDocumentsState) {
			if(!state.service.workflowState) return;
			
			switch (state.service.workflowState.alias) {
				case SnoInstallationServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE:
					return SnoInstallationServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY;
				case SnoInstallationServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE:
					return SnoInstallationServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY;
				case SnoInstallationServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY:
				case SnoInstallationServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY:
					return;
				default:
					throw new Error();
			}
		}
	},
	actions: {
		...page.actions,
		...baseSignServiceDocumentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			
			await Promise.all([
				this.fetchServiceApplication(id),
				this.fetchDocuments(id),
				this.verifySigningAuthority()
			]);
		},
		async fetchServiceApplication(serviceId: string) {
			try {
				let serviceApplication = await snoInstallationServiceController.getService(serviceId);
				
				this.service = SnoInstallationServiceApplicationMapper.map(serviceApplication);
				this.title = formatServiceTitle(this.service.type, this.service.applicationNumber);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async approve() {
			this.approving = true;
			
			try {
				await snoInstallationServiceController.updateStatusByTransition(this.id!,
					snoInstallationWorkflowService.getTransition(this.service.workflowState.alias,
						this.targetState!) as ApiSnoInstallationServiceTransitionCodeEnum);

				this.service.workflowState.alias = this.targetState!;
				
				alertService.addSuccess(AlertKeys.DOCUMENTS_SENT);
				
				await router.push({ name: RouteNames.SNO_INSTALLATION_SERVICE, params: { id: this.id } });
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.approving = false;
			}
		}
	}
});
