import {
	ServiceApplicationStepState
} from "@rmp/counterparty/stores/serviceApplication/steps/shared/serviceApplicationState";
import { defineStore } from "pinia";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import { TugboatServiceController } from "@rmp/counterparty/api/service/tugboat";
import AbortService from "@rmp/core/services/abortService";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useDepartmentStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/department";
import ServiceApplicationTugboatMapper, { ServiceApplicationTugboat } from "@rmp/core/types/services/serviceApplicationTugboat";
import { useServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication";
import { uniq } from "lodash";
import { TugboatServiceApplication } from "@rmp/core/types/services/tugboat/tugboatServiceApplication";

const abortService = new AbortService();
const page = usePageStore(abortService);

const tugboatServiceController = new TugboatServiceController(abortService);

export interface TugboatStepState extends ServiceApplicationStepState, PageState {
	tugboats: ServiceApplicationTugboat[];
	selectedTugboatIds: string[];
}

const getDefaultState = (): TugboatStepState => {
	return {
		...page.getDefaultPageState(),
		tugboats: [],
		selectedTugboatIds: [],
		stepType: ServiceApplicationStepTypeEnum.TUGBOAT
	};
};

export const useTugboatStepStore = defineStore({
	id: "tugboat-step",
	state: (): TugboatStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return state.initialized;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			await this.fetch();
			
			this.trySetDefaultTugboat();
		},
		async fetch() {
			try {
				const { selectedDepartmentId } = useDepartmentStepStore();
				
				let apiTugboats = await tugboatServiceController.getTugboats(selectedDepartmentId);
				
				this.tugboats = apiTugboats.map(x => ServiceApplicationTugboatMapper.map(x));
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		trySetDefaultTugboat() {
			if(!this.tugboats.length) return;
			
			const { copiedService } = useServiceApplicationStore() as { copiedService: TugboatServiceApplication };
			
			let requestedTariffs = copiedService.requestedTugBoats || [];
			
			if(requestedTariffs.length) {
				// Берем уникальные значения, так как объекты повторяются, если на буксир выбрано несколько тарифов
				let copiedTugboatIds = uniq(copiedService.requestedTugBoats.map(x => x.tugboatId));
				
				// Оставляем только те буксиры, которые доступны пользователю
				this.selectedTugboatIds = copiedTugboatIds.filter(x => this.tugboats.find(y => y.id === x));
			} else {
				let [tugboat] = this.tugboats;
				this.selectedTugboatIds = [tugboat.id];
			}
		}
	}
});
