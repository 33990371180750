import { parseISO } from "date-fns";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";
import { PropertyServiceApplicationBase } from "@rmp/core/types/services/base/propertyServiceApplicationBase";
import { ApiInformationServiceApplication } from "@rmp/core/api/types/service/information/apiInformationServiceApplication";
import {
	ServiceApplicationInformationTariff,
	ServiceApplicationInformationTariffMapper
} from "@rmp/core/types/services/information/serviceApplicationInformationTariff";
import { ApiInformationServiceWorkflowState } from "@rmp/core/api/types/service/information/apiInformationServiceWorkflowState";

export interface InformationServiceApplication extends PropertyServiceApplicationBase {
	workflowState: ApiInformationServiceWorkflowState;
	requestedTariff: ServiceApplicationInformationTariff;
	tariff: ServiceApplicationInformationTariff | null;
}

export class InformationServiceApplicationMapper {
	static map(source: ApiInformationServiceApplication): InformationServiceApplication {
		return {
			...source,
			createdAt: parseISO(source.createdAt),
			updatedAt: parseISO(source.updatedAt),
			type: ServiceMapperProfile.mapServiceType(source.type),
			requestedTariff: ServiceApplicationInformationTariffMapper.map(source.requestedTariff),
			tariff: source.tariff && ServiceApplicationInformationTariffMapper.map(source.tariff)
		};
	}
}
