import { render, staticRenderFns } from "./RmpBtn.vue?vue&type=template&id=4cf84e23&scoped=true&"
import script from "./RmpBtn.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RmpBtn.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./RmpBtn.vue?vue&type=style&index=0&id=4cf84e23&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cf84e23",
  null
  
)

export default component.exports