import { IsEnum } from "class-validator";
import { ProfileType } from "@rmp/core/types/ProfileType";

export class AccountProfileBase {
	id: string;
	createdAt: string;
	updatedAt: string;
	@IsEnum(ProfileType)
	type: ProfileType;
	accountId: string;
	title: string;
	email: string;
	phone: string;
	isActive: boolean;
	isPhoneConfirmed: boolean;
	isSignatureRequired: boolean;
	
	constructor(
		id: string = "",
		createdAt: string = "",
		updatedAt: string = "",
		type = ProfileType.UNKNOWN,
		accountId: string = "",
		title: string = "",
		email: string = "",
		phone: string = "",
		isActive: boolean = false,
		isPhoneConfirmed: boolean = false,
		isSignatureRequired: boolean = false
	)
	{
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.type = type;
		this.accountId = accountId;
		this.title = title;
		this.email = email;
		this.phone = phone;
		this.isActive = isActive;
		this.type = type;
		this.isPhoneConfirmed = isPhoneConfirmed;
		this.isSignatureRequired = isSignatureRequired;
	}
}
