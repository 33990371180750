import { ApiServiceDocumentKindEnum } from "@rmp/core/api/types/service/document/ApiServiceDocumentKindEnum";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import { COUNTERPARTY_SIGNATURE_REQUIRED_SERVICE_DOCUMENT_TYPES } from "@rmp/core/constants/service";

export function useServiceDocuments() {
	const isApplicableServiceTypeForFetchConstitutionalDocuments = (serviceType: ServiceTypeEnum) => {
		const applicableServiceTypes = [
			ServiceTypeEnum.FLOATING_CRANE_SERVICE,
			ServiceTypeEnum.DREDGING_WORKS_SERVICE,
			ServiceTypeEnum.SNO_INSTALLATION_SERVICE,
			ServiceTypeEnum.GEODETIC_HYDROGRAPHIC_SERVICE
		];
		
		return applicableServiceTypes.includes(serviceType);
	};
	
	
	const isSignatureRequiredForDocumentType = (type: ApiServiceDocumentKindEnum) => {		
		return COUNTERPARTY_SIGNATURE_REQUIRED_SERVICE_DOCUMENT_TYPES.includes(type);
	};
	
	return { isSignatureRequiredForDocumentType, isApplicableServiceTypeForFetchConstitutionalDocuments };
}
