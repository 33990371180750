import { CounterpartyTypeEnum } from "@rmp/core/types/CounterpartyTypeEnum";
import { IsEnum } from "class-validator";

export default class ApiCounterpartyBase {
	@IsEnum(CounterpartyTypeEnum)
	type: CounterpartyTypeEnum;

	constructor(
		type: CounterpartyTypeEnum = CounterpartyTypeEnum.UNKNOWN,
	)
	{
		this.type = type;
	}
}
