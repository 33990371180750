import ApiCounterpartyBase from "@rmp/core/api/types/counterparty/apiCounterpartyBase";
import { CounterpartyTypeEnum } from "@rmp/core/types/CounterpartyTypeEnum";

export default class ApiPhysicalPersonCounterparty extends ApiCounterpartyBase {
	inn: string;
	snils: string | null;
	firstName: string;
	lastName: string;
	middleName: string;
	description: string;

	constructor(
		type: CounterpartyTypeEnum = CounterpartyTypeEnum.PHYSICAL_PERSON,
		inn: string = "",
		snils: string = "",
		firstName: string = "",
		lastName: string = "",
		middleName: string = "",
		description: string = "")
	{
		super(type);

		this.inn = inn;
		this.snils = snils;
		this.firstName = firstName;
		this.lastName = lastName;
		this.middleName = middleName;
		this.description = description;
	}
}
