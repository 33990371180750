import { parseISO } from "date-fns";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";
import { ApiPierServiceApplication } from "@rmp/core/api/types/service/pier/apiPierServiceApplication";
import { ServiceApplicationPier, ServiceApplicationPierMapper } from "@rmp/core/types/services/pier/serviceApplicationPier";
import { PropertyServiceApplicationBase } from "@rmp/core/types/services/base/propertyServiceApplicationBase";
import { ApiServiceWorkflowState } from "@rmp/core/api/types/service/apiServiceWorkflowState";

export interface PierServiceApplication extends PropertyServiceApplicationBase {
	workflowState: ApiServiceWorkflowState;
	from: Date;
	till: Date;
	startDestinationShortName: string;
	endDestinationShortName: string;
	startDestinationId: string;
	endDestinationId: string;
	counterpartyBoatId: string;
	requestedPiers: ServiceApplicationPier[];
	assignedPiers: ServiceApplicationPier[];
}

export class PierServiceApplicationMapper {
	static map(source: ApiPierServiceApplication): PierServiceApplication {
		return {
			...source,
			createdAt: parseISO(source.createdAt),
			updatedAt: parseISO(source.updatedAt),
			from: parseISO(source.from),
			till: parseISO(source.till),
			type: ServiceMapperProfile.mapServiceType(source.type),
			requestedPiers: source.requestedPiers.map(x => ServiceApplicationPierMapper.map(x)),
			assignedPiers: source.assignedPiers.map(x => ServiceApplicationPierMapper.map(x))
		};
	}
}
