import { defineStore } from "pinia";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import { useServiceStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/service";
import { useTugboatServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication/services/tugboat";
import NotImplementedException from "@rmp/core/exceptions/notImplementedException";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import { useCrewboatServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication/services/crewboat";
import { useDepartmentStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/department";
import { usePortStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/port";
import { useAgreementStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/agreement";
import { useBoatStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/boat";
import { useTugboatStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/tugboat";
import { useTariffStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/tariff";
import { useRouteStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/route";
import { usePropertyCostStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/propertyCost";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useCrewboatStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/crewboat";
import { usePierServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication/services/pier";
import { usePierStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/pier";
import { usePropertyConfirmationStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/propertyConfirmation";
import router from "@rmp/counterparty/router";
import { useDredgingServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication/services/dredging";
import { useDocumentsStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/documents";
import { useConfirmationStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/confirmation";
import { useGeodeticHydrographicServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication/services/geodeticHydrographic";
import { useSnoInstallationServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication/services/snoInstallation";
import { useInformationServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication/services/information";
import { useInformationTariffStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/informationTariff";
import { useTimeStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/time";
import { useInformationCostStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/informationCost";
import { useInformationConfirmationStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/informationConfirmation";
import ServiceApplicationApiFacade from "@rmp/counterparty/api/service/facade";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";
import { ApiServiceTypeEnum } from "@rmp/core/types/services/ApiServiceTypeEnum";
import { ApiTugboatServiceApplication } from "@rmp/core/api/types/service/tugboat/apiTugboatServiceApplication";
import { TugboatServiceApplicationMapper } from "@rmp/core/types/services/tugboat/tugboatServiceApplication";
import { ApiCrewboatServiceApplication } from "@rmp/core/api/types/service/crewboat/apiCrewboatServiceApplication";
import { CrewboatServiceApplicationMapper } from "@rmp/core/types/services/crewboat/crewboatServiceApplication";
import { ServiceApplicationType } from "@rmp/core/types/services/ServiceApplicationType";
import { PierServiceApplicationMapper } from "@rmp/core/types/services/pier/pierServiceApplication";
import { ApiPierServiceApplication } from "@rmp/core/api/types/service/pier/apiPierServiceApplication";
import { ApiInformationServiceApplication } from "@rmp/core/api/types/service/information/apiInformationServiceApplication";
import { InformationServiceApplicationMapper } from "@rmp/core/types/services/information/informationServiceApplication";
import { ApiDredgingServiceApplication } from "@rmp/core/api/types/service/dredging/apiDredgingServiceApplication";
import { DredgingServiceApplicationMapper } from "@rmp/core/types/services/dredging/dredgingServiceApplication";
import {
	ApiGeodeticHydrographicServiceApplication
} from "@rmp/core/api/types/service/geodeticHydrographic/apiGeodeticHydrographicServiceApplication";
import {
	GeodeticHydrographicServiceApplicationMapper
} from "@rmp/core/types/services/geodeticHydrographic/geodeticHydrographicServiceApplication";
import { ApiSnoInstallationServiceApplication } from "@rmp/core/api/types/service/snoIntallation/apiSnoInstallationServiceApplication";
import { SnoInstallationServiceApplicationMapper } from "@rmp/core/types/services/snoInstallation/snoInstallationServiceApplication";
import { useFloatingCraneServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication/services/floatingCrane";
import { useFloatingCraneStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/floatingCrane";
import { FloatingCraneServiceApplicationMapper } from "@rmp/core/types/services/floatingCrane/floatingCraneServiceApplication";
import { ApiFloatingCraneServiceApplication } from "@rmp/core/api/types/service/floatingCrane/apiFloatingCraneServiceApplication";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceApplicationApiFacade = new ServiceApplicationApiFacade(abortService);

export interface ServiceApplicationState extends PageState {
	currentStep: ServiceApplicationStepTypeEnum;
	copiedService: ServiceApplicationType;
}

const getDefaultState = (): ServiceApplicationState => {
	return {
		...page.getDefaultPageState(),
		currentStep: ServiceApplicationStepTypeEnum.SERVICE,
		copiedService: {} as ServiceApplicationType
	};
};

export const useServiceApplicationStore = defineStore({
	id: "service-application",
	state: (): ServiceApplicationState => getDefaultState(),
	getters: {
		...page.getters,
		service() {
			
		},
		steps(): ServiceApplicationStepTypeEnum[] {
			const { selectedServiceType } = useServiceStepStore();
			
			switch (selectedServiceType) {
				case ServiceTypeEnum.TUGBOAT_SERVICE:
				{
					const { steps } = useTugboatServiceApplicationStore();
					return steps;
				}
				case ServiceTypeEnum.CREWBOAT_SERVICE:
				{
					const { steps } = useCrewboatServiceApplicationStore();
					return steps;
				}
				case ServiceTypeEnum.PIER_SERVICE:
				{
					const { steps } = usePierServiceApplicationStore();
					return steps;
				}
				case ServiceTypeEnum.FLOATING_CRANE_SERVICE:
				{
					const { steps } = useFloatingCraneServiceApplicationStore();
					return steps;
				}
				case ServiceTypeEnum.DREDGING_WORKS_SERVICE:
				{
					const { steps } = useDredgingServiceApplicationStore();
					return steps;
				}
				case ServiceTypeEnum.SNO_INSTALLATION_SERVICE:
				{
					const { steps } = useSnoInstallationServiceApplicationStore();
					return steps;
				}
				case ServiceTypeEnum.GEODETIC_HYDROGRAPHIC_SERVICE:
				{
					const { steps } = useGeodeticHydrographicServiceApplicationStore();
					return steps;
				}
				case ServiceTypeEnum.INFORMATION_SERVICE:
				{
					const { steps } = useInformationServiceApplicationStore();
					return steps;
				}
				default:
					throw new NotImplementedException("service");
			}
		},
		nextStepAvailable(state): boolean {
			switch (state.currentStep) {
				case ServiceApplicationStepTypeEnum.SERVICE:
				{
					// TODO сделать base стор для шагов
					const { completed } = useServiceStepStore();
					return completed;
				}
				case ServiceApplicationStepTypeEnum.DEPARTMENT:
				{
					const { completed } = useDepartmentStepStore();
					return completed;
				}
				case ServiceApplicationStepTypeEnum.PORT:
				{
					const { completed } = usePortStepStore();
					return completed;
				}
				case ServiceApplicationStepTypeEnum.AGREEMENT:
				{
					const { completed } = useAgreementStepStore();
					return completed;
				}
				case ServiceApplicationStepTypeEnum.BOAT:
				{
					const { completed } = useBoatStepStore();
					return completed;
				}
				case ServiceApplicationStepTypeEnum.TUGBOAT:
				{
					const { completed } = useTugboatStepStore();
					return completed;
				}
				case ServiceApplicationStepTypeEnum.PIER:
				{
					const { completed } = usePierStepStore();
					return completed;
				}
				case ServiceApplicationStepTypeEnum.FLOATING_CRANE:
				{
					const { completed } = useFloatingCraneStepStore();
					return completed;
				}
				case ServiceApplicationStepTypeEnum.TARIFF:
				{
					const { completed } = useTariffStepStore();
					return completed;
				}
				case ServiceApplicationStepTypeEnum.INFORMATION_TARIFF:
				{
					const { completed } = useInformationTariffStepStore();
					return completed;
				}
				case ServiceApplicationStepTypeEnum.ROUTE:
				{
					const { completed } = useRouteStepStore();
					return completed;
				}
				case ServiceApplicationStepTypeEnum.TIME:
				{
					const { completed } = useTimeStepStore();
					return completed;
				}
				case ServiceApplicationStepTypeEnum.PROPERTY_COST:
				{
					const { completed } = usePropertyCostStepStore();
					return completed;
				}
				case ServiceApplicationStepTypeEnum.INFORMATION_COST:
				{
					const { completed } = useInformationCostStepStore();
					return completed;
				}
				case ServiceApplicationStepTypeEnum.PROPERTY_CONFIRMATION:
				{
					const { completed } = usePropertyConfirmationStepStore();
					return completed;
				}
				case ServiceApplicationStepTypeEnum.INFORMATION_CONFIRMATION:
				{
					const { completed } = useInformationConfirmationStepStore();
					return completed;
				}
				case ServiceApplicationStepTypeEnum.CREWBOAT:
				{
					const { completed } = useCrewboatStepStore();
					return completed;
				}
				case ServiceApplicationStepTypeEnum.DOCUMENTS:
				{
					const { completed } = useDocumentsStepStore();
					return completed;
				}
				case ServiceApplicationStepTypeEnum.CONFIRMATION:
				{
					const { completed } = useConfirmationStepStore();
					return completed;
				}
				default:
					throw new NotImplementedException("nextStepAvailable");
			}
		},
		prevStepAvailable(): boolean {
			return this.currentStepIndex !== 0;
		},
		currentStepIndex(): number {
			return this.steps.indexOf(this.currentStep);
		}
		// prevStepAvailable
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			const serviceId = router.currentRoute.query.copy;
			const serviceType = router.currentRoute.query.type as ApiServiceTypeEnum | undefined;
			
			if(serviceId && serviceType) {
				serviceApplicationApiFacade.configure(ServiceMapperProfile.mapServiceType(serviceType));
				await this.fetchCopiedService(serviceId as string);
			}
		},
		async fetchCopiedService(serviceId: string) {
			try {
				let apiService = await serviceApplicationApiFacade.getService(serviceId);
				
				switch (apiService.type) {
					case ApiServiceTypeEnum.TUGBOAT_SERVICE:
						this.copiedService = TugboatServiceApplicationMapper.map(apiService as ApiTugboatServiceApplication);
						break;
					case ApiServiceTypeEnum.CREWBOAT_SERVICE:
						this.copiedService = CrewboatServiceApplicationMapper.map(apiService as ApiCrewboatServiceApplication);
						break;
					case ApiServiceTypeEnum.PIER_SERVICE:
						this.copiedService = PierServiceApplicationMapper.map(apiService as ApiPierServiceApplication);
						break;
					case ApiServiceTypeEnum.FLOATING_CRANE_SERVICE:
						this.copiedService = FloatingCraneServiceApplicationMapper.map(apiService as ApiFloatingCraneServiceApplication);
						break;
					case ApiServiceTypeEnum.INFORMATION_SERVICE:
						this.copiedService = InformationServiceApplicationMapper.map(apiService as ApiInformationServiceApplication);
						break;
					case ApiServiceTypeEnum.DREDGING_WORKS_SERVICE:
						this.copiedService = DredgingServiceApplicationMapper.map(apiService as ApiDredgingServiceApplication);
						break;
					case ApiServiceTypeEnum.GEODETIC_HYDROGRAPHIC_SERVICE:
						this.copiedService =
							GeodeticHydrographicServiceApplicationMapper.map(apiService as ApiGeodeticHydrographicServiceApplication);
						break;
					case ApiServiceTypeEnum.SNO_INSTALLATION_SERVICE:
						this.copiedService =
							SnoInstallationServiceApplicationMapper.map(apiService as ApiSnoInstallationServiceApplication);
						break;
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		moveToNextStep() {
			this.currentStep = this.steps[this.currentStepIndex + 1];
		},
		moveToPrevStep() {
			this.currentStep = this.steps[this.currentStepIndex - 1];
		}
	}
});
