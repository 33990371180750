import { ApiVatEnum } from "@rmp/core/api/types/vat/ApiVatEnum";
import { i18n } from "@rmp/core/plugins";


export const vatEnumToText = (vat: ApiVatEnum) => {
	switch (vat) {
		case ApiVatEnum.Vat0:
			return `${i18n.tc("content.vat")} 0%`;
		case ApiVatEnum.Vat10:
			return `${i18n.tc("content.vat")} 10%`;
		case ApiVatEnum.Vat20:
			return `${i18n.tc("content.vat")} 20%`;
		case ApiVatEnum.NoVat:
			return i18n.tc("content.withoutVat");
	}
};

export const vatEnumToPercents = (vat: ApiVatEnum) => {
	switch (vat) {
		case ApiVatEnum.Vat0:
		case ApiVatEnum.NoVat:
			return `0%`;
		case ApiVatEnum.Vat10:
			return `10%`;
		case ApiVatEnum.Vat20:
			return `20%`;
	}
};

export const getApproximateCostTextByVat = (vat: ApiVatEnum) => {
	if(!vat) return;
	
	if(vat === ApiVatEnum.NoVat || vat ===  ApiVatEnum.Vat0)
		return i18n.tc("service.tariff.approximateCost");
	
	return i18n.tc("service.tariff.approximateCostWithVat"); 
};

export const getCostTextByVat = (vat: ApiVatEnum) => {
	if(!vat) return;
	
	if(vat === ApiVatEnum.NoVat || vat ===  ApiVatEnum.Vat0)
		return i18n.tc("service.tariff.price");
	
	return i18n.tc("service.tariff.costWithVat"); 
};
