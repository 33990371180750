import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useServicesBreadcrumb } from "@rmp/counterparty/stores/services/composables/useServicesBreadcrumb";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/counterparty/router";
import { RouteNames } from "@rmp/counterparty/router/routes";
import { DredgingServiceController } from "@rmp/counterparty/api/service/dredging";
import {
	useSignDredgingServiceDocumentsBreadcrumb
} from "@rmp/counterparty/stores/uploadServiceDocuments/dredging/composables/useSignDredgingServiceDocumentsBreadcrumb";
import { DredgingServiceApplication, DredgingServiceApplicationMapper } from "@rmp/core/types/services/dredging/dredgingServiceApplication";
import { useDredgingServiceBreadcrumb } from "@rmp/counterparty/stores/serviceDetails/dredging/composables/useDredgingServiceBreadcrumb";
import { DredgingServiceWorkflowStateType } from "@rmp/core/types/services/dredging/DredgingServiceWorkflowStateType";
import DredgingServiceApplicationWorkflowService from "@rmp/core/services/serviceApplication/dredgingServiceApplicationWorkflowService";
import { ApiDredgingServiceTransitionCodeEnum } from "@rmp/core/api/types/service/dredging/ApiDredgingServiceTransitionCodeEnum";
import {
	BaseSignServiceDocumentsState,
	useBaseSignServiceDocumentsStore
} from "@rmp/counterparty/stores/composables/baseSignServiceDocuments";
import { formatServiceTitle } from "@rmp/core/utils/formatting";
import { ServiceDocument, ServiceDocumentMapper } from "@rmp/core/types/services/serviceDocument";
import { ServiceController } from "@rmp/counterparty/api/service";

const abortService = new AbortService();
const page = usePageStore(abortService);

const dredgingServiceController = new DredgingServiceController(abortService);
const serviceController = new ServiceController(abortService);

const baseSignServiceDocumentsStore = useBaseSignServiceDocumentsStore<ServiceDocument>({
	abortService,
	controller: serviceController,
	mapper: ServiceDocumentMapper
});

const dredgingWorkflowService = new DredgingServiceApplicationWorkflowService();

export interface SignDredgingServiceDocumentsState extends PageState, BaseSignServiceDocumentsState<ServiceDocument> {
	service: DredgingServiceApplication;
}

const getDefaultState = (): SignDredgingServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...baseSignServiceDocumentsStore.getDefaultState(),
		service: {} as DredgingServiceApplication
	};
};

export const useSignDredgingServiceDocumentsStore = defineStore({
	id: "sign-dredging-service-documents",
	state: (): SignDredgingServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...baseSignServiceDocumentsStore.getters,
		breadcrumbs(state: SignDredgingServiceDocumentsState) {
			return [
				useServicesBreadcrumb(),
				useDredgingServiceBreadcrumb(state.title, { id: state.service.id }),
				useSignDredgingServiceDocumentsBreadcrumb()
			];
		},
		targetState(state: SignDredgingServiceDocumentsState) {
			if(!state.service.workflowState) return;
			
			switch (state.service.workflowState.alias) {
				case DredgingServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE:
					return DredgingServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY;
				case DredgingServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE:
					return DredgingServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY;
				case DredgingServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY:
				case DredgingServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY:
					return;
				default:
					throw new Error();
			}
		}
	},
	actions: {
		...page.actions,
		...baseSignServiceDocumentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			
			await Promise.all([
				this.fetchServiceApplication(id),
				this.fetchDocuments(id),
				this.verifySigningAuthority()
			]);
		},
		async fetchServiceApplication(serviceId: string) {
			try {
				let serviceApplication = await dredgingServiceController.getService(serviceId);
				
				this.service = DredgingServiceApplicationMapper.map(serviceApplication);
				this.title = formatServiceTitle(this.service.type, this.service.applicationNumber);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async approve() {
			this.approving = true;
			
			try {
				await dredgingServiceController.updateStatusByTransition(this.id!,
					dredgingWorkflowService.getTransition(this.service.workflowState.alias,
						this.targetState!) as ApiDredgingServiceTransitionCodeEnum);
				
				this.service.workflowState.alias = this.targetState!;
				
				alertService.addSuccess(AlertKeys.DOCUMENTS_SENT);
				
				await router.push({ name: RouteNames.DREDGING_SERVICE, params: { id: this.id } });
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.approving = false;
			}
		}
	}
});
