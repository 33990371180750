import AbortService from "@rmp/core/services/abortService";
import { urls } from "@rmp/counterparty/api/config/urls";
import BaseCounterpartyController from "@rmp/counterparty/api/base/baseCounterpartyController";
import { ApiDepartmentPersisted } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { ApiAgreementPersisted } from "@rmp/core/api/types/agreement/apiAgreement";
import { ApiPortPersisted } from "@rmp/core/api/types/divisions/port/apiPort";
import { ApiTariff } from "@rmp/core/api/types/service/tariffs/apiTariff";
import { ApiTariffTotalAmount } from "@rmp/core/api/types/service/apiTariffTotalAmount";
import { ServiceControllerBase } from "@rmp/counterparty/api/service/serviceControllerBase";
import { ApiCreatePropertyServiceApplicationBase } from "@rmp/core/api/types/service/base/apiCreatePropertyServiceApplicationBase";
import { ApiExpectedCost } from "@rmp/core/api/types/service/apiExpectedCost";
import NotImplementedException from "@rmp/core/exceptions/notImplementedException";
import { ApiInformationExpectedCostRequest } from "@rmp/core/api/types/service/information/apiInformationExpectedCostRequest";
import { ApiInformationServiceApplication } from "@rmp/core/api/types/service/information/apiInformationServiceApplication";
import { ApiInformationServiceTransitionCodeEnum } from "@rmp/core/api/types/service/information/ApiInformationServiceTransitionCodeEnum";
import {
	ApiGetInformationTariffTotalAmountRequest
} from "@rmp/core/api/types/service/information/apiGetInformationTariffTotalAmountRequest";
import { ApiCreateInformationApplication } from "@rmp/core/api/types/service/information/apiCreateInformationApplication";

export class InformationServiceController extends BaseCounterpartyController implements ServiceControllerBase {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getService = async (serviceId: string) => {
		const { informationServiceApplication } = await this.get<{ informationServiceApplication: ApiInformationServiceApplication }>(urls.service.information.$serviceId,
			{ serviceId });
		return informationServiceApplication;
	};
	
	getAgreements = async (departmentId: string) => {
		return await this.get<ApiAgreementPersisted[]>(urls.service.information.department.$departmentId.agreement, { departmentId });
	};
	
	getDepartments = async () => {
		return await this.get<ApiDepartmentPersisted[]>(urls.service.information.department);
	};
	
	getDepartmentPorts = async (departmentId: string) => {
		return await this.get<ApiPortPersisted[]>(urls.service.information.department.$departmentId.port, { departmentId });
	};
	
	getTariffs = async () => {
		throw new NotImplementedException("getTariffs");
	};
	
	calculateTariffCost = async () => {
		throw new NotImplementedException("calculateTariffCost");
	};
	
	getInformationTariffs = async (departmentId: string) => {
		return await this.get<ApiTariff[]>(urls.service.information.department.$departmentId.tariff, { departmentId });
	};
	
	calculateInformationTariffCost = async (departmentId: string, tariffId: string, payload: ApiGetInformationTariffTotalAmountRequest) => {
		const [data] = await this.post<ApiTariffTotalAmount[]>(urls.service.information.department.$departmentId.tariff.$tariffId.calculate,
			{ departmentId, tariffId }, payload);
		return data;
	};
	
	updateStatusByTransition = async (serviceId: string, code: ApiInformationServiceTransitionCodeEnum, reason?: string) => {
		return await this.post<void>(urls.service.information.$serviceId.$code,
			{ serviceId, code },
			{ rejectionTransition: { reason } });
	};
	
	createApplication = async (request: ApiCreateInformationApplication) => {
		return await this.post<{ id: string }>(urls.service.information, {}, request);
	};
	
	createDraftApplication = async (request: ApiCreatePropertyServiceApplicationBase) => {
		throw new NotImplementedException("createDraftApplication");
	};
	
	calculateExpectedCost = async (payload: ApiInformationExpectedCostRequest) => {
		return await this.post<ApiExpectedCost>(urls.service.information.expectedCost.calculate, {}, payload);
	};
}
