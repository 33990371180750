import BaseServiceApplicationWorkflowService from "@rmp/core/services/serviceApplication/baseServiceApplicationWorkflowService";
import { GeodeticHydrographicServiceWorkflowStateType } from "@rmp/core/types/services/geodeticHydrographic/GeodeticHydrographicServiceWorkflowStateType";
import { ApiGeodeticHydrographicServiceTransitionCodeEnum } from "@rmp/core/api/types/service/geodeticHydrographic/ApiGeodeticHydrographicServiceTransitionCodeEnum";

export default class GeodeticHydrographicServiceApplicationWorkflowService extends BaseServiceApplicationWorkflowService {
	transitions = new Map<GeodeticHydrographicServiceWorkflowStateType, GeodeticHydrographicServiceWorkflowStateType[]>([
		[GeodeticHydrographicServiceWorkflowStateType.NEW, [GeodeticHydrographicServiceWorkflowStateType.DRAFT]],
		[
			GeodeticHydrographicServiceWorkflowStateType.DRAFT,
			[GeodeticHydrographicServiceWorkflowStateType.SENT, GeodeticHydrographicServiceWorkflowStateType.CANCELED]
		],
		[
			GeodeticHydrographicServiceWorkflowStateType.SENT,
			[
				GeodeticHydrographicServiceWorkflowStateType.APPROVED_BY_NGO_SERVICE_EMPLOYEE,
				GeodeticHydrographicServiceWorkflowStateType.REJECTED,
				GeodeticHydrographicServiceWorkflowStateType.CANCELED
			]
		],
		[
			GeodeticHydrographicServiceWorkflowStateType.APPROVED_BY_NGO_SERVICE_EMPLOYEE,
			[
				GeodeticHydrographicServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE,
				GeodeticHydrographicServiceWorkflowStateType.REJECTED
			]
		],
		[
			GeodeticHydrographicServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE,
			[
				GeodeticHydrographicServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE,
				GeodeticHydrographicServiceWorkflowStateType.REJECTED
			]
		],
		[
			GeodeticHydrographicServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE,
			[
				GeodeticHydrographicServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY,
				GeodeticHydrographicServiceWorkflowStateType.REJECTED
			]
		],
		[
			GeodeticHydrographicServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY,
			[GeodeticHydrographicServiceWorkflowStateType.PROVIDING, GeodeticHydrographicServiceWorkflowStateType.REJECTED]
		],
		[GeodeticHydrographicServiceWorkflowStateType.PROVIDING, [GeodeticHydrographicServiceWorkflowStateType.PROVIDED]],
		[
			GeodeticHydrographicServiceWorkflowStateType.PROVIDED,
			[GeodeticHydrographicServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED]
		],
		[
			GeodeticHydrographicServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED,
			[
				GeodeticHydrographicServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE,
				GeodeticHydrographicServiceWorkflowStateType.PROVIDED
			]
		],
		[
			GeodeticHydrographicServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE,
			[
				GeodeticHydrographicServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY,
				GeodeticHydrographicServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED
			]
		],
		[
			GeodeticHydrographicServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY,
			[GeodeticHydrographicServiceWorkflowStateType.COMPLETED]
		]
	]);
	
	transitionNames = new Map<string, ApiGeodeticHydrographicServiceTransitionCodeEnum>([
		[
			[GeodeticHydrographicServiceWorkflowStateType.NEW, GeodeticHydrographicServiceWorkflowStateType.DRAFT].join(),
			ApiGeodeticHydrographicServiceTransitionCodeEnum.CREATE
		],
		[
			[GeodeticHydrographicServiceWorkflowStateType.DRAFT, GeodeticHydrographicServiceWorkflowStateType.SENT].join(),
			ApiGeodeticHydrographicServiceTransitionCodeEnum.SEND
		],
		[
			[
				GeodeticHydrographicServiceWorkflowStateType.SENT,
				GeodeticHydrographicServiceWorkflowStateType.APPROVED_BY_NGO_SERVICE_EMPLOYEE
			].join(),
			ApiGeodeticHydrographicServiceTransitionCodeEnum.APPROVE
		],
		[
			[
				GeodeticHydrographicServiceWorkflowStateType.APPROVED_BY_NGO_SERVICE_EMPLOYEE,
				GeodeticHydrographicServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE
			].join(),
			ApiGeodeticHydrographicServiceTransitionCodeEnum.APPROVE
		],
		[
			[
				GeodeticHydrographicServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE,
				GeodeticHydrographicServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE
			].join(),
			ApiGeodeticHydrographicServiceTransitionCodeEnum.INITIALLY_SIGN
		],
		[
			[
				GeodeticHydrographicServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE,
				GeodeticHydrographicServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY
			].join(),
			ApiGeodeticHydrographicServiceTransitionCodeEnum.INITIALLY_SIGN
		],
		[
			[
				GeodeticHydrographicServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY,
				GeodeticHydrographicServiceWorkflowStateType.PROVIDING
			].join(),
			ApiGeodeticHydrographicServiceTransitionCodeEnum.START
		],
		[
			[GeodeticHydrographicServiceWorkflowStateType.PROVIDING, GeodeticHydrographicServiceWorkflowStateType.PROVIDED].join(),
			ApiGeodeticHydrographicServiceTransitionCodeEnum.FINISH
		],
		[
			[
				GeodeticHydrographicServiceWorkflowStateType.PROVIDED,
				GeodeticHydrographicServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED
			].join(),
			ApiGeodeticHydrographicServiceTransitionCodeEnum.ATTACH
		],
		[
			[
				GeodeticHydrographicServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED,
				GeodeticHydrographicServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE
			].join(),
			ApiGeodeticHydrographicServiceTransitionCodeEnum.FINALLY_SIGN
		],
		[
			[
				GeodeticHydrographicServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE,
				GeodeticHydrographicServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY
			].join(),
			ApiGeodeticHydrographicServiceTransitionCodeEnum.FINALLY_SIGN
		],
		[
			[
				GeodeticHydrographicServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY,
				GeodeticHydrographicServiceWorkflowStateType.COMPLETED
			].join(),
			ApiGeodeticHydrographicServiceTransitionCodeEnum.COMPLETE
		],
		[
			[GeodeticHydrographicServiceWorkflowStateType.DRAFT, GeodeticHydrographicServiceWorkflowStateType.CANCELED].join(),
			ApiGeodeticHydrographicServiceTransitionCodeEnum.CANCEL
		],
		[
			[GeodeticHydrographicServiceWorkflowStateType.SENT, GeodeticHydrographicServiceWorkflowStateType.REJECTED].join(),
			ApiGeodeticHydrographicServiceTransitionCodeEnum.REJECT
		],
		[
			[
				GeodeticHydrographicServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE,
				GeodeticHydrographicServiceWorkflowStateType.REJECTED
			].join(),
			ApiGeodeticHydrographicServiceTransitionCodeEnum.REJECT
		],
		[
			[
				GeodeticHydrographicServiceWorkflowStateType.APPROVED_BY_NGO_SERVICE_EMPLOYEE,
				GeodeticHydrographicServiceWorkflowStateType.REJECTED
			].join(),
			ApiGeodeticHydrographicServiceTransitionCodeEnum.REJECT
		],
		[
			[
				GeodeticHydrographicServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE,
				GeodeticHydrographicServiceWorkflowStateType.REJECTED
			].join(),
			ApiGeodeticHydrographicServiceTransitionCodeEnum.REJECT
		],
		[
			[
				GeodeticHydrographicServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY,
				GeodeticHydrographicServiceWorkflowStateType.REJECTED
			].join(),
			ApiGeodeticHydrographicServiceTransitionCodeEnum.REJECT
		],
		[
			[
				GeodeticHydrographicServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE,
				GeodeticHydrographicServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED
			].join(),
			ApiGeodeticHydrographicServiceTransitionCodeEnum.RESET
		],
		[
			[
				GeodeticHydrographicServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED,
				GeodeticHydrographicServiceWorkflowStateType.PROVIDED
			].join(),
			ApiGeodeticHydrographicServiceTransitionCodeEnum.RESET
		]
	]);
}
