import { urls } from "@rmp/counterparty/api/config/urls";
import AbortService from "@rmp/core/services/abortService";
import { ApiPierPersisted } from "@rmp/core/api/types/enterpriseProperty/apiPier";
import { ApiTugboatPersisted } from "@rmp/core/api/types/enterpriseProperty/apiTugboat";
import BaseCounterpartyController from "@rmp/counterparty/api/base/baseCounterpartyController";
import { ApiCrewboatPersisted } from "@rmp/core/api/types/enterpriseProperty/apiCrewboat";
import { ApiFloatingCranePersisted } from "@rmp/core/api/types/enterpriseProperty/apiFloatingCrane";

export class EnterprisePropertyController extends BaseCounterpartyController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getTugboat = async (id: string) => {
		return await this.get<ApiTugboatPersisted>(urls.enterprise.property.tugboat.$id, { id });
	};
	
	getCrewboat = async (id: string) => {
		return await this.get<ApiCrewboatPersisted>(urls.enterprise.property.crewboat.$id, { id });
	};
	
	getFloatingCrane = async (id: string) => {
		return await this.get<ApiFloatingCranePersisted>(urls.enterprise.property.floatingCrane.$id, { id });
	};
	
	getPier = async (id: string) => {
		return await this.get<ApiPierPersisted>(urls.enterprise.property.pier.$id, { id });
	};
}
