import { ApiServiceApplicationInformationTariff } from "@rmp/core/api/types/service/information/apiServiceApplicationInformationTariff";
import { ApiTariffTotalAmount } from "@rmp/core/api/types/service/apiTariffTotalAmount";

export interface ServiceApplicationInformationTariff {
	amount: ApiTariffTotalAmount;
	tariffId: string;
	units: number;
}

export class ServiceApplicationInformationTariffMapper {
	static map(source: ApiServiceApplicationInformationTariff): ServiceApplicationInformationTariff {
		return {
			...source
		};
	}
}
