import { IMiddleware } from "@rmp/core/types/core/middleware";
import { Route } from "vue-router/types/router";
import SecurityService from "@rmp/core/services/auth/securityService";
import { BaseRouteNames } from "@rmp/core/router/routeNames";

const securityService = new SecurityService();

export default class AuthenticationMiddleware implements IMiddleware {
	constructor() {
		
	}
	
	async invoke(to: Route, from: Route) {
		try {
			let access_token = await securityService.getToken();
			
			if(!access_token) {
				try {
					await securityService.signIn({}, { url: to.fullPath });
				} catch (e) {
					return { name: BaseRouteNames.ERROR_INTERNAL_SERVER_ERROR };
				}
			}
		} catch (e) {
			console.error(e);
		}
	}
}
