import { parseISO } from "date-fns";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";
import { ServiceApplicationBase } from "@rmp/core/types/services/base/serviceApplicationBase";
import { ApiFloatingCraneServiceWorkflowState } from "@rmp/core/api/types/service/floatingCrane/apiFloatingCraneServiceWorkflowState";
import { ApiFloatingCraneServiceApplication } from "@rmp/core/api/types/service/floatingCrane/apiFloatingCraneServiceApplication";
import { ApiServiceApplicationFloatingCrane } from "@rmp/core/api/types/service/floatingCrane/apiServiceApplicationFloatingCrane";

export interface FloatingCraneServiceApplication extends ServiceApplicationBase {
	workflowState: ApiFloatingCraneServiceWorkflowState;
	requestedFloatingcranes: ApiServiceApplicationFloatingCrane[];
	assignedFloatingcranes: ApiServiceApplicationFloatingCrane[];
}

export class FloatingCraneServiceApplicationMapper {
	static map(source: ApiFloatingCraneServiceApplication): FloatingCraneServiceApplication {
		return {
			...source,
			createdAt: parseISO(source.createdAt),
			updatedAt: parseISO(source.updatedAt),
			type: ServiceMapperProfile.mapServiceType(source.type)
		};
	}
}
