export const urls = {
	own: {
		path: "own",
		counterparty: {
			path: "counterparty",
			document: {
				path: "document", 
				$id: {
					path: ":id",
					toggleArchived: {
						path: "toggle-archived"
					}
				}
			}
		}
	},
	counterparty: {
		path: "counterparty",
		$id: {
			path: ":id",
			document: {
				path: "document"
			}
		}
	},
	role: {
		path: "role",
		officeEmployee: {
			path: "office-employee"
		}
	},
	staff: {
		path: "staff",
		office: {
			path: "office",
			$officeId: {
				path: ":officeId",
				employee: {
					path: "employee"
				}
			},
			search: {
				path: "search"
			}
		}
	}
};
