import { splitFullName } from "@rmp/core/utils/formatting";
import { ApiLegalEntityCounterpartyHead } from "@rmp/core/api/types/counterparty/apiLegalEntityCounterpartyHead";

export interface AgreementLegalEntityCounterpartyHead extends ApiLegalEntityCounterpartyHead {
	lastName: string;
	middleName: string;
	firstName: string;
}

export class AgreementLegalEntityCounterpartyHeadMapper {
	static map(source: ApiLegalEntityCounterpartyHead): AgreementLegalEntityCounterpartyHead {
		return {
			...source,
			...splitFullName(source.fullName)
		};
	}
}
