import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useAppStore } from "@rmp/core/stores/appStore";
import { useCrewboatServiceStore } from "@rmp/counterparty/stores/serviceDetails/crewboat";
import { cloneDeep, groupBy, uniqBy } from "lodash";
import { ApiAgreementPersisted } from "@rmp/core/api/types/agreement/apiAgreement";
import { ApiPort } from "@rmp/core/api/types/divisions/port/apiPort";
import { AgreementController } from "@rmp/counterparty/api/agreement";
import { ApiDepartment } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { EnterprisePropertyController } from "@rmp/counterparty/api/enterpriseProperty";
import { ApiTariff } from "@rmp/core/api/types/service/tariffs/apiTariff";
import { DivisionController } from "@rmp/counterparty/api/division";
import { CounterpartyBoatController } from "@rmp/counterparty/api/boat";
import { ApiCrewboatPersisted } from "@rmp/core/api/types/enterpriseProperty/apiCrewboat";
import { CrewboatServiceApplication } from "@rmp/core/types/services/crewboat/crewboatServiceApplication";
import { ServiceApplicationPropertyTariff } from "@rmp/core/types/services/serviceApplicationPropertyTariff";
import { ServiceApplicationTariff, ServiceApplicationTariffMapper } from "@rmp/core/types/services/serviceApplicationTariff";
import { parseWorkingTime } from "@rmp/core/utils/dates";
import { ServiceController } from "@rmp/counterparty/api/service";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";
import { ApiCounterpartyBoat } from "@rmp/core/api/types/counterpartyBoat/apiCounterpartyBoat";
import { ServiceApplicationCrewboat } from "@rmp/core/types/services/crewboat/serviceApplicationCrewboat";

const abortService = new AbortService();
const page = usePageStore(abortService);

const agreementController = new AgreementController(abortService);
const divisionController = new DivisionController(abortService);
const enterprisePropertyController = new EnterprisePropertyController(abortService);
const boatController = new CounterpartyBoatController(abortService);
const serviceController = new ServiceController(abortService);

export interface ServiceDetailsGeneralState extends PageState {
	id: string | null;
	service: CrewboatServiceApplication;
	agreement: ApiAgreementPersisted;
	port: ApiPort;
	department: ApiDepartment;
	boat: ApiCounterpartyBoat | undefined;
	tariffs: ServiceApplicationPropertyTariff[];
	preferredTariffs: ServiceApplicationPropertyTariff[];
}

const getDefaultState = (): ServiceDetailsGeneralState => {
	return {
		...page.getDefaultPageState(),
		id: null,
		service: {} as CrewboatServiceApplication,
		agreement: {} as ApiAgreementPersisted,
		department: {} as ApiDepartment,
		boat: {} as ApiCounterpartyBoat,
		port: {} as ApiPort,
		tariffs: [],
		preferredTariffs: []
	};
};

export const useCrewboatServiceGeneralStore = defineStore({
	id: "crewboat-service-details-general",
	state: (): ServiceDetailsGeneralState => getDefaultState(),
	getters: {
		...page.getters,
		breadcrumbs() {
			const { breadcrumbs } = useCrewboatServiceStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.fetch();
		},
		async fetch() {
			try {
				let { service } = useCrewboatServiceStore();
				
				const tasks: Promise<any>[] = [
					agreementController.getAgreement(service.agreementId),
					divisionController.getDepartment(service.divisionId),
					divisionController.getPort(service.portId),
					serviceController.getTariffs(ServiceMapperProfile.mapApiServiceType(service.type))
				];
				
				const crewboatTasks: Promise<ApiCrewboatPersisted>[] = [];
				
				uniqBy([...service.assignedCrewBoats, ...service.requestedCrewBoats], x => x.crewboatId).forEach(x => {
					crewboatTasks.push(enterprisePropertyController.getCrewboat(x.crewboatId));
				});
				
				const [
					agreement,
					{ division: department },
					{ division: port },
					{ tariffs }
				] = await Promise.all([...tasks, crewboatTasks]);
				
				let counterpartyBoat = null;
				
				if (service.counterpartyBoatId)
					counterpartyBoat = await boatController.getBoat(service.counterpartyBoatId);
				
				const counterpartyBoatPersisted = counterpartyBoat?.counterpartyBoatPersisted;
				
				const crewboats = await Promise.all(crewboatTasks);
				
				let propertyGroups = groupBy(service.assignedCrewBoats, x => x.crewboatId);
				let preferredGroups = groupBy(service.requestedCrewBoats, x => x.crewboatId);
				
				
				const prepareTariffs = (groups: { [key: string]: ServiceApplicationCrewboat[] }) => {
					let result: ServiceApplicationPropertyTariff[] = [];
					
					for (const [propertyId, items] of Object.entries(groups)) {
						let internalTariffs: ServiceApplicationTariff[] = [];
						
						for (const item of items) {
							let tariff = (tariffs as ApiTariff[]).find(x => x.id === item.tariffId);
							if(!tariff || !item.totalAmount) continue;
							
							internalTariffs.push({
								...ServiceApplicationTariffMapper.map(tariff),
								value: item.workingTime ? parseWorkingTime(item.workingTime) : 0,
								amount: item.totalAmount.amount,
								vatIncluded: item.totalAmount.vatIncluded
							});
						}
						
						result.push({
							propertyName: crewboats.find(y => y.id === propertyId)!.crewboat.name,
							tariffs: internalTariffs
						});
					}
					
					return result;
				};
				
				this.agreement = agreement;
				this.department = department;
				this.port = port;
				this.tariffs = prepareTariffs(propertyGroups);
				this.preferredTariffs = prepareTariffs(preferredGroups);
				this.boat = counterpartyBoatPersisted?.counterpartyBoat;
				this.service = cloneDeep(service);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		}
	}
});
