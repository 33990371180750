import { AgreementApplicationStepState } from "@rmp/counterparty/stores/agreementApplication/steps/shared/agreementApplicationStepState";
import { defineStore } from "pinia";
import { AgreementApplicationStepTypeEnum } from "@rmp/counterparty/stores/agreementApplication/steps/shared/AgreementApplicationStepTypeEnum";
import AbortService from "@rmp/core/services/abortService";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import AgreementApplicationDepartmentMapper from "@rmp/counterparty/stores/agreementApplication/steps/department/mapper";
import { AgreementApplicationDepartment } from "@rmp/counterparty/stores/agreementApplication/steps/department/types/agreementApplicationDepartment";
import ServiceApplicationApiFacade from "@rmp/counterparty/api/service/facade";
import { useServiceStepStore } from "@rmp/counterparty/stores/agreementApplication/steps/service";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import { useServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication";
import { ApiDepartmentPersisted } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { AgreementWorkflowStateType } from "@rmp/core/types/agreement/AgreementWorkflowStateType";


const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceApplicationApiFacade = new ServiceApplicationApiFacade(abortService);

const mapper = new AgreementApplicationDepartmentMapper();

export interface DepartmentStepState extends AgreementApplicationStepState, PageState {
	departments: AgreementApplicationDepartment[];
	selectedDepartmentId: string;
}

const getDefaultState = (): DepartmentStepState => {
	return {
		...page.getDefaultPageState(),
		selectedDepartmentId: "",
		departments: [],
		stepType: AgreementApplicationStepTypeEnum.DEPARTMENT
	};
};

export const useDepartmentStepStore = defineStore({
	id: "agreement-application-department-step",
	state: (): DepartmentStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state): boolean {
			return !!state.selectedDepartmentId;
		},
		selectedDepartment(state): AgreementApplicationDepartment | undefined {
			return state.departments.find(x => x.id === state.selectedDepartmentId);
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			const { selectedServiceType } = useServiceStepStore();
			serviceApplicationApiFacade.configure(selectedServiceType);
			
			await this.fetch();
			this.selectDefaultDepartment();
		},
		async fetch() {
			try {
				let departments = await serviceApplicationApiFacade.getDepartments();
				
				const tasks = departments.map(x => serviceApplicationApiFacade.getAgreements(x.id));
				let agreementsByDepartment = await Promise.all(tasks);
				
				this.departments = departments.map((x, i) => mapper.mapDepartment(x, agreementsByDepartment[i].filter(y => [
					AgreementWorkflowStateType.SENT,
					AgreementWorkflowStateType.NEGOTIATED,
					AgreementWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE,
					AgreementWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY,
					AgreementWorkflowStateType.ACTIVE
				].includes(y.agreement.state.code)).length));
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		selectDefaultDepartment() {
			let [department] = this.departments;
			
			this.selectedDepartmentId = department.id;
		}
	}
});
