import { ref, watch } from "vue";
import { FileMeta } from "@rmp/core/types/storage/fileMeta";
import { cloneDeep } from "lodash";
import { defineStore, storeToRefs } from "pinia";
import {
	BaseSignServiceDocumentsState,
	useBaseSignServiceDocumentsStore
} from "@rmp/counterparty/stores/composables/baseSignServiceDocuments";
import { BaseDocument } from "@rmp/core/types/services/baseDocument";

export function useSignServiceDocuments(useStore: SignServiceDocumentsStoreDefinition, requiredTypes: string[]) {
	const store = useStore();
	const { trySaveSignature } = store;
	const { documents } = storeToRefs(store);
	
	const sendDocumentsConfirmDialogOpened = ref(false);
	const metaList = ref<(FileMeta & { signatureRequired: boolean })[]>([]);
	
	const handleUpdateMeta = async (meta: FileMeta) => {
		const document = documents.value.find(x => x.fileId === meta.id)!;
		const signatureRequired = requiredTypes.includes(document.documentKind);
		
		metaList.value.push({ ...cloneDeep(meta), signatureRequired });
	};
	const handleSaveSignature = async (id: string, signature: string) => {
		const res = await trySaveSignature.call(store, id, signature);
		const hasNotSignedDocument = metaList.value.some(x => x.signatureRequired && x.signatures.length !== 2);
		
		if(res && !hasNotSignedDocument)
			sendDocumentsConfirmDialogOpened.value = true;
	};
	
	watch(documents, () => {
		metaList.value = [];
	});
	
	return { handleUpdateMeta, handleSaveSignature, sendDocumentsConfirmDialogOpened };
}

let storeComposables: ReturnType<typeof useBaseSignServiceDocumentsStore<BaseDocument>>;
export type SignServiceDocumentsStoreDefinition =
	ReturnType<typeof defineStore<string, BaseSignServiceDocumentsState<BaseDocument>, typeof storeComposables.getters, typeof storeComposables.actions & {
		[key: string]: any
	}>>;
