import { render, staticRenderFns } from "./RmpFormCardBlock.vue?vue&type=template&id=30a8b12c&scoped=true&"
import script from "./RmpFormCardBlock.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RmpFormCardBlock.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30a8b12c",
  null
  
)

export default component.exports