import urls from "@rmp/core/api/config/urls";
import { plainToInstance } from "class-transformer";
import ApiAccountProfilePersistedBase from "@rmp/core/api/types/account/apiAccountProfilePersistedBase";
import BaseController from "@rmp/core/api/shared/baseController";
import AbortService from "@rmp/core/services/abortService";

export class AccountController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	getAccountProfiles = async (): Promise<ApiAccountProfilePersistedBase[]> => {
		let { profiles } = await this.client.get<{ profiles: [] }>(urls.account.getProfiles);

		return plainToInstance(ApiAccountProfilePersistedBase, profiles);
	};
}
