import { mimeToExtensionsMap } from "@rmp/core/utils/mimeToExtensionsMap";
import InvalidArgumentException from "@rmp/core/exceptions/invalidArgumentException";

export const mimeToExtension = (mime: string) => {
	if(!mimeToExtensionsMap.has(mime))
		throw new InvalidArgumentException("mime", mime);
	
	const [extension] = mimeToExtensionsMap.get(mime)!;
	return extension;
}

export const getFileNameWithExtension = (name: string, mimeType: string) => {
	const ext = mimeToExtension(mimeType);
	return ext ? `${name}.${ext}` : name;
}
