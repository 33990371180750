import AbortService from "@rmp/core/services/abortService";
import { urls } from "@rmp/counterparty/api/config/urls";
import BaseCounterpartyController from "@rmp/counterparty/api/base/baseCounterpartyController";
import { ApiDepartmentPersisted } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { ApiPortPersisted } from "@rmp/core/api/types/divisions/port/apiPort";
import { ServiceControllerBase } from "@rmp/counterparty/api/service/serviceControllerBase";
import NotImplementedException from "@rmp/core/exceptions/notImplementedException";
import { ApiCreateFloatingCraneApplication } from "@rmp/core/api/types/service/floatingCrane/apiCreateFloatingCraneApplication";
import { ApiFloatingCraneServiceApplication } from "@rmp/core/api/types/service/floatingCrane/apiFloatingCraneServiceApplication";
import {
	ApiFloatingCraneServiceTransitionCodeEnum
} from "@rmp/core/api/types/service/floatingCrane/ApiFloatingCraneServiceTransitionCodeEnum";
import { ApiFloatingCranePersisted } from "@rmp/core/api/types/enterpriseProperty/apiFloatingCrane";
import { ApiCreateServiceDocument } from "@rmp/core/api/types/service/document/apiCreateServiceDocument";

export class FloatingCraneServiceController extends BaseCounterpartyController implements ServiceControllerBase {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getService = async (serviceId: string) => {
		const { serviceApplication } = await this.get<{
			serviceApplication: ApiFloatingCraneServiceApplication
		}>(urls.service.floatingCrane.$serviceId, { serviceId });
		return serviceApplication;
	};
	
	getDepartments = async () => {
		return await this.get<ApiDepartmentPersisted[]>(urls.service.floatingCrane.department);
	};
	
	getDepartmentPorts = async (departmentId: string) => {
		return await this.get<ApiPortPersisted[]>(urls.service.floatingCrane.department.$departmentId.port, { departmentId });
	};
	
	createApplication = async (request: ApiCreateFloatingCraneApplication) => {
		return await this.post<{ id: string }>(urls.service.floatingCrane, {}, request);
	};
	
	saveDocuments = async (serviceId: string, documents: ApiCreateServiceDocument[]) => {
		return await this.put<void>(urls.service.floatingCrane.$serviceId.document, { serviceId }, documents);
	};
	
	updateStatusByTransition = async (serviceId: string, code: ApiFloatingCraneServiceTransitionCodeEnum, reason?: string) => {
		return await this.post<void>(urls.service.floatingCrane.$serviceId.$code,
			{ serviceId, code },
			{ rejectionTransition: { reason } });
	};
	
	getFloatingCranes = async (departmentId: string) => {
		return await this.get<ApiFloatingCranePersisted[]>(urls.service.floatingCrane.department.$departmentId.floatingCrane,
			{ departmentId });
	};
	
	getAgreements = async () => {
		throw new NotImplementedException("getAgreements");
	};
	
	getTariffs = async () => {
		throw new NotImplementedException("getTariffs");
	};
	
	calculateTariffCost = async () => {
		throw new NotImplementedException("calculateTariffCost");
	};
	
	createDraftApplication = async () => {
		throw new NotImplementedException("createDraftApplication");
	};
	
	calculateExpectedCost = async () => {
		throw new NotImplementedException("calculateExpectedCost");
	};
}
