import { Account } from "@rmp/core/types/account/account";
import { ApiPersistedBase } from "@rmp/core/api/types/base/apiPersistedBase";

export interface ApiAccount {
	login: string;
	email: string;
	isActive: boolean;
	isEmailConfirmed: boolean;
}

export class ApiAccountMapper {
	static map(source: Account): ApiAccount {
		return {
			...source
		};
	}
	
	static getEmpty(): ApiAccount {
		return {
			login: "",
			email: "",
			isActive: false,
			isEmailConfirmed: false
		};
	}
}

export interface ApiAccountPersisted extends ApiPersistedBase {
	account: ApiAccount;
}

export class ApiAccountPersistedMapper {
	static getEmpty(): ApiAccountPersisted {
		return {
			id: "",
			createdAt: "",
			updatedAt: "",
			account: ApiAccountMapper.getEmpty()
		};
	}
}
