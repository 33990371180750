import ApiCounterpartyBoatDocumentPackage from "@rmp/core/api/types/counterparty/boatApplication/apiCounterpartyBoatDocumentPackage";
import { ApiCounterpartyBoatApplication } from "@rmp/core/api/types/counterpartyBoatApplication/apiCounterpartyBoatApplication";
import { BoatApplicationStatusEnum } from "@rmp/core/types/boatApplication/BoatApplicationStatusEnum";

export interface BoatApplication {
	name: string;
	callSign: string;
	imo: string;
	mmsi?: string;
	flag: string;
	type: string;
	grt: string;
	exclusionGRT?: string;
	nrt: string;
	deadWeight: string;
	length: string;
	width: string;
	height: string;
	power: string;
	portOfRegistry: string;
	numberOfRegistry: string;
	yearOfBuilding?: string;
	precipitationMaxValue?: string;
	calculatedGRT?: string;
	status: BoatApplicationStatusEnum;
	shipowner: string;
	documentsPackage: ApiCounterpartyBoatDocumentPackage;
}

export class BoatApplicationMapper {
	static map(source: ApiCounterpartyBoatApplication): BoatApplication {
		return {
			...source.applicationBoat,
			grt: source.applicationBoat.grt.toString(),
			exclusionGRT:  source.applicationBoat.exclusionGRT?.toString(),
			nrt: source.applicationBoat.nrt.toString(),
			deadWeight: source.applicationBoat.deadWeight.toString(),
			length: source.applicationBoat.length.toString(),
			width: source.applicationBoat.width.toString(),
			height: source.applicationBoat.height.toString(),
			power: source.applicationBoat.power.toString(),
			yearOfBuilding: source.applicationBoat.yearOfBuilding?.toString(),
			calculatedGRT: source.applicationBoat?.calculatedGRT?.toString(),
			precipitationMaxValue: source.applicationBoat?.precipitationMaxValue?.toString(),
			status: source.status as BoatApplicationStatusEnum
		};
	}
	
	static getEmpty(): BoatApplication {
		return {
			name: "",
			callSign: "",
			imo: "",
			mmsi: "",
			flag: "",
			type: "",
			grt: "",
			exclusionGRT: "",
			nrt: "",
			deadWeight: "",
			length: "",
			width: "",
			height: "",
			power: "",
			portOfRegistry: "",
			numberOfRegistry: "",
			yearOfBuilding: "",
			calculatedGRT: "",
			precipitationMaxValue: "",
			status: BoatApplicationStatusEnum.CREATED, // TODO неправильно было status переносить в модель заявки
			shipowner: "",
			documentsPackage: new ApiCounterpartyBoatDocumentPackage()
		};
	}
}
