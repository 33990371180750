import { AgreementApplicationStepState } from "@rmp/counterparty/stores/agreementApplication/steps/shared/agreementApplicationStepState";
import { defineStore } from "pinia";
import {
	AgreementApplicationStepTypeEnum
} from "@rmp/counterparty/stores/agreementApplication/steps/shared/AgreementApplicationStepTypeEnum";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import { FileMetaHelper } from "@rmp/core/api/types/storage/fileMeta";
import ApiFileMeta from "@rmp/core/api/types/storage/apiFileMeta";
import { AgreementFileMeta } from "@rmp/counterparty/stores/agreementApplication/steps/documents/types/agreementFileMeta";
import { AgreementDocumentKindEnum } from "@rmp/core/types/agreement/agreementDocumentKindEnum";
import { CounterpartyDocumentsController } from "@rmp/core/api/counterpartyDocument";
import { ApiGetCounterpartyDocumentsParameters } from "@rmp/core/api/types/documents/apiGetCounterpartyDocumentsParameters";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { isNil } from "lodash";
import { parseISO } from "date-fns";
import { ApiCounterpartyDocumentType } from "@rmp/core/api/types/documents/ApiCounterpartyDocumentType";
import { StorageController } from "@rmp/core/api/storage";
import ApiFile from "@rmp/core/api/types/storage/apiFile";
import { getFileNameWithExtension } from "@rmp/core/utils/mime";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";

const abortService = new AbortService();
const counterpartyDocumentsController = new CounterpartyDocumentsController(abortService);
const storageController = new StorageController(abortService);

const page = usePageStore(abortService);

export interface DocumentsStepState extends AgreementApplicationStepState, PageState {
	customDocuments: object[];
	organizationCharter: AgreementFileMeta;
	foundationAgreement: AgreementFileMeta;
	hasUsedMyDocumentsSection: boolean
}

const getDefaultState = (): DocumentsStepState => {
	return {
		...page.getDefaultPageState(),
		stepType: AgreementApplicationStepTypeEnum.DOCUMENTS,
		customDocuments: [],
		organizationCharter: {} as AgreementFileMeta,
		foundationAgreement: {} as AgreementFileMeta,
		hasUsedMyDocumentsSection: false
	};
};

export const useDocumentsStepStore = defineStore({
	id: "agreement-application-documents-step",
	state: (): DocumentsStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return state.initialized;
		},
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			await this.fetch();
		},
		async fetch() {
			try {
				const findActiveDocument = (type: ApiCounterpartyDocumentType) => {
					return documents.find(x => {
						const expired = isNil(x.document.expirationDate) ?
							false :
							(parseISO(x.document.expirationDate).getTime() <= new Date().getTime());
						const necessaryType = x.document.documentType === type;
						
						return !expired && necessaryType;
					});
				};
				const prepareFileMeta = (meta: ApiFileMeta, name: string, type: AgreementDocumentKindEnum) => {
					const fileName = getFileNameWithExtension(name, meta.mimeType);
					return { ...FileMetaHelper.map(meta), documentKind: type, name: fileName };
				};
				const prepareFile = (name: string, blob: Blob, type: AgreementDocumentKindEnum) => {
					const fileName = getFileNameWithExtension(name, blob.type);
					return new ApiFile(new File([blob], fileName), fileName, type, blob.type, "");
				};
				
				
				// Получение и поиск последних активных документов
				let { documents } = await counterpartyDocumentsController.getDocuments("", {
					isArchived: false,
					sortField: "creationDate",
					sortingDirection: SortingOrderTypeEnum.DESCENDING
				} as ApiGetCounterpartyDocumentsParameters, AuthorizationScopeType.OWN);

				let organizationCharterTasks = [];
				let foundationAgreementTasks = [];
				const organizationCharter = findActiveDocument(ApiCounterpartyDocumentType.STATUTE_OF_THE_ORGANIZATION);
				const foundationAgreement = findActiveDocument(ApiCounterpartyDocumentType.MEMORANDUM_OF_ASSOCIATION);
				
				
				// Получение файлов документов
				if(organizationCharter)
					organizationCharterTasks.push(storageController.getFile(organizationCharter.document.fileId));
				if(foundationAgreement)
					foundationAgreementTasks.push(storageController.getFile(foundationAgreement.document.fileId));
				
				await Promise.all([...organizationCharterTasks, ...foundationAgreementTasks]);
				const [organizationCharterBlob] = await Promise.all(organizationCharterTasks);
				const [foundationAgreementBlob] = await Promise.all(foundationAgreementTasks);
				
				organizationCharterTasks = [];
				foundationAgreementTasks = [];
				
				
				// Загрузка документов в виде новых файлов
				if(organizationCharter)
					organizationCharterTasks.push(storageController.createTemperFile(prepareFile(organizationCharter.document.name,
						organizationCharterBlob,
						AgreementDocumentKindEnum.ORGANIZATION_CHARTER)));
				if(foundationAgreement)
					foundationAgreementTasks.push(storageController.createTemperFile(prepareFile(foundationAgreement.document.name,
						foundationAgreementBlob,
						AgreementDocumentKindEnum.FOUNDATION_AGREEMENT)));
				
				await Promise.all([...organizationCharterTasks, ...foundationAgreementTasks]);
				const [organizationCharterMeta] = await Promise.all(organizationCharterTasks);
				const [foundationAgreementMeta] = await Promise.all(foundationAgreementTasks);
				
				
				// Установка мета-данных "новых" документов
				if(organizationCharter)
					this.organizationCharter =
						prepareFileMeta(organizationCharterMeta,
							organizationCharter.document.name,
							AgreementDocumentKindEnum.ORGANIZATION_CHARTER);
				if(foundationAgreement)
					this.foundationAgreement =
						prepareFileMeta(foundationAgreementMeta,
							foundationAgreement.document.name,
							AgreementDocumentKindEnum.FOUNDATION_AGREEMENT);
				
				this.hasUsedMyDocumentsSection = !!this.organizationCharter.name || !!this.foundationAgreement.name
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		async setOrganizationCharter(meta: ApiFileMeta | null) {
			if(!meta) {
				this.organizationCharter = {} as AgreementFileMeta;
				return;
			}
			
			this.organizationCharter = {
				...FileMetaHelper.map(meta!),
				documentKind: AgreementDocumentKindEnum.ORGANIZATION_CHARTER
			} as AgreementFileMeta;
		},
		async setFoundationAgreement(meta: ApiFileMeta | null) {
			if(!meta) {
				this.foundationAgreement = {} as AgreementFileMeta;
				return;
			}
			
			this.foundationAgreement = {
				...FileMetaHelper.map(meta!),
				documentKind: AgreementDocumentKindEnum.FOUNDATION_AGREEMENT
			} as AgreementFileMeta;
		},
		async addCustomDocument(meta: ApiFileMeta) {
			this.customDocuments.push(FileMetaHelper.map(meta));
		},
		async removeCustomDocument(i: number) {
			this.customDocuments.splice(i, 1);
		}
	}
});
