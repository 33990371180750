import { ApiFileMetaSignature } from "@rmp/core/api/types/storage/apiFileMeta";
import { Certificate, CertificateMapper } from "@rmp/core/types/storage/certificate";
import { parseISO } from "date-fns";

export interface FileMetaSignature {
	signature: string;
	createdAt: Date;
	certificates: Certificate[];
}

export class FileMetaSignatureMapper {
	static map(source: ApiFileMetaSignature): FileMetaSignature {
		return {
			...source,
			createdAt: parseISO(source.createdAt),
			certificates: source.certificates.map(x => CertificateMapper.map(x))
		};
	}
}
