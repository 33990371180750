import { RouteNames } from "@rmp/counterparty/router/routes";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import { AgreementApplicationStepTypeEnum } from "@rmp/counterparty/stores/agreementApplication/steps/shared/AgreementApplicationStepTypeEnum";
import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";

export default {
	titles: {
		services: "Мои услуги"
	},
	subheaders: {
		feedback: "Отзыв",
		rateService: "Оцените услуги"
	},
	alerts: {
		serviceAgreementLimitAlmostExhausted: "На балансе отсутствуют необходимые для оказания услуг средства. Для формирования счета на оплату воспользуйтесь формой на странице договора.",
		agreementEndDateNotBeEarlierServiceStartDate: "Срок окончания договора не может предшествовать дате начала предоставления услуг. Рекомендуется заключить новый договор."
	},
	navigation: {
		breadcrumbs: {
			[RouteNames.ROOT]: "Главная",
			[RouteNames.COUNTERPARTY_DOCUMENTS]: "Мои документы",
			[RouteNames.BOAT_APPLICATIONS]: "Мой флот",
			[RouteNames.BOAT_APPLICATION_DETAILS_DOCUMENTS]: "Документы",
			[RouteNames.BOAT_APPLICATION_DETAILS_HISTORY]: "История",
			[RouteNames.BOAT_APPLICATION_DETAILS_COMMENTS]: "Комментарии",
			[RouteNames.BOAT_APPLICATION_CREATE]: "Новое судно",
			[RouteNames.SERVICES]: "Услуги",
			[RouteNames.PIER_SERVICE_DOCUMENTS]: "Документы",
			[RouteNames.PIER_SERVICE_HISTORY]: "История",
			[RouteNames.PIER_SERVICE_COMMENTS]: "Комментарии",
			[RouteNames.PIER_SERVICE_FEEDBACK]: "Отзыв",
			[RouteNames.PIER_SERVICE_UPLOAD_DOCUMENTS]: "Подписание отчетных документов",
			[RouteNames.TUGBOAT_SERVICE_DOCUMENTS]: "Документы",
			[RouteNames.TUGBOAT_SERVICE_HISTORY]: "История",
			[RouteNames.TUGBOAT_SERVICE_COMMENTS]: "Комментарии",
			[RouteNames.TUGBOAT_SERVICE_FEEDBACK]: "Отзыв",
			[RouteNames.TUGBOAT_SERVICE_UPLOAD_DOCUMENTS]: "Подписание отчетных документов",
			[RouteNames.CREWBOAT_SERVICE_DOCUMENTS]: "Документы",
			[RouteNames.CREWBOAT_SERVICE_HISTORY]: "История",
			[RouteNames.CREWBOAT_SERVICE_COMMENTS]: "Комментарии",
			[RouteNames.CREWBOAT_SERVICE_FEEDBACK]: "Отзыв",
			[RouteNames.CREWBOAT_SERVICE_UPLOAD_DOCUMENTS]: "Подписание отчетных документов",
			[RouteNames.DREDGING_SERVICE_DOCUMENTS]: "Документы",
			[RouteNames.DREDGING_SERVICE_HISTORY]: "История",
			[RouteNames.DREDGING_SERVICE_COMMENTS]: "Комментарии",
			[RouteNames.DREDGING_SERVICE_FEEDBACK]: "Отзыв",
			[RouteNames.DREDGING_SERVICE_SIGN_DOCUMENTS]: "Подписание документов",
			[RouteNames.FLOATING_CRANE_SERVICE_DOCUMENTS]: "Документы",
			[RouteNames.FLOATING_CRANE_SERVICE_HISTORY]: "История",
			[RouteNames.FLOATING_CRANE_SERVICE_COMMENTS]: "Комментарии",
			[RouteNames.FLOATING_CRANE_SERVICE_FEEDBACK]: "Отзыв",
			[RouteNames.FLOATING_CRANE_SERVICE_SIGN_DOCUMENTS]: "Подписание документов",
			[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_DOCUMENTS]: "Документы",
			[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_HISTORY]: "История",
			[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_COMMENTS]: "Комментарии",
			[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_FEEDBACK]: "Отзыв",
			[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_SIGN_DOCUMENTS]: "Подписание документов",
			[RouteNames.SNO_INSTALLATION_SERVICE_DOCUMENTS]: "Документы",
			[RouteNames.SNO_INSTALLATION_SERVICE_HISTORY]: "История",
			[RouteNames.SNO_INSTALLATION_SERVICE_COMMENTS]: "Комментарии",
			[RouteNames.SNO_INSTALLATION_SERVICE_FEEDBACK]: "Отзыв",
			[RouteNames.SNO_INSTALLATION_SERVICE_SIGN_DOCUMENTS]: "Подписание документов",
			[RouteNames.INFORMATION_SERVICE_DOCUMENTS]: "Документы",
			[RouteNames.INFORMATION_SERVICE_HISTORY]: "История",
			[RouteNames.INFORMATION_SERVICE_COMMENTS]: "Комментарии",
			[RouteNames.INFORMATION_SERVICE_FEEDBACK]: "Отзыв",
			[RouteNames.INFORMATION_SERVICE_SIGN_DOCUMENTS]: "Подписание отчетных документов",
			[RouteNames.AGREEMENTS]: "Мои договоры",
			[RouteNames.AGREEMENT_DETAILS_DOCUMENTS]: "Документы",
			[RouteNames.AGREEMENT_DETAILS_HISTORY]: "История",
			[RouteNames.AGREEMENT_DETAILS_COMMENTS]: "Комментарии",
			[RouteNames.AGREEMENT_DETAILS_SERVICES]: "Услуги",
			[RouteNames.AGREEMENT_SIGN_DOCUMENTS]: "Подписание договора"
		}
	},
	aliases: {
		serviceApplicationStep: {
			[ServiceApplicationStepTypeEnum.SERVICE]: "Услуги",
			[ServiceApplicationStepTypeEnum.DEPARTMENT]: "Управление",
			[ServiceApplicationStepTypeEnum.PORT]: "Порт",
			[ServiceApplicationStepTypeEnum.AGREEMENT]: "Договор",
			[ServiceApplicationStepTypeEnum.BOAT]: "Судно",
			[ServiceApplicationStepTypeEnum.TUGBOAT]: "Буксир",
			[ServiceApplicationStepTypeEnum.FLOATING_CRANE]: "Плавкран",
			[ServiceApplicationStepTypeEnum.CREWBOAT]: "Разъездное судно",
			[ServiceApplicationStepTypeEnum.PIER]: "Причал",
			[ServiceApplicationStepTypeEnum.TARIFF]: "Тариф",
			[ServiceApplicationStepTypeEnum.INFORMATION_TARIFF]: "Тариф",
			[ServiceApplicationStepTypeEnum.ROUTE]: "Время и место",
			[ServiceApplicationStepTypeEnum.TIME]: "Время",
			[ServiceApplicationStepTypeEnum.PROPERTY_COST]: "Стоимость",
			[ServiceApplicationStepTypeEnum.INFORMATION_COST]: "Стоимость",
			[ServiceApplicationStepTypeEnum.DOCUMENTS]: "Документы",
			[ServiceApplicationStepTypeEnum.PROPERTY_CONFIRMATION]: "Подтверждение",
			[ServiceApplicationStepTypeEnum.INFORMATION_CONFIRMATION]: "Подтверждение",
			[ServiceApplicationStepTypeEnum.CONFIRMATION]: "Подтверждение"
		},
		agreementApplicationStep: {
			[AgreementApplicationStepTypeEnum.SERVICE]: "Услуги",
			[AgreementApplicationStepTypeEnum.DEPARTMENT]: "Управление",
			[AgreementApplicationStepTypeEnum.COUNTERPARTY]: "Данные об организации",
			[AgreementApplicationStepTypeEnum.DOCUMENTS]: "Документы",
			[AgreementApplicationStepTypeEnum.CONFIRMATION]: "Формирование договора"
		}
	},
	service: {
		signDocuments: "Подписать документы",
		propertyTariff: {
			title: {
				preferred: {
					[ServiceTypeEnum.TUGBOAT_SERVICE]: "Данные о буксирах, запрошенных клиентом",
					[ServiceTypeEnum.CREWBOAT_SERVICE]: "Данные о разъездных судах, запрошенных клиентом",
					[ServiceTypeEnum.PIER_SERVICE]: "Данные о причалах, запрошенных клиентом"
				}
			}
		}
	}
};
