import { defineStore } from "pinia";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { AuthorizationController } from "@rmp/core/api/authorization";
import { ResetPasswordModeType } from "@rmp/core/stores/composables/resetPassword/types/resetPasswordModeType";
import { useUser } from "@rmp/core/composables/useUser";

const abortService = new AbortService();
const authorizationController = new AuthorizationController(abortService);

const page = usePageStore(abortService);
const form = useFormStore();

export interface ResetPasswordState extends PageState, FormState {
	password: string;
	mode: ResetPasswordModeType;
}

const getDefaultState = (): ResetPasswordState => {
	return {
		...page.getDefaultPageState(),
		...form.getDefaultState(),
		password: "",
		mode: ResetPasswordModeType.INITIAL
	};
};

export const useResetPasswordStore = defineStore({
	id: "reset-password",
	state: (): ResetPasswordState => getDefaultState(),
	getters: {
		...page.getters,
		...form.getters
	},
	actions: {
		...page.actions,
		...form.actions,
		async beforeInitialized() {
		},
		async save() {
			this.formSaving = true;

			try {
				const { signInSilent, user } = useUser();
				
				await authorizationController.setPassword(this.password, user.value.account.login);
				
				await signInSilent();
				
				this.mode = ResetPasswordModeType.SUCCESS;
			} catch (ex) {
				console.error(ex);
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.formSaving = false;
			}
		}
	}
});

