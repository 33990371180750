import NotImplementedException from "@rmp/core/exceptions/notImplementedException";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { defineStore, Store } from "pinia";

export interface ArchiveStatusState {
	archiveStatusUpdating: boolean;
	updatingArchiveStatusItemIds: string[];
}

export const useArchiveStatusStore = () => {
	type ArchiveStatusStore = Store<string, ArchiveStatusState, {}, { [key: string]: any, updateArchiveStatus(payload?: any): Promise<void> }>;
	
	const getDefaultState = (): ArchiveStatusState => {
		return {
			archiveStatusUpdating: false,
			updatingArchiveStatusItemIds: []
		};
	};
	
	const getters = {};
	
	const actions = {
		async tryUpdateArchiveStatus(this: ArchiveStatusStore, id?: string) {
			if(id)
				this.updatingArchiveStatusItemIds.push(id);
			else
				this.archiveStatusUpdating = true;
			
			try {
				await this.updateArchiveStatus(id);
				
				return true;
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
				
				return false;
			} finally {
				if(id)
					this.updatingArchiveStatusItemIds = this.updatingArchiveStatusItemIds.filter(x => x !== id);
				else
					this.archiveStatusUpdating = false;
			}
		},
		async updateArchiveStatus(this: ArchiveStatusState) {
			throw new NotImplementedException("updateArchiveStatus");
		}
	};
	
	return { getters, actions, getDefaultState };
};

let storeComposables: ReturnType<typeof useArchiveStatusStore>;
export type ArchiveStatusStoreDefinition =
	ReturnType<typeof defineStore<string, ArchiveStatusState, typeof storeComposables.getters, typeof storeComposables.actions & {
		[key: string]: any
	}>> | any;
