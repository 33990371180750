import { AgreementApplicationStepState } from "@rmp/counterparty/stores/agreementApplication/steps/shared/agreementApplicationStepState";
import { defineStore } from "pinia";
import { AgreementApplicationStepTypeEnum } from "@rmp/counterparty/stores/agreementApplication/steps/shared/AgreementApplicationStepTypeEnum";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { useDepartmentStepStore } from "@rmp/counterparty/stores/agreementApplication/steps/department";
import { useServiceStepStore } from "@rmp/counterparty/stores/agreementApplication/steps/service";
import { AgreementController } from "@rmp/counterparty/api/agreement";
import AbortService from "@rmp/core/services/abortService";
import ServiceApplicationApiFacade from "@rmp/counterparty/api/service/facade";
import { ApiPortPersisted } from "@rmp/core/api/types/divisions/port/apiPort";
import { ApiCreateAgreementApplication } from "@rmp/core/api/types/agreement/apiCreateAgreementApplication";
import { useDocumentsStepStore } from "@rmp/counterparty/stores/agreementApplication/steps/documents";
import { ApiCreateAgreementDocument } from "@rmp/core/api/types/agreement/apiCreateAgreementDocument";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";
import { RouteNames } from "@rmp/counterparty/router/routes";
import router from "@rmp/counterparty/router";
import ApiAgreementDocumentKindMapper from "@rmp/core/api/types/agreement/ApiAgreementDocumentKindEnum";
import InvalidBusinessOperationException from "@rmp/core/exceptions/invalidBusinessOperationException";
import { ApiAgreementTransitionCodeType } from "@rmp/core/api/types/agreement/ApiAgreementTransitionCodeType";

const abortService = new AbortService();

const agreementController = new AgreementController(abortService);
const serviceApplicationApiFacade = new ServiceApplicationApiFacade(abortService);

const page = usePageStore(abortService);

export interface ConfirmationStepState extends AgreementApplicationStepState, PageState {
	isSaving: boolean;
	isDraftSaving: boolean;
	ports: ApiPortPersisted[];
}

const getDefaultState = (): ConfirmationStepState => {
	return {
		...page.getDefaultPageState(),
		isSaving: false,
		isDraftSaving: false,
		ports: [],
		stepType: AgreementApplicationStepTypeEnum.CONFIRMATION
	};
};

export const useConfirmationStepStore = defineStore({
	id: "agreement-application-confirmation-step",
	state: (): ConfirmationStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return state.initialized;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			await this.fetch();
		},
		async fetch() {
			try {
				const { selectedServiceType } = useServiceStepStore();
				const { selectedDepartmentId } = useDepartmentStepStore();
				serviceApplicationApiFacade.configure(selectedServiceType);
				
				this.ports = await serviceApplicationApiFacade.getDepartmentPorts(selectedDepartmentId);
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		prepareAgreementApplication(): ApiCreateAgreementApplication {
			const { selectedServiceType } = useServiceStepStore();
			const { selectedDepartmentId } = useDepartmentStepStore();
			
			// TODO: customDocuments
			
			return {
				divisionId: selectedDepartmentId,
				serviceType: ServiceMapperProfile.mapApiServiceType(selectedServiceType),
			};
		},
		async save() {
			this.isSaving = true;
			
			try {
				const { organizationCharter, foundationAgreement } = useDocumentsStepStore();
				
				const documents = [organizationCharter, foundationAgreement].filter(x => x.id).map(x => ({
					documentKind: ApiAgreementDocumentKindMapper.mapApiAgreementDocumentKind(x.documentKind),
					tempFileId: x.id,
					title: x.name
				} as ApiCreateAgreementDocument))
				
				const { id } = await agreementController.createAgreementApplication(this.prepareAgreementApplication());
				
				const tasks: Promise<any>[] = [];
				
				documents.forEach(x => tasks.push(
					agreementController.assignAgreementDocument(id, x)
				))
				
				await Promise.all(tasks);
				
				// TODO
				let toDraft = await agreementController.changeAgreementStatus(id, ApiAgreementTransitionCodeType.CREATE);
				if(!toDraft.isSuccess)
					throw new InvalidBusinessOperationException("Не удалось перевести заявку в статус DRAFT", toDraft);
				
				const toSent = await agreementController.changeAgreementStatus(id, ApiAgreementTransitionCodeType.SEND);
				if(!toSent.isSuccess)
					throw new InvalidBusinessOperationException("Не удалось перевести заявку в статус SENT", toSent);
				
				alertService.addSuccess(AlertKeys.AGREEMENT_CREATED);
				
				await router.push({ name: RouteNames.AGREEMENT_DETAILS, params: { id } });
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.isSaving = false;
			}
		},
		async saveAsDraft() {
			// this.isDraftSaving = true;
			//
			// try {
			// 	const serviceApplication = await this.prepareAgreementApplication();
			//	
			// 	const { id } = await serviceApplicationApiFacade.createDraftApplication(serviceApplication);
			//	
			// 	await router.push({ name: RouteNames.TUGBOAT_SERVICE, params: { id } });
			//	
			// 	alertService.addInfo(AlertKeys.SERVICE_APPLICATION_CREATED);
			// } catch (error) {
			// 	console.error(error);
			// 	AlertHelper.handleGeneralRequestErrors(error);
			// } finally {
			// 	this.isDraftSaving = false;
			// }
		}
	}
});
