export const OID_CP_GOST_R3410_12_256 = "1.2.643.7.1.1.1.1";
export const OID_CP_GOST_R3410_12_512 = "1.2.643.7.1.1.1.2";
export const OID_CP_GOST_R3411_12_256_R3410 = "1.2.643.7.1.1.3.2";
export const OID_CP_GOST_R3411_12_512_R3410 = "1.2.643.7.1.1.3.3";
export const OID_CP_INN_LE = "1.2.643.100.4";
export const OID_CP_EMAIL = "1.2.840.113549.1.9.1";
export const OID_CP_LAST_NAME = "2.5.4.4";
export const OID_CP_FIRST_AND_PARTONYMIC_NAMES = "2.5.4.42";
export const OID_CP_ISSUER_NAME = "2.5.4.3";

export const CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256 = 101;
export const CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512 = 102;
	
