import {
	ServiceApplicationStepState
} from "@rmp/counterparty/stores/serviceApplication/steps/shared/serviceApplicationState";
import { defineStore } from "pinia";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import AbortService from "@rmp/core/services/abortService";
import { ServiceController } from "@rmp/counterparty/api/service";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";
import router from "@rmp/counterparty/router";
import { ApiServiceTypeEnum } from "@rmp/core/types/services/ApiServiceTypeEnum";

const abortService = new AbortService();
const serviceController = new ServiceController(abortService);

const page = usePageStore(abortService);

export interface ServiceStepState extends ServiceApplicationStepState, PageState {
	selectedServiceType: ServiceTypeEnum;
	availableServiceTypes: ServiceTypeEnum[];
}

const getDefaultState = (): ServiceStepState => {
	return {
		...page.getDefaultPageState(),
		stepType: ServiceApplicationStepTypeEnum.SERVICE,
		selectedServiceType: ServiceTypeEnum.TUGBOAT_SERVICE,
		availableServiceTypes: []
	};
};

export const useServiceStepStore = defineStore({
	id: "service-step",
	state: (): ServiceStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return !!state.selectedServiceType;
		},
		agreementRequired(state): boolean {
			return [
				ServiceTypeEnum.TUGBOAT_SERVICE, ServiceTypeEnum.CREWBOAT_SERVICE, ServiceTypeEnum.PIER_SERVICE,
				ServiceTypeEnum.INFORMATION_SERVICE
			].includes(state.selectedServiceType);
		},
		boatRequired(state): boolean {
			return [ServiceTypeEnum.TUGBOAT_SERVICE, ServiceTypeEnum.PIER_SERVICE].includes(state.selectedServiceType);
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			await this.fetch();
			this.selectDefaultService();
		},
		async fetch() {
			try {
				let serviceDefinitions = await serviceController.getDefinitions();
				
				this.availableServiceTypes =
					serviceDefinitions.filter(x => x.available).map(x => ServiceMapperProfile.mapServiceType(x.enum));
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		selectDefaultService() {
			if(!router.currentRoute.query.type) return;
			this.selectedServiceType = ServiceMapperProfile.mapServiceType(router.currentRoute.query.type as ApiServiceTypeEnum);
		}
	}
});
