import OfflineException from "@rmp/core/exceptions/offlineException";
import ServerUnavailableException from "@rmp/core/exceptions/serverUnavailableException";
import RequestCancelledException from "@rmp/core/exceptions/requestCancelledException";
import BadRequestException from "@rmp/core/exceptions/badRequestException";
import InvalidBusinessOperationException from "@rmp/core/exceptions/invalidBusinessOperationException";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";
import AccessForbiddenException from "@rmp/core/exceptions/accessForbiddenException";

export default class AlertHelper {
	static handleGeneralRequestErrors(error: Error) {
		switch (error.constructor) {
			case OfflineException:
				alertService.addError(AlertKeys.OFFLINE_REQUEST_ERROR);
				break;
			case ServerUnavailableException:
				alertService.addError(AlertKeys.SERVER_UNAVAILABLE_ERROR);
				break;
			case BadRequestException:
				alertService.addCustomError(error.message);
				break;
			case InvalidBusinessOperationException:
				alertService.addCustomError(error.message);
				break;
			case HttpNotFoundException:
				alertService.addError(AlertKeys.PAGE_NOT_FOUND);
				break;
			case AccessForbiddenException:
				alertService.addError(AlertKeys.ACCESS_FORBIDDEN);
				break;
			case RequestCancelledException:
				break;
			default:
				alertService.addError(AlertKeys.REQUEST_UNKNOWN_ERROR);
				break;
		}
	}
}
