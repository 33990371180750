import { ServiceWorkflowStateType } from "@rmp/core/types/services/ServiceWorkflowStateType";
import { DredgingServiceWorkflowStateType } from "@rmp/core/types/services/dredging/DredgingServiceWorkflowStateType";
import {
	GeodeticHydrographicServiceWorkflowStateType
} from "@rmp/core/types/services/geodeticHydrographic/GeodeticHydrographicServiceWorkflowStateType";
import { SnoInstallationServiceWorkflowStateType } from "@rmp/core/types/services/snoInstallation/SnoInstallationServiceWorkflowStateType";
import { InformationServiceWorkflowStateType } from "@rmp/core/types/services/information/InformationServiceWorkflowStateType";
import { ApiInformationServiceTransitionCodeEnum } from "@rmp/core/api/types/service/information/ApiInformationServiceTransitionCodeEnum";
import { ApiPropertyServiceTransitionCodeEnum } from "@rmp/core/api/types/service/ApiPropertyServiceTransitionCodeEnum";
import { ApiDredgingServiceTransitionCodeEnum } from "@rmp/core/api/types/service/dredging/ApiDredgingServiceTransitionCodeEnum";
import { ApiSnoInstallationServiceTransitionCodeEnum } from "@rmp/core/api/types/service/snoIntallation/ApiSnoInstallationServiceTransitionCodeEnum";
import { ApiGeodeticHydrographicServiceTransitionCodeEnum } from "@rmp/core/api/types/service/geodeticHydrographic/ApiGeodeticHydrographicServiceTransitionCodeEnum";
import { FloatingCraneServiceWorkflowStateType } from "@rmp/core/types/services/floatingCrane/FloatingCraneServiceWorkflowStateType";
import { ApiFloatingCraneServiceTransitionCodeEnum } from "@rmp/core/api/types/service/floatingCrane/ApiFloatingCraneServiceTransitionCodeEnum";

type WorkflowStateType =
	InformationServiceWorkflowStateType
	| SnoInstallationServiceWorkflowStateType
	| GeodeticHydrographicServiceWorkflowStateType
	| DredgingServiceWorkflowStateType
	| ServiceWorkflowStateType
	| FloatingCraneServiceWorkflowStateType;

type TransitionType =
	ApiPropertyServiceTransitionCodeEnum
	| ApiDredgingServiceTransitionCodeEnum
	| ApiSnoInstallationServiceTransitionCodeEnum
	| ApiGeodeticHydrographicServiceTransitionCodeEnum
	| ApiInformationServiceTransitionCodeEnum
	| ApiFloatingCraneServiceTransitionCodeEnum;

export default abstract class BaseServiceApplicationWorkflowService {
	abstract transitions: Map<WorkflowStateType, WorkflowStateType[]>;
	abstract transitionNames: Map<string, TransitionType>;
	
	checkTransition(currentStatus: WorkflowStateType, target: WorkflowStateType[] | WorkflowStateType) {
		const allowedStates = this.transitions.get(currentStatus);
		
		if(!allowedStates)
			return false;
		
		if(Array.isArray(target))
			return target.some(x => allowedStates.includes(x));
		else
			return allowedStates.includes(target);
	}
	
	getTransition(from: WorkflowStateType, to: WorkflowStateType) {
		const transition = this.transitionNames.get([from, to].join());
		
		if(!transition)
			throw new Error(`Невозможен переход из статуса ${from} в ${to}`);
		
		return transition;
	}
}
