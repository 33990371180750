import { ChatMessage } from "@rmp/core/types/chat/chatMessage";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { BaseChatController } from "@rmp/core/api/shared/baseChatController";
import ChatMapperProfile from "@rmp/core/api/mappers/chat";
import NotDefinedException from "@rmp/core/exceptions/notDefinedException";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";

const mapper = new ChatMapperProfile();

export interface ChatState {
	id: string | null;
	messages: ChatMessage[];
	isMessageCreating: boolean;
}
export interface ChatOptions {
	controller: BaseChatController;
}

export const useChatStore = (options: ChatOptions) => {
	const getDefaultState = (): ChatState => {
		return {
			id: null,
			messages: [],
			isMessageCreating: false
		};
	};
	
	const getters = {};
	
	const actions = {
		async initializeChatStore(this: ChatState, id: string | null) {
			if(!this.id)
				throw new NotDefinedException("id");
			
			try {
				let { messages } = await options.controller.getChatMessages(this.id!);
				
				this.messages = messages.map(x => mapper.mapToChatMessage(x));
			} catch (ex) {
				if(ex.constructor === HttpNotFoundException)
					return;
				
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async createChatMessage(this: ChatState, message: string) {
			if(!this.id)
				throw new NotDefinedException("id");
			
			this.isMessageCreating = true;
			
			try {
				let newMessage = await options.controller.createChatMessage(this.id!, message);
				
				this.messages.push(mapper.mapToChatMessage(newMessage));
				
				alertService.addSuccess(AlertKeys.CHAT_MESSAGE_SUCCESS_CREATED);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.isMessageCreating = false;
			}
		}
	};
	
	return { getters, actions, getDefaultState };
};
