import { ServiceApplicationStepState } from "@rmp/counterparty/stores/serviceApplication/steps/shared/serviceApplicationState";
import { defineStore } from "pinia";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/counterparty/router";
import { RouteNames } from "@rmp/counterparty/router/routes";
import { ServiceController } from "@rmp/counterparty/api/service";
import { useDepartmentStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/department";
import { useAgreementStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/agreement";
import { useRouteStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/route";
import { useBoatStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/boat";
import { usePortStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/port";
import { useTariffStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/tariff";
import { getHours, getMinutes, setHours, setMinutes } from "date-fns";
import ServiceApplicationApiFacade from "@rmp/counterparty/api/service/facade";
import { useServiceStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/service";
import { ApiCreatePropertyServiceApplicationBase } from "@rmp/core/api/types/service/base/apiCreatePropertyServiceApplicationBase";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import { ApiCreateCrewboatApplication } from "@rmp/core/api/types/service/crewboat/apiCreateCrewboatApplication";
import { ApiCreateTugboatApplication } from "@rmp/core/api/types/service/tugboat/apiCreateTugboatApplication";
import { ServiceWorkflowStateType } from "@rmp/core/types/services/ServiceWorkflowStateType";
import { ApiCreatePierApplication } from "@rmp/core/api/types/service/pier/apiCreatePierApplication";
import { useUserStore } from "@rmp/core/stores/user";
import { CounterpartyController } from "@rmp/core/api/counterparty";
import ApiLegalEntityCounterpartyPersisted from "@rmp/core/api/types/counterparty/apiLegalEntityCounterpartyPersisted";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";
import InvalidBusinessOperationException from "@rmp/core/exceptions/invalidBusinessOperationException";
import { ApiBaseServiceTransitionCodeEnum } from "@rmp/core/api/types/service/base/ApiInformationServiceTransitionCodeEnum";

const abortService = new AbortService();

const serviceApplicationApiFacade = new ServiceApplicationApiFacade(abortService);
const serviceController = new ServiceController(abortService);
const counterpartyController = new CounterpartyController(abortService);

const page = usePageStore(abortService);

export interface PropertyConfirmationStepState extends ServiceApplicationStepState, PageState {
	isSaving: boolean;
	isDraftSaving: boolean;
}

const getDefaultState = (): PropertyConfirmationStepState => {
	return {
		...page.getDefaultPageState(),
		isSaving: false,
		isDraftSaving: false,
		stepType: ServiceApplicationStepTypeEnum.PROPERTY_CONFIRMATION
	};
};

export const usePropertyConfirmationStepStore = defineStore({
	id: "property-confirmation-step",
	state: (): PropertyConfirmationStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return state.initialized;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			const { selectedServiceType } = useServiceStepStore();
			serviceApplicationApiFacade.configure(selectedServiceType);
		},
		async prepareBaseServiceApplication(): Promise<ApiCreatePropertyServiceApplicationBase> {
			const { profile } = useUserStore();
			const { selectedDepartmentId } = useDepartmentStepStore();
			const { selectedAgreementId } = useAgreementStepStore();
			const { startDestinationId, endDestinationId, startTime, startDate } = useRouteStepStore();
			const { selectedBoatId } = useBoatStepStore();
			const { selectedPortId } = usePortStepStore();
			
			const startDateTime = setMinutes(setHours(startDate!, getHours(startTime!)), getMinutes(startTime!));
			
			const counterparty = await counterpartyController.getCounterparty("",
				ApiLegalEntityCounterpartyPersisted,
				AuthorizationScopeType.OWN);
			
			
			return {
				agreementId: selectedAgreementId,
				initiatorCounterpartyEmployeeId: profile!.externalId,
				initiatorCounterpartyId: counterparty.id,
				startDestinationId,
				endDestinationId,
				counterpartyBoatId: selectedBoatId,
				divisionId: selectedDepartmentId,
				portId: selectedPortId,
				from: startDateTime!.toISOString(),
				till: ""
			};
		},
		async prepareServiceApplication() {
			const { selectedServiceType } = useServiceStepStore();
			const { requestedProperties } = useTariffStepStore();
			
			const baseApplication = await this.prepareBaseServiceApplication();
			
			switch (selectedServiceType) {
				case ServiceTypeEnum.TUGBOAT_SERVICE:
				{
					return {
						...baseApplication,
						requestedTugBoats: requestedProperties
					} as ApiCreateTugboatApplication;
				}
				
				case ServiceTypeEnum.CREWBOAT_SERVICE:
				{
					return {
						...baseApplication,
						requestedCrewBoats: requestedProperties
					} as ApiCreateCrewboatApplication;
				}
				
				case ServiceTypeEnum.PIER_SERVICE:
				{
					return {
						...baseApplication,
						requestedPiers: requestedProperties
					} as ApiCreatePierApplication;
				}
				default:
					throw new Error();
			}
		},
		async save() {
			this.isSaving = true;
			
			try {
				const serviceApplication = await this.prepareServiceApplication();
				
				const { id } = await serviceApplicationApiFacade.createApplication(serviceApplication);
				
				
				// TODO Будет перенесено на бек, пока за перевод статусов фронт отвечает
				await serviceApplicationApiFacade.updateStatusByTransition(id, ApiBaseServiceTransitionCodeEnum.CREATE);
				await serviceApplicationApiFacade.updateStatusByTransition(id, ApiBaseServiceTransitionCodeEnum.SEND);
				
				const { selectedServiceType } = useServiceStepStore();
				
				const serviceTypeToRouteMap = new Map([
					[ServiceTypeEnum.CREWBOAT_SERVICE, RouteNames.CREWBOAT_SERVICE],
					[ServiceTypeEnum.TUGBOAT_SERVICE, RouteNames.TUGBOAT_SERVICE],
					[ServiceTypeEnum.PIER_SERVICE, RouteNames.PIER_SERVICE]
				]);
				
				await router.push({ name: serviceTypeToRouteMap.get(selectedServiceType)!, params: { id } });
				
				alertService.addSuccess(AlertKeys.SERVICE_APPLICATION_CREATED);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.isSaving = false;
			}
		},
		async saveAsDraft() {
			this.isDraftSaving = true;
			
			try {
				const serviceApplication = await this.prepareServiceApplication();
				
				const { id } = await serviceApplicationApiFacade.createApplication(serviceApplication);
				
				await serviceApplicationApiFacade.updateStatusByTransition(id, ApiBaseServiceTransitionCodeEnum.CREATE);
				
				const { selectedServiceType } = useServiceStepStore();
				
				const serviceTypeToRouteMap = new Map([
					[ServiceTypeEnum.CREWBOAT_SERVICE, RouteNames.CREWBOAT_SERVICE],
					[ServiceTypeEnum.TUGBOAT_SERVICE, RouteNames.TUGBOAT_SERVICE],
					[ServiceTypeEnum.PIER_SERVICE, RouteNames.PIER_SERVICE]
				]);
				
				await router.push({ name: serviceTypeToRouteMap.get(selectedServiceType)!, params: { id } });
				
				alertService.addSuccess(AlertKeys.SERVICE_APPLICATION_CREATED);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.isDraftSaving = false;
			}
		}
	}
});
