import { RouteNames } from "@rmp/counterparty/router/routes";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import routeToPermissionsMap from "@rmp/counterparty/router/routeToPermissionsMap";
import { Dictionary } from "vue-router/types/router";

export function useBoatApplicationBreadcrumb(text: string, params?: Dictionary<string>) {
	const routeName = params?.id ? RouteNames.BOAT_APPLICATION : RouteNames.BOAT_APPLICATION_CREATE
	
	return useBreadcrumb({
		route: {
			name: routeName, params
		},
		text,
		routeToPermissionsMap
	});
}
