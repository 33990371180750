import { difference } from "@rmp/core/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import RouteQueryService from "@rmp/core/services/route/routeQueryService";
import { ServicesState } from "@rmp/counterparty/stores/services";
import ServicesRouteQuery from "@rmp/counterparty/stores/services/types/servicesRouteQuery";
import { parseArrayParameter } from "@rmp/core/utils/query";
import { ServicesFilterStatusEnum } from "@rmp/core/types/enums/ServicesFilterStatusEnum";

export default class ServicesRouteQueryService implements RouteQueryService {
	defaultRouteQuery: ServicesRouteQuery;
	
	constructor(defaultRouteQuery: ServicesRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}
	
	public resolveRouteQueryDictionary(state: ServicesState) {
		let query = this.mapStateToQuery(state);
		
		return difference(this.defaultRouteQuery, query);
	}
	
	public async hasRouteChanges(state: ServicesState, route: Dictionary<any>): Promise<{ [key: string]: any }> {
		let stateQuery = this.mapStateToQuery(state);
		let routeQuery = await this.resolveRouteQuery(route);
		
		return difference(routeQuery, stateQuery);
	}
	
	public async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(ServicesRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				statuses: parseArrayParameter(query.statuses),
				serviceTypes: parseArrayParameter(query.serviceTypes),
				status: query.status || ServicesFilterStatusEnum.ACTIVE
			}, { enableImplicitConversion: true });
			
			await validateOrReject(result);
			
			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
	
	private mapStateToQuery(state: ServicesState) {
		return new ServicesRouteQuery(
			state.paging.page,
			state.sorting.type,
			state.sorting.order,
			state.search.query,
			state.filter.statuses,
			state.filter.serviceTypes,
			state.filter.status
		);
	}
}
