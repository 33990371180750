import AbortService from "@rmp/core/services/abortService";
import { urls } from "@rmp/counterparty/api/config/urls";
import { ApiAgreementPersisted } from "@rmp/core/api/types/agreement/apiAgreement";
import BaseCounterpartyController from "@rmp/counterparty/api/base/baseCounterpartyController";
import { ApiAgreementsItem } from "@rmp/core/api/types/agreement/apiAgreementsItem";
import { ApiCreateAgreementApplication } from "@rmp/core/api/types/agreement/apiCreateAgreementApplication";
import { ApiCreateAgreementDocument } from "@rmp/core/api/types/agreement/apiCreateAgreementDocument";
import { ApiAgreementDocument } from "@rmp/core/api/types/agreement/apiAgreementDocument";
import ApiGetAgreementsParameters from "@rmp/core/api/types/agreement/apiGetAgreementsParameters";
import { ApiChatMessage } from "@rmp/core/api/types/chat/apiChatMessage";
import { ApiAgreementTransitionCodeType } from "@rmp/core/api/types/agreement/ApiAgreementTransitionCodeType";
import { ApiServiceTypeEnum } from "@rmp/core/types/services/ApiServiceTypeEnum";
import { ApiAgreementHistoryEntryPersisted } from "@rmp/core/api/types/agreement/apiAgreementHistoryEntryPersisted";
import { BaseHistoryController } from "@rmp/core/api/shared/baseHistoryController";
import { ApiServicesItem } from "@rmp/core/api/types/service/apiServicesItem";
import { ApiGetServicesParameters } from "@rmp/core/api/types/service/apiGetServicesParameters";

export class AgreementController extends BaseCounterpartyController implements BaseHistoryController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	createAgreementApplication = async (payload: ApiCreateAgreementApplication) => {
		return await this.post<ApiAgreementPersisted>(urls.agreement, {}, payload);
	};
	
	assignAgreementDocument = async (id: string, payload: ApiCreateAgreementDocument) => {
		return await this.put<ApiAgreementDocument>(urls.agreement.$id.documents, { id }, payload);
	};
	
	getAgreement = async (id: string) => {
		return await this.get<ApiAgreementPersisted>(urls.agreement.$id, { id });
	};
	
	getDocuments = async (id: string) => {
		const documents = await this.get<ApiAgreementDocument[]>(urls.agreement.$id.documents, { id });
		return { documents };
	};
	
	getAgreements = async (parameters: ApiGetAgreementsParameters) => {
		return await this.get<{ items: ApiAgreementsItem[], totalCount: number }>(urls.agreement.all, parameters);
		// TODO
		// return await this.get<{ items: ApiAgreementsItem[], totalCount: number }>(urls.agreement, parameters);
	};
	
	getServices = async (id: string, parameters: ApiGetServicesParameters) => {
		return await this.get<{ items: ApiServicesItem[], totalCount: number }>(urls.agreement.$id.services,{id, ...parameters});
	};
	
	getChatMessages = async (id: string) => {
		return await this.get<{ messages: ApiChatMessage[] }>(urls.agreement.$id.chat.message, { id });
	};
	
	createChatMessage = async (id: string, message: string): Promise<ApiChatMessage> => {
		return await this.post(urls.agreement.$id.chat.message, { id }, { message });
	};
	
	getHistory = async (id: string) => {
		return await this.get<{ history: ApiAgreementHistoryEntryPersisted[] }>(urls.agreement.$id.history, { id });
	};
	
	changeAgreementStatus = async (id: string, code: ApiAgreementTransitionCodeType) => {
		return await this.post<{ isSuccess: boolean }>(urls.agreement.$id.transition.$code, { id, code });
	};
	
	getAgreementDefinitions = async () => {
		return await this.get<{ name: string, type: ApiServiceTypeEnum }[]>(urls.agreement.definitions);
	};
	
	getPaymentInvoice = async (id: string, sum: number): Promise<Blob> => {
		return await this.get<Blob>(urls.agreement.$id.paymentInvoice, { id, sum }, { responseType: "blob" });
	};
	
	getRemainingLimit = async (id: string) => {
		return await this.get<{ remainingLimit: number }>(urls.agreement.$id.remainingLimit, { id });
	};
	
	archive = async (id: string) => {
		return await this.post(urls.agreement.$id.archive, { id }, {});
	};
	
	unarchive = async (id: string) => {
		return await this.post(urls.agreement.$id.unarchive, { id }, {});
	};
}
