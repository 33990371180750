import NotDefinedException from "@rmp/core/exceptions/notDefinedException";
import { useUserStore } from "@rmp/core/stores/user";
import Breadcrumb from "@rmp/core/stores/composables/page/types/breadcrumb";
import { Location, RawLocation, VueRouter } from "vue-router/types/router";
import { Permissions } from "@rmp/core/constants/permissions";

export interface UseBreadcrumbOptions {
	route: Location,
	text: string,
	routeToPermissionsMap: Map<string, Permissions[]>
}

export const useBreadcrumb = ({ route, text, routeToPermissionsMap }: UseBreadcrumbOptions) => {
	const permissions = routeToPermissionsMap.get(route.name!);
	if(!permissions) throw new NotDefinedException("permissions");
	
	const userStore = useUserStore();
	
	return new Breadcrumb(text, route, !userStore.check(permissions));
};
