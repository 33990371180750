import { parseISO } from "date-fns";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";
import { ServiceApplicationBase } from "@rmp/core/types/services/base/serviceApplicationBase";
import { ApiSnoInstallationServiceApplication } from "@rmp/core/api/types/service/snoIntallation/apiSnoInstallationServiceApplication";
import { ApiSnoInstallationServiceWorkflowState } from "@rmp/core/api/types/service/snoIntallation/apiSnoInstallationServiceWorkflowState";

export interface SnoInstallationServiceApplication extends ServiceApplicationBase {
	workflowState: ApiSnoInstallationServiceWorkflowState;
}

export class SnoInstallationServiceApplicationMapper {
	static map(source: ApiSnoInstallationServiceApplication): SnoInstallationServiceApplication {
		return {
			...source,
			createdAt: parseISO(source.createdAt),
			updatedAt: parseISO(source.updatedAt),
			type: ServiceMapperProfile.mapServiceType(source.type)
		};
	}
}
