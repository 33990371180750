import { ApiTugboatPersisted } from "@rmp/core/api/types/enterpriseProperty/apiTugboat";

export interface ServiceApplicationTugboat {
	id: string;
	name: string;
	imo: string;
	mmsi: string;
	deadWeight: number;
	length: number;
	width: number;
	height: number;
	power: number;
	yearOfBuilding?: number;
}


export default class ServiceApplicationTugboatMapper {
	static map(source: ApiTugboatPersisted): ServiceApplicationTugboat {
		return {
			id: source.id,
			...source.tugboat
		};
	}
}
