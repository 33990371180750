import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { ServiceController } from "@rmp/counterparty/api/service";
import { DocumentsState, useDocumentsStore } from "@rmp/counterparty/stores/composables/baseDocuments";
import { ServiceDocument, ServiceDocumentMapper } from "@rmp/core/types/services/serviceDocument";
import {
	useFloatingCraneServiceDocumentsBreadcrumb
} from "@rmp/counterparty/stores/serviceDetails/floatingCrane/documents/composables/useFloatingCraneServiceDocumentsBreadcrumb";
import { useFloatingCraneServiceStore } from "@rmp/counterparty/stores/serviceDetails/floatingCrane";

const abortService = new AbortService();
const page = usePageStore(abortService);
const serviceController = new ServiceController(abortService);

const documentsStore = useDocumentsStore({ controller: serviceController, mapper: ServiceDocumentMapper });

export interface FloatingCraneServiceDocumentsState extends PageState, DocumentsState<ServiceDocument> {
}

const getDefaultState = (): FloatingCraneServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...documentsStore.getDefaultState()
	};
};

export const useFloatingCraneServiceDocumentsStore = defineStore({
	id: "floating-crane-service-documents",
	state: (): FloatingCraneServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...documentsStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useFloatingCraneServiceStore();
			return [...breadcrumbs, useFloatingCraneServiceDocumentsBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...documentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			const { title } = useFloatingCraneServiceStore();
			
			this.id = id;
			
			await this.initializeDocumentsStore(title);
		}
	}
});
