import vuetifyColors, { Colors } from "vuetify/lib/util/colors";
import { computed } from "vue";
import { useVuetify } from "@rmp/core/plugins/vuetify";
import { VuetifyThemeItem } from "vuetify/types/services/theme";

export function useColors() {
	const vuetify = useVuetify();
	const { value: currentTheme } = computed(() => vuetify.theme.currentTheme);
	
	const colors: Record<string, VuetifyThemeItem | string | any> = {
		...vuetifyColors as Colors,
		primary: currentTheme.primary,
		secondary: currentTheme.secondary,
		white: currentTheme.white,
		grey: currentTheme.grey,
		blue: currentTheme.blue,
		green: currentTheme.green,
		orange: currentTheme.orange,
		red: currentTheme.red,
		accent: currentTheme.accent as string,
		error: currentTheme.error as string,
		success: currentTheme.success as string,
		warning: currentTheme.warning as string
	};
	
	return {
		colors
	};
}
