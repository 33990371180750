import { RouteNames } from "@rmp/counterparty/router/routes";
import { Permissions } from "@rmp/core/constants/permissions";

const routeToPermissionsMap = new Map<string, Permissions[]>([
	[RouteNames.ROOT, []],
	[RouteNames.CALLBACK, []],
	[RouteNames.LOGOUT, []],
	[RouteNames.SILENT_RENEW, []],
	[RouteNames.ERROR_PAGE_NOT_FOUND, []],
	[RouteNames.ERROR_ACCESS_DENIED, []],
	[RouteNames.ERROR_INTERNAL_SERVER_ERROR, []],
	
	[RouteNames.CONFIRM_PHONE, []],
	[RouteNames.CONFIRM_EMAIL, []],
	[RouteNames.RESET_PASSWORD, []],
	
	[RouteNames.COUNTERPARTY_DOCUMENTS, [Permissions.OWN_COUNTERPARTY_DOCUMENT_READ_LIST]],

	[RouteNames.BOAT_APPLICATIONS, []],
	[RouteNames.BOAT_APPLICATION, []],
	[RouteNames.BOAT_APPLICATION_CREATE, []],
	[RouteNames.BOAT_APPLICATION_DETAILS, []],
	[RouteNames.BOAT_APPLICATION_DETAILS_GENERAL, []],
	[RouteNames.BOAT_APPLICATION_DETAILS_DOCUMENTS, []],
	[RouteNames.BOAT_APPLICATION_DETAILS_HISTORY, []],
	[RouteNames.BOAT_APPLICATION_DETAILS_COMMENTS, []],
	
	[RouteNames.AGREEMENTS, []],
	[RouteNames.CREATE_AGREEMENT_APPLICATION, []],
	
	[RouteNames.AGREEMENT_DETAILS, []],
	[RouteNames.AGREEMENT_DETAILS_GENERAL, []],
	[RouteNames.AGREEMENT_DETAILS_DOCUMENTS, []],
	[RouteNames.AGREEMENT_DETAILS_HISTORY, []],
	[RouteNames.AGREEMENT_DETAILS_COMMENTS, []],
	[RouteNames.AGREEMENT_DETAILS_SERVICES, []],
	[RouteNames.AGREEMENT_SIGN_DOCUMENTS, []],
	
	[RouteNames.CREATE_SERVICE_APPLICATION, []],
	[RouteNames.SERVICES, []],
	
	[RouteNames.PIER_SERVICE, []],
	[RouteNames.PIER_SERVICE_GENERAL, []],
	[RouteNames.PIER_SERVICE_DOCUMENTS, []],
	[RouteNames.PIER_SERVICE_HISTORY, []],
	[RouteNames.PIER_SERVICE_COMMENTS, []],
	[RouteNames.PIER_SERVICE_FEEDBACK, []],
	[RouteNames.PIER_SERVICE_UPLOAD_DOCUMENTS, []],
	
	[RouteNames.TUGBOAT_SERVICE, []],
	[RouteNames.TUGBOAT_SERVICE_GENERAL, []],
	[RouteNames.TUGBOAT_SERVICE_DOCUMENTS, []],
	[RouteNames.TUGBOAT_SERVICE_HISTORY, []],
	[RouteNames.TUGBOAT_SERVICE_COMMENTS, []],
	[RouteNames.TUGBOAT_SERVICE_FEEDBACK, []],
	[RouteNames.TUGBOAT_SERVICE_UPLOAD_DOCUMENTS, []],
	
	[RouteNames.CREWBOAT_SERVICE, []],
	[RouteNames.CREWBOAT_SERVICE_GENERAL, []],
	[RouteNames.CREWBOAT_SERVICE_DOCUMENTS, []],
	[RouteNames.CREWBOAT_SERVICE_HISTORY, []],
	[RouteNames.CREWBOAT_SERVICE_COMMENTS, []],
	[RouteNames.CREWBOAT_SERVICE_FEEDBACK, []],
	[RouteNames.CREWBOAT_SERVICE_UPLOAD_DOCUMENTS, []],
	
	[RouteNames.DREDGING_SERVICE, []],
	[RouteNames.DREDGING_SERVICE_GENERAL, []],
	[RouteNames.DREDGING_SERVICE_DOCUMENTS, []],
	[RouteNames.DREDGING_SERVICE_HISTORY, []],
	[RouteNames.DREDGING_SERVICE_COMMENTS, []],
	[RouteNames.DREDGING_SERVICE_FEEDBACK, []],
	[RouteNames.DREDGING_SERVICE_SIGN_DOCUMENTS, []],
	
	[RouteNames.SNO_INSTALLATION_SERVICE, []],
	[RouteNames.SNO_INSTALLATION_SERVICE_GENERAL, []],
	[RouteNames.SNO_INSTALLATION_SERVICE_DOCUMENTS, []],
	[RouteNames.SNO_INSTALLATION_SERVICE_HISTORY, []],
	[RouteNames.SNO_INSTALLATION_SERVICE_COMMENTS, []],
	[RouteNames.SNO_INSTALLATION_SERVICE_FEEDBACK, []],
	[RouteNames.SNO_INSTALLATION_SERVICE_SIGN_DOCUMENTS, []],
	
	[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE, []],
	[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_GENERAL, []],
	[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_DOCUMENTS, []],
	[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_HISTORY, []],
	[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_COMMENTS, []],
	[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_FEEDBACK, []],
	[RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_SIGN_DOCUMENTS, []],
	
	[RouteNames.INFORMATION_SERVICE, []],
	[RouteNames.INFORMATION_SERVICE_GENERAL, []],
	[RouteNames.INFORMATION_SERVICE_DOCUMENTS, []],
	[RouteNames.INFORMATION_SERVICE_HISTORY, []],
	[RouteNames.INFORMATION_SERVICE_COMMENTS, []],
	[RouteNames.INFORMATION_SERVICE_FEEDBACK, []],
	[RouteNames.INFORMATION_SERVICE_SIGN_DOCUMENTS, []],
	
	[RouteNames.FLOATING_CRANE_SERVICE, []],
	[RouteNames.FLOATING_CRANE_SERVICE_GENERAL, []],
	[RouteNames.FLOATING_CRANE_SERVICE_DOCUMENTS, []],
	[RouteNames.FLOATING_CRANE_SERVICE_HISTORY, []],
	[RouteNames.FLOATING_CRANE_SERVICE_COMMENTS, []],
	[RouteNames.FLOATING_CRANE_SERVICE_FEEDBACK, []],
	[RouteNames.FLOATING_CRANE_SERVICE_SIGN_DOCUMENTS, []]
]);

export default routeToPermissionsMap;
