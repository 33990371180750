import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import NotDefinedException from "@rmp/core/exceptions/notDefinedException";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";
import { BaseFeedbackController } from "@rmp/core/api/shared/baseFeedbackController";
import { ApiServiceFeedback } from "@rmp/core/api/types/service/apiServiceFeedback";
import { Store } from "pinia";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { ApiGetServiceFeedbackResponsePersisted } from "@rmp/core/api/types/service/apiGetServiceFeedbackResponse";

export interface FeedbackState {
	id: string | null;
	feedback: ApiGetServiceFeedbackResponsePersisted;
	newFeedback: ApiServiceFeedback;
	isFeedbackSaving: boolean;
	isFeedbackAlreadyEvaluated: boolean;
}
export interface FeedbackOptions {
	controller: BaseFeedbackController;
}

export const useFeedbackStore = (options: FeedbackOptions) => {
	type FeedbackStore = Store<string, FeedbackState, {}, { [key: string]: any, patchStateWithRouteQuery(query: { [key: string]: any }): void }>;
	
	const getDefaultState = (): FeedbackState => {
		return {
			id: null,
			feedback: {} as ApiGetServiceFeedbackResponsePersisted,
			newFeedback: {} as ApiServiceFeedback,
			isFeedbackSaving: false,
			isFeedbackAlreadyEvaluated: false
		};
	};
	
	const getters = {};
	
	const actions = {
		async initializeFeedbackStore(this: FeedbackStore) {
			if(!this.id)
				throw new NotDefinedException("id");
			
			try {
				let feedback = await options.controller.getFeedback(this.id!);
				
				if(feedback) {
					this.feedback = feedback;
					this.isFeedbackAlreadyEvaluated = true;
				}
			} catch (ex) {
				if(ex.constructor === HttpNotFoundException)
					return;
				
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async saveFeedback(this: FeedbackStore) {
			if(!this.id)
				throw new NotDefinedException("id");
			
			this.isFeedbackSaving = true;
			
			try {
				const { serviceApplicationReviewPersisted } = await options.controller.sendFeedback!(this.id!, this.newFeedback);
				
				this.feedback = serviceApplicationReviewPersisted;
				this.isFeedbackAlreadyEvaluated = true;
				
				alertService.addSuccess(AlertKeys.FEEDBACK_SENT);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.isFeedbackSaving = false;
			}
		}
	};
	
	return { getters, actions, getDefaultState };
};
