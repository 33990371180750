import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { ServiceController } from "@rmp/counterparty/api/service";
import { FeedbackState, useFeedbackStore } from "@rmp/core/stores/composables/feedback";
import { useInformationServiceFeedbackBreadcrumb } from "@rmp/counterparty/stores/serviceDetails/information/feedback/composables/useInformationServiceFeedbackBreadcrumb";
import { useInformationServiceStore } from "@rmp/counterparty/stores/serviceDetails/information";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceController = new ServiceController(abortService);
const feedbackStore = useFeedbackStore({ controller: serviceController });

export interface InformationServiceFeedbackState extends PageState, FeedbackState {
}

const getDefaultState = (): InformationServiceFeedbackState => {
	return {
		...page.getDefaultPageState(),
		...feedbackStore.getDefaultState()
	};
};

export const useInformationServiceFeedbackStore = defineStore({
	id: "information-service-feedback",
	state: (): InformationServiceFeedbackState => getDefaultState(),
	getters: {
		...page.getters,
		...feedbackStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useInformationServiceStore();
			return [...breadcrumbs, useInformationServiceFeedbackBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...feedbackStore.actions,
		async beforeInitialized(this: any, { id }: { id: string }) {
			this.id = id;
			await this.initializeFeedbackStore();
		}
	}
});
