import { ApiAccountProfileBase } from "@rmp/core/api/types/account/profile/apiAccountProfileBase";
import ApiLegalEntityProfile from "@rmp/core/api/types/account/profile/apiLegalEntityProfile";
import ApiEntrepreneurProfile from "@rmp/core/api/types/account/profile/apiEntrepreneurProfile";
import ApiForeignOrganizationProfile from "@rmp/core/api/types/account/profile/apiForeignOrganizationProfile";
import ApiLegalEntityEmployeeProfile from "@rmp/core/api/types/account/profile/apiLegalEntityEmployeeProfile";
import ApiEntrepreneurEmployeeProfile from "@rmp/core/api/types/account/profile/apiEntrepreneurEmployeeProfile";
import ApiOfficeEmployeeProfile from "@rmp/core/api/types/account/profile/apiOfficeEmployeeProfile";
import { AccountProfileBase } from "@rmp/core/stores/user/types/accountProfileBase";
import { ApiAccount, ApiAccountMapper } from "@rmp/core/api/types/account/apiAccount";

type ProfileType =
	ApiAccountProfileBase
	| ApiLegalEntityProfile
	| ApiEntrepreneurProfile
	| ApiForeignOrganizationProfile
	| ApiLegalEntityEmployeeProfile
	| ApiEntrepreneurEmployeeProfile
	| ApiOfficeEmployeeProfile;
export default class User {
	profile: ProfileType | null;
	account: ApiAccount;
	profiles: AccountProfileBase[];
	isUserinfoLoading: boolean;
	isProfilesLoading: boolean;
	permissions: string[];
	passwordExpiresAt: number;
	
	constructor(
		profile: ApiAccountProfileBase | null = null,
		account: ApiAccount = ApiAccountMapper.getEmpty(),
		profiles: AccountProfileBase[] = [],
		isUserinfoLoading: boolean = false,
		isProfilesLoading: boolean = false,
		permissions: string[] = [],
		passwordExpiresAt: number = 0
	)
	{
		this.profile = profile;
		this.account = account;
		this.profiles = profiles;
		this.isUserinfoLoading = isUserinfoLoading;
		this.isProfilesLoading = isProfilesLoading;
		this.permissions = permissions;
		this.passwordExpiresAt = passwordExpiresAt;
	}
}
