import { isArray } from "lodash";
import SecurityService from "@rmp/core/services/auth/securityService";
import userManager from "@rmp/core/services/auth/userManager";
import { Permissions } from "@rmp/core/constants/permissions";
import { storeToRefs } from "pinia";
import { useUserStore } from "@rmp/core/stores/user";

const securityService = new SecurityService();

export function useUser() {
	const {
		permissions,
		initialized: isUserInitialized,
		user,
		isMasterAccount,
		isCounterpartyEmployee,
		profile,
		passwordCloseExpiration,
		passwordExpirationRemainingDays
	} = storeToRefs(useUserStore());
	
	const can = (value: any) => {
		let can = false;
		
		if(!value) {
			return true;
		}
		
		if(isArray(value)) {
			can = value.some(x => permissions.value.includes(x));
		} else {
			can = permissions.value.includes(value);
		}
		
		return can;
	};
	
	const signIn = async () => {
		await securityService.signIn({});
	};
	
	const signInSilent = async () => {
		await securityService.signinSilent();
	};
	
	const signOut = async () => {
		await securityService.signOut();
	};
	
	const getToken = async () => {
		return await securityService.getToken();
	};
	
	const getUser = async () => {
		return await securityService.getUser();
	};
	
	const selectProfile = async (profileId: string, payload?: any) => {
		return await securityService.selectProfile(profileId, payload);
	};
	
	return {
		permissions,
		userManager,
		Permissions,
		can,
		signIn,
		signInSilent,
		signOut,
		getToken,
		getUser,
		selectProfile,
		isUserInitialized,
		profile,
		user,
		isMasterAccount,
		isCounterpartyEmployee,
		passwordCloseExpiration,
		passwordExpirationRemainingDays
	};
}
