import { Permissions } from "@rmp/core/constants/permissions";
import AbortService from "@rmp/core/services/abortService";
import { AuthorizationController } from "@rmp/core/api/authorization/index";
import { PERMISSIONS_STORAGE_KEY } from "@rmp/core/constants/localStorage";
import * as storage from "local-storage";

const abortService = new AbortService();
abortService.initialize();

let authorizationController = new AuthorizationController(abortService);

export default class PermissionsResolver {
	constructor() {
	}

	getPermissionsFromStorage(): Permissions[] {
		return storage.get<Permissions[]>(PERMISSIONS_STORAGE_KEY);
	}

	async updatePermissionsStorage() {
		const permissions = await authorizationController.getPermissions();

		storage.set(PERMISSIONS_STORAGE_KEY, permissions);
	}

	resetPermissionsStorage() {
		storage.remove(PERMISSIONS_STORAGE_KEY);
	}

	async resolve(): Promise<Permissions[]> {
		let permissions = this.getPermissionsFromStorage();

		if(!permissions) {
			await this.updatePermissionsStorage();
			permissions = this.getPermissionsFromStorage();
		}

		return permissions;
	}
}
