import { RouteNames } from "@rmp/counterparty/router/routes";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import { i18n } from "@rmp/counterparty/plugins";
import routeToPermissionsMap from "@rmp/counterparty/router/routeToPermissionsMap";

export function useFloatingCraneServiceHistoryBreadcrumb() {
	return useBreadcrumb({
		route: {
			name: RouteNames.FLOATING_CRANE_SERVICE_HISTORY
		},
		text: i18n.t(`navigation.breadcrumbs.${RouteNames.FLOATING_CRANE_SERVICE_HISTORY}`).toString(),
		routeToPermissionsMap
	});
}
