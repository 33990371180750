import {
	STORAGE_APPOINTMENT_HEAD_ORDER,
	STORAGE_INN_NAMESPACE,
	STORAGE_SIGNED_REQUEST,
	STORAGE_SNILS_NAMESPACE
} from "@rmp/core/constants/storage";
import { CounterpartyTypeEnum } from "@rmp/core/types/CounterpartyTypeEnum";
import { CounterpartyEmployeeTrustStatus } from "@rmp/core/types/CounterpartyEmployeeTrustStatus";
import { ApiCounterpartyDocumentType } from "@rmp/core/api/types/documents/ApiCounterpartyDocumentType";
import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";
import { BoatType } from "@rmp/core/api/types/enterpriseProperty/boatType";
import { ApplicationStatusType } from "@rmp/core/types/ApplicationStatusType";
import { ApplicationFilterStatusType } from "@rmp/core/types/ApplicationFilterStatusType";
import { BoatApplicationDocumentType } from "@rmp/core/types/boatApplicationDocumentType";
import { DocumentType } from "@rmp/core/types/documentType";
import { BranchType } from "@rmp/core/types/BranchType";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import { ApiServiceTariffsEnum } from "@rmp/core/api/types/service/tariffs/ApiServiceTariffsEnum";
import { ServiceWorkflowStateType } from "@rmp/core/types/services/ServiceWorkflowStateType";
import { ApiServiceDocumentKindEnum } from "@rmp/core/api/types/service/document/ApiServiceDocumentKindEnum";
import { AgreementDocumentKindEnum } from "@rmp/core/types/agreement/agreementDocumentKindEnum";
import { AgreementWorkflowStateType } from "@rmp/core/types/agreement/AgreementWorkflowStateType";
import { MetricsPeriodType } from "@rmp/core/types/metrics/metricsPeriodType";
import { DredgingServiceWorkflowStateType } from "@rmp/core/types/services/dredging/DredgingServiceWorkflowStateType";
import {
	GeodeticHydrographicServiceWorkflowStateType
} from "@rmp/core/types/services/geodeticHydrographic/GeodeticHydrographicServiceWorkflowStateType";
import { SnoInstallationServiceWorkflowStateType } from "@rmp/core/types/services/snoInstallation/SnoInstallationServiceWorkflowStateType";
import { FloatingCraneServiceWorkflowStateType } from "@rmp/core/types/services/floatingCrane/FloatingCraneServiceWorkflowStateType";
import { ApiPropertyType } from "@rmp/core/api/types/enterpriseProperty/apiPropertyType";
import { ApiAllServiceWorkflowStateTypeEnum } from "@rmp/core/api/types/service/base/ApiAllServiceWorkflowStateTypeEnum";
import { MasterAccountAccessStatusFilterEnum } from "@rmp/core/types/masterAccountAccess/MasterAccountAccessStatusFilter";
import { ApiAgreementPaymentMethodEnum } from "@rmp/core/api/types/agreement/ApiAgreementPaymentMethodEnum";
import { TrustActionerKind } from "@rmp/core/types/counterpartyEmployee/trust/TrustActionerKind";

export default {
	fields: {
		calculationObjectCode: {
			label: "Калькуляционный объект (КО)",
			placeholder: "Введите значение"
		},
		harborDuesIdentifier: {
			label: "Идентификатор",
			placeholder: "Введите значение"
		},
		paymentMethod: {
			label: "Выберите способ оплаты по договору "
		},
		agreementStartDate: {
			label: "Дата начала",
			placeholder: "ДД.ММ.ГГГГ"
		},
		agreementEndDate: {
			label: "Дата окончания",
			placeholder: "ДД.ММ.ГГГГ"
		},
		agreementDate: {
			label: "Дата договора",
			placeholder: "ДД.ММ.ГГГГ"
		},
		propertyType: {
			placeholder: "Тип объекта"
		},
		dateFrom: {
			placeholder: "с"
		},
		dateUntil: {
			placeholder: "по"
		},
		enterprise: {
			placeholder: "Все предприятия"
		},
		property: {
			placeholder: "Все объекты имущества"
		},
		period: {
			placeholder: "Период"
		},
		date: {
			placeholder: "Введите дату",
			label: "Дата"
		},
		validUntilDate: {
			placeholder: "Введите дату",
			label: "Действительна до"
		},
		validFromDate: {
			placeholder: "Введите дату",
			label: "Действует с"
		},
		phone: {
			placeholder: "Введите номер телефона",
			label: "Номер телефона"
		},
		email: {
			placeholder: "Введите адрес электронной почты",
			label: "Адрес электронной почты"
		},
		simpleEmail: {
			placeholder: "Введите email",
			label: "Электронная почта"
		},
		password: {
			placeholder: "Введите пароль",
			label: "Пароль"
		},
		newPassword: {
			placeholder: "Введите пароль",
			label: "Ваш новый пароль"
		},
		confirmedPassword: {
			placeholder: "Повторите пароль",
			label: "Введите пароль"
		},
		inn: {
			placeholder: "Введите ИНН",
			label: "ИНН"
		},
		ogrn: {
			placeholder: "Введите ОГРН",
			label: "ОГРН"
		},
		ogrnip: {
			placeholder: "Введите ОГРНИП",
			label: "ОГРНИП"
		},
		kpp: {
			placeholder: "Введите КПП",
			label: "КПП"
		},
		opf: {
			placeholder: "Введите организационно-правовую форму",
			label: "Организационно-правовая форма"
		},
		snils: {
			placeholderInput: "Введите СНИЛС",
			placeholderChoose: "Выберите файл",
			label: "СНИЛС"
		},
		snilsFile: {
			label: "Файл СНИЛС",
			placeholder: "Выберите файл"
		},
		trust: {
			placeholder: "Выберите файл",
			label: "Доверенность"
		},
		signature: {
			placeholder: "Выберите файл",
			label: "ЭП"
		},
		position: {
			placeholder: "Введите должность",
			label: "Должность"
		},
		username: {
			label: "Логин"
		},
		firstName: {
			placeholder: "Введите имя",
			label: "Имя"
		},
		lastName: {
			placeholder: "Введите фамилию",
			label: "Фамилия"
		},
		middleName: {
			placeholder: "Введите отчество",
			label: "Отчество"
		},
		personFullName: {
			placeholder: "Введите ФИО",
			label: "ФИО"
		},
		fullName: {
			placeholder: "Введите полное наименование",
			label: "Полное наименование"
		},
		name: {
			placeholder: "Введите наименование",
			label: "Наименование"
		},
		shortName: {
			placeholder: "Введите краткое наименование",
			label: "Краткое наименование"
		},
		registerDate: {
			placeholder: "Введите дату регистрации",
			label: "Дата регистрации"
		},
		counterpartyRegisterDate: {
			placeholder: "Введите дату регистрации организации",
			label: "Дата регистрации организации"
		},
		legalPersonRegisterDate: {
			placeholder: "Введите дату регистрации ИП",
			label: "Дата регистрации"
		},
		registerCountry: {
			placeholder: "Выберите страну регистрации",
			label: "Страна регистрации"
		},
		legalAddress: {
			placeholder: "Введите юридический адрес",
			label: "Юридический адрес"
		},
		comment: {
			placeholder: "Введите комментарий",
			label: "Комментарий"
		},
		description: {
			placeholderInput: "Введите описание",
			placeholderAdd: "Добавьте описание",
			label: "Описание"
		},
		reason: {
			placeholder: "Укажите причину",
			label: "Причина"
		},
		paymentDescription: {
			placeholder: "Напишите описание",
			label: "Назначение платежа"
		},
		paymentActiveDate: {
			placeholder: "Введите дату",
			label: "Дата, с которой задолженность становится срочной"
		},
		switch: {
			frp: {
				label: "РФРП"
			},
			leasingCompany: {
				label: "Лизинговая компания"
			},
			collateralExpertOrganisation: {
				label: "Экспертная организация по залогам"
			},
			vkmExpertCompany: {
				label: "Экспертная организация по ВКМ"
			},
			frpAssigneeCompany: {
				label: "Цессионарий"
			},
			enableFnsUpdate: {
				label: "Обновлять из Контур.Фокус по расписанию"
			},
			canSignDocumentsOnBehalfOfEnterprise: {
				label: "Может осуществлять подписание документов от имени предприятия"
			}
		},
		headFullName: {
			placeholder: "Введите ФИО руководителя",
			label: "ФИО"
		},
		headPosition: {
			placeholder: "Введите должность руководителя",
			label: "Должность"
		},
		headInn: {
			placeholder: "Введите ИНН руководителя",
			label: "ИНН"
		},
		headSnils: {
			placeholderInput: "Введите СНИЛС руководителя",
			label: "СНИЛС"
		},
		employeeSnils: {
			placeholderInput: "Введите СНИЛС сотрудника",
			label: "СНИЛС"
		},
		titleDocument: {
			placeholder: "Выбрать",
			label: "Документ-основание"
		},
		version: {
			placeholder: "Выбрать",
			label: "Основание"
		},
		changeReason: {
			placeholder: "Выбрать",
			label: "Причина изменения графика"
		},
		number: {
			placeholder: "Введите номер",
			label: "Номер"
		},
		financeSource: {
			placeholder: "Выберите субсидию",
			label: "Субсидия"
		},
		amount: {
			placeholder: "Введите сумму",
			labelRUB: "Сумма, ₽"
		},
		quarter: {
			placeholder: "Выберите квартал",
			label: "Квартал"
		},
		accrualDate: {
			placeholder: "Выбрать дату",
			label: "Дата начисления"
		},
		paymentDate: {
			placeholder: "Выбрать дату",
			label: "Дата оплаты"
		},
		plannedPaymentDate: {
			placeholder: "Выбрать дату",
			label: "Дата платежа"
		},
		accountedDate: {
			placeholder: "Выбрать дату",
			label: "Дата передачи в бухгалтерию"
		},
		accrualType: {
			placeholder: "Выбрать тип",
			label: "Тип начисления"
		},
		projectNumber: {
			placeholder: "Номер проекта"
		},
		agreementNumber: {
			placeholder: "Договор займа"
		},
		agreementStatus: {
			placeholder: "Статус договора"
		},
		allSelected: {
			label: "Все отмеченные"
		},
		penaltyType: {
			placeholder: "Выбрать категорию",
			label: "Категории начисления штрафов/пеней"
		},
		accruedAmount: {
			placeholder: "Введите проценты",
			label: "Сумма процентов"
		},
		additionalInterest: {
			placeholder: "Введите проценты",
			label: "Дополнительная процентная ставка"
		},
		paymentExpense: {
			placeholder: "Выберите статью ДДС",
			label: "Статья ДДС"
		},
		borrower: {
			placeholder: "Выберите заёмщика",
			label: "Заёмщик"
		},
		treasuryBorrower: {
			placeholder: "Название заемщика / Номер проекта / Договор",
			label: "Заёмщик"
		},
		paymentsType: {
			placeholder: "Выбрать",
			label: "Тип записи"
		},
		dropzone: {
			title: {
				uploadFile: "Перенесите файл или нажмите для загрузки"
			},
			description: {
				maxFileWeight: "Максимальный вес файла {maxSize}Мб",
				minFileWeight: "Минимальный вес файла {minSize}Мб",
				formats: "Загрузите {formats} файлы."
			}
		},
		status: {
			placeholder: "Выберите статус",
			label: "Статус"
		},
		organization: {
			placeholder: "Выберите организацию",
			label: "Организация"
		},
		roleInProject: {
			placeholder: "Выберите роль",
			label: "Роль в проекте"
		},
		obligedCompany: {
			placeholder: "Выберите организацию",
			label: "По обязательствам какого юр.лица"
		},
		requirement: {
			placeholder: "Выберите требование",
			label: "Требование"
		},
		event: {
			placeholder: "Выберите мероприятие",
			label: "Мероприятие"
		},
		judicialDecision: {
			placeholder: "Выберите решение суда",
			label: "Решение суда"
		},
		controlDate: {
			label: "Контрольная дата"
		},
		factualDate: {
			label: "Фактическая дата"
		},
		factualPenaltySum: {
			placeholder: "Введите сумму",
			label: "Фактическая сумма взыскания, руб."
		},
		organizationName: {
			placeholder: "Введите название",
			label: "Название организации"
		},
		responsibleUser: {
			placeholder: "Выберите сотрудника",
			label: "Ответственный"
		},
		startDate: {
			label: "Дата действия с"
		},
		headCertificateInn: {
			label: "Свидетельство ИНН руководителя"
		},
		appointmentHeadOrder: {
			label: "Приказ о назначении руководителем"
		},
		documentSearch: {
			placeholder: "Поиск документа"
		},
		financeSchemesSearch: {
			placeholder: "Все программы"
		},
		departmentSearch: {
			placeholder: "Подразделение ФРП"
		},
		documentSectionSearch: {
			placeholder: "Раздел документа"
		},
		activeOnly: {
			label: "Активные элементы"
		},
		financingScheme: {
			label: "Программа",
			placeholder: "Выбрать"
		},
		supervisor: {
			label: "Руководитель (лицо, акцептующее публикацию документа)",
			placeholder: "Выбрать"
		},
		department: {
			label: "Ответственное подразделение ФРП",
			placeholder: "Выбрать"
		},
		documentSection: {
			label: "Раздел",
			placeholder: "Выбрать"
		},
		documentTitle: {
			label: "Название документа",
			placeholder: "Введите название документа"
		},
		documentEditableFile: {
			label: "В редактируемом формате"
		},
		documentPdfFile: {
			label: "В формате pdf"
		},
		initiator: {
			label: "Инициатор",
			placeholder: "Инициатор"
		},
		acceptedOnly: {
			label: "Согласованные заявки"
		},
		createdDate: {
			label: "Дата создания"
		},
		changeRequestDescription: {
			placeholder: "Введите описание",
			label: "Суть изменения"
		},
		changeRequestType: {
			placeholder: "Выбрать",
			label: "Тип вносимого изменения"
		},
		changeWarrant: {
			placeholder: "Введите основание",
			label: "Основание для внесения изменений"
		},
		login: {
			label: "Email / ИНН организации",
			placeholder: "Введите ИНН или Email"
		},
		confirmationCode: {
			label: "Код подтверждения"
		},
		documentType: {
			label: "Вид документа",
			placeholder: "Выберите вид документа"
		},
		bankAccountNumber: {
			label: {
				short: "Номер счета",
				full: "Номер счета в банке"
			},
			placeholder: "Введите номер счета"
		},
		bankIdentificationCode: {
			label: "БИК",
			placeholder: "Введите БИК"
		},
		bankName: {
			label: "Наименование банка",
			placeholder: "Введите наименование банка"
		},
		correspondentAccount: {
			label: "Корреспондентский счет",
			placeholder: "Введите корреспондентский счет"
		},
		boatName: {
			label: "Название судна",
			placeholder: "Введите название судна"
		},
		boatIMO: {
			label: "Код IMO",
			placeholder: "Введите код IMO"
		},
		precipitationMaxValue: {
			label: "Максимальная осадка (м.)",
			placeholder: "Введите максимальную осадку"
		},
		calculatedGRT: {
			label: "Расчетное значение GRT (т.)",
			placeholder: "Введите расчетное значение GRT"
		},
		boatFlag: {
			label: "Флаг",
			placeholder: "Введите флаг"
		},
		GRT: {
			label: "GRT (т.)",
			placeholder: "Введите GRT"
		},
		NRT: {
			label: "NRT (т.)",
			placeholder: "Введите NRT"
		},
		boatLength: {
			label: "Длина (м.)",
			placeholder: "Введите длину"
		},
		boatHeight: {
			label: "Высота борта (м.)",
			placeholder: "Введите высоту борта"
		},
		portOfRegistry: {
			label: "Порт регистрации",
			placeholder: "Введите порт регистрации"
		},
		yearOfBuilding: {
			label: "Год постройки",
			placeholder: "Введите год постройки"
		},
		callSign: {
			label: "Позывной",
			placeholder: "Введите позывной"
		},
		MMSI: {
			label: "Код MMSI",
			placeholder: "Введите код MMSI"
		},
		boatType: {
			label: "Тип судна",
			placeholder: "Выберите тип судна"
		},
		exclusionGRT: {
			label: "Исключение из GRT рег (т.)",
			placeholder: "Введите исключение из GRT"
		},
		boatDeadWeight: {
			label: "Дедвейт (т.)",
			placeholder: "Введите дедвейт"
		},
		boatWidth: {
			label: "Ширина (м.)",
			placeholder: "Введите ширину"
		},
		boatPower: {
			label: "Мощность ГД (кВт.)",
			placeholder: "Введите мощность"
		},
		numberOfRegistry: {
			label: "Регистровый номер",
			placeholder: "Введите регистровый номер"
		},
		boatStatus: {
			label: "Текущий статус",
			placeholder: "Введите текущий статус"
		},
		pierName: {
			label: "Название",
			placeholder: "Введите название"
		},
		pierLength: {
			label: "Длина причальной стенки, предназначенная для стоянки судов (м.)",
			placeholder: "Введите длину"
		},
		pierDepth: {
			label: "Глубина у причальной стенки (м.)",
			placeholder: "Введите глубину"
		},
		pierStatus: {
			label: "Текущий статус",
			placeholder: "Введите текущий статус"
		},
		shipowner: {
			label: "Судовладелец",
			placeholder: "Введите судовладельца"
		},
		ownershipCertificate: {
			label: "Свидетельство о праве на судно"
		},
		internationalMeasuringCertificate: {
			label: "Международное мерительное свидетельство"
		},
		classificationCertificate: {
			label: "Классификационное свидетельство"
		},
		internationalOilPollutionPreventionCertificate: {
			label: "Международное свидетельство о предотвращении загрязнения нефтью"
		},
		customDocument: {
			label: "Иной документ"
		},
		expirationDate: {
			label: "Истечение срока действия",
			placeholder: "Введите дату"
		},
		giveBoatDate: {
			label: "Дата подачи",
			placeholder: "ДД.ММ.ГГГГ"
		},
		giveBoatTime: {
			label: "Время подачи",
			placeholder: "ЧЧ:ММ"
		},
		startLocation: {
			label: "Место начала",
			placeholder: "Заполните данные"
		},
		endLocation: {
			label: "Место окончания",
			placeholder: "Заполните данные"
		},
		chatComment: {
			placeholder: "Ваш комментарий"
		},
		feedback: {
			label: "Отзыв",
			placeholder: "Добавьте отзыв"
		},
		declineReason: {
			label: "Причина отклонения",
			placeholder: "Напишите причину отклонения"
		},
		cancelReason: {
			label: "Причина отмены",
			placeholder: "Укажите причину отмены"
		},
		actualAddress: {
			label: "Фактический адрес"
		},
		opfName: {
			label: "Наименование ОПФ"
		},
		branch: {
			label: "Филиал",
			placeholder: "Выберите филиал"
		},
		division: {
			label: "Управление",
			placeholder: "Выберите управление"
		},
		deposit: {
			placeholder: "Введите сумму пополнения"
		},
		englishName: {
			label: "Английское наименование",
			placeholder: "Введите английское наименование"
		}
	},
	alerts: {
		errors: {
			loadExternalDataError: "Произошла ошибка при загрузке данных. Пожалуйста, попробуйте еще раз",
			onSaveExternalError: "Не удалось сохранить данные. Пожалуйста, проверьте введенные сведения и попробуйте снова",
			onCalibrationExternalError: "Ошибка инициализации процесса калибровки. Рекомендуем перезапустить операцию",
			onRequestError: "Возникла ошибка при отправке запроса",
			settings: {
				errorUpdate: "Не удалось обновить данные пользователя. Пожалуйста, убедитесь в корректности информации и повторите попытку"
			},
			organisationAlreadyExists: "Организация с указанным именем уже зарегистрирована в системе",
			legalEntityAlreadyExists: "Указанный ИНН уже принадлежит другому зарегистрированному юридическому лицу",
			legalPersonAlreadyExists: "Введенный ИНН уже принадлежит другому зарегистрированному ИП",
			physicalPersonAlreadyExists: "Введенный ИНН уже принадлежит другому зарегистрированному физическому лицу",
			requiredInnForLegalEntity: "Для автоматического заполнения данных, пожалуйста, введите ИНН из ЕГРЮЛ",
			requiredInnForLegalPerson: "Введите ИНН для автоматического заполнения информации из ЕГРИП",
			requiredHeadSnils: "Введите СНИЛС сотрудника для добавления доверенности",
			requiredSnilsForAddEmployeeTrust: "Для добавления доверенности укажите СНИЛС сотрудника",
			reloadTrust: {
				ifReload: "Обратите внимание: загрузка новой доверенности приведет к аннулированию",
				current: "текущей"
			},
			notRightsToViewPage: "К сожалению, у вас нет доступа к данной странице",
			pageNotFound: "Страница, которую вы пытаетесь просмотреть, не найдена или доступ ограничен",
			internalServerError: "Произошла ошибка на стороне сервера. Пожалуйста, попробуйте позже",
			accessDenied: "Доступ запрещен. У вас нет соответствующих прав для выполнения этой операции",
			requestedPageNotFound: "Страница, которую вы ищете, не может быть найдена",
			legalPersonByInnNotFound: "Индивидуальный предприниматель с ИНН {inn} не найден",
			legalEntityByInnNotFound: "Не удалось найти организацию с ИНН {inn} в ЕГРЮЛ",
			masterAccountAccessApplicationExists: "Запрос на получение доступа к мастер-аккаунту уже был направлен и находится на рассмотрении. Результат будет направлен на указанный при подаче адрес электронной почты"
		},
		info: {
			noData: "Данные отсутствуют",
			onSuccessCreated: "Данные успешно сохранены",
			onSuccessUpdated: "Данные успешно обновлены",
			onSuccessUploaded: "Документ успешно загружен",
			onSuccessStartCalibration: "Калибровка успешно запущена",
			settings: {
				successUpdated: "Данные пользователя успешно обновлены"
			},
			inputSnilsOnAddTrust: {
				onAddTrust: "Для добавления доверенности укажите",
				inputSnils: "СНИЛС сотрудника"
			},
			organisationWasCreated: "Организация создана",
			youCanCloseCurrentBrowserPage: "Вы можете закрыть текущую страницу браузера",
			afterAcceptCounterpartyBoatApplication: "После рассмотрения заявки сотрудником ФГУП \"Росморпорт\" судно будет добавлено в ваш реестр",
			youCanSelectMultipleTariffs: "Вы можете выбрать несколько тарифов для каждого буксира",
			youCanSelectMultipleTugBoats: "Вы можете выбрать несколько буксиров для выполнения услуг",
			youCanSelectMultipleCrewboats: "Вы можете выбрать несколько разъездных судов для выполнения услуг",
			youCanSelectMultipleFloatingCranes: "Вы можете выбрать несколько плавкранов для выполнения услуг",
			youCanSelectMultiplePiers: "Вы можете выбрать несколько причалов для выполнения услуг",
			servicesCostIsBasedOnActualData: "Стоимость услуг рассчитывается исходя из фактических данных",
			noAgreements: "Для получения услуг вам необходимо заключить договор на их оказание. Если договор на оказание необходимого типа услуг не был заключен, вы можете перейти к форме его заключения.",
			selectedNoAgreementsDivision: "Для продолжения вам необходимо заключить договор на оказание услуг с одним из управлений.",
			agreementLimitAlmostExhausted: "Общая сумма оказанных в рамках договора услуг приближается к лимиту. Рекомендуется заключить новый договор.",
			agreementServiceAdvanceReached: "Достигнут минимально допустимый аванс для оказания услуг по договору. Для продолжения, пожалуйста, пополните баланс с помощью формы формирования счета на странице договора.",
			noActiveTrust: "Активная доверенность отсутствует."
		},
		saveChangesAlert: {
			title: "Данные изменены",
			text: "Не забудьте сохранить их"
		}
	},
	buttons: {
		withdrawDocuments: "Отозвать отчетные документы",
		withdrawSign: "Отозвать ЭП",
		digitalSignatureInstructions: "Проблемы с электронной подписью?",
		instructions: "Инструкция",
		changeTariffs: "Внести изменения",
		downloadTemplate: "Скачать шаблон",
		signAgreement: "Подписать договор",
		signAccountingDocuments: "Подписать отчетные документы",
		downloadReport: "Скачать отчет",
		serviceProvided: "Услуги оказаны",
		approveAssignment: "Подтвердить назначение",
		sign: "Подписать",
		saveSignature: "Скачать подпись",
		saveSignatures: "Скачать подписи",
		changeAccount: "Сменить аккаунт",
		clear: "Очистить",
		clearAll: "Очистить все",
		accept: "Применить",
		choose: "Выбрать",
		signed: "Подписан",
		loadMore: "Загрузить еще",
		upload: "Загрузить",
		cancel: "Отменить",
		edit: "Редактировать",
		declineTrust: "Отменить одобрение",
		cancelTrust: "Отклонить",
		addTrust: "Добавить доверенность",
		skip: "Пропустить",
		save: "Сохранить",
		downloadTrustTemplate: "Скачать шаблон",
		close: "Закрыть",
		dontSave: "Не сохранять",
		ok: "Ок",
		backToList: "Назад к списку",
		add: "Добавить",
		remove: "Удалить",
		update: "Обновить",
		send: "Отправить",
		doNotSend: "Не отправлять",
		resend: "Отправить еще раз",
		back: "Назад",
		next: "Далее",
		logout: "Выйти",
		personalArea: "Личный кабинет",
		signIn: "Вход",
		account: "Пользователь",
		goToHome: "На главную",
		goToAccount: "Перейти к учетной записи",
		goTo: "Перейти",
		profileUser: "Профиль пользователя",
		restoreAccountAccess: "Восстановить доступ",
		continue: "Продолжить",
		esiaSignIn: "Войти с помощью госуслуг",
		resetPassword: "Сбросить пароль",
		addCounterparty: "Добавить контрагента",
		addHead: "Добавить руководителя",
		addEmployee: "Добавить сотрудника",
		recognize: "Распознать",
		addOffice: "Добавить предприятие",
		confirm: "Подтвердить",
		indicate: "Указать",
		change: "Изменить",
		updateViaFns: "Обновить из Контур.Фокус",
		toInvite: "Пригласить",
		updateAndSendCredentials: "Сформировать и отправить новые учётные данные на почту",
		requestPhoneConfirmation: "Запросить подтверждение телефона",
		create: "Создать",
		createDraft: "Создать черновик",
		createVersion: "Создать версию",
		saveAsDraft: "Сохранить как черновик",
		saveAsVersion: "Сохранить как версию",
		addNote: "Добавить запись",
		deleteNote: "Удалить запись",
		submitToAccounting: "Передать в бухгалтерию",
		submitTo1C: "Передать в 1C",
		accrueInterest: "Начислить проценты",
		signWithDigitalSignature: "Подписать электронной подписью",
		signWithDigitalSignatureAbbr: "Подписать ЭП",
		download: "Скачать",
		addManually: "Добавить вручную",
		additionalInterest: "Дополнительные проценты",
		recalculateInterest: "Переначислить проценты",
		massEdit: "Массовое редактирование",
		addInterest: "Добавить проценты",
		addPayment: "Добавить платеж",
		isForcedPayment: "Принудительное взыскание",
		importTreasuryStatement: "Импорт выписки",
		handleTreasuryStatement: "Обработать выписку",
		setTreasuryStatementRecordIsNotFinal: "Убрать п/п из обработанных",
		setTreasuryStatementRecordIsFinal: "Сохранить п/п",
		clearForm: "Очистить форму",
		finishProcessing: "Завершить обработку",
		more: "еще",
		addDebtor: "Добавить должника",
		addFinalGoal: "Добавить конечную цель",
		goToSign: "Перейти к подписанию",
		submitTrustOriginal: "Передать оригиналы документов",
		search: "Поиск",
		generatePassport: "Генерация паспорта",
		digitalSignature: {
			isSigned: "Подтверждена с помощью ЭП руководителя",
			default: "Подтвердить с помощью ЭП руководителя"
		},
		goToAuthenticationPage: "Перейти на страницу аутентификации",
		addDocument: "Добавить документ",
		sendNewDocumentRequest: "Направить заявку на публикацию",
		sendChangeDocumentRequest: "Направить заявку на согласование",
		shareDocument: "Поделиться документом",
		unpublish: "Снять с публикации",
		createChangeRequest: "Заявка на изменение",
		rejectRequest: "Отклонить",
		acceptRequest: "Согласовать",
		forgotPassword: "Забыли пароль?",
		login: "Войти",
		registerOrganization: "Регистрация организации",
		confirmCode: "Подтвердить код",
		resendCode: "Выслать код повторно",
		resendInstructions: {
			long: "выслать инструкции ещё раз",
			short: "выслать ещё раз"
		},
		toSignIn: "К авторизации",
		register: "Зарегистрироваться",
		addBankAccount: "Добавить счет",
		registerShip: "Зарегистрировать судно",
		archive: "Перенести в архив",
		unarchive: "Вынести из архива",
		registerCrewShip: "Зарегистрировать судно",
		addPier: "Добавить причал",
		addBoat: "Добавить судно",
		formAccount: "Сформировать счет",
		concludeAgreement: "Заключить договор",
		approve: "Одобрить",
		decline: "Отклонить",
		addServices: "Добавить услуги",
		addAgreement: "Добавить договор",
		goToAgreementCreation: "Перейти к созданию договора",
		leaveFeedback: "Оставить отзыв",
		repeatService: "Повторить услуги",
		repeatApplication: "Повторить заявку",
		orderServices: "Заказать услуги",
		newServices: "Новые услуги",
		addTugboat: "Добавить буксир",
		addCrewboat: "Добавить судно",
		addFloatingCrane: "Добавить плавкран",
		assignTugboat: "Назначить буксиры",
		assignCrewboat: "Назначить разъездные суда",
		assignFloatingCrane: "Назначить плавкраны",
		assignPier: "Назначить причал",
		assign: "Назначить",
		addServiceDocuments: "Загрузить отчетные документы",
		downloadDocumentTemplate: "Скачать типовой документ",
		formAgreement: "Сформировать договор",
		addPortDestination: "Добавить место начала и окончания операции",
		goBack: "Вернуться"
	},
	common: {
		myDocuments: "\"Мои документы\"",
		fileMustNotBeEmpty: "Файл не может быть пустым",
		phoneConfirmationError: "Телефон отсутствует или уже подтвержден",
		wrongCode: "Код неверный",
		vatIncluded: "в.т.ч. НДС",
		or: "или",
		legalPerson: "ИП",
		certificatesLength: "Число сертификатов",
		noData: "Отсутствуют данные",
		masterAccountEmailFormText: {
			successText: "На указанный адрес электронной почты направлено письмо с доступом к мастер-аккаунту.",
			editModeText: "Укажите адрес электронной почты. На этот адрес будет направлено письмо с доступом к мастер-аккаунту."
		},
		accountEmailFormText: {
			changeEmailSuccessText: "На указанный адрес электронной почты отправлено письмо со ссылкой для подтверждения.",
			editModeText: "Укажите новый адрес электронной почты. На этот адрес будет направлено письмо со ссылкой для подтверждения или же новые учётные данные для входа."
		},
		accountPasswordFormText: {
			changePasswordSuccessText: "Пароль успешно изменён. На указанный адрес электронной почты было направлено письмо с новым временным паролем.",
			editModeText: "Укажите новый пароль. После изменения на указанный адрес электронной почты будет направлено письмо с временным паролем."
		},
		masterAccountAccessContactInformationStep: {
			stepOne: "Регистрация организации доступна для юридических лиц и индивидуальных предпринимателей, входящих в реестры ЕГРЮЛ и ЕГРИП Федеральной налоговой системы. Введенный ИНН используется для получения сведений об организации из ЕГРЮЛ и ЕГРИП с целью верификации и автоматического заполнения сведений.",
			subtitleOne: "Если при вводе ИНН организации возникает ошибка, обратитесь в службу технической поддержки по электронной почте: {email}",
			stepTwo: "На указанный адрес электронной почты будут направлены данные для входа в аккаунт организации.",
			subtitleTwo: "Аккаунт организации позволяет вести реестр сотрудников: добавлять, редактировать, активировать и деактивировать. Используя такой аккаунт, нельзя заказать услуги или завести договор – для этого необходимо добавить сотрудника организации."
		},
		masterAccountAccessDocumentsStep: {
			toConfirmRightPerformActionsOnBehalfOf: "Для подтверждения права на выполнение действий от имени",
			legalEntity: "юридического лица",
			legalPerson: "индивидуального предпринимателя",
			attachDocumentsListBelow: "приложите перечень указанных ниже документов"
		},
		masterAccountAccessFinalStep: {
			default: "Результаты рассмотрения заявки будут направлены на указанный адрес электронной почты."
		},
		numbers: {
			adjectives: {
				"1": "Первый",
				"2": "Второй",
				"3": "Третий",
				"4": "Четвертый",
				"5": "Пятый",
				"6": "Шестой",
				"7": "Седьмой"
			}
		},
		emptyFileName: "Файл без названия",
		documentFromDate: "{number} от {date}",
		missing: "Отсутствует",
		tooltipTextSnils: "СНИЛС используется для проверки ЭП сотрудника",
		inn: "ИНН",
		ogrn: "ОГРН",
		lastAssessmentHint: "Типы последней оценки: [д] – договор, [с] - решение суда, [т] - результат торгов, [п] - результат переоценки",
		inMillionRubles: "млн. руб",
		counterpartyIsMissing: "Отсутствует необходимый контрагент?",
		signTrustOptions: {
			title: "Вы можете подписать доверенность одним из указанных способов:",
			option1: {
				title: "1. “Подписать ЭП”",
				text: "При выборе этого варианта сотрудники ФГУП \"Росморпорт\" рассматривают доверенность в течение двух рабочих дней. О результате рассмотрения пользователю приходит письмо на почту."
			},
			option2: {
				title: "2. “Передать документы”",
				text: "В случае, если у руководителя отсутствует возможность подписать доверенность ЭП, то для ее рассмотрения необходимо передать оригиналы документов в ближайшее управление ФГУП \"Росморпорт\". Контактная информация по ",
				link: "ссылке"
			}
		},
		uploadTrustHint: {
			title: "Загрузка доверенности",
			subtitle: "Для подписания отчётных документов ЭП сотрудника организации необходимо добавить доверенность на его имя.",
			steps: "Что нужно сделать:",
			step1: "1. Заполнить шаблон доверенности, подписать у руководителя организации;",
			step2: "2. Подписанную доверенность необходимо отсканировать вместе с приложениями и загрузить на Портал."
		},
		noSigningAuthorityHint: "Для подписания документов от имени организации необходимо войти под учетной записью генерального директора или добавить выбранному сотруднику доверенность.",
		// TODO: текст временный
		createServiceApplicationRequiredStepsHint: {
			step1: {
				title: "Шаг 1: Подготовка необходимых документов и прав",
				text1: "1.1. Перед началом процесса подачи заявки убедитесь, что у вас есть право осуществлять действия от лица генерального директора вашей организации.",
				text2: "1.2. Для подписи документов, связанных с буксирными услугами, вы можете использовать электронную подпись генерального директора. Если у вас нет электронной подписи, необходимо предоставить доверенность, разрешающую осуществление данного действия."
			},
			step2: {
				title: "Шаг 2: Проверка прав контрагента",
				text1: "2.1. Убедитесь, что у вашего контрагента (поставщика буксирных услуг) есть право на буксировку судна.",
				text2: "2.2. В случае, если у контрагента нет необходимых прав, перейдите по {url}."
			},
			step3: {
				title: "Шаг 3: Заключение договора",
				text1: "3.1. Для заключения договора на оказание буксирных услуг, перейдите по {url}.",
				text2: "3.2. Прочтите и ознакомьтесь с условиями договора и необходимыми документами.",
				text3: "3.3. Заполните все необходимые поля в заявке, включая информацию о ваших контактных данных и деталях буксировки.",
				text4: "3.4. Прикрепите к заявке копии необходимых документов, включая свидетельство о праве на буксировку судна (если требуется)."
			},
			step4: {
				title: "Шаг 4: Подача заявки",
				text1: "4.1. Перейдите на официальный веб-сайт контрагента или организации, предоставляющей буксирные услуги.",
				text2: "4.2. На сайте найдите раздел \"Подача заявки на буксировку\" или аналогичный, и следуйте инструкциям для заполнения и отправки заявки.",
				text3: "4.3. Убедитесь, что все ваши данные и документы прикреплены правильно и полностью.",
				text4: "4.4. Ожидайте подтверждения заявки от контрагента или управления буксировки судов."
			},
			summary: "Следуя этой инструкции, вы сможете подать заявку на оказание буксирных услуг, удостоверившись в наличии необходимых прав и документов. Не забывайте следовать официальным источникам и ресурсам при подаче заявки и заключении договоров.",
			link: "ссылке"
		},
		signatureIsMissing: "ЭП отсутствует",
		helpWorkWithDigitalSignature: "Справка по работе с ЭП",
		unPublished: "Документ снят с публикации",
		allFinanceSchemes: "Все программы",
		help: "Справка",
		confirmEmailFormText: {
			successText: "Адрес электронной почты подтвержден, вы можете перейти в личный кабинет",
			editModeText: "На адрес электронной почты отправлено письмо с кодом подтверждения"
		},
		confirmPhoneFormText: {
			successText: "Номер телефона подтвержден, вы можете перейти в личный кабинет",
			editModeText: "На номер телефона отправлено письмо с кодом подтверждения"
		},
		forgotPasswordFormText: {
			successText: {
				part1: "Если аккаунт",
				part2: "активен, на почту будет отправлено письмо с инструкцией для сброса пароля"
			}
		},
		resetPasswordFormText: {
			successText: "Перейдите к авторизации, чтобы войти в систему"
		},
		setPasswordFormText: {
			successText: "Пароль установлен, вы можете перейти в личный кабинет"
		},
		selectProfileText: "Выберите необходимый профиль для работы в системе:",
		resendCodeAfter: "Выслать код повторно через:",
		resendInstructionsAgainAfter: "Выслать инструкции повторно через:",
		seconds: "с",
		year: "г",
		itemNotInList: {
			title: "Отсутствует в списке",
			warning: "Не указано место начала или окончания операции. Для уточнения информации, пожалуйста, после отправки заявки перейдите в раздел «Комментарии» и добавьте комментарий с необходимыми сведениями о месте начала или окончания операции."
		}
	},
	dialogs: {
		archiveConfirm: {
			title: "Подтвердите перенос объекта в архив",
			text: "Обратите внимание, что после архивации объект будет недоступен для осуществления любых операций, но его можно будет восстановить в любой момент.\nВы уверены, что хотите продолжить?"
		},
		counterpartySendDocumentsConfirm: {
			accountingDocuments: {
				title: "Отчетные документы подписаны",
				text: "Вы завершили подписание отчетных документов по оказанным услугам. Отправить их на рассмотрение в ФГУП \"Росморпорт\"?"
			},
			agreement: {
				title: "Договор подписан",
				text: "Вы завершили подписание договора. Отправить договор на рассмотрение в ФГУП \"Росморпорт\"?"
			}
		},
		digitalSignatureInstructions: {
			title: "Проблемы с электронной подписью"
		},
		passwordExpiration: {
			title: "Рекомендуется изменить ваш пароль",
			description: "Число дней до истечения срока действия пароля: {days}. После истечения срока действия доступ к аккаунту будет потерян.",
			accept: "Сменить пароль",
			cancel: "Позже"
		},
		declineBoatApplication: {
			title: "Отклонить заявку на судно"
		},
		declineServiceApplication: {
			title: "Отклонить услуги"
		},
		cancelServiceApplication: {
			title: "Подтвердите отмену услуг"
		},
		declineEmployeeJoin: {
			title: "Отклонить заявку на прикрепление сотрудника к организации"
		},
		confirmCancelFormChanges: {
			title: "Отменить изменения?",
			description: "Последние изменения не были сохранены, данные могут быть утеряны."
		},
		confirmOperationWithUnsavedChanges: {
			title: "Изменения будут утеряны",
			description: "Последние изменения не были сохранены, выполнение операции приведет к их утере. Вы действительно хотите продолжить?"
		},
		confirmUnsavedChanges: {
			title: "Сохранить изменения?",
			description: "Форма содержит несохраненные изменения"
		},
		acceptUnappliedChanges: {
			title: "Предупреждение",
			description: "Система не сохранила некоторые из параметров",
			accept: "Понятно"
		},
		datePicker: {
			submit: "Подтвердить",
			cancel: "Отмена"
		},
		resetPassword: {
			title: "Сбросить пароль",
			description: "На указаную вами почту придет письмо с дальнейшими инструкциями по смене пароля"
		},
		deleteNote: {
			title: "Удалить запись",
			warning: "Вы действительно хотите удалить запись? Данное действие невозможно будет восстановить."
		},
		deletePayment: {
			title: "Удалить платеж",
			warning: "Вы действительно хотите удалить платеж? После нажатия кнопки “Удалить” отменить действие будет невозможно."
		},
		updateNotActiveAgreementStatus: {
			title: "Вы действительно хотите завершить действие договора?",
			warning: "Данное действие нельзя будет отменить, оформление услуг по данному договору станет невозможным."
		},
		masterAccountExit: {
			title: "Сменить аккаунт?"
		},
		declineTrust: {
			title: "Подтвердите отклонение доверенности"
		},
		declineApplication: {
			title: "Подтвердите отклонение запроса"
		},
		addDocument: {
			title: "Добавить документ"
		}
	},
	tooltips: {
		signIn: "Для входа в личный кабинет введите email зарегистрированного пользователя от организации. Для входа в мастер-аккаунт введите ИНН организации",
		download: "Скачать",
		archive: "Перенести в архив",
		unarchive: "Вернуть из архива"
	},
	errorsPages: {
		pageNotFound: "Что-то пошло не так, давайте попробуем сначала!",
		internalServer: "500 (Ошибка сервера)"
	},
	placeholders: {
		search: "Поиск",
		email: "email@mail.ru",
		password: "Введите пароль",
		request: "Введите запрос",
		dateMask: "дд.мм.гггг"
	},
	hints: {
		range: "Диапазон значений от {min} до {max}"
	},
	logs: {
		error: {
			requestError: "Ошибка выполнения запроса"
		},
		info: {
			request: "Запрос {url}",
			response: "Ответ {url}"
		}
	},
	navigation: {
		applications: {
			index: "Запросы и обращения",
			masterAccountAccess: "Доступ к мастер-аккаунту",
			employeeJoin: "Прикрепление к организации"
		},
		admin: {
			index: "Администрирование",
			counterparties: "Контрагенты",
			counterparty: "Контрагент",
			offices: "Предприятия",
			accounts: "Пользователи",
			applications: "Запросы"
		},
		counterparty: {
			documents: "Мои документы",
			boatApplications: "Мой флот",
			agreements: "Мои договоры",
			services: "Мои услуги"
		},
		enterprise: {
			tugBoats: "Буксиры",
			crewBoats: "Разъездные суда",
			floatingCranes: "Плавкраны",
			piers: "Причалы",
			branches: "Филиалы",
			services: "Услуги",
			counterparties: "Контрагенты",
			boatApplications: "Флот контрагентов",
			metrics: "Статистика",
			agreements: "Договоры",
			tariffs: "Тарифы",
			department: "Управление"
		},
		finance: {
			title: "Финансовый блок"
		},
		procuring: {
			title: "Обеспечение"
		},
		support: {
			title: "Техподдержка"
		},
		common: {
			legalEntity: "Организации",
			users: "Пользователи"
		},
		breadcrumbs: {},
		documents: "Реестр документов",
		requests: "Реестр заявок на изменение"
	},
	subheaders: {
		general: "Сведения",
		profile: "Профиль",
		profiles: "Профили",
		trusts: "Доверенности",
		employees: "Сотрудники",
		masterAccount: "Мастер-аккаунт",
		head: "Руководитель",
		organization: "Сведения об организации",
		employee: "Сведения о сотруднике",
		changeEmail: "Изменение адреса электронной почты",
		changePassword: "Изменение пароля",
		fieldFilter: "Фильтр по полю",
		setValue: "Установить значение",
		project: "Проект",
		mainInfo: "Основные данные",
		additionalInfo: "Дополнительные данные",
		document: "Документ",
		changeHistory: "История изменения",
		changeDocumentRequest: "Заявка по документу",
		bankDetails: "Банковские реквизиты",
		documents: "Документы",
		history: "История",
		comments: "Комментарии",
		services: "Услуги",
		ports: "Порты",
		departments: "Управления",
		metricsByTime: "Статистика по времени",
		metricsByCost: "Статистика по стоимости",
		metricsByProperty: "Статистика по объекту имущества",
		metricsByCounterparty: "Статистика по контрагентам",
		tariffGeneral: "Данные о тарифе",
		tariffs: "Тарифы",
		destinations: "Места начала и окончания операций"
	},
	titles: {
		agreements: "Договоры",
		agreement: "Договор",
		ownAgreements: "Мои договоры",
		newAgreement: "Новый договор",
		general: "Сведения",
		trust: "Доверенность",
		trusts: "Доверенности",
		profile: "Профиль",
		profiles: "Профили",
		roles: "Роли",
		applications: "Список заявок",
		counterparties: "Контрагенты",
		counterpartyCreate: "Новый контрагент",
		users: "Пользователи",
		characteristics: "Признаки",
		fns: "Контур.Фокус",
		createEmployee: "Новый сотрудник",
		createTrust: "Добавление доверенности",
		employee: "Сотрудник",
		employees: "Сотрудники",
		masterAccount: "Мастер-аккаунт",
		offices: "Предприятия",
		officeCreate: "Новое предприятие",
		head: "Руководитель",
		inviteAnEmployee: "Приглашение сотрудника",
		inviteToJoinAnOrganization: "Запрос на прикрепление к организации",
		invitesToJoinAnOrganization: "Запросы на присоединение к организации",
		requestForAccessToMasterAccount: "Запрос на получение доступа к мастер-аккаунту",
		requestsForAccessToMasterAccount: "Запросы",
		appealToDigitalSignature: "Обращение к электронной подписи",
		chooseDigitalSignature: "Выберите электронную подпись",
		loanAgreement: "Договор займа",
		loanAgreements: "Договоры займа",
		payments: "Фактическое движение денежных средств",
		overdues: "Просроченная задолженность",
		tranches: "Траншевое финансирование",
		loanSchedule: "График возврата займа",
		rmp: "ФГУП «Росморпорт»",
		signatureGeneral: "Сведения ЭП",
		certificateGeneral: "Сведения о сертификате",
		documentEditing: "Редактирование документа",
		interestByPeriod: "Проценты за период",
		overdueInterest: "Просроченная задолженность по процентам",
		penaltyInterest: "Реестр начисленных штрафов/пеней",
		accruedInterest: "Реестр начисленных процентов",
		treasuryStatementRecords: "Платежные поручения",
		treasuryStatementPayments: "Платежи",
		securityAgreements: "Обеспечение",
		productionStatus: "Статус производства в разрезе должников",
		debtorRequirements: "Реестр требований",
		attachDocumentsList: "Приложите перечень документов",
		enterContactInformation: "Шаг 2. Укажите контактную информацию",
		enterInnYourOrganization: "Шаг 1. Укажите ИНН организации",
		masterAccountAccessApplication: {
			legalEntityCounterpartyType: "Шаг 3. Подтвердите сведения об организации",
			legalPersonCounterpartyType: "Подтвердите сведения об индивидуальном предпринимателе"
		},
		publicDocuments: "База общедоступных документов",
		createNewDocumentRequest: "Заявка на создание нового документа",
		document: "Карточка документа",
		newDocument: "Новый документ",
		requestAccepting: "Согласование заявки",
		createUpdateDocumentRequest: "Заявка на изменение",
		createUnpublishDocumentRequest: "Заявка на снятие с публикации",
		requests: "Реестр заявок на изменение документов",
		auth: "Авторизация",
		confirmPhone: "Подтверждение номера телефона",
		confirmPhoneSuccess: "Номер успешно подтвержден!",
		confirmEmail: "Подтверждение почты",
		confirmEmailSuccess: "Адрес успешно подтвержден!",
		forgotPassword: "Восстановить доступ",
		resetPassword: "Установка нового пароля",
		selectProfile: "Выбор профиля",
		myDocuments: "Мои документы",
		welcome: "Добро пожаловать!",
		bankAccounts: "Счета",
		bankAccountCreate: "Новый счет",
		bankAccount: "Просмотр счета",
		tugBoats: "Буксиры",
		tugboatCreate: "Новое судно",
		crewboats: "Разъездные суда",
		crewboatCreate: "Новое разъездное судно",
		floatingCranes: "Плавкраны",
		floatingCraneCreate: "Новый плавкран",
		piers: "Причалы",
		pierCreate: "Новый причал",
		ownBoatApplications: "Мой флот",
		boatApplications: "Флот контрагентов",
		boatCreate: "Новое судно",
		servicesAgreement: "Договор на услуги",
		newServices: "Новые услуги",
		tugBoatSelection: "Выбор буксира",
		crewBoatSelection: "Выбор разъездных судов",
		floatingCraneSelection: "Выбор плавкранов",
		pierSelection: "Выбор причалов",
		metrics: "Статистика",
		tariffs: "Тарифы",
		portDestinationCreate: "Новое место начала и окончания операции"
	},
	metaTitles: {
		loan: "Кабинет заемщика",
		loanSchedule: "График платежей",
		accruedInterest: "Реестр начисленных процентов",
		overdueInterest: "Просроченные проценты и пени",
		agreementsAccruedInterest: "Начисление процентов",
		cashFlow: "Реестр ДДС",
		treasuryStatement: "Загрузка выписки УФК",
		troubledBorrower: "Паспорт проблемного заемщика",
		troubledBorrowers: "Реестр проблемных заемщиков",
		bodDocuments: "База общедоступных документов",
		createNewDocumentRequest: "Заявка на создание нового документа",
		document: "Карточка документа"
	},
	content: {
		noDocuments: "Документы отсутствуют",
		noData: "Отсутствуют данные",
		noFeedback: "Отзыв отсутствует",
		kopecks: "коп",
		rubles: "руб",
		noEmployees: "Нет сотрудников",
		noOffices: "Нет предприятий",
		noProfiles: "Нет профилей",
		noTariffs: "Нет тарифов",
		noCounterparties: "Нет контрагентов",
		noMasterAccountAccessApplications: "Нет заявок на доступ к мастер-аккаунту",
		noPorts: "Нет портов",
		noBranches: "Нет филиалов",
		noDepartments: "Нет управлений",
		noBoatApplications: "Нет судов",
		noFloatingCranes: "Нет плавкранов",
		noCrewboats: "Нет разъездных судов",
		noPiers: "Нет причалов",
		noTugboats: "Нет буксиров",
		noOwnDocuments: "У вас нет документов",
		noAgreements: "Нет договоров",
		filter: "Фильтры",
		chooseType: "Выберите тип",
		enterGeneral: "Укажите сведения",
		lastUpdateTime: "Время последнего обновления:",
		generalNotUpdated: "сведения не обновлялись",
		availableAfterSaving: "Доступно после сохранения",
		expireAt: "Доверенность до",
		owner: "Владелец",
		verificationCenter: "Удостоверяющий центр",
		from: "от",
		documentVersion: "Версия документа",
		titleDocument: "Документ основания",
		reasonForScheduleChange: "Причина изменения графика",
		comment: "Комментарий",
		withoutComments: "Без комментариев",
		noNotes: "Нет записей",
		total: "Итого",
		allInAll: "Всего",
		remainingDebt: "Остаток задолженности",
		loanScheduleVersionsNotFound: "Не найдено версий графика возврата займа",
		uploadScanOfSnilsDocumentAnHeadOfOrganization: "Загрузите скан документа СНИЛС руководителя организации",
		uploadScanOfSnilsDocumentAnEmployeeOfOrganization: "Загрузите скан документа СНИЛС сотрудника организации",
		youCanDownloadTrustTemplateInWordFormat: "Вы можете скачать шаблон доверенности в формате word",
		isProblem: "Является проблемой",
		agreementNumber: "Номер договора",
		noDebtors: "У вас нет должников",
		noBoats: "У вас нет судов",
		noTrusts: "У вас нет доверенностей",
		noBankAccounts: "Нет счетов",
		chooseModuleToContinue: "Выберите модуль для продолжения",
		agreements: "Договоры",
		selectedTugBoatsCount: "Выбрано буксиров",
		selectedCrewBoatsCount: "Выбрано разъездных судов",
		selectedFloatingCranesCount: "Выбрано плавкранов",
		selectedPiersCount: "Выбрано причалов",
		tugBoat: "Буксир",
		crewboat: "Разъездное судно",
		pier: "Причал",
		floatingCrane: "Плавкран",
		vat: "НДС",
		withoutVat: "Без НДС",
		withoutVatLowercase: "без НДС",
		noServices: "Нет услуг",
		noOwnAgreements: "У вас нет договоров",
		uploadServiceDocumentText: "Вы можете подписать сформированный документ или обновить текущий через загрузку файла.",
		agreementPaymentInvoice: "Cчет на оплату договора",
		noPortDestinations: "Нет мест начала и окончания операций"
	},
	validation: {
		bankAccountNumber: "Неверный номер счета",
		correspondentAccount: "Неверный корреспондентский счет",
		bankIdentificationCode: "Неверный БИК",
		inn: "Неверный ИНН",
		snils: "Неверный СНИЛС",
		snilsFormat: "Неверный формат файла СНИЛС",
		ogrn: "Неверный ОГРН",
		ogrnip: "Неверный ОГРНИП",
		kpp: "Неверный КПП",
		validPassword: "Неверный пароль",
		validPhone: "Неверный формат телефона",
		required: "Поле не может быть пустым",
		maxNumber: "Не более {number}",
		minNumber: "Не менее {number}",
		maxDate: "Не позднее {number}",
		minDate: "Не раньше {number}",
		validOnlyNumbers: "Неверный формат числа",
		validOnlyIntegerNumbers: "Поле принимает только целые числа",
		validOnlyMultipleOfHalfNumbers: "Поле принимает только числа, кратные 0.5",
		validIpAddress: "Неверный формат адреса",
		invalidDate: "Неверная дата",
		validLastname: "Неверный формат фамилии",
		validFirstname: "Неверный формат имени",
		validMiddleName: "Неверный формат отчества",
		validEmail: "Неверный формат почты",
		validTime: "Неверный формат времени",
		validConfirmedEmail: "Адреса электронной почты не совпадают",
		emailAlreadyUsedInAccount: "Указанный адрес уже используется в учётной записи",
		accountWithThisEmailIsExist: "Аккаунт с таким email уже существует",
		maxLength: "Максимальное количество символов: {number}",
		minLength: "Минимальное количество символов: {number}",
		year: "Неверный год",
		imo: "Неверный формат IMO",
		mmsi: "Неверный формат MMSI",
		calculationObjectCode: "Неверный формат КО",
		validOnlyCurrency: "Неверный формат суммы",
		passwordMinSymbols: "Нет символов | Не менее {n} символа | Не менее {n} символов",
		passwordMaxSymbols: "Нет символов | Не более {n} символа | Не более {n} символов",
		passwordMinUppercaseSymbols: "Нет букв | Не менее {n} заглавной буквы | Не менее {n} заглавных букв",
		passwordMinLowercaseSymbols: "Нет букв | Не менее {n} строчной буквы | Не менее {n} строчных букв",
		passwordNoSpaces: "Пароль не должен содержать пробелов",
		passwordMinDigits: "Нет цифр | Не менее {n} цифры | Не менее {n} цифр",
		passwordMinSpecialSymbols: "Нет специальных символов | Не менее {n} специального символа | Не менее {n} специальных символов",
		passwordMinUniqChars: "Минимальное число уникальных символов в пароле - {num}"
	},
	tables: {
		loading: "Загрузка данных...",
		createdAt: "Дата добавления",
		expireAt: "Действительно до",
		ogrn: "ОГРН",
		inn: "ИНН",
		kpp: "КПП",
		type: "Тип",
		counterpartyType: "Тип",
		phone: "Номер телефона",
		status: "Статус",
		fullName: "ФИО",
		heads: "ФИО руководителя",
		title: "Наименование",
		shortName: "Наименование",
		login: "Логин",
		password: "Пароль",
		email: "Email",
		position: "Должность",
		number: "Номер",
		acceptDate: "Дата акцептования",
		isSigned: "ЭП",
		roles: "Роли",
		description: "Описание",
		financeSource: "Субсидия",
		date: "Дата",
		dateFact: "Дата (факт)",
		quarter: "Квартал",
		amountRUB: "Сумма, ₽",
		isProblem: "Является проблемной",
		comment: "Комментарий",
		plannedRepaymentAmount: "Плановая сумма погашения",
		issuanceLoanRUB: "Выдача займа, ₽",
		actualRepaymentAmountRUB: "Фактическая сумма погашения, ₽",
		withdrawalForDelay: "Вынос на просрочку",
		paymentOfDelay: "Уплата просрочки",
		consider: "Учитывать",
		borrower: "Заемщик",
		agreement: "Договор",
		currentStatus: "Текущий статус",
		daysByPeriod: "Дней за период",
		accruedAmount: "Начислено (сумма), ₽",
		accountedAmount: "Передано в бухгалтерию, ₽",
		accountedDate: "Дата педачи в бухгалтерию",
		accrualType: "Тип начисления",
		accrualDate: "Дата начисления",
		paymentDate: "Дата оплаты",
		plannedPaymentDate: "Дата платежа",
		accruedAmountSum: "Сумма процентов",
		numberPaymentOrder: "№п/п",
		paymentExpense: "Статья ДДС",
		isForcedPayment: "Принудительное взыскание",
		paymentDescription: "Назначение платежа",
		trust: "Доверенность",
		pledges: "Залоги",
		securityKindAndLocation: "Вид обеспечения [местонахождения: субъект РФ]",
		guarantor: "Обеспечитель",
		agreementValue: "Стоимость по договору, млн. руб.",
		lastAssessmentDateAndType: "Дата последней оценки [тип проведенной оценки]",
		lastValuation: "Стоимость по последней оценке, млн. руб.",
		event: "Мероприятие",
		controlDate: "Контрольная дата",
		factualDate: "Фактическая дата",
		organizationShortName: "Сокращенное название организации",
		loanAgreements: "Договоры займа",
		frpSum: "Сумма ДЗ, млн. руб.",
		requirements: "Требования",
		fullRefundStatus: "Полный возврат",
		responsibleUser: "Ответственный",
		newTroubledBorrowerPassportGeneration: "Генерация",
		decliner: "Отклонил",
		documentSection: "Раздел",
		financingScheme: "Программа",
		documentTitle: "Название документа",
		responsibleDepartment: "Ответственное подразделение ФРП",
		documentOnDisk: "Документ на диске",
		share: "Поделиться",
		createdDate: "Дата создания",
		initiator: "Инициатор",
		requestType: "Тип заявки",
		changeWarrant: "Основание для изменения",
		changeDescription: "Суть изменения",
		documentName: "Наименование документа",
		documentType: "Вид документа",
		fileName: "Наименование файла",
		creationDate: "Дата добавления",
		expirationDate: "Истечение срока действия",
		bankAccountNumber: "Номер счета",
		bankIdentificationCode: "БИК",
		bankName: "Наименование банка",
		correspondentAccount: "Корреспондентский счет",
		name: "Название",
		shipType: "Тип судна",
		power: "Мощность, (кВт)",
		yearOfBuilding: "Год постройки",
		portOfRegistry: "Порт регистрации",
		port: "Порт",
		department: "Управление",
		flag: "Флаг",
		shipowner: "Судовладелец",
		action: "Действие",
		isArchived: "Статус",
		managementDepartments: "Управление",
		serviceNumber: "№ услуги",
		serviceType: "Тип услуги",
		counterpartyTitle: "Организация",
		division: "Управление",
		workflowState: "Статус",
		applicationDate: "Дата подачи заявки",
		employee: "Сотрудник",
		address: "Адрес",
		location: "Координаты",
		unit: "Единица измерения",
		algorithm: "Алгоритм",
		cost: "Стоимость",
		vat: "НДС",
		propertyName: "Наименование объекта",
		portDestinations: {
			shortName: "Краткое наименование",
			fullName: "Наименование"
		},
		englishName: "Английское наименование"
	},
	specialDetails: {
		pierGeneral: {
			name: "Название",
			length: "Длина причальной стенки, предназначенная для стоянки судов (м.)",
			depth: "Глубина у причальной стенки (м.)",
			status: "Текущий статус",
		},
		counterpartyEmployee: {
			inn: "Инн",
			email: "Адрес электронной почты",
			phone: "Номер телефона",
			position: "Должность",
			snils: "СНИЛС",
			firstName: "Имя",
			middleName: "Отчество",
			lastName: "Фамилия",
		},
		trust: {
			file: "Доверенность",
			certificateInfo: "ЭП",
			expireDate: "Действителен до",
			acceptedDate: "Дата и время акцептования",
			actionerFullName: "Кем акцептована"
		},
		boatGeneral: {
			name: "Название судна",
			portOfRegistry: "Порт регистрации",
			grt: "GRT (т.)",
			callSign: "Позывной",
			kind: "Тип судна",
			length: "Длина (м.)",
			flag: "Флаг",
			mmsi: "Код mmsi",
			power: "Мощность ГД (кВт.)",
			numberOfRegistry: "Регистровый номер",
			width: "Ширина (м.)",
			imo: "Номер IMO",
			height: "Высота борта (м.)",
			nrt: "NRT (т.)",
			deadWeight: "Дедвейт (т.)",
			status: "Текущий статус",
			exclusionGRT: "Исключение из GRT рег (т.)",
			yearOfBuilding: "Год постройки",
			precipitationMaxValue: "Максимальная осадка (м.)",
			calculatedGRT: "Расчетное значение GRT (т.)"
		},
		titles: {
			pierGeneral: "Основные данные о причале",
			counterpartyEmployee: "Сведения",
			boatGeneral: "Основные данные о судне",
			trust: "Доверенность",
			axapta: "Axapta",
			harborDues: "Harbor Dues",
			portDestination: "Основные сведения"
		},
		axapta: {
			calculationObjectCode: "Калькуляционный объект (КО)"
		},
		harborDues: {
			harborDuesIdentifier: "Идентификатор"
		},
		portDestination: {
			fullName: "Наименование",
			shortName: "Краткое наименование",
			englishName: "Английское наименование"
		}
	},
	details: {
		titles: {
			bankName: "Наименование банка",
			paymentMethod: "Тип оплаты",
			amountLimit: "Лимит",
			rejectDetails: "Сведения об отклонении",
			documents: "Документы",
			additionalDocuments: "Дополнительные документы",
			bankIdentificationCode: "БИК",
			opfName: "Наименование ОПФ",
			correspondentAccount: "Корреспондентский счет",
			bankAccountNumber: "Номер счета в банке",
			counterpartyRegisterDate: "Дата регистрации организации",
			legalPersonRegisterDate: "Дата регистрации ИП",
			registrationDate: "Дата регистрации",
			serviceProvider: "Организация, предоставляющая услугу",
			branch: "Филиал",
			general: "Основные сведения",
			boatGeneral: "Основные данные о судне",
			divisionGeneral: "Основные данные об управлении",
			axapta: "Axapta",
			harborDues: "Harbor Dues",
			legalPersonCounterpartyGeneral: "Данные об ИП",
			counterpartyGeneral: "Данные об организации",
			counterpartyHeadGeneral: "Данные о руководителе",
			boatDocuments: "Документы на судно",
			
			officeGeneral: "Сведения о Росморпорт",
			employeeGeneral: "Сведения о сотруднике",
			legalEntityGeneral: "Сведения об организации",
			legalPersonGeneral: "Сведения об индивидуальном предпринимателе",
			status: "Статус",
			name: "Наименование",
			fullName: "Наименование",
			fullPersonName: "ФИО",
			position: "Должность",
			snils: "СНИЛС",
			ogrn: "ОГРН",
			opf: "Организационно-правовая форма",
			ogrnip: "ОГРНИП",
			kpp: "КПП",
			inn: "ИНН",
			description: "Описание",
			phone: "Номер телефона",
			legalAddress: "Юридический адрес",
			requestorEmail: "Контактный адрес электронной почты",
			email: "Email",
			commonData: "Общие данные",
			documentsList: "Перечень документов",
			approvalApplicationResult: "Результат рассмотрения запроса",
			snilsFile: "Файл СНИЛС",
			owner: "Владелец",
			issuedBy: "Кем выдан",
			issuedTo: "Кому выдан",
			certificate: "Сертификат",
			expireDate: "Действителен до",
			expire: "Действителен",
			interestPeriod: "Процентный период",
			loanAgreementsCount: "Количество договоров займа",
			totalSum: "Общая сумма",
			interestAccruedAmount: "Доначислено процентов на сумму",
			treasuryStatementRecordsCount: "Всего документов",
			treasuryStatementOrderRecordSum: "Итого поступления",
			treasuryStatementPaymentRecordSum: "Итого выплаты",
			treasuryStatementFinalPaymentsSum: "Итого обработанные",
			treasuryStatementNotFinalPaymentsSum: "Итого необработанные",
			treasuryStatementRecord: "Платежное поручение",
			treasuryStatementRecordDocument: "{number} от {date}",
			counterparty: "Контрагент",
			kbk: "КБК",
			paymentSum: "Сумма",
			paymentPurpose: "Назначение платежа",
			borrower: "Заемщик",
			financingScheme: "Программа",
			loanAgreementsFrpSum: "Сумма ДЗ, млн. руб.",
			loanAgreementsRequirements: "Требования, млн. руб.",
			loanAgreementsMainDebt: "Основной долг",
			loanAgreementsInterest: "Проценты",
			loanAgreementsPenalty: "Неустойка",
			refund: "Возврат средств",
			refundStatus: "Полный возврат",
			partialRefund: "Частичный возврат, в т.ч.",
			refundWithoutAcceptance: "Безакцептно",
			refundBankGuarantee: "Банковская гарантия",
			refundPenalty: "Взыскание",
			remainingRefund: "Остаток к возврату",
			keyDates: "Ключевые даты",
			keyDatesRecall: "Отзыв [ЭС/НС]",
			keyDatesRequirements: "Требования",
			keyDatesDeclaration: "Исковое заявление",
			keyDatesSettlement: "Урегулирование [ЭС/НС]",
			responsibleUsers: "Ответственное подразделение",
			legalDepartmentUser: "ЮД",
			projectManagerUser: "ДП [В случае урегулирования]",
			currentDebtorStatusComment: "Текущий статус процесса взыскания",
			declineReason: "Причина отклонения",
			cancelReason: "Причина отмены",
			applicationDeclineReason: "Причина отклонения запроса",
			acceptor: "Одобрил",
			decliner: "Отклонил",
			canceller: "Отменил изменения",
			file: "Наименование",
			boatName: "Название судна",
			IMO: "Код IMO",
			flag: "Флаг",
			shipowner: "Судовладелец",
			GRT: "GRT (т.)",
			NRT: "NRT (т.)",
			boatLength: "Длина (м.)",
			boatHeight: "Высота борта (м.)",
			portOfRegistry: "Порт регистрации",
			yearOfBuilding: "Год постройки",
			callSign: "Позывной",
			precipitationMaxValue: "Максимальная осадка (м.)",
			MMSI: "Код MMSI",
			boatType: "Тип судна",
			exclusionGRT: "Исключение из GRT рег (т.)",
			calculatedGRT: "Расчетное значение GRT (т.)",
			boatDeadWeight: "Дедвейт (т.)",
			boatWidth: "Ширина (м.)",
			boatPower: "Мощность ГД (кВт.)",
			numberOfRegistry: "Регистровый номер",
			boatStatus: "Текущий статус",
			operationalData: "Оперативные данные",
			balanceInformation: "Сведения о балансе",
			customer: "Заказчик",
			customerSigner: "Подписант со стороны заказчика",
			executor: "Исполнитель",
			executorSigner: "Подписант со стороны исполнителя",
			agreementNumber: "Номер договора",
			agreementStartDate: "Дата начала действия договора",
			agreementEndDate: "Дата окончания действия договора",
			agreementSignDate: "Дата заключения договора",
			services: "Услуги",
			balance: "Баланс",
			shortName: "Краткое наименование",
			longName: "Полное наименование",
			trust: "Доверенность",
			lastName: "Фамилия",
			firstName: "Имя",
			middleName: "Отчество",
			terminationReason: "Причина расторжения",
			rejectReason: "Причина отклонения",
			actualAddress: "Фактический адрес",
			type: "Тип",
			agreementSignedDate: "Дата подписания договора",
			agreementExpiredDate: "Срок действия договора, до",
			division: "Управление",
			ports: "Порты",
			tugBoatsInfo: "Данные о буксирах",
			crewboatsInfo: "Данные о разъездных судах",
			piersInfo: "Данные о причалах",
			tugBoats: "Буксиры",
			crewboats: "Разъездные суда",
			pier: "Причал",
			costCalculation: "Расчет стоимости",
			tariff: "Тариф",
			vat: "НДС",
			preliminaryCostWithVat: "Предварительная стоимость (в том числе НДС)",
			providedServices: "Оказываемые услуги",
			serviceType: "Тип услуги",
			agreement: "Договор",
			port: "Порт",
			boat: "Судно",
			towingDateLocation: "Время и место буксировки",
			towDate: "Дата подачи буксира",
			towTime: "Время подачи буксира",
			towStartLocation: "Место начала буксировки",
			towEndLocation: "Место окончания буксировки",
			dateTimeLocation: "Дата, время и место",
			cost: "Стоимость",
			hoursCount: "Количество часов",
			servicesGeneral: "Сведения об услугах",
			serviceDescription: "Описание услуги",
			serviceStart: "Начало оказания услуг",
			serviceEnd: "Окончание оказания услуг",
			totalTimeSpent: "Всего затрачено времени",
			initiatorCounterparty: "Клиент",
			servicePrice: "Стоимость услуги",
			boatRepresentative: "Представитель судна",
			pierGeneral: "Основные данные о причале",
			englishName: "Английское наименование",
			portDestinationGeneral: "Основные данные о месте начала и окончания операции"
		},
		labels: {
			isRmpSigned: "Подпись со стороны ФГУП «Росморпорт»"
		},
		isSigned: {
			digitallySigned: "Подписано ЭП",
			rmpSigned: "Подпись со стороны ФГУП «Росморпорт»",
			counterpartySigned: "Подпись со стороны контрагента",
			digitallyMissed: "ЭП отсутствует"
		},
		values: {
			missing: "Отсутствует",
			missingData: "Сведения отсутствуют"
		},
		expireDate: "Действителен до",
		approvedDate: "Дата и время одобрения",
		acceptedDate: "Дата и время акцептования",
		acceptedEmployeeName: "Кем акцептована",
		declinedDate: "Дата и время отклонения",
		declinedEmployeeName: "Кем отклонена",
		canceledDate: "Дата и время отмены",
		declineReason: "Причина отклонения",
		cancelReason: "Причина отмены",
		canceledEmployeeName: "Кем отменена",
		phone: "Телефон",
		phoneConfirmationStatus: "Статус подтверждения телефона",
		isSignatureRequired: "Необходимо подтверждение с помощью ЭП при входе"
	},
	status: {
		Active: "Активный",
		Archived: "Архивный",
		Draft: "Черновик",
		Initiated: "На исполнении"
	},
	aliases: {
		paymentMethod: {
			[ApiAgreementPaymentMethodEnum.PRE_PAYMENT]: "Предоплата",
			[ApiAgreementPaymentMethodEnum.POST_PAYMENT]: "Постоплата"
		},
		approveStatus: {
			Created: "Открыт",
			Approved: "Одобрен",
			Rejected: "Отклонен",
			Undefined: "Неизвестно"
		},
		applicationStatus: {
			[ApplicationStatusType.CREATED]: "На рассмотрении",
			[ApplicationStatusType.APPROVED]: "Одобрено",
			[ApplicationStatusType.DECLINED]: "Отклонено"
		},
		boatsFilterStatus: {
			"All": "Все",
			"Created": "На рассмотрении",
			"Accepted": "Утвержден"
		},
		trustStatus: {
			Undefined: "Неизвестно",
			Created: "На рассмотрении",
			Accepted: "Одобрена",
			Declined: "Отклонена"
		},
		documents: {
			[STORAGE_SNILS_NAMESPACE]: "СНИЛС",
			[STORAGE_INN_NAMESPACE]: "ИНН",
			"trust/files": "Файл доверенности",
			signatureGeneral: "Сведения об ЭП",
			trustTemplate: "Шаблон доверенности",
			[STORAGE_APPOINTMENT_HEAD_ORDER]: "Приказ о назначении руководителем",
			[STORAGE_SIGNED_REQUEST]: "Запрос, подписанный ЭП руководителя",
			treasuryStatement: "Файл выписки",
			[DocumentType.ownershipCertificate]: "Свидетельство о праве на судно",
			[DocumentType.internationalMeasuringCertificate]: "Международное мерительное свидетельство",
			[DocumentType.classificationCertificate]: "Классификационное свидетельство",
			[DocumentType.internationalOilPollutionPreventionCertificate]: "Международное свидетельство о предотвращении загрязнения нефтью",
			[DocumentType.customDocuments]: "Иной документ",
			[DocumentType.agreement]: "Договор",
			[DocumentType.account]: "Счет",
			[DocumentType.outfitTasks]: "Наряд-задание",
			[DocumentType.servicesActs]: "Акт об оказании услуг",
			[DocumentType.invoices]: "Счет-фактура",
			[DocumentType.associationArticle]: "Устав организации",
			[DocumentType.associationMemorandum]: "Учредительный договор"
		},
		counterpartyType: {
			[CounterpartyTypeEnum.LEGAL_ENTITY]: "Юридическое лицо",
			[CounterpartyTypeEnum.LEGAL_PERSON]: "Индивидуальный предприниматель",
			[CounterpartyTypeEnum.PHYSICAL_PERSON]: "Физическое лицо",
			[CounterpartyTypeEnum.FOREIGN_ORGANIZATION]: "Иностранная организация"
		},
		counterpartyEmployeeTrustStatus: {
			[CounterpartyEmployeeTrustStatus.ABSENT]: "Отсутствует",
			[CounterpartyEmployeeTrustStatus.REVIEW]: "На рассмотрении",
			[CounterpartyEmployeeTrustStatus.ACTIVE]: "Действующая",
			[CounterpartyEmployeeTrustStatus.REJECTED]: "Отклонена",
			[CounterpartyEmployeeTrustStatus.OUTDATED]: "Срок действия истек"
		},
		activeStatus: {
			active: "Активен",
			inactive: "Неактивен"
		},
		documentStatus: {
			active: "Активный",
			archived: "Архивный"
		},
		enterprisePropertyStatus: {
			[ArchiveStatusType.ACTIVE]: "Активный",
			[ArchiveStatusType.ARCHIVED]: "В архиве"
		},
		affirmation: {
			yes: "Да",
			no: "Нет"
		},
		confirmation: {
			confirmed: "Подтверджен",
			notConfirmed: "Не подтвержден"
		},
		itemAvailable: {
			available: "Есть",
			notAvailable: "Нет"
		},
		userAgreement: {
			pushingButton: "Нажимая кнопку",
			send: "“Отправить”",
			iGiveMyConsentToProcessingMyPersonalData: "Вы подтверждаете согласие лица, данные о котором указаны в форме, на обработку персональных данных, в соответствии с Федеральным законом от 27.07.2006 года № 152-ФЗ",
			onPersonalData: "“О персональных данных”, на условиях и для целей, определенных в",
			consentToProcessingMyPersonalData: "Согласии на обработку персональных данных."
		},
		filterArchiveStatus: {
			[ArchiveStatusType.ALL]: "Все",
			[ArchiveStatusType.ACTIVE]: "Активные",
			[ArchiveStatusType.ARCHIVED]: "В архиве"
		},
		filterApplicationStatus: {
			[ApplicationFilterStatusType.ALL]: "Все",
			[ApplicationFilterStatusType.APPROVED]: "Одобрено",
			[ApplicationFilterStatusType.DECLINED]: "Отклонено",
			[ApplicationFilterStatusType.CREATED]: "На рассмотрении"
		},
		counterpartyDocumentType: {
			[ApiCounterpartyDocumentType.MEMORANDUM_OF_ASSOCIATION]: "Учредительный договор",
			[ApiCounterpartyDocumentType.ORDER_APPOINTING_THE_HEAD]: "Приказ о назначении руководителя",
			[ApiCounterpartyDocumentType.STATUTE_OF_THE_ORGANIZATION]: "Устав организации"
		},
		boatType: {
			[BoatType.Tugboat]: "Буксир",
			[BoatType.Crewboat]: "Разъездное судно",
			[BoatType.FloatingCrane]: "Плавкран"
		},
		branchType: {
			[BranchType.BASIN]: "Бассейновый"
		},
		boatApplicationDocumentType: {
			[BoatApplicationDocumentType.ownershipCertificate]: "Свидетельство о праве на судно",
			[BoatApplicationDocumentType.classificationCertificate]: "Классификационное свидетельство",
			[BoatApplicationDocumentType.customDocuments]: "Иной документ",
			[BoatApplicationDocumentType.internationalMeasuringCertificate]: "Международное мерительное свидетельство",
			[BoatApplicationDocumentType.internationalOilPollutionPreventionCertificate]: "Международное свидетельство о предотвращении загрязнения нефтью"
		},
		archiveStatus: {
			branch: {
				[ArchiveStatusType.ACTIVE]: "Активен",
				[ArchiveStatusType.ARCHIVED]: "В архиве"
			},
			department: {
				[ArchiveStatusType.ACTIVE]: "Активно",
				[ArchiveStatusType.ARCHIVED]: "В архиве"
			},
			port: {
				[ArchiveStatusType.ACTIVE]: "Активен",
				[ArchiveStatusType.ARCHIVED]: "В архиве"
			},
			pier: {
				[ArchiveStatusType.ACTIVE]: "Активен",
				[ArchiveStatusType.ARCHIVED]: "В архиве"
			},
			tugboat: {
				[ArchiveStatusType.ACTIVE]: "Активен",
				[ArchiveStatusType.ARCHIVED]: "В архиве"
			},
			floatingCrane: {
				[ArchiveStatusType.ACTIVE]: "Активен",
				[ArchiveStatusType.ARCHIVED]: "В архиве"
			},
			crewboat: {
				[ArchiveStatusType.ACTIVE]: "Активно",
				[ArchiveStatusType.ARCHIVED]: "В архиве"
			},
			tariffs: {
				[ArchiveStatusType.ACTIVE]: "Активно",
				[ArchiveStatusType.ARCHIVED]: "В архиве"
			}
		},
		service: {
			[ServiceTypeEnum.UNDEFINED_SERVICE]: "Не определено",
			[ServiceTypeEnum.TUGBOAT_SERVICE]: "Буксирные услуги",
			[ServiceTypeEnum.CREWBOAT_SERVICE]: "Услуги по предоставлению разъездных судов",
			[ServiceTypeEnum.INFORMATION_SERVICE]: "Информационные услуги",
			[ServiceTypeEnum.FLOATING_CRANE_SERVICE]: "Услуги плавкрана",
			[ServiceTypeEnum.PIER_SERVICE]: "Услуги по предоставлению причалов для стоянки судов",
			[ServiceTypeEnum.GEODETIC_HYDROGRAPHIC_SERVICE]: "Геодезические и гидрографические услуги",
			[ServiceTypeEnum.SNO_INSTALLATION_SERVICE]: "Услуги по установке и обслуживанию СНО",
			[ServiceTypeEnum.DREDGING_WORKS_SERVICE]: "Дноуглубительные услуги"
		},
		serviceStatusFilter: {
			ALL: "Все"
		},
		propertyName: {
			[ApiPropertyType.Tugboat]: "Буксир",
			[ApiPropertyType.Crewboat]: "Разъездное судно",
			[ApiPropertyType.FloatingCrane]: "Плавкран",
			[ApiPropertyType.Pier]: "Причал"
		},
		masterAccountAccessStatusFilter: {
			[MasterAccountAccessStatusFilterEnum.CREATED]: "Открыт",
			[MasterAccountAccessStatusFilterEnum.APPROVED]: "Одобрен",
			[MasterAccountAccessStatusFilterEnum.REJECTED]: "Отклонен"
		},
		trustActionerKind: {
			[TrustActionerKind.ACCEPTOR]: "Одобрил",
			[TrustActionerKind.DECLINER]: "Отклонил",
			[TrustActionerKind.CANCELLER]: "Отменил изменения"
		}
	},
	links: {
		agreementServices: "Список услуг по договору"
	},
	boat: {
		imo: "Код IMO",
		mmsi: "Код MMSI",
		flag: "Флаг",
		volume: "Объем судна, м3",
		numberOfRegistry: "Регистровый номер"
	},
	servicesItem: {
		number: "№",
		type: "Тип услуг",
		counterparty: "Контрагент",
		agreement: "Договор",
		department: "Управление",
		port: "Порт",
		createdAt: "Дата подачи заявки",
		status: "Статус"
	},
	service: {
		tariff: {
			[ApiServiceTariffsEnum.PER_CUBIC_METER]: "Условный объем",
			[ApiServiceTariffsEnum.PER_HOUR]: "Почасовая ставка",
			[ApiServiceTariffsEnum.PER_DAY]: "Посуточная ставка",
			[ApiServiceTariffsEnum.PER_MONTH]: "Помесячная ставка",
			approximateCost: "Предварительная стоимость",
			approximateCostWithVat: "Предварительная стоимость (в том числе НДС)",
			costWithVat: "Стоимость (в том числе НДС)",
			price: "Стоимость",
			hoursNumber: "Количество часов",
			hoursNumberPlaceholder: "Введите количество",
			monthsNumber: "Количество месяцев",
			monthsNumberPlaceholder: "Введите количество",
			daysNumber: "Количество дней",
			daysNumberPlaceholder: "Введите количество",
			vat: "НДС",
			withoutVat: "Без НДС",
			priceTitle: {
				[ApiServiceTariffsEnum.PER_CUBIC_METER]: "Стоимость кубического метра",
				[ApiServiceTariffsEnum.PER_HOUR]: "Стоимость часа работы",
				[ApiServiceTariffsEnum.PER_DAY]: "Стоимость за сутки",
				[ApiServiceTariffsEnum.PER_MONTH]: "Стоимость за месяц"
			},
			rateTitle: {
				[ApiServiceTariffsEnum.PER_CUBIC_METER]: "Ставка за кубический метр",
				[ApiServiceTariffsEnum.PER_HOUR]: "Часовая ставка",
				[ApiServiceTariffsEnum.PER_DAY]: "Суточная ставка",
				[ApiServiceTariffsEnum.PER_MONTH]: "Месячная ставка"
			},
			valueTitle: {
				[ApiServiceTariffsEnum.PER_CUBIC_METER]: "Объем судна, м3",
				[ApiServiceTariffsEnum.PER_HOUR]: "Количество часов",
				[ApiServiceTariffsEnum.PER_DAY]: "Количество часов",
				[ApiServiceTariffsEnum.PER_MONTH]: "Количество месяцев"
			}
		},
		agreement: {
			title: {
				[ServiceTypeEnum.PIER_SERVICE]: "Договор на оказание услуг по предоставлению причала",
				[ServiceTypeEnum.TUGBOAT_SERVICE]: "Договор на оказание буксирных услуг",
				[ServiceTypeEnum.CREWBOAT_SERVICE]: "Договор на оказание услуг по предоставлению разъездных судов",
				[ServiceTypeEnum.DREDGING_WORKS_SERVICE]: "Договор на оказание дноуглубительных услуг",
				[ServiceTypeEnum.SNO_INSTALLATION_SERVICE]: "Договор на услуги по установке и обслуживанию СНО ",
				[ServiceTypeEnum.GEODETIC_HYDROGRAPHIC_SERVICE]: "Договор на оказание геодезических и гидрографических услуг",
				[ServiceTypeEnum.FLOATING_CRANE_SERVICE]: "Договор на оказание услуг плавкрана",
				[ServiceTypeEnum.INFORMATION_SERVICE]: "Договор на оказание информационных услуг"
			}
		},
		tugBoat: {
			title: "Наименование буксира"
		},
		dateLocation: {
			form: {
				title: {
					default: "",
					[ServiceTypeEnum.TUGBOAT_SERVICE]: "Время и место",
					[ServiceTypeEnum.CREWBOAT_SERVICE]: "Время и место",
					[ServiceTypeEnum.PIER_SERVICE]: "Дата и время"
				},
				date: {
					[ServiceTypeEnum.TUGBOAT_SERVICE]: "Дата подачи буксира",
					[ServiceTypeEnum.CREWBOAT_SERVICE]: "Дата начала использования",
					[ServiceTypeEnum.PIER_SERVICE]: "Дата начала использования"
				},
				time: {
					[ServiceTypeEnum.TUGBOAT_SERVICE]: "Время подачи буксира",
					[ServiceTypeEnum.CREWBOAT_SERVICE]: "Время начала использования",
					[ServiceTypeEnum.PIER_SERVICE]: "Время начала использования"
				},
				startLocation: {
					default: "Место начала работы",
					[ServiceTypeEnum.TUGBOAT_SERVICE]: "Место начала",
					[ServiceTypeEnum.CREWBOAT_SERVICE]: "Место начала"
				},
				endLocation: {
					default: "Место окончания работы",
					[ServiceTypeEnum.TUGBOAT_SERVICE]: "Место окончания",
					[ServiceTypeEnum.CREWBOAT_SERVICE]: "Место окончания"
				},
				startDate: "Дата начала работы",
				endDate: "Дата окончания работы",
				startTime: "Время начала работы",
				endTime: "Время окончания работы"
			},
			details: {
				title: {
					[ServiceTypeEnum.TUGBOAT_SERVICE]: "Время и место",
					[ServiceTypeEnum.CREWBOAT_SERVICE]: "Время и место",
					[ServiceTypeEnum.PIER_SERVICE]: "Дата и время"
				},
				startLocation: {
					[ServiceTypeEnum.TUGBOAT_SERVICE]: "Место начала",
					[ServiceTypeEnum.CREWBOAT_SERVICE]: "Место начала"
				},
				endLocation: {
					[ServiceTypeEnum.TUGBOAT_SERVICE]: "Место окончания",
					[ServiceTypeEnum.CREWBOAT_SERVICE]: "Место окончания"
				},
				startDate: "Дата начала использования",
				startTime: "Время начала использования",
				endDate: "Дата окончания использования",
				endTime: "Время окончания использования"
			}
		},
		workflowState: {
			[ApiAllServiceWorkflowStateTypeEnum.NEW]: "Созданы",
			[ApiAllServiceWorkflowStateTypeEnum.CANCELED]: "Отменены",
			[ApiAllServiceWorkflowStateTypeEnum.REJECTED]: "Отклонены",
			[ApiAllServiceWorkflowStateTypeEnum.DRAFT]: "Черновик",
			[ApiAllServiceWorkflowStateTypeEnum.SENT]: "Отправлены в ФГУП \"Росморпорт\"",
			[ApiAllServiceWorkflowStateTypeEnum.APPROVED_BY_DISPATCHER]: "Одобрены диспетчером",
			[ApiAllServiceWorkflowStateTypeEnum.APPROVED_BY_PILOT]: "Одобрены лоцманом",
			[ApiAllServiceWorkflowStateTypeEnum.PROVIDING]: "Услуги на исполнении",
			[ApiAllServiceWorkflowStateTypeEnum.PROVIDED]: "Услуги исполнены",
			[ApiAllServiceWorkflowStateTypeEnum.ACCOUNTING_DOCUMENTS_ATTACHED]: "Отчетные документы загружены",
			[ApiAllServiceWorkflowStateTypeEnum.FINALLY_SIGNED_BY_ENTERPRISE]: "Требуется подпись контрагента",
			[ApiAllServiceWorkflowStateTypeEnum.FINALLY_SIGNED_BY_COUNTERPARTY]: "Отчетные документы подписаны контрагентом",
			[ApiAllServiceWorkflowStateTypeEnum.COMPLETED]: "Услуги выполнены",
			[ApiAllServiceWorkflowStateTypeEnum.APPROVED_BY_FINANSIST]: "Приняты сотрудником финансового отдела",
			[ApiAllServiceWorkflowStateTypeEnum.APPROVED_BY_NGO_SERVICE_EMPLOYEE]: "Одобрены сотрудником службы НГО",
			[ApiAllServiceWorkflowStateTypeEnum.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE]: "Одобрены сотрудником службы эксплуатации флота",
			[ApiAllServiceWorkflowStateTypeEnum.APPROVED_BY_FLEET_OPERATIONS_HEAD_OFFICE_EMPLOYEE]: "Одобрены сотрудником управления эксплуатации флота",
			[ApiAllServiceWorkflowStateTypeEnum.INITIALLY_SIGNED_BY_ENTERPRISE]: "Договор подписан ФГУП \"Росморпорт\"",
			[ApiAllServiceWorkflowStateTypeEnum.INITIALLY_SIGNED_BY_COUNTERPARTY]: "Договор подписан контрагентом"
		},
		workflowTransition: {
			[ServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE]: "Отправить на подписание контрагенту",
			[ServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY]: "Отправить документы",
			[ServiceWorkflowStateType.COMPLETED]: "Завершить услугу",
			[ServiceWorkflowStateType.APPROVED_BY_FINANSIST]: "Принять",
			[ServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED]: "Отправить на подписание",
			dredging: {
				[DredgingServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE]: "Отправить договор",
				[DredgingServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE]: "Отправить на подписание контрагенту",
				[DredgingServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY]: "Отправить документы",
				[DredgingServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE]: "Отправить на подписание контрагенту",
				[DredgingServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY]: "Отправить документы",
				[DredgingServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED]: "Отправить на подписание"
			},
			geodeticHydrographic: {
				[GeodeticHydrographicServiceWorkflowStateType.APPROVED_BY_NGO_SERVICE_EMPLOYEE]: "Отправить договор",
				[GeodeticHydrographicServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE]: "Отправить на подписание контрагенту",
				[GeodeticHydrographicServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY]: "Отправить документы",
				[GeodeticHydrographicServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE]: "Отправить на подписание контрагенту",
				[GeodeticHydrographicServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY]: "Отправить документы",
				[GeodeticHydrographicServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED]: "Отправить на подписание"
			},
			snoInstallation: {
				[SnoInstallationServiceWorkflowStateType.APPROVED_BY_NGO_SERVICE_EMPLOYEE]: "Отправить договор",
				[SnoInstallationServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE]: "Отправить на подписание контрагенту",
				[SnoInstallationServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY]: "Отправить документы",
				[SnoInstallationServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE]: "Отправить на подписание контрагенту",
				[SnoInstallationServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY]: "Отправить документы",
				[SnoInstallationServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED]: "Отправить на подписание"
			},
			floatingCrane: {
				[FloatingCraneServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE]: "Отправить на подписание контрагенту",
				[FloatingCraneServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY]: "Отправить документы",
				[FloatingCraneServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE]: "Отправить на подписание контрагенту",
				[FloatingCraneServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY]: "Отправить документы",
				[FloatingCraneServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED]: "Отправить на подписание"
			}
		},
		propertyTariff: {
			multipleSelectionAlert: {
				[ServiceTypeEnum.TUGBOAT_SERVICE]: "Вы можете выбрать несколько тарифов для каждого буксира",
				[ServiceTypeEnum.CREWBOAT_SERVICE]: "Вы можете выбрать несколько тарифов для каждого разъездного судна",
				[ServiceTypeEnum.PIER_SERVICE]: "Вы можете выбрать несколько тарифов для каждого причала"
			},
			title: {
				[ServiceTypeEnum.TUGBOAT_SERVICE]: "Данные о буксирах",
				[ServiceTypeEnum.CREWBOAT_SERVICE]: "Данные о разъездных судах",
				[ServiceTypeEnum.PIER_SERVICE]: "Данные о причалах",
				[ServiceTypeEnum.FLOATING_CRANE_SERVICE]: "Данные о плавкранах",
				[ServiceTypeEnum.INFORMATION_SERVICE]: "Фактические данные о тарифе"
			},
			propertyName: {
				[ServiceTypeEnum.TUGBOAT_SERVICE]: "Название судна",
				[ServiceTypeEnum.CREWBOAT_SERVICE]: "Название судна",
				[ServiceTypeEnum.PIER_SERVICE]: "Причал",
				[ServiceTypeEnum.FLOATING_CRANE_SERVICE]: "Плавкран"
			},
			property: {
				[ServiceTypeEnum.TUGBOAT_SERVICE]: "Буксир",
				[ServiceTypeEnum.CREWBOAT_SERVICE]: "Разъездное судно",
				[ServiceTypeEnum.PIER_SERVICE]: "Причал",
				[ServiceTypeEnum.FLOATING_CRANE_SERVICE]: "Плавкран"
			}
		},
		document: {
			[ApiServiceDocumentKindEnum.SERVICE_ACCEPTANCE_CERTIFICATE]: "Акт об оказании услуг",
			[ApiServiceDocumentKindEnum.FACTURE_INVOICE]: "Счёт-фактура",
			[ApiServiceDocumentKindEnum.INVOICE]: "Счёт",
			[ApiServiceDocumentKindEnum.GRAPHIC_ANNEX]: "Графическое приложение",
			[ApiServiceDocumentKindEnum.BUILDING_PERMIT]: "Разрешение на строительство",
			[ApiServiceDocumentKindEnum.NATURE_INSPECTOR_RESOLUTION]: "Разрешение Росприроднадзора на захоронение донного грунта, извлеченного при производстве работ по реализации указанного проекта",
			[ApiServiceDocumentKindEnum.CHIEF_STATE_EXPERTISE]: "Главгосэкспертиза о ГЭЭ",
			[ApiServiceDocumentKindEnum.PROVIDING_WATER_FACILITY_DECISION]: "Решение о предоставлении водного объекта (свалка грунта) в пользование Заказчика",
			[ApiServiceDocumentKindEnum.ROSFISHING_CONCLUSION]: "Заключение Росрыболовства о согласовании осуществления деятельности в рамках реализации указанного проекта",
			[ApiServiceDocumentKindEnum.AGREEMENT]: "Договор на оказание услуг",
			[ApiServiceDocumentKindEnum.ORGANIZATION_CHARTER]: "Устав организации",
			[ApiServiceDocumentKindEnum.FOUNDATION_AGREEMENT]: "Учредительный договор",
			[ApiServiceDocumentKindEnum.WORK_ORDER]: "Наряд-задание",
			[ApiServiceDocumentKindEnum.ORDER_ASSIGNMENT]: "Приказ-задание"
		}
	},
	agreement: {
		status: {
			[AgreementWorkflowStateType.NEW]: "Создан",
			[AgreementWorkflowStateType.CANCELED]: "Отменен",
			[AgreementWorkflowStateType.REJECTED]: "Отклонен",
			[AgreementWorkflowStateType.DRAFT]: "Черновик",
			[AgreementWorkflowStateType.SENT]: "На рассмотрении у сотрудника ФГУП \"Росморпорт\"",
			[AgreementWorkflowStateType.NEGOTIATED]: "Ожидает подпись ФГУП \"Росморпорт\"",
			[AgreementWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE]: "Ожидает подпись сотрудника контрагента",
			[AgreementWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY]: "Подписан сотрудником контрагента",
			[AgreementWorkflowStateType.ACTIVE]: "Активен",
			[AgreementWorkflowStateType.NOT_ACTIVE]: "Неактивен",
			[ServiceTypeEnum.TUGBOAT_SERVICE]: {
				[AgreementWorkflowStateType.SENT]: "На рассмотрении у сотрудника службы эксплуатации флота"
			},
			[ServiceTypeEnum.PIER_SERVICE]: {
				[AgreementWorkflowStateType.SENT]: "На рассмотрении у диспетчера"
			},
			[ServiceTypeEnum.CREWBOAT_SERVICE]: {
				[AgreementWorkflowStateType.SENT]: "На рассмотрении у сотрудника службы эксплуатации флота"
			},
			[ServiceTypeEnum.INFORMATION_SERVICE]: {
				[AgreementWorkflowStateType.SENT]: "На рассмотрении у сотрудника финансового отдела"
			}
		},
		document: {
			[AgreementDocumentKindEnum.HEAD_APPOINTMENTS_ORDER]: "Приказ о назначении руководителя",
			[AgreementDocumentKindEnum.ORGANIZATION_CHARTER]: "Устав организации",
			[AgreementDocumentKindEnum.FOUNDATION_AGREEMENT]: "Учредительный договор",
			[AgreementDocumentKindEnum.AGREEMENT_NEGOTIATION_LIST]: "Лист согласования договора",
			[AgreementDocumentKindEnum.AGREEMENT]: "Договор"
		},
		workflowTransition: {
			[AgreementWorkflowStateType.NEGOTIATED]: "Отправить на подписание",
			[AgreementWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE]: "Отправить на подписание контрагенту",
			[AgreementWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY]: "Отправить документы",
			[AgreementWorkflowStateType.ACTIVE]: "Активировать договор",
			[AgreementWorkflowStateType.NOT_ACTIVE]: "Завершить"
		}
	},
	agreementsItem: {
		agreementNumber: "№",
		counterpartyShortName: "Контрагент",
		serviceKind: "Тип услуг",
		department: "Управление",
		agreementDate: "Дата договора",
		port: "Порты",
		startDate: "Дата начала",
		endDate: "Дата окончания",
		amount: "Баланс",
		state: "Статус"
	},
	boatApplicationsItem: {
		counterparty: "Организация",
		name: "Название",
		createdAt: "Дата подачи заявки",
		status: "Статус"
	},
	metricsItem: {
		serviceType: "Тип услуг",
		averageProcessingTime: "Среднее время обработки, ч",
		averageDelayTime: "Среднее время задержки, ч",
		minCost: "Минимальная сумма, ₽",
		maxCost: "Максимальная сумма, ₽",
		averageCost: "Средняя сумма, ₽",
		totalCost: "Общая сумма, ₽",
		propertyName: "Наименование объекта",
		propertyType: "Тип объекта",
		activeServices: "Активные услуги",
		declinedServices: "Количество отказов",
		totalServices: "Всего заявок",
		providedServices: "Количество оказанных услуг",
		newCounterpartyEmployeesCount: "Количество новых сотрудников контрагента",
		newCounterpartiesCount: "Количество новых контрагентов",
		services: "Услуги",
		providedDate: "Дата выполнения",
		port: "Порт",
		sum: "Сумма, ₽",
		status: "Статус"
	},
	metrics: {
		subtitles: {
			byTime: "Время обработки услуг",
			byCost: "Статистика по стоимости"
		},
		periods: {
			[MetricsPeriodType.CURRENT_MONTH]: "Текущий месяц",
			[MetricsPeriodType.PAST_MONTH]: "Прошедший месяц",
			[MetricsPeriodType.CURRENT_QUARTER]: "Текущий квартал",
			[MetricsPeriodType.PAST_QUARTER]: "Прошедший квартал",
			[MetricsPeriodType.CURRENT_YEAR]: "Текущий год",
			[MetricsPeriodType.PAST_YEAR]: "Прошедший год",
			[MetricsPeriodType.PERIOD]: "За период"
		}
	},
	warnings: {
		documentsInfo: "Вы можете воспользоваться разделом {0}{1}для загрузки и хранения ваших документов. Это позволит вам использовать их в дальнейшем без необходимости повторной загрузки."
	}
};
