import { ApiAccountProfileBase } from "@rmp/core/api/types/account/profile/apiAccountProfileBase";
import { ProfileType } from "@rmp/core/types/ProfileType";

export default class ApiOfficeEmployeeProfile extends ApiAccountProfileBase {
	inn: string;
	number: number;
	snils: string;
	externalId: string;
	officeInn: string;
	officeOgrn: string;
	officeKpp: string;
	
	constructor(
		type = ProfileType.OFFICE_EMPLOYEE,
		number = 0,
		accountId: string = "",
		title: string = "",
		email: string = "",
		phone: string = "",
		inn: string = "",
		snils: string = "",
		isActive: boolean = false,
		externalId = "",
		officeInn = "",
		officeOgrn = "",
		officeKpp = ""
	)
	{
		super(type, accountId, externalId, title, email, phone, isActive);
		
		this.inn = inn;
		this.number = number;
		this.snils = snils;
		this.externalId = externalId;
		this.officeInn = officeInn;
		this.officeOgrn = officeOgrn;
		this.officeKpp = officeKpp;
	}
}
