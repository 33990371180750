import { parseISO } from "date-fns";
import { ApiServiceDocument } from "@rmp/core/api/types/service/document/apiServiceDocument";
import { ApiServiceDocumentKindEnum } from "@rmp/core/api/types/service/document/ApiServiceDocumentKindEnum";
import { i18n } from "@rmp/core/plugins";
import { BaseDocument } from "@rmp/core/types/services/baseDocument";

export interface ServiceDocument extends BaseDocument {
	documentTypeId: string;
	createdAt: Date;
	updatedAt: Date;
	documentKind: ApiServiceDocumentKindEnum;
	isSigning: boolean;
}

export class ServiceDocumentMapper {
	static map(source: ApiServiceDocument, titleFirstPart: string): ServiceDocument {
		return {
			...source,
			createdAt: parseISO(source.createdAt),
			updatedAt: parseISO(source.updatedAt),
			isSigning: false,
			fileName: `${titleFirstPart} - ${i18n.t(`service.document.${source.documentKind}`)}`
		};
	}
}
