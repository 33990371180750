import AbortService from "@rmp/core/services/abortService";
import Breadcrumb from "@rmp/core/stores/composables/page/types/breadcrumb";
import { useAppStore } from "@rmp/core/stores/appStore";
import NotImplementedException from "@rmp/core/exceptions/notImplementedException";
import { defineStore } from "pinia";

export interface PageState {
	initialized: boolean;
}


export function usePageStore(abortService: AbortService) {
	const getDefaultState = (): PageState => {
		return {
			initialized: false
		};
	};
	
	const getters = {
		breadcrumbs() {
			return [];
		}
	};
	
	const actions = {
		async beforeInitialized(...args: any) {
			throw new NotImplementedException("beforeInitialized хук не был определен");
		},
		async initialize(...args: any) {
			const { setBreadcrumbs, setBreadcrumbsLoading } = useAppStore();
			
			// TODO Костыль немного. Сначала смотрим есть ли геттер с хлебными крошками, если есть, отображаем loader
			// @ts-ignore
			if(this.breadcrumbs.length) {
				setBreadcrumbsLoading(true);
			} else {
				setBreadcrumbs([]);
			}
			
			abortService.initialize();
			
			await this.beforeInitialized(...args);
			
			// @ts-ignore
			this.initialized = true;
			
			// @ts-ignore
			setBreadcrumbs(this.breadcrumbs);
			setBreadcrumbsLoading(false);
		},
		destroyBase() {
			abortService.abort();
		},
		destroy() {
			this.destroyBase();
			// @ts-ignore
			this.$reset();
		},
		setBreadcrumbs(this: PageState, value: Breadcrumb[]) {
		}
	};
	
	return {
		actions, getters,
		getDefaultPageState: getDefaultState
	};
}

let storeComposables: ReturnType<typeof usePageStore>;
export type PageStoreDefinition =
	ReturnType<typeof defineStore<string, PageState, typeof storeComposables.getters, typeof storeComposables.actions & {
		[key: string]: any
	}>> | any;
