import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { ChatState, useChatStore } from "@rmp/core/stores/composables/chat";
import { ServiceController } from "@rmp/counterparty/api/service";
import {
	useFloatingCraneServiceChatBreadcrumb
} from "@rmp/counterparty/stores/serviceDetails/floatingCrane/chat/composables/useFloatingCraneServiceChatBreadcrumb";
import { useFloatingCraneServiceStore } from "@rmp/counterparty/stores/serviceDetails/floatingCrane";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceController = new ServiceController(abortService);
const chatStore = useChatStore({ controller: serviceController });

export interface FloatingCraneServiceChatState extends PageState, ChatState {
	id: string | null;
}

const getDefaultState = (): FloatingCraneServiceChatState => {
	return {
		...page.getDefaultPageState(),
		...chatStore.getDefaultState(),
		id: null
	};
};

export const useFloatingCraneServiceChatStore = defineStore({
	id: "floating-crane-service-chat",
	state: (): FloatingCraneServiceChatState => getDefaultState(),
	getters: {
		...page.getters,
		...chatStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useFloatingCraneServiceStore();
			return [...breadcrumbs, useFloatingCraneServiceChatBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...chatStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.initializeChatStore(id);
		}
	}
});
