import { defineStore } from "pinia";
import { useServiceStepStore } from "@rmp/counterparty/stores/agreementApplication/steps/service";
import NotImplementedException from "@rmp/core/exceptions/notImplementedException";
import { AgreementApplicationStepTypeEnum } from "@rmp/counterparty/stores/agreementApplication/steps/shared/AgreementApplicationStepTypeEnum";
import { useDepartmentStepStore } from "@rmp/counterparty/stores/agreementApplication/steps/department";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useConfirmationStepStore } from "@rmp/counterparty/stores/agreementApplication/steps/confirmation";
import { useCounterpartyStepStore } from "@rmp/counterparty/stores/agreementApplication/steps/counterparty";
import { useDocumentsStepStore } from "@rmp/counterparty/stores/agreementApplication/steps/documents";
import { useUserStore } from "@rmp/core/stores/user";

const abortService = new AbortService();
const page = usePageStore(abortService);

export interface AgreementApplicationState extends PageState {
	currentStep: AgreementApplicationStepTypeEnum;
}

const getDefaultState = (): AgreementApplicationState => {
	return {
		...page.getDefaultPageState(),
		currentStep: AgreementApplicationStepTypeEnum.SERVICE
	};
};

export const useAgreementApplicationStore = defineStore({
	id: "agreement-application",
	state: (): AgreementApplicationState => getDefaultState(),
	getters: {
		...page.getters,
		steps(): AgreementApplicationStepTypeEnum[] {
			const { isEntrepreneurEmployee } = useUserStore();
			
			let steps = [
				AgreementApplicationStepTypeEnum.SERVICE,
				AgreementApplicationStepTypeEnum.DEPARTMENT,
				AgreementApplicationStepTypeEnum.COUNTERPARTY,
				AgreementApplicationStepTypeEnum.DOCUMENTS,
				AgreementApplicationStepTypeEnum.CONFIRMATION
			];
			
			if(isEntrepreneurEmployee)
				steps = steps.filter(x => x !== AgreementApplicationStepTypeEnum.DOCUMENTS);
			
			return steps;
		},
		nextStepAvailable(state): boolean {
			switch (state.currentStep) {
				case AgreementApplicationStepTypeEnum.SERVICE:
				{
					const { completed } = useServiceStepStore();
					return completed;
				}
				case AgreementApplicationStepTypeEnum.DEPARTMENT:
				{
					const { completed } = useDepartmentStepStore();
					return completed;
				}
				case AgreementApplicationStepTypeEnum.COUNTERPARTY:
				{
					const { completed } = useCounterpartyStepStore();
					return completed;
				}
				case AgreementApplicationStepTypeEnum.DOCUMENTS:
				{
					const { completed } = useDocumentsStepStore();
					return completed;
				}
				case AgreementApplicationStepTypeEnum.CONFIRMATION:
				{
					const { completed } = useConfirmationStepStore();
					return completed;
				}
				default:
					throw new NotImplementedException("nextStepAvailable");
			}
		},
		prevStepAvailable(): boolean {
			return this.currentStepIndex !== 0;
		},
		currentStepIndex(): number {
			return this.steps.indexOf(this.currentStep);
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
		},
		moveToNextStep() {
			this.currentStep = this.steps[this.currentStepIndex + 1];
		},
		moveToPrevStep() {
			this.currentStep = this.steps[this.currentStepIndex - 1];
		}
	}
});
