import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { ServiceController } from "@rmp/counterparty/api/service";
import { useHistoryStore } from "@rmp/core/stores/composables/history";
import ServiceHistoryMapperProfile, { ServiceHistoryEntry } from "@rmp/core/types/services/serviceHistoryEntry";
import { useGeodeticHydrographicServiceHistoryBreadcrumb } from "@rmp/counterparty/stores/serviceDetails/geodeticHydrographic/history/composables/useGeodeticHydrographicServiceHistoryBreadcrumb";
import { useGeodeticHydrographicServiceStore } from "@rmp/counterparty/stores/serviceDetails/geodeticHydrographic";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceController = new ServiceController(abortService);

const mapper = new ServiceHistoryMapperProfile();
const historyStore = useHistoryStore({ controller: serviceController, mapper });

export interface GeodeticHydrographicServiceHistoryState extends PageState {
	id: string | null;
	history: ServiceHistoryEntry[];
}

const getDefaultState = (): GeodeticHydrographicServiceHistoryState => {
	return {
		...page.getDefaultPageState(),
		...historyStore.getDefaultState(),
		id: null,
		history: []
	};
};

export const useGeodeticHydrographicServiceHistoryStore = defineStore({
	id: "geodetic-hydrographic-service-history",
	state: (): GeodeticHydrographicServiceHistoryState => getDefaultState(),
	getters: {
		...page.getters,
		...historyStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useGeodeticHydrographicServiceStore();
			return [...breadcrumbs, useGeodeticHydrographicServiceHistoryBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...historyStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.initializeHistoryStore();
		}
	}
});
