import { ApiCrewboatServiceApplication } from "@rmp/core/api/types/service/crewboat/apiCrewboatServiceApplication";
import { parseISO } from "date-fns";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";
import { ServiceApplicationCrewboat, ServiceApplicationCrewboatMapper } from "@rmp/core/types/services/crewboat/serviceApplicationCrewboat";
import { PropertyServiceApplicationBase } from "@rmp/core/types/services/base/propertyServiceApplicationBase";
import { ApiServiceWorkflowState } from "@rmp/core/api/types/service/apiServiceWorkflowState";
import { parseISODate } from "@rmp/core/utils/dates";

export interface CrewboatServiceApplication extends PropertyServiceApplicationBase {
	workflowState: ApiServiceWorkflowState;
	from: Date | null;
	till: Date | null;
	startDestinationShortName: string;
	endDestinationShortName: string;
	startDestinationId: string;
	endDestinationId: string;
	counterpartyBoatId: string;
	requestedCrewBoats: ServiceApplicationCrewboat[];
	assignedCrewBoats: ServiceApplicationCrewboat[];
}

export class CrewboatServiceApplicationMapper {
	static map(source: ApiCrewboatServiceApplication): CrewboatServiceApplication {
		return {
			...source,
			createdAt: parseISO(source.createdAt),
			updatedAt: parseISO(source.updatedAt),
			from: parseISODate(source.from),
			till: parseISODate(source.till),
			type: ServiceMapperProfile.mapServiceType(source.type),
			assignedCrewBoats: source.assignedCrewBoats.map(x => ServiceApplicationCrewboatMapper.map(x)),
			requestedCrewBoats: source.requestedCrewBoats.map(x => ServiceApplicationCrewboatMapper.map(x))
		};
	}
}
