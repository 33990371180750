import { ApiTugboatServiceApplication } from "@rmp/core/api/types/service/tugboat/apiTugboatServiceApplication";
import { parseISO } from "date-fns";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";
import { ServiceApplicationTugboat, ServiceApplicationTugboatMapper } from "@rmp/core/types/services/tugboat/serviceApplicationTugboat";
import { PropertyServiceApplicationBase } from "@rmp/core/types/services/base/propertyServiceApplicationBase";
import { ApiServiceWorkflowState } from "@rmp/core/api/types/service/apiServiceWorkflowState";
import { parseISODate } from "@rmp/core/utils/dates";

export interface TugboatServiceApplication extends PropertyServiceApplicationBase {
	workflowState: ApiServiceWorkflowState;
	from: Date | null;
	till: Date | null;
	startDestinationShortName: string;
	endDestinationShortName: string;
	startDestinationId: string;
	endDestinationId: string;
	counterpartyBoatId: string;
	requestedTugBoats: ServiceApplicationTugboat[];
	assignedTugBoats: ServiceApplicationTugboat[];
}

export class TugboatServiceApplicationMapper {
	static map(source: ApiTugboatServiceApplication): TugboatServiceApplication {
		return {
			...source,
			createdAt: parseISO(source.createdAt),
			updatedAt: parseISO(source.updatedAt),
			from: parseISODate(source.from),
			till: parseISODate(source.till),
			type: ServiceMapperProfile.mapServiceType(source.type),
			requestedTugBoats: source.requestedTugBoats.map(x => ServiceApplicationTugboatMapper.map(x)),
			assignedTugBoats: source.assignedTugBoats.map(x => ServiceApplicationTugboatMapper.map(x))
		};
	}
}
