import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useServicesBreadcrumb } from "@rmp/counterparty/stores/services/composables/useServicesBreadcrumb";
import { BaseServiceState, useBaseServiceStore } from "@rmp/counterparty/stores/composables/baseService";
import { SnoInstallationServiceController } from "@rmp/counterparty/api/service/snoInstallation";
import {
	SnoInstallationServiceApplication,
	SnoInstallationServiceApplicationMapper
} from "@rmp/core/types/services/snoInstallation/snoInstallationServiceApplication";
import {
	useSnoInstallationServiceBreadcrumb
} from "@rmp/counterparty/stores/serviceDetails/snoInstallation/composables/useSnoInstallationServiceBreadcrumb";
import { formatServiceTitle } from "@rmp/core/utils/formatting";

const abortService = new AbortService();
const page = usePageStore(abortService);
const baseServiceStore = useBaseServiceStore({ abortService });

const snoInstallationServiceController = new SnoInstallationServiceController(abortService);

export interface SnoInstallationServiceState extends PageState, BaseServiceState {
	service: SnoInstallationServiceApplication;
}

const getDefaultState = (): SnoInstallationServiceState => {
	return {
		...page.getDefaultPageState(),
		...baseServiceStore.getDefaultState(),
		service: {} as SnoInstallationServiceApplication
	};
};

export const useSnoInstallationServiceStore = defineStore({
	id: "sno-installation-service",
	state: (): SnoInstallationServiceState => getDefaultState(),
	getters: {
		...page.getters,
		...baseServiceStore.getters,
		breadcrumbs(state: SnoInstallationServiceState) {
			return [
				useServicesBreadcrumb(),
				useSnoInstallationServiceBreadcrumb(state.title)
			];
		}
	},
	actions: {
		...page.actions,
		...baseServiceStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.fetch();
			
			await this.initializeBaseServiceStore(this.service.type);
		},
		async fetch() {
			try {
				let serviceApplication = await snoInstallationServiceController.getService(this.id!);
				
				this.service = SnoInstallationServiceApplicationMapper.map(serviceApplication);
				this.title = formatServiceTitle(this.service.type, this.service.applicationNumber);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		}
	}
});
