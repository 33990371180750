import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import router from "@rmp/counterparty/router";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { Store } from "pinia";
import ServiceApplicationApiFacade, { BaseServiceWorkflowStateType } from "@rmp/counterparty/api/service/facade";
import AbortService from "@rmp/core/services/abortService";
import { ServiceController } from "@rmp/counterparty//api/service";
import { ServiceWorkflowStateType } from "@rmp/core/types/services/ServiceWorkflowStateType";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import { ApiBaseServiceTransitionCodeEnum } from "@rmp/core/api/types/service/base/ApiInformationServiceTransitionCodeEnum";
import { ArchiveStatusState, useArchiveStatusStore } from "@rmp/core/stores/composables/archiveStatus";

const archiveStatusStore = useArchiveStatusStore();

export interface BaseServiceState extends ArchiveStatusState {
	id: string;
	title: string;
	isDeclining: boolean;
	isApproving: boolean;
	isSending: boolean;
	isCancelling: boolean;
	cancelReason: string;
}

interface BaseServiceOptions {
	abortService: AbortService;
}

export const useBaseServiceStore = (options: BaseServiceOptions) => {
	type BaseServiceStore = Store<string, BaseServiceState, {}, { [key: string]: any, updateArchiveStatus(): void }>;
	
	const serviceApplicationApiFacade = new ServiceApplicationApiFacade(options.abortService);
	const serviceController = new ServiceController(options.abortService);
	
	const getDefaultState = (): BaseServiceState => {
		return {
			...archiveStatusStore.getDefaultState(),
			id: "",
			title: "",
			isDeclining: false,
			isApproving: false,
			isSending: false,
			isCancelling: false,
			cancelReason: ""
		};
	};
	
	const getters = {
		...archiveStatusStore.getters,
		preliminaryAmountIsUsed(this: BaseServiceStore) {
			if(!this.service.workflowState?.alias) return;
			return [
				ServiceWorkflowStateType.DRAFT, ServiceWorkflowStateType.SENT, ServiceWorkflowStateType.APPROVED_BY_DISPATCHER,
				ServiceWorkflowStateType.APPROVED_BY_PILOT, ServiceWorkflowStateType.PROVIDING
			].includes(this.service.workflowState.alias);
		},
		finalAmountIsUsed(this: BaseServiceStore) {
			if(!this.service.workflowState?.alias) return;
			return [
				ServiceWorkflowStateType.PROVIDED, ServiceWorkflowStateType.APPROVED_BY_FINANSIST,
				ServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE, ServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY
			].includes(this.service.workflowState.alias);
		}
	};
	
	const actions = {
		...archiveStatusStore.actions,
		async initializeBaseServiceStore(this: BaseServiceState, serviceType: ServiceTypeEnum) {
			serviceApplicationApiFacade.configure(serviceType);
		},
		async updateArchiveStatus(this: BaseServiceStore) {
			if(!this.service.isArchived)
				await serviceController.archive(router.currentRoute.params.id);
			else
				await serviceController.unarchive(router.currentRoute.params.id);
			
			this.service.isArchived = !this.service.isArchived;
			
			alertService.addSuccess(AlertKeys.STATUS_SUCCESS_UPDATED);
		},
		async updateStatus(this: BaseServiceStore, transitionCode: string, targetStatus: BaseServiceWorkflowStateType, reason?: string) {
			try {
				await serviceApplicationApiFacade.updateStatusByTransition(this.id!, transitionCode, reason);
				
				// TODO
				this.service.workflowState.alias = targetStatus;
				
				alertService.addSuccess(AlertKeys.SERVICE_STATUS_UPDATED);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async decline(this: BaseServiceStore) {
			this.isDeclining = true;
			
			try {
				await serviceApplicationApiFacade.updateStatusByTransition(this.id!, ApiBaseServiceTransitionCodeEnum.REJECT);
				
				// TODO
				this.service.workflowState.alias = ServiceWorkflowStateType.REJECTED;
				
				alertService.addSuccess(AlertKeys.SERVICE_DECLINED);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.isDeclining = false;
			}
		},
		async approve(this: BaseServiceStore, transitionCode: string, targetStatus: BaseServiceWorkflowStateType) {
			this.isApproving = true;
			
			try {
				await this.updateStatus(transitionCode, targetStatus);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.isApproving = false;
			}
		},
		async cancel(this: BaseServiceStore) {
			this.isCancelling = true;
			
			try {
				await this.updateStatus(ApiBaseServiceTransitionCodeEnum.CANCEL, ServiceWorkflowStateType.CANCELED, this.cancelReason);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.isCancelling = false;
			}
		},
		async send(this: BaseServiceStore) {
			this.isSending = true;
			
			try {
				await this.updateStatus(ApiBaseServiceTransitionCodeEnum.SEND, ServiceWorkflowStateType.SENT);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.isSending = false;
			}
		}
	};
	
	return { getters, actions, getDefaultState };
};
