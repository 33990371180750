import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { ServiceController } from "@rmp/counterparty/api/service";
import ServiceHistoryMapperProfile, { ServiceHistoryEntry } from "@rmp/core/types/services/serviceHistoryEntry";
import {
	usePierServiceHistoryBreadcrumb
} from "@rmp/counterparty/stores/serviceDetails/pier/history/composables/usePierServiceHistoryBreadcrumb";
import { usePierServiceStore } from "@rmp/counterparty/stores/serviceDetails/pier";
import { useHistoryStore } from "@rmp/core/stores/composables/history";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceController = new ServiceController(abortService);

const mapper = new ServiceHistoryMapperProfile();
const historyStore = useHistoryStore({ controller: serviceController, mapper });

export interface PierServiceHistoryState extends PageState {
	id: string | null;
	history: ServiceHistoryEntry[];
}

const getDefaultState = (): PierServiceHistoryState => {
	return {
		...page.getDefaultPageState(),
		...historyStore.getDefaultState(),
		id: null,
		history: []
	};
};

export const usePierServiceHistoryStore = defineStore({
	id: "pier-service-history",
	state: (): PierServiceHistoryState => getDefaultState(),
	getters: {
		...page.getters,
		...historyStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = usePierServiceStore();
			return [...breadcrumbs, usePierServiceHistoryBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...historyStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.initializeHistoryStore();
		}
	}
});
