import urls, { urlTemplateParts } from "@rmp/core/api/config/urls";
import ApiFile from "@rmp/core/api/types/storage/apiFile";
import { prepareUrl } from "@rmp/core/utils/prepareUrlQuery";
import { plainToInstance } from "class-transformer";
import BaseController from "@rmp/core/api/shared/baseController";
import AbortService from "@rmp/core/services/abortService";
import { prepareFormData } from "@rmp/core/utils/prepareFormData";
import { getFormDataHeaders } from "@rmp/core/utils/getFormDataHeaders";
import ApiFileMeta from "@rmp/core/api/types/storage/apiFileMeta";

export class StorageController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	createTemperFile = async (file: ApiFile): Promise<ApiFileMeta> => {
		const url = file.signature ? urls.storage.file.createTemperSigned : urls.storage.file.createTemper;
		
		let result = await this.client.post<ApiFileMeta>(url, prepareFormData(file), getFormDataHeaders());
		
		return plainToInstance(ApiFileMeta, result);
	};
	
	getFile = async (id: string) => {
		return this.client.get<Blob>(prepareUrl(urls.storage.file.download.replace(urlTemplateParts.id, id)), { responseType: "blob" });
	};
	
	getTempFile = async (id: string) => {
		return this.client.get<Blob>(prepareUrl(urls.storage.file.downloadTempFile.replace(urlTemplateParts.id, id)),
			{ responseType: "blob" });
	};
	
	getFileMeta = async (id: string) => {
		let result = await this.client.get<ApiFileMeta>(prepareUrl(urls.storage.file.getMeta.replace(urlTemplateParts.id, id)));
		
		return plainToInstance(ApiFileMeta, result);
	};
	
	getTempFileMeta = async (id: string) => {
		let result = await this.client.get<ApiFileMeta>(prepareUrl(urls.storage.file.getTempMeta.replace(urlTemplateParts.id, id)));
		
		return plainToInstance(ApiFileMeta, result);
	};
	
	signTemper = async (id: string, signature: string) => {
		const url = urls.storage.file.signTemper.replace(urlTemplateParts.id, id);
		
		let result = await this.client.post<ApiFileMeta>(url, prepareFormData({
			signature
		}), getFormDataHeaders());
		
		return plainToInstance(ApiFileMeta, result);
	};
	signFile = async (id: string, signature: string) => {
		const url = urls.storage.file.signFile.replace(urlTemplateParts.id, id);
		
		let result = await this.client.post<ApiFileMeta>(url, prepareFormData({
			signature
		}), getFormDataHeaders());
		
		return plainToInstance(ApiFileMeta, result);
	};
	
	clone = async (id: string) => {
		const url = urls.storage.file.clone.replace(urlTemplateParts.id, id);
		
		let result = await this.client.put<ApiFileMeta>(url, {});
		
		return plainToInstance(ApiFileMeta, result);
	};
	
	downloadSignature = async (id: string, fileName: string) => {
		return await this.client.get<Blob>(prepareUrl(urls.storage.file.downloadSignature.replace(urlTemplateParts.id, id), { fileName }),
			{ responseType: "blob" });
	};
	
	downloadTempSignature = async (id: string) => {
		return await this.client.get<Blob>(prepareUrl(urls.storage.file.downloadTempSignature.replace(urlTemplateParts.id, id)),
			{ responseType: "blob" });
	};
	
	cloneTemporary = async (id: string) => {
		const url = urls.storage.file.cloneTemporary.replace(urlTemplateParts.id, id);
		
		let result = await this.client.put<ApiFileMeta>(url, {});
		
		return plainToInstance(ApiFileMeta, result);
	};
}


