import { difference } from "@rmp/core/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import BoatApplicationsRouteQuery from "@rmp/counterparty/stores/boatApplications/types/boatApplicationsRouteQuery";
import { ApplicationFilterStatusType } from "@rmp/core/types/ApplicationFilterStatusType";
import RouteQueryService from "@rmp/core/services/route/routeQueryService";
import { BoatApplicationsState } from "@rmp/counterparty/stores/boatApplications";

export default class BoatApplicationsRouteQueryService implements RouteQueryService {
	defaultRouteQuery: BoatApplicationsRouteQuery;

	constructor(defaultRouteQuery: BoatApplicationsRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}

	private mapStateToQuery(state: BoatApplicationsState) {
		return new BoatApplicationsRouteQuery(
			state.paging.page,
			state.sorting.type,
			state.sorting.order,
			state.search.query,
			state.filter.status
		);
	}

	public resolveRouteQueryDictionary(state: BoatApplicationsState) {
		let query = this.mapStateToQuery(state);

		return difference(this.defaultRouteQuery, query);
	}

	public async hasRouteChanges(state: BoatApplicationsState, route: Dictionary<any>): Promise<{ [key: string]: any }> {
		let stateQuery = this.mapStateToQuery(state);
		let routeQuery = await this.resolveRouteQuery(route);

		return difference(routeQuery, stateQuery);
	}

	public async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(BoatApplicationsRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				status: query.status || ApplicationFilterStatusType.ALL
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
