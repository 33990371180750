export const roundDownToDecimalPlace = (value: number, fractionDigits: number = 1) => {
	return Math.floor(value * (10 * fractionDigits)) / (10 * fractionDigits);
};

export const multiply = (...numbers: number[]) => {
	return numbers.reduce((acc, val) => {
		return (acc * (val * 100)) / 100;
	}, 1)
};

export const parseFloatNumber = (value: string): number => {
	return +value.replace(",", ".");
};
