import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useTugboatServiceStore } from "@rmp/counterparty/stores/serviceDetails/tugboat";
import { useTugboatServiceChatBreadcrumb } from "@rmp/counterparty/stores/serviceDetails/tugboat/chat/composables/useTugboatServiceChatBreadcrumb";
import { ChatState, useChatStore } from "@rmp/core/stores/composables/chat";
import { ServiceController } from "@rmp/counterparty/api/service";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceController = new ServiceController(abortService);
const chatStore = useChatStore({ controller: serviceController });

export interface TugboatServiceChatState extends PageState, ChatState {
	id: string | null;
}

const getDefaultState = (): TugboatServiceChatState => {
	return {
		...page.getDefaultPageState(),
		...chatStore.getDefaultState(),
		id: null
	};
};

export const useTugboatServiceChatStore = defineStore({
	id: "tugboat-service-chat",
	state: (): TugboatServiceChatState => getDefaultState(),
	getters: {
		...page.getters,
		...chatStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useTugboatServiceStore();
			return [...breadcrumbs, useTugboatServiceChatBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...chatStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.initializeChatStore(id);
		}
	}
});
