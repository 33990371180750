export enum BaseRouteNames {
	ROOT = "ROOT",
	ERROR_PAGE_NOT_FOUND = "ERROR_PAGE_NOT_FOUND",
	ERROR_ACCESS_DENIED = "ERROR_ACCESS_DENIED",
	ERROR_INTERNAL_SERVER_ERROR = "ERROR_INTERNAL_SERVER_ERROR"
}

export enum AuthenticationRouteNames {
	CALLBACK = "CALLBACK",
	LOGOUT = "LOGOUT",
	SILENT_RENEW = "SILENT_RENEW",
}

export enum AccountManagementRouteNames {
	CONFIRM_PHONE = "CONFIRM_PHONE",
	CONFIRM_EMAIL = "CONFIRM_EMAIL",
	RESET_PASSWORD = "RESET_PASSWORD"
}
