import { IMiddleware } from "@rmp/core/types/core/middleware";
import { NavigationGuardNext, Route } from "vue-router/types/router";
import { ProfileType } from "@rmp/core/types/ProfileType";
import { RouteNames } from "@rmp/counterparty/router/routes";
import { useUserStore } from "@rmp/core/stores/user";

export default class RedirectMiddleware implements IMiddleware {
	constructor() {
		
	}
	
	async invoke(to: Route, from: Route) {
		try {
			const userStore = useUserStore();
			
			if(!userStore.initialized) {
				await userStore.initialize();
			}
			
			if(!userStore.profile || !userStore.profile.isActive || !userStore.isCounterpartyEmployee)
				return { name: RouteNames.ERROR_ACCESS_DENIED };
		} catch (e) {
			console.error(e);
		}
	}
}
