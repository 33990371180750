import ApiFileMeta from "@rmp/core/api/types/storage/apiFileMeta";
import { first } from "lodash";

export interface FileMeta {
	id: string;
	name: string;
	mimeType: string;
	namespace: string;
	signature: string;
	isLoading: boolean;
	isSigned: boolean;
}

export class FileMetaHelper {
	static map(source: ApiFileMeta): FileMeta {
		return {
			...source,
			signature: first(source.signatures)?.signature || "",
			isLoading: false,
			isSigned: source.signatures && !!source.signatures.length
		};
	}
	static getEmpty(): FileMeta {
		return {
			id: "",
			name: "",
			mimeType: "",
			namespace: "",
			signature: "",
			isLoading: false,
			isSigned: false
		};
	}
}
