import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useCrewboatServiceStore } from "@rmp/counterparty/stores/serviceDetails/crewboat";
import { ServiceController } from "@rmp/counterparty/api/service";
import { useCrewboatServiceFeedbackBreadcrumb } from "@rmp/counterparty/stores/serviceDetails/crewboat/feedback/composables/useCrewboatServiceFeedbackBreadcrumb";
import { FeedbackState, useFeedbackStore } from "@rmp/core/stores/composables/feedback";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceController = new ServiceController(abortService);
const feedbackStore = useFeedbackStore({ controller: serviceController });

export interface CrewboatServiceFeedbackState extends PageState, FeedbackState {
}

const getDefaultState = (): CrewboatServiceFeedbackState => {
	return {
		...page.getDefaultPageState(),
		...feedbackStore.getDefaultState()
	};
};

export const useCrewboatServiceFeedbackStore = defineStore({
	id: "crewboat-service-feedback",
	state: (): CrewboatServiceFeedbackState => getDefaultState(),
	getters: {
		...page.getters,
		...feedbackStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useCrewboatServiceStore();
			return [...breadcrumbs, useCrewboatServiceFeedbackBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...feedbackStore.actions,
		async beforeInitialized(this: any, { id }: { id: string }) {
			this.id = id;
			await this.initializeFeedbackStore();
		}
	}
});
