import { UserManager, WebStorageStateStore, Log, OidcClient, OidcClientSettings, UserManagerSettings } from "oidc-client-ts";
import { DEVELOPMENT_APP_MODE } from "@rmp/core/constants/development";
import PermissionsResolver from "@rmp/core/api/authorization/permissionsResolver";

const permissionsResolver = new PermissionsResolver();

const {
	VUE_APP_BASE_URL,
	VUE_APP_OIDC_AUTHORITY,
	VUE_APP_OIDC_CLIENT_ID,
	VUE_APP_POST_LOGOUT_REDIRECT_URI
} = process.env;

const getStateStorageUserKey = () => `oidc.user:${VUE_APP_OIDC_AUTHORITY}:${VUE_APP_OIDC_CLIENT_ID}`;

const settings: OidcClientSettings = {
	staleStateAgeInSeconds: 60,
	authority: VUE_APP_OIDC_AUTHORITY!,
	client_id: VUE_APP_OIDC_CLIENT_ID!,
	redirect_uri: `${VUE_APP_BASE_URL}/callback.html`,
	response_type: "code",
	response_mode: "query",
	scope: "openid offline_access",
	post_logout_redirect_uri: VUE_APP_POST_LOGOUT_REDIRECT_URI,
	filterProtocolClaims: true,
	loadUserInfo: true,
	clockSkewInSeconds: 900
};

const userManagerSettings: UserManagerSettings = {
	...settings,
	userStore: new WebStorageStateStore({
		store: window.localStorage
	}),
	monitorSession: true,
	silent_redirect_uri: `${VUE_APP_BASE_URL}/silent-renew.html`,
	includeIdTokenInSilentRenew: true,
	automaticSilentRenew: true
};

const userManager = new UserManager(userManagerSettings);

export const oidcClient = new OidcClient(settings);

const resetState = async () => {
	await userManager.removeUser();
	await userManager.clearStaleState();
};

userManager.events.addUserLoaded(async () => {
	await permissionsResolver.updatePermissionsStorage();
});

if(process.env.NODE_ENV === DEVELOPMENT_APP_MODE) {
	Log.setLevel(Log.INFO);
	Log.setLogger(console);
}


export const checkUserLoaded = () => {
	return !!localStorage.getItem(getStateStorageUserKey());
};

userManager.events.addAccessTokenExpired(() => {
	(async () => {
		try {
			await userManager.signinSilent();
		} catch (err) {
			console.error(err);
			await resetState();
			await userManager.signoutRedirect();
		}
	})();
});

userManager.events.addSilentRenewError(function () {
	console.error("Silent Renew Error：", arguments);
	(async () => {
		try {
			await resetState();
			await userManager.signoutRedirect();
		} catch (err) {
			console.error(err);
		}
	})();
});

userManager.events.addUserSignedOut(async () => {
	await resetState();
});

export default userManager;
