import { urls } from "@rmp/counterparty/api/config/urls";
import AbortService from "@rmp/core/services/abortService";
import ApiGetBoatApplicationsParameters from "@rmp/core/api/types/counterparty/boatApplication/apiGetBoatApplicationsParameters";
import ApiBoatHistoryEntryPersisted from "@rmp/core/api/types/counterparty/boatApplication/apiBoatHistoryEntryPersisted";
import BaseCounterpartyController from "@rmp/counterparty/api/base/baseCounterpartyController";
import { ApiChatMessage } from "@rmp/core/api/types/chat/apiChatMessage";
import {
	ApiApplicationBoat,
	ApiCounterpartyBoatApplicationPersisted
} from "@rmp/core/api/types/counterpartyBoatApplication/apiCounterpartyBoatApplication";
import { BaseHistoryController } from "@rmp/core/api/shared/baseHistoryController";

export class BoatApplicationController extends BaseCounterpartyController implements BaseHistoryController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getBoatApplications = async (parameters: ApiGetBoatApplicationsParameters) => {
		return await this.get<{
			counterpartyBoatApplications: ApiCounterpartyBoatApplicationPersisted[],
			totalCount: number
		}>(urls.boatApplication,
			parameters);
	};
	
	getBoatKind = async (): Promise<{ value: number, description: string, code: string }[]> => {
		return await this.get<{ value: number, description: string, code: string }[]>(urls.boatApplication.kind, {});
	};
	
	getHistory = async (boatApplicationId: string) => {
		return await this.get<{ history: ApiBoatHistoryEntryPersisted[] }>(urls.boatApplication.$boatApplicationId.history,
			{ boatApplicationId });
	};
	
	getBoatApplication = async (boatApplicationId: string) => {
		return await this.get<ApiCounterpartyBoatApplicationPersisted>(urls.boatApplication.$boatApplicationId, { boatApplicationId });
	};
	
	updateBoatApplication = async (boatApplicationId: string,
		boat: ApiApplicationBoat) => {
		
		return await this.put<ApiCounterpartyBoatApplicationPersisted>(urls.boatApplication.$boatApplicationId,
			{ boatApplicationId },
			boat);
	};
	
	createBoatApplication = async (boat: ApiApplicationBoat) => {
		return await this.post<ApiCounterpartyBoatApplicationPersisted>(urls.boatApplication, {}, boat);
	};
	
	getChatMessages = async (boatApplicationId: string) => {
		return await this.get<{ messages: ApiChatMessage[] }>(urls.boatApplication.$boatApplicationId.chat.message, { boatApplicationId });
	};
	
	createChatMessage = async (boatApplicationId: string, message: string): Promise<ApiChatMessage> => {
		return await this.post(urls.boatApplication.$boatApplicationId.chat.message, { boatApplicationId }, { message });
	};
}
