<template>
</template>

<script>
import userManager from "@rmp/core/services/auth/userManager";

export default {
	async beforeCreate() {
		try {
			const user = await userManager.signinRedirectCallback();
			window.location.href = user.state.url;
		} catch (e) {
			console.error(e);
			await userManager.signoutRedirect();
		}
	}
};
</script>
