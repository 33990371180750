import {
	ServiceApplicationStepState
} from "@rmp/counterparty/stores/serviceApplication/steps/shared/serviceApplicationState";
import { defineStore } from "pinia";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import { TugboatServiceController } from "@rmp/counterparty/api/service/tugboat";
import AbortService from "@rmp/core/services/abortService";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useDepartmentStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/department";
import ServiceApplicationPortMapper from "@rmp/counterparty/stores/serviceApplication/steps/port/mapper";
import { ServiceApplicationPort } from "@rmp/counterparty/stores/serviceApplication/steps/port/types/serviceApplicationPort";
import { useServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication";
import ServiceApplicationApiFacade from "@rmp/counterparty/api/service/facade";
import { useServiceStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/service";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceApplicationApiFacade = new ServiceApplicationApiFacade(abortService);

const mapper = new ServiceApplicationPortMapper();

export interface PortStepState extends ServiceApplicationStepState, PageState {
	ports: ServiceApplicationPort[];
	selectedPortId: string;
}

const getDefaultState = (): PortStepState => {
	return {
		...page.getDefaultPageState(),
		ports: [],
		selectedPortId: "",
		stepType: ServiceApplicationStepTypeEnum.PORT
	};
};

export const usePortStepStore = defineStore({
	id: "port-step",
	state: (): PortStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return !!state.selectedPortId;
		},
		selectedPort(state) {
			return state.ports.find(x => x.id === state.selectedPortId);
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			const { selectedServiceType } = useServiceStepStore();
			serviceApplicationApiFacade.configure(selectedServiceType);
			
			await this.fetch();
			this.selectDefaultPort();
		},
		async fetch() {
			try {
				const { selectedDepartmentId } = useDepartmentStepStore();
				
				let ports = await serviceApplicationApiFacade.getDepartmentPorts(selectedDepartmentId);
				
				this.ports = ports.map(x => mapper.mapPort(x));
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		selectDefaultPort() {
			if(!this.ports.length) return;
			
			const { copiedService } = useServiceApplicationStore();
			
			if(copiedService?.portId) {
				this.selectedPortId = copiedService.portId;
			} else {
				const [port] = this.ports;
				
				this.selectedPortId = port.id;
			}
		}
	}
});
