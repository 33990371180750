import { defineStore } from "pinia";
import { ServiceApplicationStateBase } from "@rmp/counterparty/stores/serviceApplication/services/base/useServiceApplicationStoreBase";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";


export interface PierServiceApplicationState extends ServiceApplicationStateBase {
	// currentStep (title, index, alias)
}

const getDefaultState = (): PierServiceApplicationState => {
	return {};
};

export const usePierServiceApplicationStore = defineStore({
	id: "pier-service-application",
	state: (): PierServiceApplicationState => getDefaultState(),
	getters: {
		steps: state => {
			return [
				ServiceApplicationStepTypeEnum.SERVICE,
				ServiceApplicationStepTypeEnum.DEPARTMENT,
				ServiceApplicationStepTypeEnum.PORT,
				ServiceApplicationStepTypeEnum.AGREEMENT,
				ServiceApplicationStepTypeEnum.BOAT,
				ServiceApplicationStepTypeEnum.PIER,
				ServiceApplicationStepTypeEnum.TARIFF,
				ServiceApplicationStepTypeEnum.ROUTE,
				ServiceApplicationStepTypeEnum.PROPERTY_COST,
				ServiceApplicationStepTypeEnum.PROPERTY_CONFIRMATION
			];
		}
	},
	actions: {}
});
