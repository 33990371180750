import InvalidArgumentException from "@rmp/core/exceptions/invalidArgumentException";
import {
	CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256, CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512,
	OID_CP_GOST_R3410_12_256,
	OID_CP_GOST_R3410_12_512,
	OID_CP_GOST_R3411_12_256_R3410,
	OID_CP_GOST_R3411_12_512_R3410
} from "@rmp/core/constants/signatureIdentifiers";
import { Certificate } from "@bsp-team/crypto-pro/lib/api/certificate";

const digitalSignatureAlgorithms = [
	OID_CP_GOST_R3410_12_256, OID_CP_GOST_R3410_12_512,
	OID_CP_GOST_R3411_12_256_R3410, OID_CP_GOST_R3411_12_512_R3410
];

export const checkDigitalSignatureAlgorithm = async (certificate: Certificate) => {
	if(!certificate) throw new InvalidArgumentException("certificate", certificate);
	
	// @ts-ignore
	let { oid }: { oid: string } = await certificate.getAlgorithm();
	
	return digitalSignatureAlgorithms.includes(oid);
};

export const getHashAlgorithmByCertificateAlgorithm = (certificateAlgorithmOid: string) => {
	switch (certificateAlgorithmOid) {
		case OID_CP_GOST_R3410_12_256:
			return CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256;
		case OID_CP_GOST_R3410_12_512:
			return CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512;
		default: 
			return null;
	}
};
