import { defineStore } from "pinia";
import AbortService from "@rmp/core/services/abortService";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import { FileMeta, FileMetaHelper } from "@rmp/core/api/types/storage/fileMeta";
import ApiFileMeta from "@rmp/core/api/types/storage/apiFileMeta";
import { ServiceApplicationStepState } from "@rmp/counterparty/stores/serviceApplication/steps/shared/serviceApplicationState";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import { ApiServiceDocumentKindEnum } from "@rmp/core/api/types/service/document/ApiServiceDocumentKindEnum";
import { ServiceFileMeta } from "@rmp/core/types/services/serviceFileMeta";
import { useServiceStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/service";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import { useServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication";
import { useUserStore } from "@rmp/core/stores/user";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { isNil } from "lodash";
import { parseISO } from "date-fns";
import { getFileNameWithExtension } from "@rmp/core/utils/mime";
import ApiFile from "@rmp/core/api/types/storage/apiFile";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { ApiGetCounterpartyDocumentsParameters } from "@rmp/core/api/types/documents/apiGetCounterpartyDocumentsParameters";
import { CounterpartyDocumentsController } from "@rmp/core/api/counterpartyDocument";
import { StorageController } from "@rmp/core/api/storage";
import { ApiCounterpartyDocumentType } from "@rmp/core/api/types/documents/ApiCounterpartyDocumentType";
import { useServiceDocuments } from "@rmp/counterparty/composables/useServiceDocuments";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";

const abortService = new AbortService();
const counterpartyDocumentsController = new CounterpartyDocumentsController(abortService);
const storageController = new StorageController(abortService);

const page = usePageStore(abortService);

export interface DocumentsStepState extends ServiceApplicationStepState, PageState {
	customDocuments: FileMeta[];
	documents: ServiceFileMeta[];
	documentsList: ApiServiceDocumentKindEnum[];
}

const getDefaultState = (): DocumentsStepState => {
	return {
		...page.getDefaultPageState(),
		stepType: ServiceApplicationStepTypeEnum.DOCUMENTS,
		customDocuments: [],
		documents: [],
		documentsList: []
	};
};

export const useDocumentsStepStore = defineStore({
	id: "service-application-documents-step",
	state: (): DocumentsStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state: DocumentsStepState): boolean {
			return state.initialized;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			await this.initializeDocumentsList();
			
			this.selectDefaultDocuments();
		},
		async initializeDocumentsList() {
			try {
				const { isLegalEntityEmployee } = useUserStore();
				const { selectedServiceType } = useServiceStepStore();
				const { isApplicableServiceTypeForFetchConstitutionalDocuments } = useServiceDocuments();
				
				const legalEntityDocuments = [
					ApiServiceDocumentKindEnum.ORGANIZATION_CHARTER, ApiServiceDocumentKindEnum.FOUNDATION_AGREEMENT
				];
				
				// если у нас юридическое лицо, то мы загружаем устав организации и учредительный договор для применимого типа документов
				if(isLegalEntityEmployee) {
					this.documentsList = legalEntityDocuments;
					
					if(isApplicableServiceTypeForFetchConstitutionalDocuments(selectedServiceType))
						await this.fetchConstitutionalDocuments();
				}
				
				if(selectedServiceType === ServiceTypeEnum.DREDGING_WORKS_SERVICE)
					this.documentsList.push(ApiServiceDocumentKindEnum.GRAPHIC_ANNEX,
						ApiServiceDocumentKindEnum.CHIEF_STATE_EXPERTISE,
						ApiServiceDocumentKindEnum.BUILDING_PERMIT,
						ApiServiceDocumentKindEnum.PROVIDING_WATER_FACILITY_DECISION,
						ApiServiceDocumentKindEnum.NATURE_INSPECTOR_RESOLUTION,
						ApiServiceDocumentKindEnum.ROSFISHING_CONCLUSION);
				
				if(selectedServiceType === ServiceTypeEnum.SNO_INSTALLATION_SERVICE)
					this.documentsList.push(ApiServiceDocumentKindEnum.GRAPHIC_ANNEX);
				
				if(selectedServiceType === ServiceTypeEnum.GEODETIC_HYDROGRAPHIC_SERVICE)
					this.documentsList.push(ApiServiceDocumentKindEnum.GRAPHIC_ANNEX);
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		async fetchConstitutionalDocuments() {
			try {
				const findActiveDocument = (type: ApiCounterpartyDocumentType) => {
					return documents.find(x => {
						const expired = isNil(x.document.expirationDate) ?
							false :
							(parseISO(x.document.expirationDate).getTime() <= new Date().getTime());
						const necessaryType = x.document.documentType === type;
						
						return !expired && necessaryType;
					});
				};
				const prepareFileMeta = (meta: ApiFileMeta, name: string, type: ApiServiceDocumentKindEnum) => {
					const fileName = getFileNameWithExtension(name, meta.mimeType);
					return { ...FileMetaHelper.map(meta), documentKind: type, name: fileName };
				};
				const prepareFile = (name: string, blob: Blob, type: ApiServiceDocumentKindEnum) => {
					const fileName = getFileNameWithExtension(name, blob.type);
					return new ApiFile(new File([blob], fileName), fileName, type, blob.type, "");
				};
				
				
				// Получение и поиск последних активных документов
				let { documents } = await counterpartyDocumentsController.getDocuments("", {
					isArchived: false,
					sortField: "creationDate",
					sortingDirection: SortingOrderTypeEnum.DESCENDING
				} as ApiGetCounterpartyDocumentsParameters, AuthorizationScopeType.OWN);
				
				let organizationCharterTasks = [];
				let foundationAgreementTasks = [];
				
				const organizationCharter = findActiveDocument(ApiCounterpartyDocumentType.STATUTE_OF_THE_ORGANIZATION);
				const foundationAgreement = findActiveDocument(ApiCounterpartyDocumentType.MEMORANDUM_OF_ASSOCIATION);
				
				// Получение файлов документов
				if(organizationCharter)
					organizationCharterTasks.push(storageController.getFile(organizationCharter.document.fileId));
				if(foundationAgreement)
					foundationAgreementTasks.push(storageController.getFile(foundationAgreement.document.fileId));
				
				await Promise.all([...organizationCharterTasks, ...foundationAgreementTasks]);
				const [organizationCharterBlob] = await Promise.all(organizationCharterTasks);
				const [foundationAgreementBlob] = await Promise.all(foundationAgreementTasks);
				
				organizationCharterTasks = [];
				foundationAgreementTasks = [];
				
				// Загрузка документов в виде новых файлов
				if(organizationCharter)
					organizationCharterTasks.push(storageController.createTemperFile(prepareFile(organizationCharter.document.name,
						organizationCharterBlob,
						ApiServiceDocumentKindEnum.ORGANIZATION_CHARTER)));
				if(foundationAgreement)
					foundationAgreementTasks.push(storageController.createTemperFile(prepareFile(foundationAgreement.document.name,
						foundationAgreementBlob,
						ApiServiceDocumentKindEnum.FOUNDATION_AGREEMENT)));
				
				await Promise.all([...organizationCharterTasks, ...foundationAgreementTasks]);
				const [organizationCharterMeta] = await Promise.all(organizationCharterTasks);
				const [foundationAgreementMeta] = await Promise.all(foundationAgreementTasks);
				
				
				// Установка мета-данных "новых" документов
				if(organizationCharter)
					this.documents.push(prepareFileMeta(organizationCharterMeta,
						organizationCharter.document.name,
						ApiServiceDocumentKindEnum.ORGANIZATION_CHARTER));
				if(foundationAgreement)
					this.documents.push(prepareFileMeta(foundationAgreementMeta,
						foundationAgreement.document.name,
						ApiServiceDocumentKindEnum.FOUNDATION_AGREEMENT));
				
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
			}
			
		},
		async setDocument(meta: ApiFileMeta | null, type: ApiServiceDocumentKindEnum) {
			if(!meta) {
				this.documents.splice(this.documents.findIndex(x => x.documentKind === type), 1);
				return;
			}
			
			this.documents.push({
				...FileMetaHelper.map(meta),
				documentKind: type
			} as ServiceFileMeta);
		},
		async addCustomDocument(meta: ApiFileMeta) {
			this.customDocuments.push(FileMetaHelper.map(meta));
		},
		async removeCustomDocument(i: number) {
			this.customDocuments.splice(i, 1);
		},
		selectDefaultDocuments() {
			const { copiedService } = useServiceApplicationStore();
		}
	}
});
