import TreeModel from "tree-model";
import Route from "@rmp/core/router/route";
import { AuthenticationRouteNames, BaseRouteNames } from "@rmp/core/router/routeNames";

enum CounterpartyRouteNames {
	COUNTERPARTY_DOCUMENTS = "COUNTERPARTY_DOCUMENTS",

	BOAT_APPLICATIONS = "BOAT_APPLICATIONS",
	BOAT_APPLICATION_CREATE = "BOAT_APPLICATION_CREATE",
	BOAT_APPLICATION = "BOAT_APPLICATION",
	BOAT_APPLICATION_DETAILS = "BOAT_APPLICATION_DETAILS",
	BOAT_APPLICATION_DETAILS_GENERAL = "BOAT_APPLICATION_DETAILS_GENERAL",
	BOAT_APPLICATION_DETAILS_DOCUMENTS = "BOAT_APPLICATION_DETAILS_DOCUMENTS",
	BOAT_APPLICATION_DETAILS_HISTORY = "BOAT_APPLICATION_DETAILS_HISTORY",
	BOAT_APPLICATION_DETAILS_COMMENTS = "BOAT_APPLICATION_DETAILS_COMMENTS",
	
	AGREEMENTS = "AGREEMENTS",
	CREATE_AGREEMENT_APPLICATION = "CREATE_AGREEMENT_APPLICATION",
	
	AGREEMENT_DETAILS = "AGREEMENT_DETAILS",
	AGREEMENT_DETAILS_GENERAL = "AGREEMENT_DETAILS_GENERAL",
	AGREEMENT_DETAILS_DOCUMENTS = "AGREEMENT_DETAILS_DOCUMENTS",
	AGREEMENT_DETAILS_HISTORY = "AGREEMENT_DETAILS_HISTORY",
	AGREEMENT_DETAILS_COMMENTS = "AGREEMENT_DETAILS_COMMENTS",
	AGREEMENT_DETAILS_SERVICES = "AGREEMENT_DETAILS_SERVICES",
	AGREEMENT_SIGN_DOCUMENTS = "AGREEMENT_SIGN_DOCUMENTS",
	
	CREATE_SERVICE_APPLICATION = "CREATE_SERVICE_APPLICATION",
	SERVICES = "SERVICES",
	
	PIER_SERVICE = "PIER_SERVICE",
	PIER_SERVICE_GENERAL = "PIER_SERVICE_GENERAL",
	PIER_SERVICE_DOCUMENTS = "PIER_SERVICE_DOCUMENTS",
	PIER_SERVICE_HISTORY = "PIER_SERVICE_HISTORY",
	PIER_SERVICE_COMMENTS = "PIER_SERVICE_COMMENTS",
	PIER_SERVICE_FEEDBACK = "PIER_SERVICE_FEEDBACK",
	PIER_SERVICE_UPLOAD_DOCUMENTS = "PIER_SERVICE_UPLOAD_DOCUMENTS",
	
	TUGBOAT_SERVICE = "TUGBOAT_SERVICE",
	TUGBOAT_SERVICE_GENERAL = "TUGBOAT_SERVICE_GENERAL",
	TUGBOAT_SERVICE_DOCUMENTS = "TUGBOAT_SERVICE_DOCUMENTS",
	TUGBOAT_SERVICE_HISTORY = "TUGBOAT_SERVICE_HISTORY",
	TUGBOAT_SERVICE_COMMENTS = "TUGBOAT_SERVICE_COMMENTS",
	TUGBOAT_SERVICE_FEEDBACK = "TUGBOAT_SERVICE_FEEDBACK",
	TUGBOAT_SERVICE_UPLOAD_DOCUMENTS = "TUGBOAT_SERVICE_UPLOAD_DOCUMENTS",
	
	CREWBOAT_SERVICE = "CREWBOAT_SERVICE",
	CREWBOAT_SERVICE_GENERAL = "CREWBOAT_SERVICE_GENERAL",
	CREWBOAT_SERVICE_DOCUMENTS = "CREWBOAT_SERVICE_DOCUMENTS",
	CREWBOAT_SERVICE_HISTORY = "CREWBOAT_SERVICE_HISTORY",
	CREWBOAT_SERVICE_COMMENTS = "CREWBOAT_SERVICE_COMMENTS",
	CREWBOAT_SERVICE_FEEDBACK = "CREWBOAT_SERVICE_FEEDBACK",
	CREWBOAT_SERVICE_UPLOAD_DOCUMENTS = "CREWBOAT_SERVICE_UPLOAD_DOCUMENTS",
	
	DREDGING_SERVICE = "DREDGING_SERVICE",
	DREDGING_SERVICE_GENERAL = "DREDGING_SERVICE_GENERAL",
	DREDGING_SERVICE_DOCUMENTS = "DREDGING_SERVICE_DOCUMENTS",
	DREDGING_SERVICE_HISTORY = "DREDGING_SERVICE_HISTORY",
	DREDGING_SERVICE_COMMENTS = "DREDGING_SERVICE_COMMENTS",
	DREDGING_SERVICE_FEEDBACK = "DREDGING_SERVICE_FEEDBACK",
	DREDGING_SERVICE_SIGN_DOCUMENTS = "DREDGING_SERVICE_SIGN_DOCUMENTS",
	
	SNO_INSTALLATION_SERVICE = "SNO_INSTALLATION_SERVICE",
	SNO_INSTALLATION_SERVICE_GENERAL = "SNO_INSTALLATION_SERVICE_GENERAL",
	SNO_INSTALLATION_SERVICE_DOCUMENTS = "SNO_INSTALLATION_SERVICE_DOCUMENTS",
	SNO_INSTALLATION_SERVICE_HISTORY = "SNO_INSTALLATION_SERVICE_HISTORY",
	SNO_INSTALLATION_SERVICE_COMMENTS = "SNO_INSTALLATION_SERVICE_COMMENTS",
	SNO_INSTALLATION_SERVICE_FEEDBACK = "SNO_INSTALLATION_SERVICE_FEEDBACK",
	SNO_INSTALLATION_SERVICE_SIGN_DOCUMENTS = "SNO_INSTALLATION_SERVICE_SIGN_DOCUMENTS",
	
	GEODETIC_HYDROGRAPHIC_SERVICE = "GEODETIC_HYDROGRAPHIC_SERVICE",
	GEODETIC_HYDROGRAPHIC_SERVICE_GENERAL = "GEODETIC_HYDROGRAPHIC_SERVICE_GENERAL",
	GEODETIC_HYDROGRAPHIC_SERVICE_DOCUMENTS = "GEODETIC_HYDROGRAPHIC_SERVICE_DOCUMENTS",
	GEODETIC_HYDROGRAPHIC_SERVICE_HISTORY = "GEODETIC_HYDROGRAPHIC_SERVICE_HISTORY",
	GEODETIC_HYDROGRAPHIC_SERVICE_COMMENTS = "GEODETIC_HYDROGRAPHIC_SERVICE_COMMENTS",
	GEODETIC_HYDROGRAPHIC_SERVICE_FEEDBACK = "GEODETIC_HYDROGRAPHIC_SERVICE_FEEDBACK",
	GEODETIC_HYDROGRAPHIC_SERVICE_SIGN_DOCUMENTS = "GEODETIC_HYDROGRAPHIC_SERVICE_SIGN_DOCUMENTS",
	
	FLOATING_CRANE_SERVICE = "FLOATING_CRANE_SERVICE",
	FLOATING_CRANE_SERVICE_GENERAL = "FLOATING_CRANE_SERVICE_GENERAL",
	FLOATING_CRANE_SERVICE_DOCUMENTS = "FLOATING_CRANE_SERVICE_DOCUMENTS",
	FLOATING_CRANE_SERVICE_HISTORY = "FLOATING_CRANE_SERVICE_HISTORY",
	FLOATING_CRANE_SERVICE_COMMENTS = "FLOATING_CRANE_SERVICE_COMMENTS",
	FLOATING_CRANE_SERVICE_FEEDBACK = "FLOATING_CRANE_SERVICE_FEEDBACK",
	FLOATING_CRANE_SERVICE_SIGN_DOCUMENTS = "FLOATING_CRANE_SERVICE_SIGN_DOCUMENTS",
	
	INFORMATION_SERVICE = "INFORMATION_SERVICE",
	INFORMATION_SERVICE_GENERAL = "INFORMATION_SERVICE_GENERAL",
	INFORMATION_SERVICE_DOCUMENTS = "INFORMATION_SERVICE_DOCUMENTS",
	INFORMATION_SERVICE_HISTORY = "INFORMATION_SERVICE_HISTORY",
	INFORMATION_SERVICE_COMMENTS = "INFORMATION_SERVICE_COMMENTS",
	INFORMATION_SERVICE_FEEDBACK = "INFORMATION_SERVICE_FEEDBACK",
	INFORMATION_SERVICE_SIGN_DOCUMENTS = "INFORMATION_SERVICE_SIGN_DOCUMENTS",
	
	CONFIRM_PHONE = "CONFIRM_PHONE",
	CONFIRM_EMAIL = "CONFIRM_EMAIL",
	RESET_PASSWORD = "RESET_PASSWORD"
}

export const RouteNames = {
	...CounterpartyRouteNames,
	...BaseRouteNames,
	...AuthenticationRouteNames
};

export type RouteNames = typeof RouteNames[keyof typeof RouteNames];

const tree = new TreeModel();

export const findRoute = (routeName: string, route = routesThreeRoot) => {
	return route.first(x => x.model.name === routeName);
};

export const findAllByAncestor = (ancestorRouteName: string) => {
	let searchNode: any = findRoute(ancestorRouteName);
	return routesThreeRoot.all(x => x.getPath().includes(searchNode));
};


export const routesThreeRoot = tree.parse<Route>(
	new Route(RouteNames.ROOT, [
		new Route(RouteNames.CALLBACK),
		new Route(RouteNames.SILENT_RENEW),

		new Route(RouteNames.COUNTERPARTY_DOCUMENTS),

		new Route(RouteNames.BOAT_APPLICATIONS, [
			new Route(RouteNames.BOAT_APPLICATION, []),
			new Route(RouteNames.BOAT_APPLICATION_CREATE, []),
			new Route(RouteNames.BOAT_APPLICATION_DETAILS, [
				new Route(RouteNames.BOAT_APPLICATION_DETAILS_GENERAL),
				new Route(RouteNames.BOAT_APPLICATION_DETAILS_DOCUMENTS),
				new Route(RouteNames.BOAT_APPLICATION_DETAILS_HISTORY),
				new Route(RouteNames.BOAT_APPLICATION_DETAILS_COMMENTS)
			])
		]),
		
		new Route(RouteNames.AGREEMENTS, [
			new Route(RouteNames.CREATE_AGREEMENT_APPLICATION),
			new Route(RouteNames.AGREEMENT_DETAILS, [
				new Route(RouteNames.AGREEMENT_DETAILS_GENERAL),
				new Route(RouteNames.AGREEMENT_DETAILS_DOCUMENTS),
				new Route(RouteNames.AGREEMENT_DETAILS_HISTORY),
				new Route(RouteNames.AGREEMENT_DETAILS_COMMENTS),
				new Route(RouteNames.AGREEMENT_DETAILS_SERVICES),
				new Route(RouteNames.AGREEMENT_SIGN_DOCUMENTS)
			])
		]),		
		
		new Route(RouteNames.SERVICES, [
			new Route(RouteNames.TUGBOAT_SERVICE, [
				new Route(RouteNames.TUGBOAT_SERVICE_GENERAL, []),
				new Route(RouteNames.TUGBOAT_SERVICE_DOCUMENTS, []),
				new Route(RouteNames.TUGBOAT_SERVICE_HISTORY, []),
				new Route(RouteNames.TUGBOAT_SERVICE_FEEDBACK, []),
				new Route(RouteNames.TUGBOAT_SERVICE_COMMENTS, []),
				new Route(RouteNames.TUGBOAT_SERVICE_UPLOAD_DOCUMENTS, [])
			]),
			new Route(RouteNames.PIER_SERVICE, [
				new Route(RouteNames.PIER_SERVICE_GENERAL, []),
				new Route(RouteNames.PIER_SERVICE_DOCUMENTS, []),
				new Route(RouteNames.PIER_SERVICE_HISTORY, []),
				new Route(RouteNames.PIER_SERVICE_FEEDBACK, []),
				new Route(RouteNames.PIER_SERVICE_COMMENTS, []),
				new Route(RouteNames.PIER_SERVICE_UPLOAD_DOCUMENTS, [])
			]),
			new Route(RouteNames.CREWBOAT_SERVICE, [
				new Route(RouteNames.CREWBOAT_SERVICE_GENERAL, []),
				new Route(RouteNames.CREWBOAT_SERVICE_DOCUMENTS, []),
				new Route(RouteNames.CREWBOAT_SERVICE_HISTORY, []),
				new Route(RouteNames.CREWBOAT_SERVICE_FEEDBACK, []),
				new Route(RouteNames.CREWBOAT_SERVICE_COMMENTS, []),
				new Route(RouteNames.CREWBOAT_SERVICE_UPLOAD_DOCUMENTS, [])
			]),
			new Route(RouteNames.DREDGING_SERVICE, [
				new Route(RouteNames.DREDGING_SERVICE_GENERAL, []),
				new Route(RouteNames.DREDGING_SERVICE_DOCUMENTS, []),
				new Route(RouteNames.DREDGING_SERVICE_HISTORY, []),
				new Route(RouteNames.DREDGING_SERVICE_FEEDBACK, []),
				new Route(RouteNames.DREDGING_SERVICE_COMMENTS, []),
				new Route(RouteNames.DREDGING_SERVICE_SIGN_DOCUMENTS, [])
			]),
			new Route(RouteNames.SNO_INSTALLATION_SERVICE, [
				new Route(RouteNames.SNO_INSTALLATION_SERVICE_GENERAL, []),
				new Route(RouteNames.SNO_INSTALLATION_SERVICE_DOCUMENTS, []),
				new Route(RouteNames.SNO_INSTALLATION_SERVICE_HISTORY, []),
				new Route(RouteNames.SNO_INSTALLATION_SERVICE_FEEDBACK, []),
				new Route(RouteNames.SNO_INSTALLATION_SERVICE_COMMENTS, []),
				new Route(RouteNames.SNO_INSTALLATION_SERVICE_SIGN_DOCUMENTS, [])
			]),
			new Route(RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE, [
				new Route(RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_GENERAL, []),
				new Route(RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_DOCUMENTS, []),
				new Route(RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_HISTORY, []),
				new Route(RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_FEEDBACK, []),
				new Route(RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_COMMENTS, []),
				new Route(RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_SIGN_DOCUMENTS, [])
			]),
			new Route(RouteNames.INFORMATION_SERVICE, [
				new Route(RouteNames.INFORMATION_SERVICE_GENERAL, []),
				new Route(RouteNames.INFORMATION_SERVICE_DOCUMENTS, []),
				new Route(RouteNames.INFORMATION_SERVICE_HISTORY, []),
				new Route(RouteNames.INFORMATION_SERVICE_FEEDBACK, []),
				new Route(RouteNames.INFORMATION_SERVICE_COMMENTS, []),
				new Route(RouteNames.INFORMATION_SERVICE_SIGN_DOCUMENTS, [])
			]),
			new Route(RouteNames.FLOATING_CRANE_SERVICE, [
				new Route(RouteNames.FLOATING_CRANE_SERVICE_GENERAL, []),
				new Route(RouteNames.FLOATING_CRANE_SERVICE_DOCUMENTS, []),
				new Route(RouteNames.FLOATING_CRANE_SERVICE_HISTORY, []),
				new Route(RouteNames.FLOATING_CRANE_SERVICE_FEEDBACK, []),
				new Route(RouteNames.FLOATING_CRANE_SERVICE_COMMENTS, []),
				new Route(RouteNames.FLOATING_CRANE_SERVICE_SIGN_DOCUMENTS, [])
			]),
			new Route(RouteNames.CREATE_SERVICE_APPLICATION),
		]),
		
		new Route(RouteNames.CONFIRM_EMAIL),
		new Route(RouteNames.CONFIRM_PHONE),
		new Route(RouteNames.RESET_PASSWORD),
		
		new Route(RouteNames.ERROR_PAGE_NOT_FOUND),
		new Route(RouteNames.ERROR_ACCESS_DENIED),
		new Route(RouteNames.ERROR_INTERNAL_SERVER_ERROR)
	]));
