import { Store, storeToRefs } from "pinia";
import { computed, nextTick, ref } from "vue";
import { useRoute } from "vue-router/composables";
import { SavingType } from "@rmp/core/types/SavingType";
import { FormActions, FormGetters, FormState, FormStore } from "@rmp/core/stores/composables/form/useFormStore";
import { SnapshotActions, SnapshotGetters, SnapshotState } from "@rmp/core/stores/composables/snapshot";

export type FormSnapshotStore = Store<string, FormState & SnapshotState, FormGetters & SnapshotGetters, FormActions & SnapshotActions>;

export interface FormOptions {
	useStore: () => FormStore;
}

export function useForm(options: FormOptions, isResetValidationDisabled: boolean = false) {
	const store = options.useStore() as FormSnapshotStore;
	
	const form = ref<any>(null);
	
	
	const {
		formStateLoading,
		formSaving,
		formDisabled,
		formValid,
		formReadonly,
		formRecordUnique,
		formRecordUniqueCheckInProgress,
		stateContainsChangesSinceLastSaved,
		changedSinceLastSavedFields
	} = storeToRefs(store);
	
	const route = useRoute();
	
	const createMode = computed(() => {
		return !route.params.id;
	});
	
	const validateForm = () => {
		return form.value.validate();
	};
	
	const onFormCancel = async () => {
		await cancelFormChanges();
		await nextTick(() => {
			if(!isResetValidationDisabled)
				form.value.resetValidation();
		});
	};
	
	const cancelFormChanges = async () => {
		await store.cancelChanges();
	};
	
	const submit = async () => {
		if(validateForm()) {
			createMode ?
				await store.save({ type: SavingType.CREATE }) :
				await store.save({ type: SavingType.UPDATE, id: route.params.id });
		}
	};
	
	return {
		form,
		formStateLoading,
		formSaving,
		formDisabled,
		formValid,
		formReadonly,
		formRecordUnique,
		formRecordUniqueCheckInProgress,
		stateContainsChangesSinceLastSaved,
		changedSinceLastSavedFields,
		createMode,
		validateForm,
		onFormCancel,
		cancelFormChanges,
		submit
	};
}
