import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { RouteNames } from "@rmp/counterparty/router/routes";
import router from "@rmp/counterparty/router";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { CounterpartyDocumentsFilter } from "@rmp/counterparty/stores/counterpartyDocuments/types/counterpartyDocumentsFilter";
import CounterpartyDocumentsRouteQuery from "@rmp/counterparty/stores/counterpartyDocuments/types/counterpartyDocumentsRouteQuery";
import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";
import CounterpartyDocumentsRouteQueryService
	from "@rmp/counterparty/stores/counterpartyDocuments/services/counterpartyDocumentsRouteQueryService";
import CounterpartyDocumentsMapperProfile from "@rmp/counterparty/stores/counterpartyDocuments/mapper";
import { CounterpartyDocument } from "@rmp/core/types/counterparty/documents/counterpartyDocument";
import { CounterpartyDocumentsController } from "@rmp/core/api/counterpartyDocument";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import { CreateCounterpartyDocumentRequest } from "@rmp/counterparty/stores/counterpartyDocuments/types/createCounterpartyDocumentRequest";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { saveAs } from "file-saver";
import { StorageController } from "@rmp/core/api/storage";
import ApiFileMeta from "@rmp/core/api/types/storage/apiFileMeta";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";
import { ArchiveStatusState, useArchiveStatusStore } from "@rmp/core/stores/composables/archiveStatus";
import NotDefinedException from "@rmp/core/exceptions/notDefinedException";

const abortService = new AbortService();
const counterpartyDocumentsController = new CounterpartyDocumentsController(abortService);
const storageController = new StorageController(abortService);

const defaultRouteQuery = new CounterpartyDocumentsRouteQuery(
	1,
	"name",
	SortingOrderTypeEnum.ASCENDING,
	"",
	ArchiveStatusType.ALL);

const routeQueryService = new CounterpartyDocumentsRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);
const archiveStatusStore = useArchiveStatusStore();
const form = useFormStore();
const snapshotStore = useSnapshotStore([
	{
		key: SnapshotKeysEnum.LAST_SAVED, fields: ["newDocument"]
	}
]);

export interface CounterpartyDocumentsState extends PageState, RegisterState<CounterpartyDocument, CounterpartyDocumentsFilter>, FormState,
	SnapshotState, ArchiveStatusState
{
	filter: CounterpartyDocumentsFilter;
	newDocument: CreateCounterpartyDocumentRequest;
}


const register = useRegisterStore<CounterpartyDocument, CounterpartyDocumentsFilter>({
	routeName: RouteNames.COUNTERPARTY_DOCUMENTS,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): CounterpartyDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...archiveStatusStore.getDefaultState(),
		...register.getDefaultRegisterState(),
		...form.getDefaultState(),
		...snapshotStore.getDefaultState(),
		filter: {
			status: ArchiveStatusType.ALL
		},
		newDocument: {
			name: "",
			expirationDate: 0,
			documentType: "",
			tempFileId: ""
		} as CreateCounterpartyDocumentRequest
	};
};

export const useCounterpartyDocumentsStore = defineStore({
	id: "counterparty-documents",
	state: (): CounterpartyDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...archiveStatusStore.getters,
		...register.getters,
		...form.getters,
		...snapshotStore.getters,
		filteredItems(state: CounterpartyDocumentsState) {
			return state.listing.items;
		}
	},
	actions: {
		...page.actions,
		...archiveStatusStore.actions,
		...register.actions,
		...form.actions,
		...snapshotStore.actions,
		async beforeInitialized() {
			await this.initializeRegister();
			
			this.setStateSnapshot();
		},
		patchStateWithRouteQuery(query: CounterpartyDocumentsRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.status = query.status;
			});
		},
		async fetchRegisterItems() {
			let { documents, totalCount } = await counterpartyDocumentsController.getDocuments("",
				CounterpartyDocumentsMapperProfile.mapToApiGetCounterpartyDocumentsParameters(this),
				AuthorizationScopeType.OWN);
			return {
				items: documents.map(x => CounterpartyDocumentsMapperProfile.mapToCounterpartyDocument(x)),
				totalCount
			};
		},
		async createDocument() {
			this.formSaving = true;
			
			try {
				const document = await counterpartyDocumentsController.createDocument(CounterpartyDocumentsMapperProfile.mapToApiCreateCounterpartyDocumentRequest(
					this.newDocument));
				
				alertService.addSuccess(AlertKeys.SUCCESS_CREATED_INFO);
				
				await this.updateListingItems();
				this.newDocument = {} as CreateCounterpartyDocumentRequest;
				
				this.setStateSnapshot();
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.formSaving = false;
			}
		},
		async updateArchiveStatus(id: string) {
			if(!id)
				throw new NotDefinedException("id");
			
			const isArchived = this.listing.items.find(x => x.id === id)!.isArchived;
			
			await counterpartyDocumentsController.updateDocumentStatus(id, !isArchived);
			
			await this.updateListingItems();
			
			alertService.addSuccess(AlertKeys.STATUS_SUCCESS_UPDATED);
		},
		async downloadFile(id: string) {
			try {
				const fileMeta = await storageController.getFileMeta(id);
				
				const file = await storageController.getFile(fileMeta.id);
				saveAs(file, fileMeta.name);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		setDocumentFileMeta(id: string, meta: ApiFileMeta) {
			this.listing.items.find(x => x.id === id)!.fileMeta = meta;
		},
		setNewDocumentTempFileId(id: string) {
			this.newDocument.tempFileId = id;
		}
	}
});
