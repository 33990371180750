import { ApiAccountProfileBase } from "@rmp/core/api/types/account/profile/apiAccountProfileBase";
import { ProfileType } from "@rmp/core/types/ProfileType";

export default class ApiLegalEntityEmployeeProfile extends ApiAccountProfileBase {
	inn: string;
	number: number;
	snils: string;
	externalId: string;
	counterpartyInn: string;
	counterpartyOgrn: string;
	counterpartyKpp: string;

	constructor(
		type = ProfileType.COUNTERPARTY_LEGAL_ENTITY_EMPLOYEE,
		number = 0,
		accountId: string = "",
		title: string = "",
		email: string = "",
		phone: string = "",
		inn: string = "",
		snils: string = "",
		isActive: boolean = false,
		externalId = "",
		counterpartyInn = "",
		counterpartyOgrn = "",
		counterpartyKpp = ""
	)
	{
		super(type, accountId, externalId, title, email, phone, isActive);

		this.inn = inn;
		this.number = number;
		this.snils = snils;
		this.externalId = externalId;
		this.counterpartyInn = counterpartyInn;
		this.counterpartyOgrn = counterpartyOgrn;
		this.counterpartyKpp = counterpartyKpp;
	}
}
