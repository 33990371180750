export enum AgreementWorkflowStateType {
	NEW = "New",
	CANCELED = "Canceled",
	REJECTED = "Rejected",
	DRAFT = "Draft",
	SENT = "Sent",
	ACTIVE = "Active",
	NOT_ACTIVE = "NotActive",
	NEGOTIATED = "Negotiated",
	FINALLY_SIGNED_BY_ENTERPRISE = "FinallySignedByEnterprise",
	FINALLY_SIGNED_BY_COUNTERPARTY = "FinallySignedByCounterparty"
}
