import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import ApiCounterpartyBoatDocumentPackage from "@rmp/core/api/types/counterparty/boatApplication/apiCounterpartyBoatDocumentPackage";
import { DocumentsObjectState, useDocumentsObjectStore } from "@rmp/core/stores/composables/documentsObject";
import { useBoatApplicationDetailsStore } from "@rmp/counterparty/stores/boatApplicationDetails";
import { useBoatApplicationDetailsDocumentsBreadcrumb } from "@rmp/counterparty/stores/boatApplicationDetails/documents/composables/useBoatApplicationDetailsDocumentsBreadcrumb";

const abortService = new AbortService();
const page = usePageStore(abortService);
const documentsStore = useDocumentsObjectStore<ApiCounterpartyBoatDocumentPackage>();

export interface BoatApplicationDetailsDocumentsState extends PageState, DocumentsObjectState<ApiCounterpartyBoatDocumentPackage> {
}

const getDefaultState = (): BoatApplicationDetailsDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...documentsStore.getDefaultState()
	};
};

export const useBoatApplicationDetailsDocumentsStore = defineStore({
	id: "boat-application-details-documents",
	state: (): BoatApplicationDetailsDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...documentsStore.getters,
		breadcrumbs() {
			// @ts-ignore
			const { breadcrumbs } = useBoatApplicationDetailsStore();
			return [...breadcrumbs, useBoatApplicationDetailsDocumentsBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...documentsStore.actions,
		async beforeInitialized() {
			let { application: { documentsPackage } } = useBoatApplicationDetailsStore();
			
			this.initializeDocumentsStore({ documentsPackage });
		}
	}
});
