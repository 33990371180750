export enum ApiCounterpartyDocumentType {
	// Приказ о назначении руководителя
	ORDER_APPOINTING_THE_HEAD = "OrderAppointingTheHead",
	
	// Устав организации
	STATUTE_OF_THE_ORGANIZATION = "StatuteOfTheOrganization",
	
	// Учредительный договор
	MEMORANDUM_OF_ASSOCIATION = "MemorandumOfAssociation"
}
