import { FileMeta, FileMetaHelper } from "@rmp/core/types/storage/fileMeta";

export interface CounterpartyBoatDocumentPackage {
	ownershipCertificate: FileMeta,
	internationalMeasuringCertificate: FileMeta,
	classificationCertificate: FileMeta,
	internationalOilPollutionPreventionCertificate: FileMeta,
	customDocuments: FileMeta[]
}

export class CounterpartyBoatDocumentPackageMapper {
	static getEmpty(): CounterpartyBoatDocumentPackage {
		return {
			ownershipCertificate: FileMetaHelper.getEmpty(),
			internationalMeasuringCertificate: FileMetaHelper.getEmpty(),
			classificationCertificate: FileMetaHelper.getEmpty(),
			internationalOilPollutionPreventionCertificate: FileMetaHelper.getEmpty(),
			customDocuments: []
		};
	}
}
