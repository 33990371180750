import NotDefinedException from "@rmp/core/exceptions/notDefinedException";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { BaseDocumentsController } from "@rmp/core/api/shared/baseDocumentsController";

export interface DocumentsState<DocumentType> {
	id: string | null;
	documents: DocumentType[];
}

interface DocumentsBaseMapper<DocumentType> {
	map: (source: any, titleFirstPart: string) => DocumentType;
}

interface DocumentsOptions<DocumentType> {
	controller: BaseDocumentsController
	mapper: DocumentsBaseMapper<DocumentType>;
}

export const useDocumentsStore = <DocumentType>(options: DocumentsOptions<DocumentType>) => {
	const getDefaultState = (): DocumentsState<DocumentType> => {
		return {
			id: null,
			documents: []
		};
	};
	
	const getters = {};
	
	const actions = {
		async initializeDocumentsStore(this: DocumentsState<DocumentType>, documentTitleFirstPart: string) {
			if(!this.id)
				throw new NotDefinedException("id");
			
			try {
				let { documents } = await options.controller.getDocuments(this.id!);
				
				this.documents = documents.map((x: any) => options.mapper.map(x, documentTitleFirstPart));
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		}
	};
	
	return { getters, actions, getDefaultState };
};
