import { format, addMinutes, parse, parseISO, min, max, getHours } from "date-fns";
import { ru } from "date-fns/locale";
import {
	dateFormat,
	dateTimeFormat,
	friendlyDateFormat,
	dayOfWeek,
	timeFormat,
	friendlyDateFormatWithoutYear,
	isoDate, isoDateFormat, timeFormatWithSeconds
} from "@rmp/core/utils/formats";
import { capitalizeFirstLetter } from "@rmp/core/utils/formatting";
import { utcToZonedTime } from "date-fns-tz";

export const convertIsoToNumber = (value: any) => {
	if(!value) return value;

	return parseISO(value).getTime();
};

export const formatDateInterval = (start: Date | number, end: Date | number) => {
	return `с ${formatDate(start, dateFormat)} по ${formatDate(end, dateFormat)}`;
};

export const formatDate = (date: Date | number, dateFormat: string) => {
	return date && format(date, dateFormat, { locale: ru });
};

export const formatWorkingTime = (value: number) => {
	const days = Math.floor(value / 24);
	const hours = Math.floor(value % 24);
	const minutes = (value - (days * 24 + hours)) * 60;
	
	return (days ? `${days}:` : "") + String(hours).padStart(2, "0") + `:${String(minutes).padStart(2, "0")}` + ":00";
};

export const parseWorkingTime = (value: string) => {
	const parsed = parseTimeSpan(value);
	return ((parsed.days || 0) * 24 + parsed.hours) + parsed.minutes / 60;
};

export const eachDateOfInterval = ({ start, end, interval }: { start: any, end: any, interval: number }) => {
	const dates = [];

	let currentDate = new Date(start);
	let endTime = new Date(end).getTime();

	while (currentDate.getTime() <= endTime) {
		dates.push(currentDate);
		currentDate = addMinutes(currentDate, interval);
	}
	return dates;
};

export const parseDateToTime = (str: string, format: string) => {
	return parse(str, format, new Date()).getTime();
};


export const formatInterval = (start: Date | number, end: Date | number) => {
	let timeInterval = `${formatDate(start, timeFormat)} - ${formatDate(end, timeFormat)}`;
	return `${formatDate(start, dateFormat)} ${timeInterval}`;
};

export const parseInterval = (strInterval: string) => {
	let segments = strInterval.split(" ");
	let [date, startTime] = segments;
	let [endTime] = segments.reverse();

	return {
		start: parseDateToTime(`${date} ${startTime}`, dateTimeFormat),
		end: parseDateToTime(`${date} ${endTime}`, dateTimeFormat)
	};
};

export const formatIntervalWeek = (start: Date | number, end: Date | number) => {
	return `${formatDate(start, friendlyDateFormatWithoutYear)} - ${formatDate(end, friendlyDateFormat)}`;
};

export const formatDateToDayOfWeek = (date: Date | number) => {
	return date && capitalizeFirstLetter(format(date, dayOfWeek, { locale: ru }));
};

export const removeTime = (date: any) => {
	return new Date(date.setHours(0, 0, 0, 0));
};

export const findFirstAndLastDates = (dates: (number | Date)[]) => {
	const firstDate = min(dates);
	const lastDate = max(dates);

	return [firstDate, lastDate];
};

export const convertToZonedIso = (date: Date | number, offset: string = timezoneOffsets.DEFAULT) => {
	if(!date)
		return "";

	return formatDate(date, isoDate) + offset;
};

export const convertToZonedTimestamp = (date: string, timezone: string = timezoneNames.MOSCOW) => {
	if(!date)
		return date;

	return utcToZonedTime(date, timezone).getTime();
};

export const convertToTimestamp = (date: string) => {
	if(!date)
		return date;

	return parseISO(date).getTime();
};

export const parseDate = (date: string, format: string = isoDateFormat) => {
	if(!date)
		return 0;

	return parse(date, format, 0);
};

export const parseISODate = (value: string) => {
	return value ? parseISO(value) : null;
};

export const formatISODate = (value: Date | null) => {
	return value ? new Date(value).toISOString() : "";
};

export const parseTimeSpan = (timeString: string): { days?: number, hours: number, minutes: number, seconds: number } => {
	let timeParts = timeString.replace(".", ":").split(':').map(Number);
	let timeObject: any = {};
	
	if (timeParts.length === 4) {
		timeObject = {
			days: timeParts[0],
			hours: timeParts[1],
			minutes: timeParts[2],
			seconds: timeParts[3]
		};
	} else if (timeParts.length === 3) {
		timeObject = {
			hours: timeParts[0],
			minutes: timeParts[1],
			seconds: timeParts[2]
		};
	} else {
		throw new Error('Invalid time string');
	}
	
	return timeObject;
};

export const enum timezoneNames {
	MOSCOW = "Europe/Moscow"
}

export const enum timezoneOffsets {
	DEFAULT = "+00:00",
	MOSCOW = "+03:00"
}
