import AbortService from "@rmp/core/services/abortService";
import BaseController from "@rmp/core/api/shared/baseController";
import { ApiSegment, createUrlsTree, Node, resolveUrl } from "@rmp/core/utils/api";
import TreeModel from "tree-model";
import { urls } from "@rmp/core/api/config/organizationUrls";
const organizationUrl = process.env.VUE_APP_BASE_API_URL!;

export default class BaseOrganizationController extends BaseController {
	urlsTree: TreeModel.Node<Node>;
	
	constructor(abortService: AbortService) {
		super(abortService);
		
		this.urlsTree = createUrlsTree(urls);
	}
	
	async get<T>(segment: ApiSegment, params: object = {}) {
		const url = resolveUrl(organizationUrl, this.urlsTree, segment, params);
		
		return await this.client.get<T>(url);
	}
	
	async post<T>(segment: ApiSegment, params: object = {}, payload: object = {}) {
		const url = resolveUrl(organizationUrl, this.urlsTree, segment, params);
		
		return await this.client.post<T>(url, payload);
	}
	
	async put<T>(segment: ApiSegment, params: object = {}, payload: object = {}) {
		const url = resolveUrl(organizationUrl, this.urlsTree, segment, params);
		
		return await this.client.put<T>(url, payload);
	}
}
