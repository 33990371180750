import { ApiServiceTariffsEnum } from "@rmp/core/api/types/service/tariffs/ApiServiceTariffsEnum";
import { ApiVatEnum } from "@rmp/core/api/types/vat/ApiVatEnum";
import { ApiTariff } from "@rmp/core/api/types/service/tariffs/apiTariff";

export interface ServiceApplicationTariff {
	id: string;
	name: string;
	price: number;
	measure: string;
	vat: ApiVatEnum;
	type: ApiServiceTariffsEnum;
	value: number;
	amount: number;
	vatIncluded: number;
}

export class ServiceApplicationTariffMapper {
	static map(source: ApiTariff): ServiceApplicationTariff {
		return {
			id: source.id,
			name: source.title,
			price: source.unitPrice,
			measure: source.unit,
			vat: source.vat,
			type: source.type,
			value: 0,
			amount: 0,
			vatIncluded: 0
		};
	}
}
