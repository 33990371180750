import { Store } from "pinia";
import { SnapshotActions, SnapshotGetters, SnapshotState } from "@rmp/core/stores/composables/snapshot";

export interface FormState {
	formStateLoading: boolean;
	formSaving: boolean;
	formDisabled: boolean;
	formValid: boolean;
	formReadonly: boolean;
	formRecordUnique: boolean;
	formRecordUniqueCheckInProgress: boolean;
}

export type FormGetters = {}

export type FormActions = {};

export type FormStore = Store<string, (FormState & SnapshotState) | FormState, (FormGetters & SnapshotGetters) | FormGetters, (FormActions & SnapshotActions) | FormActions>;

export const useFormStore = () => {
	const getDefaultState = (): FormState => {
		return {
			formStateLoading: false,
			formSaving: false,
			formDisabled: false,
			formValid: false,
			formReadonly: false,
			formRecordUnique: false,
			formRecordUniqueCheckInProgress: false
		};
	};
	
	const getters: FormGetters = {};
	
	const actions: FormActions = {};
	
	return {
		actions,
		getters,
		getDefaultState
	};
};
