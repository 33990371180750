import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { AgreementController } from "@rmp/counterparty/api/agreement";
import { useAgreementsBreadcrumb } from "@rmp/counterparty/stores/agreements/composables/useAgreementsBreadcrumb";
import { Agreement, AgreementMapper } from "@rmp/core/types/agreement/agreement";
import { useAgreementDetailsBreadcrumb } from "@rmp/counterparty/stores/agreementDetails/composables/useAgreementDetailsBreadcrumb";
import { ApiAgreementTransitionCodeType } from "@rmp/core/api/types/agreement/ApiAgreementTransitionCodeType";
import InvalidBusinessOperationException from "@rmp/core/exceptions/invalidBusinessOperationException";
import { AgreementWorkflowStateType } from "@rmp/core/types/agreement/AgreementWorkflowStateType";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { formatAgreementTitle } from "@rmp/core/utils/formatting";
import { useBaseServiceStore } from "@rmp/counterparty/stores/composables/baseService";
import { ArchiveStatusState, useArchiveStatusStore } from "@rmp/core/stores/composables/archiveStatus";
import router from "@rmp/counterparty/router";

const abortService = new AbortService();
const page = usePageStore(abortService);
const archiveStatusStore = useArchiveStatusStore();

const agreementController = new AgreementController(abortService);

export interface AgreementDetailsState extends PageState, ArchiveStatusState {
	agreement: Agreement;
	id: string;
	title: string;
	declining: boolean;
}

const getDefaultState = (): AgreementDetailsState => {
	return {
		...page.getDefaultPageState(),
		...archiveStatusStore.getDefaultState(),
		agreement: {} as Agreement,
		id: "",
		title: "",
		declining: false,
	};
};

export const useAgreementDetailsStore = defineStore({
	id: "agreement-details",
	state: (): AgreementDetailsState => getDefaultState(),
	getters: {
		...page.getters,
		...archiveStatusStore.getters,
		breadcrumbs(state: AgreementDetailsState) {
			return [
				useAgreementsBreadcrumb(),
				useAgreementDetailsBreadcrumb(state.title)
			];
		}
	},
	actions: {
		...page.actions,
		...archiveStatusStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.fetch();
		},
		async fetch() {
			try {
				let agreement = await agreementController.getAgreement(this.id!);
				
				this.agreement = AgreementMapper.map(agreement);
				this.title = formatAgreementTitle(this.agreement.serviceKind, this.agreement.agreementNumber);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async decline() {
			this.declining = true;
			
			try {
				let res = await agreementController.changeAgreementStatus(this.id!, ApiAgreementTransitionCodeType.REJECT);
				
				if(!res.isSuccess)
					throw new InvalidBusinessOperationException(`Не удалось перевести заявку в статус ${AgreementWorkflowStateType.REJECTED}`,
						res);
				
				// TODO
				this.agreement.state.code = AgreementWorkflowStateType.REJECTED;
				
				alertService.addSuccess(AlertKeys.AGREEMENT_DECLINED);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.declining = false;
			}
		},
		async updateArchiveStatus() {
			if(!this.agreement.isArchived)
				await agreementController.archive(router.currentRoute.params.id);
			else
				await agreementController.unarchive(router.currentRoute.params.id);
			
			this.agreement.isArchived = !this.agreement.isArchived;
			
			alertService.addSuccess(AlertKeys.STATUS_SUCCESS_UPDATED);
		},
	}
});
