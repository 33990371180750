import { ApiDepartmentPersisted } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { ServiceApplicationDepartment } from "@rmp/counterparty/stores/serviceApplication/steps/department/types/serviceApplicationDepartment";
import { ApiCounterpartyBoatPersisted } from "@rmp/core/api/types/counterpartyBoat/apiCounterpartyBoat";
import { ServiceApplicationCounterpartyBoat } from "@rmp/counterparty/stores/serviceApplication/steps/boat/types/serviceApplicationCounterpartyBoat";

export default class ServiceApplicationCounterpartyBoatMapper {
	mapBoat(source: ApiCounterpartyBoatPersisted): ServiceApplicationCounterpartyBoat {
		return {
			id: source.id,
			...source.counterpartyBoat,
			applicationStatus: source.counterpartyBoat.status
		};
	}
}
