export enum ApiServiceTypeEnum {
	UNDEFINED_SERVICE = "Undefined",
	
	// Буксирные услуги
	TUGBOAT_SERVICE = "TugService",
	
	// Услуги по предоставлению разъездных судов
	CREWBOAT_SERVICE = "CrewBoatService",
	
	// Информационные услуги
	INFORMATION_SERVICE = "InformationService",
	
	// Услуги плавкрана
	FLOATING_CRANE_SERVICE = "FloatingCraneService",
	
	// Услуги по предоставлению причалов для стоянки судов
	PIER_SERVICE = "PierService",
	
	// Геодезические и гидрографические услуги
	GEODETIC_HYDROGRAPHIC_SERVICE = "GeodeticHydrographicService",
	
	// Услуги по установке и обслуживанию СНО
	SNO_INSTALLATION_SERVICE = "SnoInstallationService",
	
	// Дноуглубительные услуги
	DREDGING_WORKS_SERVICE = "DredgingService"
}
