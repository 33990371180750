import { IsEnum } from "class-validator";
import { CounterpartyEmployeeTrustStatus } from "@rmp/core/types/CounterpartyEmployeeTrustStatus";

export default class ApiCounterpartyEmployeeTrustInfo {
	@IsEnum(CounterpartyEmployeeTrustStatus)
	status: CounterpartyEmployeeTrustStatus;

	constructor(status: CounterpartyEmployeeTrustStatus = CounterpartyEmployeeTrustStatus.ABSENT) {
		this.status = status;
	}
}
