import { isArray, isObject, isPlainObject, isNumber, isString, isBoolean, isEmpty, values } from "lodash";

export const hasObjectValueDeep = (obj: any, query: string) => {
	for (let property in obj) {
		if(obj.hasOwnProperty(property)) {
			let value = obj[property];
			if(value === query) {
				return true;
			} else if(isObject(value)) {
				hasObjectValueDeep(value, query);
			} else if(isArray(value)) {
				value.forEach((el) => {
					if(isObject(el)) {
						hasObjectValueDeep(el, query);
					}
				});
			}
		}
	}
};

export const flattenObj = (value: any, prefix?: string, result?: any): object => {
	prefix = prefix ? prefix : "";
	result = result ? result : {};

	if(isString(value) || isNumber(value) || isBoolean(value)) {
		result[prefix] = value;
		return result;
	}

	if(isArray(value) || isPlainObject(value)) {
		for (let i in value) {
			let pref = prefix;
			if(isArray(value)) {
				pref = pref + `[${i}]`;
			} else {
				if(isEmpty(prefix)) {
					pref = i;
				} else {
					pref = prefix + "." + i;
				}
			}

			flattenObj(value[i], pref, result);
		}

		return result;
	}

	return result;
};

export const deepObjToArray = (value: any): Array<any> => {
	return values(flattenObj(value));
};

export const deepClonePlain = (value: any) => JSON.parse(JSON.stringify(value));
