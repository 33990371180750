import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useTugboatServiceStore } from "@rmp/counterparty/stores/serviceDetails/tugboat";
import { ServiceController } from "@rmp/counterparty/api/service";
import { useTugboatServiceFeedbackBreadcrumb } from "@rmp/counterparty/stores/serviceDetails/tugboat/feedback/composables/useTugboatServiceFeedbackBreadcrumb";
import { FeedbackState, useFeedbackStore } from "@rmp/core/stores/composables/feedback";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceController = new ServiceController(abortService);
const feedbackStore = useFeedbackStore({ controller: serviceController });

export interface TugboatServiceFeedbackState extends PageState, FeedbackState {
}

const getDefaultState = (): TugboatServiceFeedbackState => {
	return {
		...page.getDefaultPageState(),
		...feedbackStore.getDefaultState()
	};
};

export const useTugboatServiceFeedbackStore = defineStore({
	id: "tugboat-service-feedback",
	state: (): TugboatServiceFeedbackState => getDefaultState(),
	getters: {
		...page.getters,
		...feedbackStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useTugboatServiceStore();
			return [...breadcrumbs, useTugboatServiceFeedbackBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...feedbackStore.actions,
		async beforeInitialized(this: any, { id }: { id: string }) {
			this.id = id;
			await this.initializeFeedbackStore();
		}
	}
});
