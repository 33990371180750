import ApiFileMeta from "@rmp/core/api/types/storage/apiFileMeta";
import { FileMetaSignature, FileMetaSignatureMapper } from "@rmp/core/types/storage/fileMetaSignature";

export interface FileMeta {
	id: string;
	name: string;
	mimeType: string;
	namespace: string;
	signatures: FileMetaSignature[];
	loading: boolean;
}

export class FileMetaHelper {
	static map(source: ApiFileMeta): FileMeta {
		return {
			...source,
			signatures: source.signatures ? source.signatures.map(x => FileMetaSignatureMapper.map(x)) : [],
			loading: false
		};
	}
	static getEmpty(): FileMeta {
		return {
			id: "",
			name: "",
			mimeType: "",
			namespace: "",
			signatures: [] as FileMetaSignature[],
			loading: false
		};
	}
}
