import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/counterparty/router";
import { RouteNames } from "@rmp/counterparty/router/routes";
import { AgreementController } from "@rmp/counterparty/api/agreement";
import { Agreement, AgreementMapper } from "@rmp/core/types/agreement/agreement";
import { useAgreementsBreadcrumb } from "@rmp/counterparty/stores/agreements/composables/useAgreementsBreadcrumb";
import { useAgreementDetailsBreadcrumb } from "@rmp/counterparty/stores/agreementDetails/composables/useAgreementDetailsBreadcrumb";
import {
	useSignAgreementDocumentsBreadcrumb
} from "@rmp/counterparty/stores/signAgreementDocuments/composables/useSignAgreementDocumentsBreadcrumb";
import InvalidBusinessOperationException from "@rmp/core/exceptions/invalidBusinessOperationException";
import { AgreementWorkflowStateType } from "@rmp/core/types/agreement/AgreementWorkflowStateType";
import { ApiAgreementTransitionCodeType } from "@rmp/core/api/types/agreement/ApiAgreementTransitionCodeType";
import { CounterpartyController } from "@rmp/core/api/counterparty";
import { AgreementDocument, AgreementDocumentMapper } from "@rmp/core/types/agreement/agreementDocument";
import { formatAgreementTitle } from "@rmp/core/utils/formatting";
import {
	BaseSignServiceDocumentsState,
	useBaseSignServiceDocumentsStore
} from "@rmp/counterparty/stores/composables/baseSignServiceDocuments";
import { StorageController } from "@rmp/core/api/storage";

const abortService = new AbortService();
const page = usePageStore(abortService);

const agreementController = new AgreementController(abortService);
const storageController = new StorageController(abortService);
const counterpartyController = new CounterpartyController(abortService);

const baseSignServiceDocumentsStore = useBaseSignServiceDocumentsStore<AgreementDocument>({
	abortService,
	controller: agreementController,
	mapper: AgreementDocumentMapper
});

export interface SignAgreementDocumentsState extends PageState, BaseSignServiceDocumentsState<AgreementDocument> {
	agreement: Agreement;
}

const getDefaultState = (): SignAgreementDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...baseSignServiceDocumentsStore.getDefaultState(),
		agreement: {} as Agreement
	};
};

export const useSignAgreementDocumentsStore = defineStore({
	id: "sign-agreement-documents",
	state: (): SignAgreementDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...baseSignServiceDocumentsStore.getters,
		breadcrumbs(state: SignAgreementDocumentsState) {
			return [
				useAgreementsBreadcrumb(),
				useAgreementDetailsBreadcrumb(state.title),
				useSignAgreementDocumentsBreadcrumb()
			];
		}
	},
	actions: {
		...page.actions,
		...baseSignServiceDocumentsStore.actions,
		async beforeInitialized() {
			this.id = router.currentRoute.params.id;
			
			await Promise.all([
				this.fetchDocuments(this.id),
				this.fetch()
			]);
		},
		async fetch() {
			try {
				const [agreement, { verified }] = await Promise.all([
					agreementController.getAgreement(this.id),
					counterpartyController.verifySigningAuthority()
				]);
				
				this.agreement = AgreementMapper.map(agreement);
				this.title = formatAgreementTitle(this.agreement.serviceKind, this.agreement.agreementNumber);
				this.canSign = verified;
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async approve() {
			this.approving = true;
			
			try {
				let toSigned = await agreementController.changeAgreementStatus(this.id!, ApiAgreementTransitionCodeType.FINALLY_SIGN);
				
				if(!toSigned.isSuccess)
					throw new InvalidBusinessOperationException(`Не удалось перевести заявку в статус ${AgreementWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY}`,
						toSigned);
				
				// TODO
				this.agreement.state.code = AgreementWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY;
				
				alertService.addSuccess(AlertKeys.AGREEMENT_STATUS_UPDATED);
				
				await router.push({ name: RouteNames.AGREEMENT_DETAILS, params: { id: this.id } });
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.approving = false;
			}
		}
	}
});
