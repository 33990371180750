import { ApiCertificate } from "@rmp/core/api/types/signature/apiCertificate";
import { IDictionary } from "@rmp/core/types/core/dictionary";
import { parseISO } from "date-fns";

export interface Certificate {
	name: string;
	issuer: string;
	friendlyName: string;
	serialNumber: string;
	signatureAlgorithm: string;
	thumbprint: string;
	subject: string;
	subjectAttributesByName: IDictionary<string>;
	subjectAttributesByOid: IDictionary<string>;
	issuerAttributesByName: IDictionary<string>;
	issuerAttributesByOid: IDictionary<string>;
	notAfter: Date;
	notBefore: Date;
	version: string;
	certificate: string;
}

export class CertificateMapper {
	static map(source: ApiCertificate): Certificate {
		return {
			...source,
			notAfter: parseISO(source.notAfter),
			notBefore: parseISO(source.notBefore)
		};
	}
}
