export interface RegisterSearchState {
	search: {
		query: string;
	};
}

export function useRegisterSearchStore() {
	const actions = {
		setSearchQuery(this: RegisterSearchState, value: string) {
			this.search.query = value;
		}
	};

	return {
		actions
	};
}
