import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { ServiceController } from "@rmp/counterparty/api/service";
import { ServiceDocument, ServiceDocumentMapper } from "@rmp/core/types/services/serviceDocument";
import { useDredgingServiceStore } from "@rmp/counterparty/stores/serviceDetails/dredging";
import { useDredgingServiceDocumentsBreadcrumb } from "@rmp/counterparty/stores/serviceDetails/dredging/documents/composables/useDredgingServiceDocumentsBreadcrumb";
import { DocumentsState, useDocumentsStore } from "@rmp/counterparty/stores/composables/baseDocuments";

const abortService = new AbortService();
const page = usePageStore(abortService);
const serviceController = new ServiceController(abortService);

const documentsStore = useDocumentsStore({ controller: serviceController, mapper: ServiceDocumentMapper });

export interface DredgingServiceDocumentsState extends PageState, DocumentsState<ServiceDocument> {
}

const getDefaultState = (): DredgingServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...documentsStore.getDefaultState()
	};
};

export const useDredgingServiceDocumentsStore = defineStore({
	id: "dredging-service-documents",
	state: (): DredgingServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...documentsStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useDredgingServiceStore();
			return [...breadcrumbs, useDredgingServiceDocumentsBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...documentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			const { title } = useDredgingServiceStore();
			
			this.id = id;
			
			await this.initializeDocumentsStore(title);
		}
	}
});
