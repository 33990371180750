import { defineStore } from "pinia";
import { ServiceApplicationStateBase } from "@rmp/counterparty/stores/serviceApplication/services/base/useServiceApplicationStoreBase";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";


export interface SnoInstallationServiceApplicationState extends ServiceApplicationStateBase {
	// currentStep (title, index, alias)
}

const getDefaultState = (): SnoInstallationServiceApplicationState => {
	return {};
};

export const useSnoInstallationServiceApplicationStore = defineStore({
	id: "sno-installtion-service-application",
	state: (): SnoInstallationServiceApplicationState => getDefaultState(),
	getters: {
		steps: state => {
			return [
				ServiceApplicationStepTypeEnum.SERVICE,
				ServiceApplicationStepTypeEnum.DEPARTMENT,
				ServiceApplicationStepTypeEnum.PORT,
				ServiceApplicationStepTypeEnum.DOCUMENTS,
				ServiceApplicationStepTypeEnum.CONFIRMATION
			];
		}
	},
	actions: {}
});
