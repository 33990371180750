const baseUrl = process.env.VUE_APP_BASE_API_URL;
const enterpriseUrl = process.env.VUE_APP_ENTERPRISE_API_URL;
const storageUrl = process.env.VUE_APP_FILE_STORAGE_API_URL;
const authUrl = process.env.VUE_APP_OIDC_AUTHORITY;
const counterpartyUrl = process.env.VUE_APP_COUNTERPARTY_API_URL;

export const urlTemplateParts = {
	id: "{id}",
	subId: "{subId}",
	thirdId: "{thirdId}"
};

const urls = {
	applications: {
		masterAccountAccess: {
			createLegalEntityAccountApplication: `${baseUrl}/master-application/legal-entity`,
			createLegalEntityAccountFromEmployeeApplication: `${baseUrl}/master-application/legal-entity/employee`,
			createSignedLegalEntityAccountApplication: `${baseUrl}/master-application/legal-entity-eds`,
			createLegalPersonAccountApplication: `${baseUrl}/master-application/entrepreneur`,
			createLegalPersonAccountFromEmployeeApplication: `${baseUrl}/master-application/entrepreneur/employee`,
			getLegalEntityAccountApplicationId: `${baseUrl}/master-application/legal-entity/exists`,
			getLegalPersonAccountApplicationId: `${baseUrl}/master-application/entrepreneur/exists`,
			updateStatus: `${baseUrl}/master-application/${urlTemplateParts.id}/status`,
			approveApplication: `${baseUrl}/master-application/${urlTemplateParts.id}/approve`,
			rejectApplication: `${baseUrl}/master-application/${urlTemplateParts.id}/reject`,
			getMasterAccountAccessApplications: `${baseUrl}/master-application`,
			getApplicationDetails: `${baseUrl}/master-application/${urlTemplateParts.id}`
		},
		employeeJoin: {
			createLegalEntityEmployeeJoinApplication: `${baseUrl}/counterparty-application/legal-entity`,
			createLegalPersonEmployeeJoinApplication: `${baseUrl}/counterparty-application/entrepreneur`,
			getEmployeeJoinApplications: `${baseUrl}/counterparty-application`,
			getOwnEmployeeJoinApplications: `${baseUrl}/own/counterparty-application`,
			getApplicationDetails: `${baseUrl}/counterparty-application/${urlTemplateParts.id}`,
			getOwnApplicationDetails: `${baseUrl}/own/counterparty-application/${urlTemplateParts.id}`,
			updateStatus: `${baseUrl}/counterparty-application/${urlTemplateParts.id}/status`,
			updateOwnStatus: `${baseUrl}/own/counterparty-application/${urlTemplateParts.id}/status`
		}
	},
	masterAccount: {
		getMasterAccountId: `${baseUrl}/master-account/id`
	},
	fns: {
		getEgrulLegalEntity: `${baseUrl}/fns/egrul/legal-entity`,
		getEgripLegalPersonByInn: `${baseUrl}/fns/egrip/entrepreneur/inn`,
		upToDate: {
			getLegalPerson: `${baseUrl}/fns/up-to-date/entrepreneur`,
			enableLegalPerson: `${baseUrl}/fns/up-to-date/entrepreneur`,
			disableLegalPerson: `${baseUrl}/fns/up-to-date/entrepreneur`,
			enableLegalEntity: `${baseUrl}/fns/up-to-date/legal-entity`,
			disableLegalEntity: `${baseUrl}/fns/up-to-date/legal-entity`,
			getLegalEntity: `${baseUrl}/fns/up-to-date/legal-entity`,
			updateLegalEntity: `${baseUrl}/fns/up-to-date/legal-entity/force`,
			updateLegalPerson: `${baseUrl}/fns/up-to-date/entrepreneur/force`
		}
	},
	storage: {
		file: {
			createTemper: `${storageUrl}/file/temp`,
			createTemperSigned: `${storageUrl}/file/temp/signed`,
			download: `${storageUrl}/file/${urlTemplateParts.id}/data`,
			clone: `${storageUrl}/file/${urlTemplateParts.id}/clone`,
			downloadTempFile: `${storageUrl}/file/temp/${urlTemplateParts.id}/data`,
			cloneTemporary: `${storageUrl}/file/temp/${urlTemplateParts.id}/clone`,
			getMeta: `${storageUrl}/file/${urlTemplateParts.id}/meta`,
			getTempMeta: `${storageUrl}/file/temp/${urlTemplateParts.id}/meta`,
			signTemper: `${storageUrl}/file/temp/${urlTemplateParts.id}/sign`,
			signFile: `${storageUrl}/file/${urlTemplateParts.id}/sign`,
			downloadSignature: `${storageUrl}/file/${urlTemplateParts.id}/signature`,
			downloadTempSignature: `${storageUrl}/file/temp/${urlTemplateParts.id}/signature`
		}
	},
	apps: {
		getApps: `${baseUrl}/apps/own`
	},
	roles: {
		officeEmployee: {
			getAllRoles: `${baseUrl}/role/office-employee`,
			getOwnActiveRoles: `${baseUrl}/role/office-employee/own-roles`,
			getActiveRolesById: `${baseUrl}/role/office-employee/${urlTemplateParts.id}`,
			saveActiveRoles: `${baseUrl}/role/office-employee/${urlTemplateParts.id}`
		}
	},
	office: {
		getRoles: `${baseUrl}/staff/role`,
		employee: {
			getEmployeeProfile: `${baseUrl}/staff/office/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/profile`,
			get: `${baseUrl}/staff/office/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}`,
			getById: `${baseUrl}/staff/employee/${urlTemplateParts.id}`,
			update: `${baseUrl}/staff/office/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}`,
			create: `${baseUrl}/staff/office/${urlTemplateParts.id}/employee`,
			activate: `${baseUrl}/staff/office/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/activate`,
			deactivate: `${baseUrl}/staff/office/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/deactivate`
		}
	},
	counterparty: {
		getCounterparties: `${baseUrl}/counterparty`,
		getCounterparty: `${baseUrl}/counterparty/${urlTemplateParts.id}`,
		getOwnCounterparty: `${baseUrl}/own/counterparty`,
		getCounterpartyProfile: `${baseUrl}/counterparty/${urlTemplateParts.id}/profile`,
		bankAccount: {
			updateAccount: `${baseUrl}/counterparty/${urlTemplateParts.id}/bank-accounts/${urlTemplateParts.subId}`,
			updateOwnAccount: `${baseUrl}/own/counterparty/bank-accounts/${urlTemplateParts.subId}`,
			createAccount: `${baseUrl}/counterparty/${urlTemplateParts.id}/bank-accounts`,
			createOwnAccount: `${baseUrl}/own/counterparty/bank-accounts`,
			getAccount: `${baseUrl}/counterparty/${urlTemplateParts.id}/bank-accounts/${urlTemplateParts.subId}`,
			getAccounts: `${baseUrl}/counterparty/${urlTemplateParts.id}/bank-accounts`,
			getOwnAccount: `${baseUrl}/own/counterparty/bank-accounts/${urlTemplateParts.subId}`,
			getOwnAccounts: `${baseUrl}/own/counterparty/bank-accounts`
		},
		employee: {
			getEmployeeProfile: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/profile`,
			getOwnEmployeeProfile: `${baseUrl}/own/counterparty/employee/${urlTemplateParts.subId}/profile`,
			get: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}`,
			getOwn: `${baseUrl}/own/counterparty/employee/${urlTemplateParts.subId}`,
			getItems: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee`,
			getOwnItems: `${baseUrl}/own/counterparty/employee`,
			activate: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/activate`,
			activateOwn: `${baseUrl}/own/counterparty/employee/own/${urlTemplateParts.subId}/activate`,
			deactivate: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/deactivate`,
			deactivateOwn: `${baseUrl}/own/counterparty/employee/own/${urlTemplateParts.subId}/deactivate`,
			trust: {
				getItems: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/trust`,
				getActiveItems: `${baseUrl}/trust/active`,
				getOwnItems: `${baseUrl}/own/counterparty/employee/${urlTemplateParts.subId}/trust`,
				get: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/trust/${urlTemplateParts.thirdId}`,
				getOwn: `${baseUrl}/own/counterparty/employee/${urlTemplateParts.subId}/trust/${urlTemplateParts.thirdId}`,
				create: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/trust`,
				accept: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/trust/${urlTemplateParts.thirdId}/accept`,
				cancel: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/trust/${urlTemplateParts.thirdId}/cancel`,
				decline: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/trust/${urlTemplateParts.thirdId}/decline`,
				getActioner: `${baseUrl}/trust/${urlTemplateParts.id}/actioner`,
				getOwnActioner: `${baseUrl}/own/counterparty/employee/${urlTemplateParts.id}/trust/${urlTemplateParts.subId}/actioner`
			},
			snils: {
				create: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/snils`,
				createOwn: `${baseUrl}/own/counterparty/employee/${urlTemplateParts.subId}/snils`,
				update: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/snils`,
				updateOwn: `${baseUrl}/own/counterparty/employee/${urlTemplateParts.subId}/snils`,
				get: `${baseUrl}/counterparty/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}/snils`,
				getOwn: `${baseUrl}/own/counterparty/employee/${urlTemplateParts.subId}/snils`
			},
			verifySigningAuthority: `${baseUrl}/own/counterparty/employee/verify-signing-authority`
		},
		legalPerson: {
			get: `${baseUrl}/counterparty/entrepreneur/${urlTemplateParts.id}`,
			create: `${baseUrl}/counterparty/entrepreneur`,
			getSuggestion: `${baseUrl}/counterparty/entrepreneur/suggestion/complete`,
			checkEmployeeExists: `${baseUrl}/counterparty/entrepreneur/check-employee-exists`,
			update: `${baseUrl}/counterparty/entrepreneur/${urlTemplateParts.id}`,
			checkIsExists: `${baseUrl}/counterparty/entrepreneur/exists`,
			search: `${baseUrl}/counterparty/entrepreneur/search`,
			profile: {
				check: `${baseUrl}/counterparty/entrepreneur/profile-check`,
				get: `${baseUrl}/counterparty/entrepreneur/${urlTemplateParts.id}/profile`,
				create: `${baseUrl}/counterparty/entrepreneur/${urlTemplateParts.id}/profile`,
				update: `${baseUrl}/counterparty/entrepreneur/${urlTemplateParts.id}/profile`
			},
			employee: {
				get: `${baseUrl}/counterparty/entrepreneur/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}`,
				getItems: `${baseUrl}/counterparty/entrepreneur/${urlTemplateParts.id}/employee`,
				update: `${baseUrl}/counterparty/entrepreneur/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}`,
				updateOwn: `${baseUrl}/own/counterparty/entrepreneur/employee/${urlTemplateParts.id}`,
				create: `${baseUrl}/counterparty/entrepreneur/${urlTemplateParts.id}/employee`,
				createOwn: `${baseUrl}/own/counterparty/entrepreneur/employee`
			}
		},
		physicalPerson: {
			get: `${baseUrl}/counterparty/individual/${urlTemplateParts.id}`,
			create: `${baseUrl}/counterparty/individual`,
			update: `${baseUrl}/counterparty/individual/${urlTemplateParts.id}`,
			checkIsExists: `${baseUrl}/counterparty/individual/exists`,
			search: `${baseUrl}/counterparty/individual/search`
		},
		foreignOrganization: {
			get: `${baseUrl}/counterparty/foreign-organization/${urlTemplateParts.id}`,
			create: `${baseUrl}/counterparty/foreign-organization`,
			checkIsExists: `${baseUrl}/counterparty/foreign-organization/exists`,
			search: `${baseUrl}/counterparty/foreign-organization/search`,
			update: `${baseUrl}/counterparty/foreign-organization/${urlTemplateParts.id}`,
			employee: {
				get: `${baseUrl}/counterparty/foreign-organization/${urlTemplateParts.id}`,
				getItems: `${baseUrl}/counterparty/foreign-organization/${urlTemplateParts.id}/employee`,
				update: `${baseUrl}/counterparty/foreign-organization/employee/${urlTemplateParts.id}`,
				create: `${baseUrl}/counterparty/foreign-organization/${urlTemplateParts.id}/employee`
			}
		},
		legalEntity: {
			get: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}`,
			checkIsExists: `${baseUrl}/counterparty/legal-entity/exists`,
			checkEmployeeExists: `${baseUrl}/counterparty/legal-entity/check-employee-exists`,
			getByIdentifiers: `${baseUrl}/counterparty/legal-entity/search`,
			create: `${baseUrl}/counterparty/legal-entity`,
			update: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}`,
			getSuggestion: `${baseUrl}/counterparty/legal-entity/suggestion/complete`,
			profile: {
				check: `${baseUrl}/counterparty/legal-entity/profile-check`,
				get: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/profile`,
				create: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/profile`,
				update: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/profile`
			},
			head: {
				get: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/head`,
				getOwn: `${baseUrl}/own/counterparty/legal-entity/head`,
				snils: {
					update: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/head/${urlTemplateParts.subId}/snils`,
					updateOwn: `${baseUrl}/own/counterparty/legal-entity/head/${urlTemplateParts.subId}/snils`,
					get: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/head/${urlTemplateParts.subId}/snils`,
					getOwn: `${baseUrl}/own/counterparty/legal-entity/head/${urlTemplateParts.subId}/snils`,
					create: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/head/${urlTemplateParts.subId}/snils`,
					createOwn: `${baseUrl}/own/counterparty/legal-entity/head/${urlTemplateParts.subId}/snils`
				}
			},
			employee: {
				get: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}`,
				getItems: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/employee`,
				update: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/employee/${urlTemplateParts.subId}`,
				updateOwn: `${baseUrl}/own/counterparty/legal-entity/employee/${urlTemplateParts.subId}`,
				create: `${baseUrl}/counterparty/legal-entity/${urlTemplateParts.id}/employee`,
				createOwn: `${baseUrl}/own/counterparty/legal-entity/employee`
			}
		},
		boatApplication: {
			getBoatApplications: `TODO`,
			getBoatApplication: `TODO`,
			updateBoatApplication: `TODO`,
			getBoatHistory: `${counterpartyUrl}/boat/${urlTemplateParts.id}/history`,
			createBoatApplication: `${counterpartyUrl}/boat-application`
		},
		chat: {
			getChatBySource: `${counterpartyUrl}/chat/by-source`,
			getChatMessages: `${counterpartyUrl}/chat/{chatId}/message`,
			createChatMessage: `${counterpartyUrl}/chat/{chatId}/message`
		}
	},
	employeeInvitation: {
		get: `${baseUrl}/employee/join`
	},
	cryptopro: {
		getSignerCertificates: `${baseUrl}/jcp/signature/signer`
	},
	snils: {
		get: `${baseUrl}/snils/${urlTemplateParts.id}`,
		recognition: {
			recognize: `${baseUrl}/snils/recognition/${urlTemplateParts.id}`
		}
	},
	account: {
		getAccounts: `${baseUrl}/account`,
		getAccount: `${baseUrl}/account/${urlTemplateParts.id}`,
		getAccountByEmail: `${baseUrl}/account/getbyemail`,
		checkEmail: `${baseUrl}/account/checkemail`,
		checkEmailById: `${baseUrl}/account/${urlTemplateParts.id}/checkemail`,
		getProfiles: `${baseUrl}/account/profile`,
		getProfilesById: `${baseUrl}/account/${urlTemplateParts.id}/profile`,
		updateStatus: `${baseUrl}/account/${urlTemplateParts.id}/active`,
		updateEmail: `${baseUrl}/account/${urlTemplateParts.id}/email`,
		updatePassword: `${baseUrl}/account/${urlTemplateParts.id}/password`,
		resetPassword: `${baseUrl}/account/${urlTemplateParts.id}/reset-password`
	},
	profile: {
		getUnassigned: `${baseUrl}/profile/${urlTemplateParts.id}`,
		assign: `${baseUrl}/profile/${urlTemplateParts.id}/assign`,
		activate: `${baseUrl}/account/${urlTemplateParts.id}/profile/${urlTemplateParts.subId}/activate`,
		deactivate: `${baseUrl}/account/${urlTemplateParts.id}/profile/${urlTemplateParts.subId}/deactivate`,
		toggleSignatureRequired: `${baseUrl}/profile/${urlTemplateParts.id}/toggle-signature-required`
	},
	auth: {
		confirmEmail: `${authUrl}/auth/confirm-email`,
		confirmPhone: `${authUrl}/auth/confirm-profile-phone`,
		getCode: `${authUrl}/auth/request-code`,
		getPhoneCode: `${authUrl}/auth/request-profile-phone-confirmation-code`,
		requestPhoneConfirmation: `${authUrl}/auth/profile-phone-confirmation-required`,
		signInWithProfile: `${authUrl}/connect/sign-in-with-profile`,
		setPassword: `${authUrl}/auth/password`
	},
	permissions: {
		get: `${baseUrl}/permissions/own`
	},
	documents: {
		getDocuments: `${baseUrl}/own/counterparty/document`,
		createDocument: `${baseUrl}/own/counterparty/document`,
		updateDocumentStatus: `${baseUrl}/own/counterparty/document/${urlTemplateParts.id}/toggle-archived`
	},
	counterpartyDocuments: {
		getDocuments: `${baseUrl}/counterparty/${urlTemplateParts.id}/document`
	}
};

export default urls;
