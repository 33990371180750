import { CounterpartyBoatDocumentPackage } from "@rmp/core/types/boatApplication/counterpartyBoatDocumentPackage";

export default class ApiCounterpartyBoatDocumentPackage {
	ownershipCertificateTempFileId: string;
	internationalMeasuringCertificateTempFileId: string;
	classificationCertificateTempFileId: string;
	internationalOilPollutionPreventionCertificateTempFileId: string;
	customDocuments: { tempFileId: string }[];
	
	constructor(
		ownershipCertificateTempFileId: string = "",
		internationalMeasuringCertificateTempFileId: string = "",
		classificationCertificateTempFileId: string = "",
		internationalOilPollutionPreventionCertificateTempFileId: string = "",
		customDocuments: { tempFileId: string }[] = []
	)
	{
		this.ownershipCertificateTempFileId = ownershipCertificateTempFileId;
		this.internationalMeasuringCertificateTempFileId = internationalMeasuringCertificateTempFileId;
		this.classificationCertificateTempFileId = classificationCertificateTempFileId;
		this.internationalOilPollutionPreventionCertificateTempFileId = internationalOilPollutionPreventionCertificateTempFileId;
		this.customDocuments = customDocuments;
	}
}

export class ApiCounterpartyBoatDocumentPackageMapper {
	static map(source: CounterpartyBoatDocumentPackage): ApiCounterpartyBoatDocumentPackage {
		return {
			ownershipCertificateTempFileId: source.ownershipCertificate.id,
			internationalMeasuringCertificateTempFileId: source.internationalMeasuringCertificate.id,
			classificationCertificateTempFileId: source.classificationCertificate.id,
			internationalOilPollutionPreventionCertificateTempFileId: source.internationalOilPollutionPreventionCertificate.id,
			customDocuments: source.customDocuments.map(x => ({ tempFileId: x.id }))
		};
	}
}
