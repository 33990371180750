export enum DocumentType {
	ownershipCertificate = "ownershipCertificate",
	internationalMeasuringCertificate = "internationalMeasuringCertificate",
	classificationCertificate = "classificationCertificate",
	internationalOilPollutionPreventionCertificate = "internationalOilPollutionPreventionCertificate",
	customDocuments = "customDocuments",
	agreement = "agreement",
	account = "account",
	outfitTasks = "outfitTasks",
	servicesActs = "servicesActs",
	invoices = "invoices",
	associationArticle = "associationArticle",
	associationMemorandum = "associationMemorandum",
	invoice = "invoice",
	serviceAcceptanceCertificate = "serviceAcceptanceCertificate"
}
