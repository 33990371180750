import { ServiceApplicationStepState } from "@rmp/counterparty/stores/serviceApplication/steps/shared/serviceApplicationState";
import { defineStore } from "pinia";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import AbortService from "@rmp/core/services/abortService";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useTariffStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/tariff";
import { useDepartmentStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/department";
import { usePortStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/port";
import { useBoatStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/boat";
import { ApiServiceTariffsEnum } from "@rmp/core/api/types/service/tariffs/ApiServiceTariffsEnum";
import { useServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication";
import ServiceApplicationApiFacade from "@rmp/counterparty/api/service/facade";
import { useServiceStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/service";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import { TugboatServiceApplication } from "@rmp/core/types/services/tugboat/tugboatServiceApplication";
import { CrewboatServiceApplication } from "@rmp/core/types/services/crewboat/crewboatServiceApplication";
import { PierServiceApplication } from "@rmp/core/types/services/pier/pierServiceApplication";
import { parseWorkingTime } from "@rmp/core/utils/dates";
import { useAgreementStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/agreement";
import { isNil } from "lodash";
import { subDays } from "date-fns";
import {
	usePortDestinationsForEndOptionsStore,
	usePortDestinationsForStartOptionsStore
} from "@rmp/counterparty/stores/options/portDestinations";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceApplicationApiFacade = new ServiceApplicationApiFacade(abortService);

export interface RouteStepState extends ServiceApplicationStepState, PageState {
	formValid: boolean;
	startDestinationShortName: string;
	endDestinationShortName: string;
	startDestinationId: string;
	endDestinationId: string;
	startDate: Date | null;
	startTime: Date | null;
}

const getDefaultState = (): RouteStepState => {
	return {
		...page.getDefaultPageState(),
		formValid: true,
		startDestinationShortName: "",
		endDestinationShortName: "",
		startDestinationId: "",
		endDestinationId: "",
		startDate: null,
		startTime: null,
		stepType: ServiceApplicationStepTypeEnum.ROUTE
	};
};

export const useRouteStepStore = defineStore({
	id: "route-step",
	state: (): RouteStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state): boolean {
			return state.formValid && !this.agreementEndDateEarlierServiceStartDate;
		},
		agreementEndDateEarlierServiceStartDate(state): boolean {
			// если срок окончания договора (agreementDuration.end - 1 день) предшествует дате начала предоставления услуг (state.startDate), тогда выводим true, иначе false;
			if(isNil(state.startDate)) return false;
			
			const { selectedAgreement } = useAgreementStepStore();
			
			const agreementEndDate = selectedAgreement.agreementDuration?.end;
			if(!agreementEndDate) return false;
			
			const agreementEndDateWithOneDayBefore = subDays(new Date(agreementEndDate), 1)
			
			return agreementEndDateWithOneDayBefore <= state.startDate;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			const { selectedServiceType } = useServiceStepStore();
			serviceApplicationApiFacade.configure(selectedServiceType);
			
			await this.fetch();
			await usePortDestinationsForStartOptionsStore().initialize();
			await usePortDestinationsForEndOptionsStore().initialize(); 
			await this.selectDefaultRoute();
		},
		async fetch() {
			try {
				const { propertyTariffs } = useTariffStepStore();
				
				const volumeTariffs = propertyTariffs.map(x => x.tariffs.filter(y => x.selectedTariffIds.includes(y.id))).flat()
													 .filter(x => x.type === ApiServiceTariffsEnum.PER_CUBIC_METER);
				const tasks: Promise<any>[] = [];
				
				volumeTariffs.forEach(x => tasks.push(this.setTariffValue(x.id, x.value)));
				
				await Promise.all(tasks);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		async setTariffValue(id: string, value: string) {
			const { propertyTariffs } = useTariffStepStore();
			const { selectedDepartmentId } = useDepartmentStepStore();
			const { selectedPortId } = usePortStepStore();
			const { selectedBoatId } = useBoatStepStore();
			
			let tariff = propertyTariffs.map(x => x.tariffs).flat().find(x => x.id === id);
			if(!tariff) return;
			
			const { propertyId } = propertyTariffs.find(x => x.tariffs.some(y => y.id === id))!;
			
			tariff.value = value;
			try {
				tariff.loading = true;
				
				const { amount, vatIncluded } = await serviceApplicationApiFacade.calculateTariffCost(selectedDepartmentId,
					selectedPortId,
					propertyId,
					id,
					{
						counterpartyBoatId: selectedBoatId,
						quantity: +value
					});
				
				tariff.amount = amount;
				tariff.vatIncluded = vatIncluded;
				
			} catch (e) {
				console.error(e);
			} finally {
				tariff.loading = false;
			}
		},
		async selectDefaultRoute() {
			const { copiedService } = useServiceApplicationStore();
			
			const { startDestinationId, endDestinationId } = copiedService as {
				startDestinationId: string,
				endDestinationId: string
			};
			
			this.startDestinationId = startDestinationId;
			this.endDestinationId = endDestinationId;
			
			switch (copiedService.type) {
				case ServiceTypeEnum.TUGBOAT_SERVICE:
					for (const tariff of (copiedService as TugboatServiceApplication).requestedTugBoats)
					{
						if(!tariff.tariffId) continue;
						await this.setTariffValue(tariff.tariffId, tariff.workingTime ? parseWorkingTime(tariff.workingTime) + "" : "");
					}
					break;
				case ServiceTypeEnum.CREWBOAT_SERVICE:
					for (const tariff of (copiedService as CrewboatServiceApplication).requestedCrewBoats)
					{
						if(!tariff.tariffId) continue;
						await this.setTariffValue(tariff.tariffId, tariff.workingTime ? parseWorkingTime(tariff.workingTime) + "" : "");
					}
					break;
				case ServiceTypeEnum.PIER_SERVICE:
					for (const tariff of (copiedService as PierServiceApplication).requestedPiers)
					{
						if(!tariff.tariffId) continue;
						await this.setTariffValue(tariff.tariffId, tariff.workingTime ? parseWorkingTime(tariff.workingTime) + "" : "");
					}
					break;
			}
		}
	}
});
