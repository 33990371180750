import {
	AgreementApplicationStepState
} from "@rmp/counterparty/stores/agreementApplication/steps/shared/agreementApplicationStepState";
import { defineStore } from "pinia";
import {
	AgreementApplicationStepTypeEnum
} from "@rmp/counterparty/stores/agreementApplication/steps/shared/AgreementApplicationStepTypeEnum";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import { AgreementController } from "@rmp/counterparty/api/agreement";

const abortService = new AbortService();
const agreementController = new AgreementController(abortService);

const page = usePageStore(abortService);

export interface ServiceStepState extends AgreementApplicationStepState, PageState {
	selectedServiceType: ServiceTypeEnum;
	availableServiceTypes: ServiceTypeEnum[];
}

const getDefaultState = (): ServiceStepState => {
	return {
		...page.getDefaultPageState(),
		stepType: AgreementApplicationStepTypeEnum.SERVICE,
		selectedServiceType: ServiceTypeEnum.TUGBOAT_SERVICE,
		availableServiceTypes: []
	};
};

export const useServiceStepStore = defineStore({
	id: "agreement-application-service-step",
	state: (): ServiceStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return !!state.selectedServiceType;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			await this.fetch();
		},
		async fetch() {
			try {
				let serviceDefinitions = await agreementController.getAgreementDefinitions();
				
				this.availableServiceTypes = serviceDefinitions.map(x => ServiceMapperProfile.mapServiceType(x.type));
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
			}
		}
	}
});
