import { IsEnum } from "class-validator";
import { ProfileType } from "@rmp/core/types/ProfileType";

export class ApiAccountProfileBase {
	@IsEnum(ProfileType)
	type: ProfileType;
	accountId: string;
	externalId: string;
	title: string;
	email: string;
	phone: string;
	isActive: boolean;
	isPhoneConfirmed: boolean;
	isSignatureRequired: boolean;
	
	constructor(
		type = ProfileType.UNKNOWN,
		accountId: string = "",
		externalId: string = "",
		title: string = "",
		email: string = "",
		phone: string = "",
		isActive: boolean = false,
		isPhoneConfirmed: boolean = false,
		isSignatureRequired: boolean = false
	)
	{
		this.type = type;
		this.accountId = accountId;
		this.externalId = externalId;
		this.title = title;
		this.email = email;
		this.phone = phone;
		this.isActive = isActive;
		this.type = type;
		this.isPhoneConfirmed = isPhoneConfirmed;
		this.isSignatureRequired = isSignatureRequired;
	}
}
