import BaseServiceApplicationWorkflowService from "@rmp/core/services/serviceApplication/baseServiceApplicationWorkflowService";
import { SnoInstallationServiceWorkflowStateType } from "@rmp/core/types/services/snoInstallation/SnoInstallationServiceWorkflowStateType";
import { ApiSnoInstallationServiceTransitionCodeEnum } from "@rmp/core/api/types/service/snoIntallation/ApiSnoInstallationServiceTransitionCodeEnum";

export default class SnoInstallationServiceApplicationWorkflowService extends BaseServiceApplicationWorkflowService {
	transitions = new Map<SnoInstallationServiceWorkflowStateType, SnoInstallationServiceWorkflowStateType[]>([
		[SnoInstallationServiceWorkflowStateType.NEW, [SnoInstallationServiceWorkflowStateType.DRAFT]],
		[
			SnoInstallationServiceWorkflowStateType.DRAFT,
			[SnoInstallationServiceWorkflowStateType.SENT, SnoInstallationServiceWorkflowStateType.CANCELED]
		],
		[
			SnoInstallationServiceWorkflowStateType.SENT,
			[
				SnoInstallationServiceWorkflowStateType.APPROVED_BY_NGO_SERVICE_EMPLOYEE,
				SnoInstallationServiceWorkflowStateType.REJECTED,
				SnoInstallationServiceWorkflowStateType.CANCELED
			]
		],
		[
			SnoInstallationServiceWorkflowStateType.APPROVED_BY_NGO_SERVICE_EMPLOYEE,
			[
				SnoInstallationServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE,
				SnoInstallationServiceWorkflowStateType.REJECTED
			]
		],
		[
			SnoInstallationServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE,
			[SnoInstallationServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE, SnoInstallationServiceWorkflowStateType.REJECTED]
		],
		[
			SnoInstallationServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE,
			[SnoInstallationServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY, SnoInstallationServiceWorkflowStateType.REJECTED]
		],
		[SnoInstallationServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY, [SnoInstallationServiceWorkflowStateType.PROVIDING]],
		[SnoInstallationServiceWorkflowStateType.PROVIDING, [SnoInstallationServiceWorkflowStateType.PROVIDED]],
		[SnoInstallationServiceWorkflowStateType.PROVIDED, [SnoInstallationServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED]],
		[
			SnoInstallationServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED,
			[SnoInstallationServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE, SnoInstallationServiceWorkflowStateType.PROVIDED]
		],
		[
			SnoInstallationServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE,
			[
				SnoInstallationServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY,
				SnoInstallationServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED
			]
		],
		[SnoInstallationServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY, [SnoInstallationServiceWorkflowStateType.COMPLETED]]
	]);
	
	transitionNames = new Map<string, ApiSnoInstallationServiceTransitionCodeEnum>([
		[
			[SnoInstallationServiceWorkflowStateType.NEW, SnoInstallationServiceWorkflowStateType.DRAFT].join(),
			ApiSnoInstallationServiceTransitionCodeEnum.CREATE
		],
		[
			[SnoInstallationServiceWorkflowStateType.DRAFT, SnoInstallationServiceWorkflowStateType.SENT].join(),
			ApiSnoInstallationServiceTransitionCodeEnum.SEND
		],
		[
			[
				SnoInstallationServiceWorkflowStateType.SENT,
				SnoInstallationServiceWorkflowStateType.APPROVED_BY_NGO_SERVICE_EMPLOYEE
			].join(),
			ApiSnoInstallationServiceTransitionCodeEnum.APPROVE
		],
		[
			[
				SnoInstallationServiceWorkflowStateType.APPROVED_BY_NGO_SERVICE_EMPLOYEE,
				SnoInstallationServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE
			].join(),
			ApiSnoInstallationServiceTransitionCodeEnum.APPROVE
		],
		[
			[
				SnoInstallationServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE,
				SnoInstallationServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE
			].join(),
			ApiSnoInstallationServiceTransitionCodeEnum.INITIALLY_SIGN
		],
		[
			[
				SnoInstallationServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE,
				SnoInstallationServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY
			].join(),
			ApiSnoInstallationServiceTransitionCodeEnum.INITIALLY_SIGN
		],
		[
			[
				SnoInstallationServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY, SnoInstallationServiceWorkflowStateType.PROVIDING
			].join(),
			ApiSnoInstallationServiceTransitionCodeEnum.START
		],
		[
			[SnoInstallationServiceWorkflowStateType.PROVIDING, SnoInstallationServiceWorkflowStateType.PROVIDED].join(),
			ApiSnoInstallationServiceTransitionCodeEnum.FINISH
		],
		[
			[
				SnoInstallationServiceWorkflowStateType.PROVIDED, SnoInstallationServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED
			].join(),
			ApiSnoInstallationServiceTransitionCodeEnum.ATTACH
		],
		[
			[
				SnoInstallationServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED,
				SnoInstallationServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE
			].join(),
			ApiSnoInstallationServiceTransitionCodeEnum.FINALLY_SIGN
		],
		[
			[
				SnoInstallationServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE,
				SnoInstallationServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY
			].join(),
			ApiSnoInstallationServiceTransitionCodeEnum.FINALLY_SIGN
		],
		[
			[
				SnoInstallationServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY, SnoInstallationServiceWorkflowStateType.COMPLETED
			].join(),
			ApiSnoInstallationServiceTransitionCodeEnum.COMPLETE
		],
		[
			[SnoInstallationServiceWorkflowStateType.DRAFT, SnoInstallationServiceWorkflowStateType.CANCELED].join(),
			ApiSnoInstallationServiceTransitionCodeEnum.CANCEL
		],
		[
			[SnoInstallationServiceWorkflowStateType.SENT, SnoInstallationServiceWorkflowStateType.REJECTED].join(),
			ApiSnoInstallationServiceTransitionCodeEnum.REJECT
		],
		[
			[
				SnoInstallationServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE,
				SnoInstallationServiceWorkflowStateType.REJECTED
			].join(),
			ApiSnoInstallationServiceTransitionCodeEnum.REJECT
		],
		[
			[
				SnoInstallationServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE, SnoInstallationServiceWorkflowStateType.REJECTED
			].join(),
			ApiSnoInstallationServiceTransitionCodeEnum.REJECT
		],
		[
			[
				SnoInstallationServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY, SnoInstallationServiceWorkflowStateType.REJECTED
			].join(),
			ApiSnoInstallationServiceTransitionCodeEnum.REJECT
		],
		[
			[
				SnoInstallationServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE,
				SnoInstallationServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED
			].join(),
			ApiSnoInstallationServiceTransitionCodeEnum.RESET
		],
		[
			[
				SnoInstallationServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED, SnoInstallationServiceWorkflowStateType.PROVIDED
			].join(),
			ApiSnoInstallationServiceTransitionCodeEnum.RESET
		]
	]);
}
