// @ts-ignore
import masker from "vue-the-mask/src/masker";
// @ts-ignore
import tokens from "vue-the-mask/src/tokens";
import { isNumber, isNil } from "lodash";
import { customTokens } from "@rmp/core/plugins/vueTheMask";

const maskTokens = { ...tokens, ...customTokens };
import ApiLegalEntityCounterparty from "@rmp/core/api/types/counterparty/apiLegalEntityCounterparty";
import ApiLegalPersonCounterparty from "@rmp/core/api/types/counterparty/apiLegalPersonCounterparty";
import { i18n } from "@rmp/core/plugins";
import ApiForeignOrganizationCounterparty from "@rmp/core/api/types/counterparty/apiForeignOrganizationCounterparty";
import ApiPhysicalPersonCounterparty from "@rmp/core/api/types/counterparty/apiPhysicalPersonCounterparty";
import { ApiCounterpartyType } from "@rmp/core/api/types/counterparty/apiCounterpartyType";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";

export const removeSpaces = (row: string) => row ? row.replace(/\s+/g, "") : "";

export const capitalizeFirstLetter = (row: string) => row && row[0].toUpperCase() + row.slice(1);

export const formatFullName = ({ firstName, lastName, middleName }: { firstName: string, lastName: string, middleName: string }) => {
	return [lastName, firstName, middleName].filter(x => !!x).join(" ");
};

export const formatFullNameWithPosition = ({ firstName, lastName, middleName, position }: { firstName: string, lastName: string, middleName: string, position: string }) => {
	return `${formatFullName({ firstName, lastName, middleName })}${position ? ", " + position : ""}`;
};

export const formatFullNameWithInitials = ({
	firstName,
	lastName,
	middleName
}: { firstName: string, lastName: string, middleName: string }) => {
	return `${lastName} ${firstName ? firstName.slice(0, 1) + "." : ""} ${middleName ? middleName.slice(0, 1) + "." : ""}`;
};

export const mask = (value: string, mask: string) => {
	return value && masker(value, mask, true, maskTokens);
};

export const unmask = (value: string, mask: string) => {
	return value && masker(value, mask, false, maskTokens);
};

export const formatNumber = (value: number, fractionDigits = 2) => {
	if(!value)
		return value;
	
	const string = fractionDigits ? value.toFixed(fractionDigits) : value.toString();
	
	return string.replace(".", ",").toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const formatNumberVolume = (value: number, fractionDigits = 2) => {
	if(!value)
		return value;
	
	const string = fractionDigits ? value.toFixed(fractionDigits) : Math.round(value).toString();
	
	return string.replace(".", ",").toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const formatCurrency = (value: number, fractionDigits = 2) => {
	if(!value)
		return value;
	
	const string = fractionDigits ? value.toFixed(fractionDigits) : value.toString();
	const [integer, decimal] = string.split(".");
	const formattedInteger = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ` ${i18n.tc("content.rubles")}.`;
	const formattedDecimal = (decimal || "0").padEnd(2, "0") + ` ${i18n.tc("content.kopecks")}.`;
	
	return `${formattedInteger} ${formattedDecimal}`;
};

export const convertNumberToIsoString = (value: number) => {
	return isNumber(value) ? new Date(value).toISOString() : value;
};

export const formatCounterpartyTitle = (counterparty: ApiCounterpartyType) => {
	if(counterparty instanceof ApiLegalEntityCounterparty) {
		return counterparty.shortName;
	} else if(counterparty instanceof ApiLegalPersonCounterparty) {
		return `${i18n.t("common.legalPerson")} "${counterparty.fullName}"`;
	} else if(counterparty instanceof ApiForeignOrganizationCounterparty) {
		return counterparty.longName;
	} else if(counterparty instanceof ApiPhysicalPersonCounterparty) {
		return formatFullName({ ...counterparty });
	} else
		return "";
};

export const splitFullName = (value: string) => {
	let lastName, middleName, firstName;
	const splitted = value.split(" ");
	
	if(splitted.length === 2)
		[lastName, firstName] = splitted;
	if(splitted.length === 3)
		[lastName, firstName, middleName] = splitted;
	else
		throw ("Incorrect fullName value");
	
	return { lastName, firstName, middleName };
};

export const formatAgreementTitle = (type: ServiceTypeEnum, number: number) => {
	return i18n.tc(`service.agreement.title.${type}`) + ` №${number}`;
};

export const formatServiceTitle = (type: ServiceTypeEnum, number: number) => {
	return i18n.tc(`aliases.service.${type}`) + ` №${number}`;
};
