import AbortService from "@rmp/core/services/abortService";
import { urls } from "@rmp/counterparty/api/config/urls";
import BaseCounterpartyController from "@rmp/counterparty/api/base/baseCounterpartyController";
import { ApiDepartmentPersisted } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { ApiPortPersisted } from "@rmp/core/api/types/divisions/port/apiPort";
import { ServiceControllerBase } from "@rmp/counterparty/api/service/serviceControllerBase";
import NotImplementedException from "@rmp/core/exceptions/notImplementedException";
import { ApiSnoInstallationServiceApplication } from "@rmp/core/api/types/service/snoIntallation/apiSnoInstallationServiceApplication";
import { ApiCreateSnoInstallationApplication } from "@rmp/core/api/types/service/snoIntallation/apiCreateSnoInstallationApplication";
import { SnoInstallationServiceWorkflowStateType } from "@rmp/core/types/services/snoInstallation/SnoInstallationServiceWorkflowStateType";
import {
	ApiSnoInstallationServiceTransitionCodeEnum
} from "@rmp/core/api/types/service/snoIntallation/ApiSnoInstallationServiceTransitionCodeEnum";
import { ApiCreateServiceDocument } from "@rmp/core/api/types/service/document/apiCreateServiceDocument";

export class SnoInstallationServiceController extends BaseCounterpartyController implements ServiceControllerBase {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getService = async (serviceId: string) => {
		const { serviceApplication } = await this.get<{ serviceApplication: ApiSnoInstallationServiceApplication }>(urls.service.snoInstallation.$serviceId,
			{ serviceId });
		return serviceApplication;
	};
	
	getDepartments = async () => {
		return await this.get<ApiDepartmentPersisted[]>(urls.service.snoInstallation.department);
	};
	
	getDepartmentPorts = async (departmentId: string) => {
		return await this.get<ApiPortPersisted[]>(urls.service.snoInstallation.department.$departmentId.port, { departmentId });
	};
	
	createApplication = async (request: ApiCreateSnoInstallationApplication) => {
		return await this.post<{ id: string }>(urls.service.snoInstallation, {}, request);
	};
	
	saveDocuments = async (serviceId: string, documents: ApiCreateServiceDocument[]) => {
		return await this.put<void>(urls.service.snoInstallation.$serviceId.document, { serviceId }, documents);
	};
	
	updateStatusByTransition = async (serviceId: string, code: ApiSnoInstallationServiceTransitionCodeEnum, reason?: string) => {
		return await this.post<void>(urls.service.snoInstallation.$serviceId.$code,
			{ serviceId, code },
			{ rejectionTransition: { reason } });
	};
	
	getAgreements = async () => {
		throw new NotImplementedException("getAgreements");
	};
	
	getTariffs = async () => {
		throw new NotImplementedException("getTariffs");
	};
	
	calculateTariffCost = async () => {
		throw new NotImplementedException("calculateTariffCost");
	};
	
	createDraftApplication = async () => {
		throw new NotImplementedException("createDraftApplication");
	};
	
	calculateExpectedCost = async () => {
		throw new NotImplementedException("calculateExpectedCost");
	};
}
