import { ServiceApplicationStepState } from "@rmp/counterparty/stores/serviceApplication/steps/shared/serviceApplicationState";
import { defineStore } from "pinia";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import AbortService from "@rmp/core/services/abortService";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import { useDepartmentStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/department";
import { useServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication";
import { InformationServiceController } from "@rmp/counterparty/api/service/information";
import { useInformationTariffStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/informationTariff";
import { InformationServiceApplication } from "@rmp/core/types/services/information/informationServiceApplication";

const abortService = new AbortService();
const page = usePageStore(abortService);

const informationServiceController = new InformationServiceController(abortService);

export interface TimeStepState extends ServiceApplicationStepState, PageState {
	formValid: boolean;
}

const getDefaultState = (): TimeStepState => {
	return {
		...page.getDefaultPageState(),
		formValid: true,
		stepType: ServiceApplicationStepTypeEnum.TIME
	};
};

export const useTimeStepStore = defineStore({
	id: "time-step",
	state: (): TimeStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return state.formValid;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			await this.selectDefaultTime();
		},
		async setTariffValue(id: string, value: string) {
			const { tariffs } = useInformationTariffStepStore();
			const { selectedDepartmentId } = useDepartmentStepStore();
			
			let tariff = tariffs.map(x => x.tariffs).flat().find(x => x.id === id);
			if(!tariff) return;
			
			tariff.value = value;
			try {
				tariff.loading = true;
				
				const { amount, vatIncluded } = await informationServiceController.calculateInformationTariffCost(selectedDepartmentId,
					id,
					{ quantity: +value });
				
				tariff.amount = amount;
				tariff.vatIncluded = vatIncluded;
			} catch (e) {
				console.error(e);
			} finally {
				tariff.loading = false;
			}
		},
		async selectDefaultTime() {
			const { copiedService } = useServiceApplicationStore();
			const copiedTariff = (copiedService as InformationServiceApplication).requestedTariff;
			
			if(!copiedTariff) return;
			
			await this.setTariffValue(copiedTariff.tariffId, copiedTariff.units + "");
		}
	}
});
