import {
	ServiceApplicationStepState
} from "@rmp/counterparty/stores/serviceApplication/steps/shared/serviceApplicationState";
import { defineStore } from "pinia";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import { TugboatServiceController } from "@rmp/counterparty/api/service/tugboat";
import AbortService from "@rmp/core/services/abortService";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useDepartmentStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/department";
import ServiceApplicationAgreementMapper from "@rmp/counterparty/stores/serviceApplication/steps/agreement/mapper";
import { ServiceApplicationAgreement } from "@rmp/counterparty/stores/serviceApplication/steps/agreement/types/serviceApplicationAgreement";
import { useServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication";
import ServiceApplicationApiFacade from "@rmp/counterparty/api/service/facade";
import { useServiceStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/service";
import { AgreementWorkflowStateType } from "@rmp/core/types/agreement/AgreementWorkflowStateType";
import { includes, isNil, orderBy, sortBy } from "lodash";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceApplicationApiFacade = new ServiceApplicationApiFacade(abortService);

const mapper = new ServiceApplicationAgreementMapper();

export interface AgreementStepState extends ServiceApplicationStepState, PageState {
	agreements: ServiceApplicationAgreement[];
	selectedAgreementId: string;
}

const getDefaultState = (): AgreementStepState => {
	return {
		...page.getDefaultPageState(),
		agreements: [],
		selectedAgreementId: "",
		stepType: ServiceApplicationStepTypeEnum.AGREEMENT
	};
};

export const useAgreementStepStore = defineStore({
	id: "agreementApplication-step",
	state: (): AgreementStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state): boolean {
			return !!state.selectedAgreementId;
		},
		selectedAgreement(state): ServiceApplicationAgreement {
			return state.agreements.find(x => x.id === state.selectedAgreementId)!;
		},
		selectedAgreementServiceAdvanceReached(state): boolean {
			// если поле minAdvanceBalance не null и баланс не null и minAdvanceBalance больше чем баланс договора, тогда возвращаем true
			const selectedAgreement = state.agreements.find(x => x.id === state.selectedAgreementId)!;
			if (!selectedAgreement) return false;
			
			const { minAdvanceBalance, balance } = selectedAgreement;
			
			return !isNil(minAdvanceBalance) && !isNil(balance) && minAdvanceBalance > balance;
		},
		filteredOrderedAgreements(state): ServiceApplicationAgreement[] {
			let orderedAgreements = orderBy(state.agreements, x => x.createdAt, ["desc"]);
			
			// если дата окончания существует и она меньше текущей даты, то не выводим данный договор
			const currentDate = new Date();
			const filteredAgreementsByDate = orderedAgreements.filter( x => {
				const agreementEndDate = x.agreementDuration?.end;
				return !agreementEndDate || agreementEndDate > currentDate;
			})
			
			return filteredAgreementsByDate.filter(x => [
				AgreementWorkflowStateType.SENT,
				AgreementWorkflowStateType.NEGOTIATED,
				AgreementWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE,
				AgreementWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY,
				AgreementWorkflowStateType.ACTIVE
			].includes(x.state.code));
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			const { selectedServiceType } = useServiceStepStore();
			serviceApplicationApiFacade.configure(selectedServiceType);
			
			await this.fetch();
			this.setDefaultAgreement();
		},
		async fetch() {
			try {
				const { selectedDepartmentId } = useDepartmentStepStore();
				
				let apiAgreements = await serviceApplicationApiFacade.getAgreements(selectedDepartmentId);
				
				this.agreements = apiAgreements.map(x => mapper.mapAgreement(x));
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		setDefaultAgreement() {
			if(!this.filteredOrderedAgreements.length) return;
			
			const { copiedService } = useServiceApplicationStore() as { copiedService: { agreementId: string } };
			
			if(copiedService.agreementId) {
				this.selectedAgreementId = copiedService.agreementId;
			} else {
				let [agreement] = this.filteredOrderedAgreements;
				
				this.selectedAgreementId = agreement.id;
			}
		}
	}
});
