import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { ServiceController } from "@rmp/counterparty/api/service";
import { DocumentsState, useDocumentsStore } from "@rmp/counterparty/stores/composables/baseDocuments";
import { ServiceDocument, ServiceDocumentMapper } from "@rmp/core/types/services/serviceDocument";
import {
	useSnoInstallationServiceDocumentsBreadcrumb
} from "@rmp/counterparty/stores/serviceDetails/snoInstallation/documents/composables/useSnoInstallationServiceDocumentsBreadcrumb";
import { useSnoInstallationServiceStore } from "@rmp/counterparty/stores/serviceDetails/snoInstallation";

const abortService = new AbortService();
const page = usePageStore(abortService);
const serviceController = new ServiceController(abortService);

const documentsStore = useDocumentsStore({ controller: serviceController, mapper: ServiceDocumentMapper });

export interface SnoInstallationServiceDocumentsState extends PageState, DocumentsState<ServiceDocument> {
}

const getDefaultState = (): SnoInstallationServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...documentsStore.getDefaultState()
	};
};

export const useSnoInstallationServiceDocumentsStore = defineStore({
	id: "sno-installation-service-documents",
	state: (): SnoInstallationServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...documentsStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useSnoInstallationServiceStore();
			return [...breadcrumbs, useSnoInstallationServiceDocumentsBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...documentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			const { title } = useSnoInstallationServiceStore();
			
			this.id = id;
			
			await this.initializeDocumentsStore(title);
		}
	}
});
