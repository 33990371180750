import ApiCounterpartyBase from "@rmp/core/api/types/counterparty/apiCounterpartyBase";
import { CounterpartyTypeEnum } from "@rmp/core/types/CounterpartyTypeEnum";

export default class ApiLegalPersonCounterparty extends ApiCounterpartyBase {
	inn: string;
	ogrnIp: string;
	fullName: string;
	description: string;
	registrationDate: string;

	constructor(
		type: CounterpartyTypeEnum = CounterpartyTypeEnum.LEGAL_PERSON,
		inn: string = "",
		ogrnIp: string = "",
		fullName: string = "",
		description: string = "",
		registrationDate: string = ""
	)
	{
		super(type);

		this.inn = inn;
		this.ogrnIp = ogrnIp;
		this.fullName = fullName;
		this.description = description;
		this.registrationDate = registrationDate;
	}
}
