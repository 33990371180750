import AbortService from "@rmp/core/services/abortService";
import { urls } from "@rmp/counterparty/api/config/urls";
import BaseCounterpartyController from "@rmp/counterparty/api/base/baseCounterpartyController";
import { ApiDepartmentPersisted } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { ApiAgreementPersisted } from "@rmp/core/api/types/agreement/apiAgreement";
import { ApiPortPersisted } from "@rmp/core/api/types/divisions/port/apiPort";
import { ApiTugboatPersisted } from "@rmp/core/api/types/enterpriseProperty/apiTugboat";
import { ApiTariff } from "@rmp/core/api/types/service/tariffs/apiTariff";
import { ApiTariffTotalAmount } from "@rmp/core/api/types/service/apiTariffTotalAmount";
import { ApiGetTariffTotalAmountRequest } from "@rmp/core/api/types/service/apiGetTariffTotalAmountRequest";
import { ApiTugboatService } from "@rmp/core/api/types/service/apiTugboatService";
import { ServiceControllerBase } from "@rmp/counterparty/api/service/serviceControllerBase";
import { ApiCreatePropertyServiceApplicationBase } from "@rmp/core/api/types/service/base/apiCreatePropertyServiceApplicationBase";
import { ApiTugboatServiceApplication } from "@rmp/core/api/types/service/tugboat/apiTugboatServiceApplication";
import { ApiTugboatExpectedCostRequest } from "@rmp/core/api/types/service/tugboat/apiTugboatExpectedCostRequest";
import { ApiExpectedCost } from "@rmp/core/api/types/service/apiExpectedCost";
import { ApiPropertyServiceTransitionCodeEnum } from "@rmp/core/api/types/service/ApiPropertyServiceTransitionCodeEnum";
import NotImplementedException from "@rmp/core/exceptions/notImplementedException";

export class TugboatServiceController extends BaseCounterpartyController implements ServiceControllerBase {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getService = async (tugboatServiceId: string) => {
		const { serviceApplication } = await this.get<{ serviceApplication: ApiTugboatServiceApplication }>(urls.service.tugboat.$tugboatServiceId,
			{ tugboatServiceId });
		return serviceApplication;
	};
	
	getAgreements = async (departmentId: string) => {
		return await this.get<ApiAgreementPersisted[]>(urls.service.tugboat.department.$departmentId.agreement, { departmentId });
	};
	
	getDepartments = async () => {
		return await this.get<ApiDepartmentPersisted[]>(urls.service.tugboat.department);
	};
	
	getDepartmentPorts = async (departmentId: string) => {
		return await this.get<ApiPortPersisted[]>(urls.service.tugboat.department.$departmentId.port, { departmentId });
	};
	
	getTugboats = async (departmentId: string) => {
		return await this.get<ApiTugboatPersisted[]>(urls.service.tugboat.department.$departmentId.tugboat, { departmentId });
	};
	
	getTugboatService = async (tugboatId: string) => {
		const { serviceApplication } = await this.get<{ serviceApplication: ApiTugboatService }>(urls.service.tugboat.$tugboatId, { tugboatId });
		return serviceApplication;
	};
	
	getTariffs = async (departmentId: string, portId: string, tugboatId: string) => {
		return await this.get<ApiTariff[]>(urls.service.tugboat.department.$departmentId.port.$portId.tugboat.$tugboat.tariff,
			{ departmentId, portId, tugboatId });
	};
	
	calculateTariffCost = async (departmentId: string,
		portId: string,
		tugboatId: string,
		tariffId: string,
		payload: ApiGetTariffTotalAmountRequest) => {
		// TODO: массив временный
		const [data] = await this.post<ApiTariffTotalAmount[]>(urls.service.tugboat.department.$departmentId.port.$portId.tugboat.$tugboat.tariff.$tariffId.calculate,
			{ departmentId, portId, tugboatId, tariffId }, payload);
		return data;
	};
	
	createApplication = async (request: ApiCreatePropertyServiceApplicationBase) => {
		return await this.post<{ id: string }>(urls.service.tugboat, {}, request);
	};
	
	createDraftApplication = async (request: ApiCreatePropertyServiceApplicationBase) => {
		return await this.post<{ id: string }>(urls.service.tugboat.draft, {}, request);
	};
	
	calculateExpectedCost = async (payload: ApiTugboatExpectedCostRequest) => {
		return await this.post<ApiExpectedCost>(urls.service.tugboat.expectedCost.calculate, {}, payload);
	};
	
	updateStatusByTransition = async (tugboatServiceId: string, code: ApiPropertyServiceTransitionCodeEnum, reason?: string) => {
		return await this.post<void>(urls.service.tugboat.$tugboatServiceId.$code,
			{ tugboatServiceId, code },
			{ rejectionTransition: { reason } });
	};
}
