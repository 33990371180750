import { defineStore } from "pinia";
import { ServiceApplicationStateBase } from "@rmp/counterparty/stores/serviceApplication/services/base/useServiceApplicationStoreBase";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";


export interface CrewboatServiceApplicationState extends ServiceApplicationStateBase {
	// currentStep (title, index, alias)
}

const getDefaultState = (): CrewboatServiceApplicationState => {
	return {};
};

export const useCrewboatServiceApplicationStore = defineStore({
	id: "crewboat-service-application",
	state: (): CrewboatServiceApplicationState => getDefaultState(),
	getters: {
		steps: state => {
			return [
				ServiceApplicationStepTypeEnum.SERVICE,
				ServiceApplicationStepTypeEnum.DEPARTMENT,
				ServiceApplicationStepTypeEnum.PORT,
				ServiceApplicationStepTypeEnum.AGREEMENT,
				ServiceApplicationStepTypeEnum.BOAT,
				ServiceApplicationStepTypeEnum.CREWBOAT,
				ServiceApplicationStepTypeEnum.TARIFF,
				ServiceApplicationStepTypeEnum.ROUTE,
				ServiceApplicationStepTypeEnum.PROPERTY_COST,
				ServiceApplicationStepTypeEnum.PROPERTY_CONFIRMATION
			];
		}
	},
	actions: {}
});
