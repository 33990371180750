import { Client } from "@rmp/core/api/client/client";
import AbortService from "@rmp/core/services/abortService";

export default class BaseController {
	client: Client;

	constructor(abortService: AbortService) {
		this.client = new Client(abortService);
	}
}
