export enum ApiDredgingServiceTransitionCodeEnum {
	CREATE = "Create",
	UPDATE = "Update",
	SEND = "Send",
	APPROVE = "Approve",
	INITIALLY_SIGN = "InitiallySign",
	START = "Start",
	FINISH = "Finish",
	ATTACH = "Attach",
	FINALLY_SIGN = "FinallySign",
	COMPLETE = "Complete",
	CANCEL = "Cancel",
	REJECT = "Reject",
	RESET = "Reset"
}
