import BaseServiceApplicationWorkflowService from "@rmp/core/services/serviceApplication/baseServiceApplicationWorkflowService";
import { FloatingCraneServiceWorkflowStateType } from "@rmp/core/types/services/floatingCrane/FloatingCraneServiceWorkflowStateType";
import { ApiFloatingCraneServiceTransitionCodeEnum } from "@rmp/core/api/types/service/floatingCrane/ApiFloatingCraneServiceTransitionCodeEnum";

export default class FloatingCraneServiceApplicationWorkflowService extends BaseServiceApplicationWorkflowService {
	transitions = new Map<FloatingCraneServiceWorkflowStateType, FloatingCraneServiceWorkflowStateType[]>([
		[FloatingCraneServiceWorkflowStateType.NEW, [FloatingCraneServiceWorkflowStateType.DRAFT]],
		[
			FloatingCraneServiceWorkflowStateType.DRAFT,
			[FloatingCraneServiceWorkflowStateType.SENT, FloatingCraneServiceWorkflowStateType.CANCELED]
		],
		[
			FloatingCraneServiceWorkflowStateType.SENT,
			[
				FloatingCraneServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE, FloatingCraneServiceWorkflowStateType.REJECTED,
				FloatingCraneServiceWorkflowStateType.CANCELED
			]
		],
		[
			FloatingCraneServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE,
			[FloatingCraneServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY, FloatingCraneServiceWorkflowStateType.REJECTED]
		],
		[FloatingCraneServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY, [FloatingCraneServiceWorkflowStateType.PROVIDING]],
		[FloatingCraneServiceWorkflowStateType.PROVIDING, [FloatingCraneServiceWorkflowStateType.PROVIDED]],
		[FloatingCraneServiceWorkflowStateType.PROVIDED, [FloatingCraneServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED]],
		[
			FloatingCraneServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED,
			[FloatingCraneServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE, FloatingCraneServiceWorkflowStateType.PROVIDED]
		],
		[
			FloatingCraneServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE,
			[
				FloatingCraneServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY,
				FloatingCraneServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED
			]
		],
		[FloatingCraneServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY, [FloatingCraneServiceWorkflowStateType.COMPLETED]]
	]);
	
	transitionNames = new Map<string, ApiFloatingCraneServiceTransitionCodeEnum>([
		[
			[FloatingCraneServiceWorkflowStateType.NEW, FloatingCraneServiceWorkflowStateType.DRAFT].join(),
			ApiFloatingCraneServiceTransitionCodeEnum.CREATE
		],
		[
			[FloatingCraneServiceWorkflowStateType.DRAFT, FloatingCraneServiceWorkflowStateType.SENT].join(),
			ApiFloatingCraneServiceTransitionCodeEnum.SEND
		],
		[
			[
				FloatingCraneServiceWorkflowStateType.SENT,
				FloatingCraneServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE
			].join(),
			ApiFloatingCraneServiceTransitionCodeEnum.INITIALLY_SIGN
		],
		[
			[
				FloatingCraneServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE,
				FloatingCraneServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY
			].join(),
			ApiFloatingCraneServiceTransitionCodeEnum.INITIALLY_SIGN
		],
		[
			[
				FloatingCraneServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY, FloatingCraneServiceWorkflowStateType.PROVIDING
			].join(),
			ApiFloatingCraneServiceTransitionCodeEnum.START
		],
		[
			[FloatingCraneServiceWorkflowStateType.PROVIDING, FloatingCraneServiceWorkflowStateType.PROVIDED].join(),
			ApiFloatingCraneServiceTransitionCodeEnum.FINISH
		],
		[
			[
				FloatingCraneServiceWorkflowStateType.PROVIDED, FloatingCraneServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED
			].join(),
			ApiFloatingCraneServiceTransitionCodeEnum.ATTACH
		],
		[
			[
				FloatingCraneServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED,
				FloatingCraneServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE
			].join(),
			ApiFloatingCraneServiceTransitionCodeEnum.FINALLY_SIGN
		],
		[
			[
				FloatingCraneServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE,
				FloatingCraneServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY
			].join(),
			ApiFloatingCraneServiceTransitionCodeEnum.FINALLY_SIGN
		],
		[
			[
				FloatingCraneServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY, FloatingCraneServiceWorkflowStateType.COMPLETED
			].join(),
			ApiFloatingCraneServiceTransitionCodeEnum.COMPLETE
		],
		[
			[FloatingCraneServiceWorkflowStateType.DRAFT, FloatingCraneServiceWorkflowStateType.CANCELED].join(),
			ApiFloatingCraneServiceTransitionCodeEnum.CANCEL
		],
		[
			[FloatingCraneServiceWorkflowStateType.SENT, FloatingCraneServiceWorkflowStateType.REJECTED].join(),
			ApiFloatingCraneServiceTransitionCodeEnum.REJECT
		],
		[
			[FloatingCraneServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE, FloatingCraneServiceWorkflowStateType.REJECTED].join(),
			ApiFloatingCraneServiceTransitionCodeEnum.REJECT
		],
		[
			[FloatingCraneServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY, FloatingCraneServiceWorkflowStateType.REJECTED].join(),
			ApiFloatingCraneServiceTransitionCodeEnum.REJECT
		],
		[
			[
				FloatingCraneServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE,
				FloatingCraneServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED
			].join(),
			ApiFloatingCraneServiceTransitionCodeEnum.RESET
		],
		[
			[FloatingCraneServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED, FloatingCraneServiceWorkflowStateType.PROVIDED].join(),
			ApiFloatingCraneServiceTransitionCodeEnum.RESET
		]
	]);
}
