import { User } from "oidc-client-ts";
import SecurityService from "@rmp/core/services/auth/securityService";
import { RateLimiter } from "limiter";
import TooManyRequestsException from "@rmp/core/exceptions/tooManyRequestsException";
import { checkUserLoaded } from "@rmp/core/services/auth/userManager";
import httpService from "@rmp/core/api/client/httpService";

const rateLimiter = new RateLimiter({ tokensPerInterval: 15, interval: "minute" });

const securityService = new SecurityService();

const addAuthInterceptor = () => {
	httpService.interceptors.request.use(async (config: any) => {
		let isUserLoaded = checkUserLoaded();

		if(!isUserLoaded)
			return config;

		try {
			const token = await securityService.getToken();
			config.headers.Authorization = `Bearer ${token}`;
			return config;
		} catch (e) {
			console.error(e);
			throw e;
		}
	}, (error) => {
		return Promise.reject(error);
	});


	httpService.interceptors.response.use((response) => {
		return response;
	}, async (error) => {
		// Отличные от 401 не обрабатываются
		if(!error.response || error.response.status !== 401)
			return Promise.reject(error);

		// Повторный запрос с обновленным токеном
		try {
			//@ts-ignore
			let { access_token }: User = await securityService.signinSilent();
			const config = error.config;
			config.headers["Authorization"] = `Bearer ${access_token}`;

			if(rateLimiter.tryRemoveTokens(1)) {
				return await httpService.request(config);
			} else {
				throw new TooManyRequestsException("Число повторных попыток обращения к серверу превысило допустимое значение.");
			}
		} catch (ex) {
			// Сессия сбрасывается, если возникла ошибка при обновлении токена
			await securityService.signOut();
		}
	});
};

export default addAuthInterceptor;
