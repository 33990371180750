import AbortService from "@rmp/core/services/abortService";
import { urls } from "@rmp/counterparty/api/config/urls";
import BaseCounterpartyController from "@rmp/counterparty/api/base/baseCounterpartyController";
import { ApiCounterpartyBoatPersisted } from "@rmp/core/api/types/counterpartyBoat/apiCounterpartyBoat";
import { ApiCounterpartyBoatApplicationPersisted } from "@rmp/core/api/types/counterpartyBoatApplication/apiCounterpartyBoatApplication";


export class CounterpartyBoatController extends BaseCounterpartyController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getBoats = async () => {
		return await this.get<{ counterpartyBoatsPersisted: ApiCounterpartyBoatPersisted[], totalCount: number }>(urls.boat);
	};
	
	getBoatApplication = async (id: string) => {
		return await this.get<ApiCounterpartyBoatApplicationPersisted>(urls.boatApplication.$boatApplicationId, { boatApplicationId: id });
	};
	
	getBoatApplications = async () => {
		return await this.get<{
			counterpartyBoatApplications: ApiCounterpartyBoatApplicationPersisted[],
			totalCount: number
		}>(urls.boatApplication);
	};
	
	getBoat = async (id: string): Promise<{ counterpartyBoatPersisted: ApiCounterpartyBoatPersisted }> => {
		return await this.get<{ counterpartyBoatPersisted: ApiCounterpartyBoatPersisted }>(urls.boat.$id, { id });
	};
}
