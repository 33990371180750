import BaseServiceApplicationWorkflowService from "@rmp/core/services/serviceApplication/baseServiceApplicationWorkflowService";
import { DredgingServiceWorkflowStateType } from "@rmp/core/types/services/dredging/DredgingServiceWorkflowStateType";
import { ApiDredgingServiceTransitionCodeEnum } from "@rmp/core/api/types/service/dredging/ApiDredgingServiceTransitionCodeEnum";

export default class DredgingServiceApplicationWorkflowService extends BaseServiceApplicationWorkflowService {
	transitions = new Map<DredgingServiceWorkflowStateType, DredgingServiceWorkflowStateType[]>([
		[DredgingServiceWorkflowStateType.NEW, [DredgingServiceWorkflowStateType.DRAFT]],
		[DredgingServiceWorkflowStateType.DRAFT, [DredgingServiceWorkflowStateType.SENT, DredgingServiceWorkflowStateType.CANCELED]],
		[
			DredgingServiceWorkflowStateType.SENT,
			[
				DredgingServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE, DredgingServiceWorkflowStateType.REJECTED,
				DredgingServiceWorkflowStateType.CANCELED
			]
		],
		[
			DredgingServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE,
			[DredgingServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_HEAD_OFFICE_EMPLOYEE, DredgingServiceWorkflowStateType.REJECTED]
		],
		[
			DredgingServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_HEAD_OFFICE_EMPLOYEE,
			[DredgingServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE, DredgingServiceWorkflowStateType.REJECTED]
		],
		[
			DredgingServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE,
			[DredgingServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY, DredgingServiceWorkflowStateType.REJECTED]
		],
		[DredgingServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY, [DredgingServiceWorkflowStateType.PROVIDING]],
		[DredgingServiceWorkflowStateType.PROVIDING, [DredgingServiceWorkflowStateType.PROVIDED]],
		[DredgingServiceWorkflowStateType.PROVIDED, [DredgingServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED]],
		[
			DredgingServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED,
			[DredgingServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE, DredgingServiceWorkflowStateType.PROVIDED]
		],
		[
			DredgingServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE, [
			DredgingServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY, DredgingServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED
		]
		],
		[DredgingServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY, [DredgingServiceWorkflowStateType.COMPLETED]]
	]);
	
	transitionNames = new Map<string, ApiDredgingServiceTransitionCodeEnum>([
		[
			[DredgingServiceWorkflowStateType.NEW, DredgingServiceWorkflowStateType.DRAFT].join(),
			ApiDredgingServiceTransitionCodeEnum.CREATE
		],
		[
			[DredgingServiceWorkflowStateType.DRAFT, DredgingServiceWorkflowStateType.SENT].join(),
			ApiDredgingServiceTransitionCodeEnum.SEND
		],
		[
			[DredgingServiceWorkflowStateType.SENT, DredgingServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE].join(),
			ApiDredgingServiceTransitionCodeEnum.APPROVE
		],
		[
			[
				DredgingServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE,
				DredgingServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_HEAD_OFFICE_EMPLOYEE
			].join(),
			ApiDredgingServiceTransitionCodeEnum.APPROVE
		],
		[
			[
				DredgingServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_HEAD_OFFICE_EMPLOYEE,
				DredgingServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE
			].join(),
			ApiDredgingServiceTransitionCodeEnum.INITIALLY_SIGN
		],
		[
			[
				DredgingServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE,
				DredgingServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY
			].join(),
			ApiDredgingServiceTransitionCodeEnum.INITIALLY_SIGN
		],
		[
			[DredgingServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY, DredgingServiceWorkflowStateType.PROVIDING].join(),
			ApiDredgingServiceTransitionCodeEnum.START
		],
		[
			[DredgingServiceWorkflowStateType.PROVIDING, DredgingServiceWorkflowStateType.PROVIDED].join(),
			ApiDredgingServiceTransitionCodeEnum.FINISH
		],
		[
			[
				DredgingServiceWorkflowStateType.PROVIDED, DredgingServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED
			].join(),
			ApiDredgingServiceTransitionCodeEnum.ATTACH
		],
		[
			[
				DredgingServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED,
				DredgingServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE
			].join(),
			ApiDredgingServiceTransitionCodeEnum.FINALLY_SIGN
		],
		[
			[
				DredgingServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE,
				DredgingServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY
			].join(),
			ApiDredgingServiceTransitionCodeEnum.FINALLY_SIGN
		],
		[
			[
				DredgingServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY, DredgingServiceWorkflowStateType.COMPLETED
			].join(),
			ApiDredgingServiceTransitionCodeEnum.COMPLETE
		],
		[
			[DredgingServiceWorkflowStateType.DRAFT, DredgingServiceWorkflowStateType.CANCELED].join(),
			ApiDredgingServiceTransitionCodeEnum.CANCEL
		],
		[
			[DredgingServiceWorkflowStateType.SENT, DredgingServiceWorkflowStateType.REJECTED].join(),
			ApiDredgingServiceTransitionCodeEnum.REJECT
		],
		[
			[
				DredgingServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE, DredgingServiceWorkflowStateType.REJECTED
			].join(),
			ApiDredgingServiceTransitionCodeEnum.REJECT
		],
		[
			[
				DredgingServiceWorkflowStateType.APPROVED_BY_FLEET_OPERATIONS_HEAD_OFFICE_EMPLOYEE,
				DredgingServiceWorkflowStateType.REJECTED
			].join(), ApiDredgingServiceTransitionCodeEnum.REJECT
		],
		[
			[DredgingServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE, DredgingServiceWorkflowStateType.REJECTED].join(),
			ApiDredgingServiceTransitionCodeEnum.REJECT
		],
		[
			[DredgingServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY, DredgingServiceWorkflowStateType.REJECTED].join(),
			ApiDredgingServiceTransitionCodeEnum.REJECT
		],
		[
			[
				DredgingServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE,
				DredgingServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED
			].join(),
			ApiDredgingServiceTransitionCodeEnum.RESET
		],
		[
			[DredgingServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED, DredgingServiceWorkflowStateType.PROVIDED].join(),
			ApiDredgingServiceTransitionCodeEnum.RESET
		]
	]);
}
