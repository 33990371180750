import urls from "@rmp/core/api/config/urls";
import { prepareUrl } from "@rmp/core/utils/prepareUrlQuery";
import BaseController from "@rmp/core/api/shared/baseController";
import AbortService from "@rmp/core/services/abortService";

export class AuthorizationController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	getPermissions = async (): Promise<string[]> => {
		let permissions = await this.client.get<[{ systemName: string, name: string }]>(urls.permissions.get);
		return permissions.map((x) => x.name);
	};

	confirmEmail = async (code: string): Promise<void> => {
		await this.client.get<object>(prepareUrl(urls.auth.confirmEmail, {
			code
		}), {
			withCredentials: true
		});
	};

	confirmPhone = async (code: string): Promise<void> => {
		await this.client.get<object>(prepareUrl(urls.auth.confirmPhone, {
			code
		}), {
			withCredentials: true
		});
	};

	getNewConfirmEmailCode = async (): Promise<void> => {
		await this.client.post<void>(prepareUrl(urls.auth.getCode), {});
	};

	getPhoneConfirmationCode = async (): Promise<void> => {
		await this.client.post<void>(prepareUrl(urls.auth.getPhoneCode), {});
	};

	requestPhoneConfirmation = async (profileId: string): Promise<void> => {
		await this.client.post<void>(urls.auth.requestPhoneConfirmation, {
			profileId
		});
	};
	
	setPassword = async (password: string, login: string): Promise<void> => {
		await this.client.post<void>(urls.auth.setPassword, { password, login });
	};
}
