import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useServicesBreadcrumb } from "@rmp/counterparty/stores/services/composables/useServicesBreadcrumb";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/counterparty/router";
import { RouteNames } from "@rmp/counterparty/router/routes";
import { InformationServiceController } from "@rmp/counterparty/api/service/information";
import {
	useInformationServiceBreadcrumb
} from "@rmp/counterparty/stores/serviceDetails/information/composables/useInformationServiceBreadcrumb";
import {
	InformationServiceApplication,
	InformationServiceApplicationMapper
} from "@rmp/core/types/services/information/informationServiceApplication";
import {
	useSignInformationServiceDocumentsBreadcrumb
} from "@rmp/counterparty/stores/uploadServiceDocuments/information/composables/useSignInformationServiceDocumentsBreadcrumb";
import { InformationServiceWorkflowStateType } from "@rmp/core/types/services/information/InformationServiceWorkflowStateType";
import InformationServiceApplicationWorkflowService
	from "@rmp/core/services/serviceApplication/informationServiceApplicationWorkflowService";
import { ApiInformationServiceTransitionCodeEnum } from "@rmp/core/api/types/service/information/ApiInformationServiceTransitionCodeEnum";
import {
	BaseSignServiceDocumentsState,
	useBaseSignServiceDocumentsStore
} from "@rmp/counterparty/stores/composables/baseSignServiceDocuments";
import { formatServiceTitle } from "@rmp/core/utils/formatting";
import { ServiceController } from "@rmp/counterparty/api/service";
import { ServiceDocument, ServiceDocumentMapper } from "@rmp/core/types/services/serviceDocument";

const abortService = new AbortService();
const page = usePageStore(abortService);

const informationServiceController = new InformationServiceController(abortService);
const serviceController = new ServiceController(abortService);

const baseSignServiceDocumentsStore = useBaseSignServiceDocumentsStore<ServiceDocument>({
	abortService,
	controller: serviceController,
	mapper: ServiceDocumentMapper
});

const informationWorkflowService = new InformationServiceApplicationWorkflowService();

export interface SignInformationServiceDocumentsState extends PageState, BaseSignServiceDocumentsState<ServiceDocument> {
	service: InformationServiceApplication;
}

const getDefaultState = (): SignInformationServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...baseSignServiceDocumentsStore.getDefaultState(),
		service: {} as InformationServiceApplication
	};
};

export const useSignInformationServiceDocumentsStore = defineStore({
	id: "sign-information-service-documents",
	state: (): SignInformationServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...baseSignServiceDocumentsStore.getters,
		breadcrumbs(state: SignInformationServiceDocumentsState) {
			return [
				useServicesBreadcrumb(),
				useInformationServiceBreadcrumb(state.title, { id: state.service.id }),
				useSignInformationServiceDocumentsBreadcrumb()
			];
		}
	},
	actions: {
		...page.actions,
		...baseSignServiceDocumentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			
			await Promise.all([
				this.fetchServiceApplication(id),
				this.fetchDocuments(id),
				this.verifySigningAuthority()
			]);
		},
		async fetchServiceApplication(serviceId: string) {
			try {
				let serviceApplication = await informationServiceController.getService(serviceId);
				
				this.service = InformationServiceApplicationMapper.map(serviceApplication);
				this.title = formatServiceTitle(this.service.type, this.service.applicationNumber);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async approve() {
			this.approving = true;
			
			try {
				await informationServiceController.updateStatusByTransition(this.id!,
					informationWorkflowService.getTransition(this.service.workflowState.alias,
						InformationServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY) as ApiInformationServiceTransitionCodeEnum);
				
				this.service.workflowState.alias = InformationServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY;
				
				alertService.addSuccess(AlertKeys.DOCUMENTS_SENT);
				
				await router.push({ name: RouteNames.INFORMATION_SERVICE, params: { id: this.id } });
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.approving = false;
			}
		}
	}
});
