import { ApiGetCounterpartyDocumentsParameters } from "@rmp/core/api/types/documents/apiGetCounterpartyDocumentsParameters";
import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";
import { CounterpartyDocumentsState } from "@rmp/counterparty/stores/counterpartyDocuments/index";
import { parseISO } from "date-fns";
import { CounterpartyDocument } from "@rmp/core/types/counterparty/documents/counterpartyDocument";
import ApiFileMeta from "@rmp/core/api/types/storage/apiFileMeta";
import ApiCreateCounterpartyDocumentRequest from "@rmp/core/api/types/documents/apiCreateCounterpartyDocumentRequest";
import { CreateCounterpartyDocumentRequest } from "@rmp/counterparty/stores/counterpartyDocuments/types/createCounterpartyDocumentRequest";
import { convertToZonedIso } from "@rmp/core/utils/dates";
import { ApiCounterpartyDocumentPersisted } from "@rmp/core/api/types/documents/apiCounterpartyDocument";
import { isNil } from "lodash";

export default class CounterpartyDocumentsMapperProfile {
	constructor() {
	}
	
	static mapToCounterpartyDocument(source: ApiCounterpartyDocumentPersisted): CounterpartyDocument {
		return {
			...source,
			...source.document,
			createdAt: parseISO(source.createdAt),
			expirationDate: isNil(source.document.expirationDate) ? null :  parseISO(source.document.expirationDate),
			creationDate: parseISO(source.document.creationDate),
			fileMeta: {} as ApiFileMeta
		};
	}
	
	static mapToApiCreateCounterpartyDocumentRequest(source: CreateCounterpartyDocumentRequest): ApiCreateCounterpartyDocumentRequest {
		return {
			...source,
			name: source.name || "",
			expirationDate: convertToZonedIso(source.expirationDate)
		};
	}
	
	static mapToApiGetCounterpartyDocumentsParameters(source: CounterpartyDocumentsState): ApiGetCounterpartyDocumentsParameters {
		return {
			take: source.paging.pageSize,
			skip: source.paging.pageSize * (source.paging.page - 1),
			query: source.search.query,
			sortField: source.sorting.type,
			sortingDirection: source.sorting.order,
			isArchived: ArchiveStatusType.ALL === source.filter.status ? null : source.filter.status === ArchiveStatusType.ARCHIVED
		};
	}
}
