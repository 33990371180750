import AbortService from "@rmp/core/services/abortService";
import BaseController from "@rmp/core/api/shared/baseController";
import { ApiSegment, createUrlsTree, Node, resolveUrl } from "@rmp/core/utils/api";
import TreeModel from "tree-model";
import { urls } from "@rmp/counterparty/api/config/urls";
const counterpartyUrl = process.env.VUE_APP_COUNTERPARTY_API_URL!;

export default class BaseCounterpartyController extends BaseController {
	urlsTree: TreeModel.Node<Node>;
	
	constructor(abortService: AbortService) {
		super(abortService);
		
		this.urlsTree = createUrlsTree(urls);
	}
	
	async get<T>(segment: ApiSegment, params: object = {}, options: object = {}) {
		const url = resolveUrl(counterpartyUrl, this.urlsTree, segment, params);
		
		return await this.client.get<T>(url, options);
	}
	
	async post<T>(segment: ApiSegment, params: object = {}, payload: object = {}) {
		const url = resolveUrl(counterpartyUrl, this.urlsTree, segment, params);
		
		return await this.client.post<T>(url, payload);
	}
	
	async put<T>(segment: ApiSegment, params: object = {}, payload: object = {}) {
		const url = resolveUrl(counterpartyUrl, this.urlsTree, segment, params);
		
		return await this.client.put<T>(url, payload);
	}
}
