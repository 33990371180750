import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useAppStore } from "@rmp/core/stores/appStore";
import { cloneDeep, uniqBy } from "lodash";
import { ApiAgreementPersisted } from "@rmp/core/api/types/agreement/apiAgreement";
import { ApiDepartment } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { ApiPort } from "@rmp/core/api/types/divisions/port/apiPort";
import { AgreementController } from "@rmp/counterparty/api/agreement";
import { DivisionController } from "@rmp/counterparty/api/division";
import { InformationServiceApplication } from "@rmp/core/types/services/information/informationServiceApplication";
import { useInformationServiceStore } from "@rmp/counterparty/stores/serviceDetails/information";
import { ServiceApplicationTariff, ServiceApplicationTariffMapper } from "@rmp/core/types/services/serviceApplicationTariff";
import { ServiceController } from "@rmp/counterparty/api/service";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";

const abortService = new AbortService();
const page = usePageStore(abortService);

const agreementController = new AgreementController(abortService);
const divisionController = new DivisionController(abortService);
const serviceController = new ServiceController(abortService);

export interface ServiceDetailsGeneralState extends PageState {
	id: string | null;
	service: InformationServiceApplication;
	agreement: ApiAgreementPersisted;
	port: ApiPort;
	department: ApiDepartment;
	tariff: ServiceApplicationTariff;
}

const getDefaultState = (): ServiceDetailsGeneralState => {
	return {
		...page.getDefaultPageState(),
		id: null,
		service: {} as InformationServiceApplication,
		agreement: {} as ApiAgreementPersisted,
		department: {} as ApiDepartment,
		port: {} as ApiPort,
		tariff: {} as ServiceApplicationTariff
	};
};

export const useInformationServiceGeneralStore = defineStore({
	id: "information-service-details-general",
	state: (): ServiceDetailsGeneralState => getDefaultState(),
	getters: {
		...page.getters,
		breadcrumbs() {
			const { breadcrumbs } = useInformationServiceStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.fetch();
		},
		async fetch() {
			try {
				let { service } = useInformationServiceStore();
				
				const [agreement, { division: department }, { division: port }, { tariffs }] = await Promise.all([
					agreementController.getAgreement(service.agreementId),
					divisionController.getDepartment(service.divisionId),
					divisionController.getPort(service.portId),
					serviceController.getTariffs(ServiceMapperProfile.mapApiServiceType(service.type))
				]);			
				
				this.agreement = agreement;
				this.department = department;
				this.port = port;
				this.service = cloneDeep(service);
				
				if(service.tariff?.tariffId)
					this.tariff = {
						...ServiceApplicationTariffMapper.map(tariffs.find(x => x.id === service.tariff!.tariffId)!),
						value: service.tariff.units,
						amount: service.tariff.amount.amount,
						vatIncluded: service.tariff.amount.vatIncluded
					} as ServiceApplicationTariff;
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		}
	}
});
