import { defineStore } from "pinia";
import { ServiceApplicationStateBase } from "@rmp/counterparty/stores/serviceApplication/services/base/useServiceApplicationStoreBase";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";


export interface InformationServiceApplicationState extends ServiceApplicationStateBase {
	// currentStep (title, index, alias)
}

const getDefaultState = (): InformationServiceApplicationState => {
	return {};
};

export const useInformationServiceApplicationStore = defineStore({
	id: "information-service-application",
	state: (): InformationServiceApplicationState => getDefaultState(),
	getters: {
		steps: state => {
			return [
				ServiceApplicationStepTypeEnum.SERVICE,
				ServiceApplicationStepTypeEnum.DEPARTMENT,
				ServiceApplicationStepTypeEnum.PORT,
				ServiceApplicationStepTypeEnum.AGREEMENT,
				ServiceApplicationStepTypeEnum.INFORMATION_TARIFF,
				ServiceApplicationStepTypeEnum.TIME,
				ServiceApplicationStepTypeEnum.INFORMATION_COST,
				ServiceApplicationStepTypeEnum.INFORMATION_CONFIRMATION
			];
		}
	},
	actions: {}
});
