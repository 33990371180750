import { ServiceApplicationStepState } from "@rmp/counterparty/stores/serviceApplication/steps/shared/serviceApplicationState";
import { defineStore } from "pinia";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import AbortService from "@rmp/core/services/abortService";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import { ServiceApplicationCounterpartyBoat } from "@rmp/counterparty/stores/serviceApplication/steps/boat/types/serviceApplicationCounterpartyBoat";
import { useServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication";
import { ApplicationFilterStatusType } from "@rmp/core/types/ApplicationFilterStatusType";
import { useServiceStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/service";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import { useDepartmentStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/department";

const abortService = new AbortService();
const page = usePageStore(abortService);

export interface BoatStepState extends ServiceApplicationStepState, PageState {
	boats: ServiceApplicationCounterpartyBoat[];
	selectedBoatId: string;
	filterStatus: ApplicationFilterStatusType;
	searchQuery: string;
}

const getDefaultState = (): BoatStepState => {
	return {
		stepType: ServiceApplicationStepTypeEnum.BOAT,
		boats: [],
		selectedBoatId: "",
		...page.getDefaultPageState(),
		filterStatus: ApplicationFilterStatusType.ALL,
		searchQuery: ""
	};
};

export const useBoatStepStore = defineStore({
	id: "boat-step",
	state: (): BoatStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			const { selectedServiceType } = useServiceStepStore();
			
			switch (selectedServiceType) {
				case ServiceTypeEnum.CREWBOAT_SERVICE:
					return true;
				default:
					return !!state.selectedBoatId;
			}
		},
		selectedBoat(state): ServiceApplicationCounterpartyBoat | undefined {
			return state.boats.find(x => x.id === state.selectedBoatId);
		},
		filteredBoats(state: BoatStepState): ServiceApplicationCounterpartyBoat[] {
			const filteredByStatus = state.boats.filter(x => x.status !== ApplicationFilterStatusType.DECLINED &&
				(state.filterStatus === ApplicationFilterStatusType.ALL || x.status === state.filterStatus));
			
			return filteredByStatus.filter(x => {
				return Object.values(x).some(y => String(y).toLocaleLowerCase().includes(state.searchQuery.toLocaleLowerCase()));
			});
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			const { boats } = useDepartmentStepStore();
			this.boats = boats;
			
			this.selectDefaultBoat();
		},
		selectDefaultBoat() {
			if(!this.boats.length) return;
			
			const { copiedService } = useServiceApplicationStore() as { copiedService: { counterpartyBoatId: string } };
			
			if(copiedService.counterpartyBoatId) {
				this.selectedBoatId = copiedService.counterpartyBoatId;
			} else {
				let [boats] = this.boats;
				
				this.selectedBoatId = boats.id;
			}
		}
	}
});
