import ApiCounterpartyBase from "@rmp/core/api/types/counterparty/apiCounterpartyBase";
import "reflect-metadata";
import { Transform, TransformationType, Type } from "class-transformer";
import { isNumber } from "lodash";
import { CounterpartyTypeEnum } from "@rmp/core/types/CounterpartyTypeEnum";
import { ApiLegalEntityCounterpartyHead } from "@rmp/core/api/types/counterparty/apiLegalEntityCounterpartyHead";

export default class ApiLegalEntityCounterparty extends ApiCounterpartyBase {
	inn: string;
	ogrn: string;
	kpp: string;
	opf: string;
	longName: string;
	shortName: string;
	legalAddress: string;
	@Transform(({ value }) => {
		return isNumber(value) ? new Date(value).toISOString() : value;
	})
	registrationDate: string;
	heads: ApiLegalEntityCounterpartyHead[];
	description: string;

	constructor(
		type: CounterpartyTypeEnum = CounterpartyTypeEnum.LEGAL_ENTITY,
		inn: string = "",
		ogrn: string = "",
		kpp: string = "",
		opf: string = "",
		longName: string = "",
		shortName: string = "",
		legalAddress: string = "",
		registrationDate: string = "",
		heads: ApiLegalEntityCounterpartyHead[] = [],
		description: string = ""
	)
	{
		super(type);

		this.inn = inn;
		this.ogrn = ogrn;
		this.kpp = kpp;
		this.opf = opf;
		this.longName = longName;
		this.shortName = shortName;
		this.legalAddress = legalAddress;
		this.registrationDate = registrationDate;
		this.heads = heads;
		this.description = description;
	}
}
