export enum FloatingCraneServiceWorkflowStateType {
	NEW = "New",
	CANCELED = "Canceled",
	REJECTED = "Rejected",
	DRAFT = "Draft",
	SENT = "Sent",
	INITIALLY_SIGNED_BY_ENTERPRISE = "InitiallySignedByEnterprise",
	INITIALLY_SIGNED_BY_COUNTERPARTY = "InitiallySignedByCounterparty",
	PROVIDING = "Providing",
	PROVIDED = "Provided",
	ACCOUNTING_DOCUMENTS_ATTACHED = "AccountingDocumentsAttached",
	FINALLY_SIGNED_BY_ENTERPRISE = "FinallySignedByEnterprise",
	FINALLY_SIGNED_BY_COUNTERPARTY = "FinallySignedByCounterparty",
	COMPLETED = "Completed"
}
