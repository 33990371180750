import InvalidArgumentException from "@rmp/core/exceptions/invalidArgumentException";
import { createDetachedSignature, createHash, getUserCertificates } from "@bsp-team/crypto-pro";
import { decodeFromBase64 } from "@rmp/core/utils/decode";
import { isObject } from "lodash";
import { encode, decode } from "js-base64";
import SignException from "@rmp/core/exceptions/signException";

export const prepareSignatureName = (name: string) => {
	if(!name)
		throw new InvalidArgumentException("name", name);
	
	const EXTENSION = ".sig";
	
	return `${name}${EXTENSION}`;
};

export const signFileWithDetachedSignature = async (file: File, thumbprint: string, hashedAlgorithm?: number): Promise<ArrayBuffer> => {
	const MAX_FILE_SIZE = 25000000;
	
	let arrayBuffer: ArrayBuffer = await new Promise(function (resolve) {
		const fileReader = new FileReader();
		
		fileReader.onload = function () {
			// @ts-ignore
			resolve(this.result);
		};
		
		if(file.size > MAX_FILE_SIZE) {
			throw new SignException("Файл для подписи не должен превышать " + MAX_FILE_SIZE / 1000000 + "МБ");
		}
		
		fileReader.readAsArrayBuffer(file);
	});
	
	let hash = await createHash(arrayBuffer, {
		hashedAlgorithm: hashedAlgorithm
	});
	
	let signature;
	try {
		signature = await createDetachedSignature(thumbprint, hash, {
			hashedAlgorithm: hashedAlgorithm
		});
	} catch (e) {
		throw new SignException(e.message);
	}
	
	// TODO возможно заменить на processSignature
	return decodeFromBase64(signature);
};

const processSignature = (signature: string) => {
	return btoa(atob(signature));
};

export const signWithDetachedSignature = async (value: string | object, thumbprint: string, hashedAlgorithm?: number): Promise<{ data: string, signature: string }> => {
	
	let json = isObject(value) ? JSON.stringify(value) : value;
	
	let hash = await createHash(json, {
		hashedAlgorithm: hashedAlgorithm
	});
	
	let signature;
	try {
		signature = await createDetachedSignature(thumbprint, hash, {
			hashedAlgorithm: hashedAlgorithm
		});
		
	} catch (e) {
		throw new SignException(e.message);
	}
	
	return {
		data: encode(json),
		signature: processSignature(signature)
	};
};
