import { ApiServiceDocumentKindEnum } from "@rmp/core/api/types/service/document/ApiServiceDocumentKindEnum";

export const ENTERPRISE_SIGNATURE_REQUIRED_SERVICE_DOCUMENT_TYPES = [
	ApiServiceDocumentKindEnum.INVOICE,
	ApiServiceDocumentKindEnum.AGREEMENT,
	ApiServiceDocumentKindEnum.SERVICE_ACCEPTANCE_CERTIFICATE
];

export const COUNTERPARTY_SIGNATURE_REQUIRED_SERVICE_DOCUMENT_TYPES = [
	ApiServiceDocumentKindEnum.AGREEMENT,
	ApiServiceDocumentKindEnum.SERVICE_ACCEPTANCE_CERTIFICATE
];

export const SERVICE_ACCOUNTING_DOCUMENT_TYPES = [
	ApiServiceDocumentKindEnum.INVOICE,
	ApiServiceDocumentKindEnum.SERVICE_ACCEPTANCE_CERTIFICATE,
	ApiServiceDocumentKindEnum.FACTURE_INVOICE
];
