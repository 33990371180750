import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import NotDefinedException from "@rmp/core/exceptions/notDefinedException";
import { BaseHistoryController } from "@rmp/core/api/shared/baseHistoryController";
import { orderBy } from "lodash";

export interface HistoryState<HistoryEntryType> {
	id: string | null;
	history: HistoryEntryType[];
}

export interface HistoryBaseMapper<HistoryEntryType> {
	mapToHistoryEntry: (source: any) => HistoryEntryType;
}

export interface HistoryOptions<HistoryEntryType> {
	controller: BaseHistoryController;
	mapper: HistoryBaseMapper<HistoryEntryType>;
}

export const useHistoryStore = <HistoryEntryType>(options: HistoryOptions<HistoryEntryType>) => {
	const getDefaultState = (): HistoryState<HistoryEntryType> => {
		return {
			id: null,
			history: []
		};
	};
	
	const getters = {};
	
	const actions = {
		async initializeHistoryStore(this: HistoryState<HistoryEntryType>) {
			if(!this.id)
				throw new NotDefinedException("id");
			
			try {
				let { history } = await options.controller.getHistory(this.id!);
				
				let mappedHistoryEntries = history.map((x: any) => options.mapper.mapToHistoryEntry(x));
				
				this.history = orderBy(mappedHistoryEntries, (x: any) => x.actionDateTime, ["desc"]);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		}
	};
	
	return { getters, actions, getDefaultState };
};
