import { ApiFnsLegalPerson } from "@rmp/core/api/types/fns/apiFnsLegalPerson";
import { parseISODate } from "@rmp/core/utils/dates";
import ApiLegalPersonCounterparty from "@rmp/core/api/types/counterparty/apiLegalPersonCounterparty";

export interface LegalPersonCounterparty {
	inn: string;
	ogrnIp: string;
	fullName: string;
	registrationDate: Date | null;
	description: string;
}

export class LegalPersonCounterpartyMapper {
	static map(source: ApiFnsLegalPerson): LegalPersonCounterparty {
		return {
			...source,
			ogrnIp: source.ogrn,
			fullName: source.fullName,
			registrationDate: parseISODate(source.registrationDate),
			description: ""
		};
	}
	
	static mapFromApiLegalPerson(source: ApiLegalPersonCounterparty): LegalPersonCounterparty {
		return {
			...source,
			registrationDate: parseISODate(source.registrationDate)
		};
	}
}
