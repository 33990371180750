import { computed } from "vue";
import { useColors } from "@rmp/core/composables/useColors";

export interface AlertComposableProps {
	type?: string;
	customBackgroundColor?: string;
	customIcon?: string;
	iconColor?: string;
	colorClass?: string;
}

export function useAlert({ ...props }: AlertComposableProps) {
	const { colors } = useColors();
	
	const backgroundColor = computed(() => {
		if(props.customBackgroundColor)
			return props.customBackgroundColor;
		
		switch (props.type) {
			case "info":
				return colors.secondary.base;
			case "error":
				return colors.error;
			case "warning":
				return colors.warning;
			case "success":
				return colors.success;
		}
	});
	
	const type = computed<string | undefined>(() => {
		return props.type;
	});
	
	const customBackgroundColor = computed<string | undefined>(() => {
		return props.customBackgroundColor;
	});
	const customIcon = computed<string | undefined>(() => {
		return props.customIcon;
	});
	
	const iconColor = computed<string | undefined>(() => {
		return props.iconColor;
	});
	
	const colorClass = computed<string>(() => {
		return props.colorClass || "white--text";
	});
	
	return {
		type,
		customBackgroundColor,
		customIcon,
		iconColor,
		colorClass,
		backgroundColor
	};
}
