import {
	AgreementApplicationStepState
} from "@rmp/counterparty/stores/agreementApplication/steps/shared/agreementApplicationStepState";
import { defineStore } from "pinia";
import { AgreementApplicationStepTypeEnum } from "@rmp/counterparty/stores/agreementApplication/steps/shared/AgreementApplicationStepTypeEnum";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import { CounterpartyController } from "@rmp/core/api/counterparty";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";
import ApiCounterpartyPersistedBase from "@rmp/core/api/types/counterparty/apiCounterpartyPersistedBase";
import ApiLegalPersonCounterparty from "@rmp/core/api/types/counterparty/apiLegalPersonCounterparty";
import ApiLegalEntityCounterparty from "@rmp/core/api/types/counterparty/apiLegalEntityCounterparty";
import { LegalPersonCounterparty, LegalPersonCounterpartyMapper } from "@rmp/core/types/counterparty/legalPerson/legalPersonCounterparty";
import { CounterpartyTypeEnum } from "@rmp/core/types/CounterpartyTypeEnum";
import {
	AgreementLegalEntityCounterparty,
	AgreementLegalEntityCounterpartyMapper
} from "@rmp/counterparty/stores/agreementApplication/steps/counterparty/types/agreementLegalEntityCounterparty";

const abortService = new AbortService();
const counterpartyController = new CounterpartyController(abortService);

const page = usePageStore(abortService);

export interface CounterpartyStepState extends AgreementApplicationStepState, PageState {
	legalEntity: AgreementLegalEntityCounterparty;
	legalPerson: LegalPersonCounterparty;
	counterpartyId: string;
	counterpartyType: CounterpartyTypeEnum;
}

const getDefaultState = (): CounterpartyStepState => {
	return {
		...page.getDefaultPageState(),
		stepType: AgreementApplicationStepTypeEnum.COUNTERPARTY,
		legalEntity: {} as AgreementLegalEntityCounterparty,
		legalPerson: {} as LegalPersonCounterparty,
		counterpartyId: "",
		counterpartyType: CounterpartyTypeEnum.UNKNOWN
	};
};

export const useCounterpartyStepStore = defineStore({
	id: "agreement-application-counterparty-step",
	state: (): CounterpartyStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return state.initialized;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			await this.fetch();
		},
		async fetch() {
			try {
				const apiCounterparty = await counterpartyController.getCounterparty("", ApiCounterpartyPersistedBase, AuthorizationScopeType.OWN);
				
				this.counterpartyId = apiCounterparty.id;
				this.counterpartyType = apiCounterparty.counterparty.type;
				
				if(apiCounterparty.counterparty instanceof ApiLegalPersonCounterparty)
					this.legalPerson = LegalPersonCounterpartyMapper.mapFromApiLegalPerson(apiCounterparty.counterparty);
				if(apiCounterparty.counterparty instanceof ApiLegalEntityCounterparty)
					this.legalEntity = AgreementLegalEntityCounterpartyMapper.map(apiCounterparty.counterparty);
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
			}
		}
	}
});
