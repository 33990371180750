import { getYear, parse } from "date-fns";
import { i18n } from "@rmp/core/plugins";
// @ts-ignore
import { checkINN, checkSnils, checkOgrn, checkOgrnip } from "ru-validation-codes";
// @ts-ignore
import isKpp from "is-kpp-js";
import { isDateWithSecondsFormatValid } from "@rmp/core/utils/formats";
import { formatDate } from "@rmp/core/utils/dates";
import validator from "is-my-date-valid";
import {
	validateLegalEntityMaskedInn,
	validateMaskedKpp,
	validateMaskedOgrn,
	validateMaskedOgrnip,
	validatePersonMaskedInn,
	validateBankAccountNumber,
	validateCorrespondentAccount,
	validateBankIdentificationCode, validateIMO, validateMMSI, validateMultipleOfHalf
} from "@rmp/core/utils/validator";
import { isPhoneValid } from "@rmp/core/utils/phone";
import { checkSnilsFormat } from "@rmp/core/utils/checkSnilsFormat";
import passwordValidator from "@rmp/core/utils/password";
import { parseFloatNumber } from "@rmp/core/utils/number";

const FIRSTNAME_REGEXP = /^[.(]?[А-ЯЁ]([\-\s.,(’]?([а-яА-ЯЁё])[\-\s.,)’]?){1,}[а-яА-ЯЁё]?[\s?IVX]{0,3}[.)]??$/;
const MIDDLE_NAME_REGEXP = /^[.(]?[А-ЯЁ]([\-\s.,(’]?([а-яА-ЯЁё])[\-\s.,)’]?){1,}[а-яА-ЯЁё]?[\s?IVX]{0,3}[.)]??$/;
const LASTNAME_REGEXP = /^[.(]?[А-ЯЁ]([\-\s.,(’]?([а-яА-ЯЁё])[\-\s.,)’]?){1,}[а-яА-ЯЁё]?[\s?IVX]{0,3}[.)]??$/;

const validateDateTime = validator({ format: isDateWithSecondsFormatValid });

export const requiredRule = () => {
	return (v: string) => v !== "" && !/^[\s]+$/.test(v) && !!v || i18n.t("validation.required");
};

export const requiredAutocompleteValueRule = (requiredKey: string) => {
	return (v: any) => v !== null && !!v[requiredKey] || i18n.t("validation.required");
};

export const requiredMultipleAutocompleteRule = () => {
	return (v: any) => v !== null && !!v.length || i18n.t("validation.required");
};

export const prepareInnRule = () => {
	return (v: any) => !v || checkINN(v) || i18n.t("validation.inn");
};

export const prepareLegalEntityInnRule = () => {
	return (v: string | any[]) => !v || (checkINN(v) && v.length === 10) || i18n.t("validation.inn");
};

export const prepareLegalEntityMaskedInnValidationRule = () => {
	return (v: string) => !v || validateLegalEntityMaskedInn(v) || i18n.t("validation.inn");
};

export const preparePersonMaskedInnValidationRule = () => {
	return (v: string) => !v || validatePersonMaskedInn(v) || i18n.t("validation.inn");
};

export const prepareMaskedKppValidationRule = () => {
	return (v: string) => !v || validateMaskedKpp(v) || i18n.t("validation.kpp");
};

export const prepareBankAccountNumberValidationRule = (getBik: () => string) => {
	return (v: string) => !v || validateBankAccountNumber(v, getBik()) || i18n.t("validation.bankAccountNumber");
};

export const prepareCorrespondentAccountValidationRule = (getBik: () => string) => {
	return (v: string) => !v || validateCorrespondentAccount(v, getBik()) || i18n.t("validation.correspondentAccount");
};

export const prepareBankIdentificationCodeValidationRule = () => {
	return (v: string) => !v || validateBankIdentificationCode(v) || i18n.t("validation.bankIdentificationCode");
};

export const prepareSnilsRule = () => {
	return (v: any) => !v || checkSnils(v) || i18n.t("validation.snils");
};

export const checkSnilsFormatRule = () => {
	return (v: any) => !v || checkSnilsFormat(v) || i18n.t("validation.snilsFormat");
};

export const prepareOgrnRule = () => {
	return (v: any) => !v || checkOgrn(v) || i18n.t("validation.ogrn");
};

export const prepareMaskedOgrnValidationRule = () => {
	return (v: string) => !v || validateMaskedOgrn(v) || i18n.t("validation.ogrn");
};

export const prepareMaskedOgrnipValidationRule = () => {
	return (v: string) => !v || validateMaskedOgrnip(v) || i18n.t("validation.ogrnip");
};

export const prepareOgrnipRule = () => {
	return (v: any) => !v || checkOgrnip(v) || i18n.t("validation.ogrnip");
};

export const prepareKppRule = () => {
	return (v: any) => !v || isKpp(v) || i18n.t("validation.kpp");
};

export const prepareMaxLengthRule = ({ maxLength }: { maxLength: number }) => {
	return (v: string | number | any[]) => !v || String(v).length <= maxLength || `${i18n.t("validation.maxLength", { number: maxLength })}`;
};

export const prepareMinLengthRule = ({ minLength }: { minLength: number }) => {
	return (v: string | any[]) => !v || v.length >= minLength || `${i18n.t("validation.minLength", { number: minLength })}`;
};

export const validDate = () => {
	return (v: string) => !v || validateDateTime(v) || i18n.t("validation.invalidDate");
};

export const prepareMaxNumbersRule = ({ getMax }: { getMax: any }) => {
	return (v: string) => !v || parseFloatNumber(v) <= getMax() || i18n.t("validation.maxNumber", { number: getMax() });
};

export const prepareMinNumbersRule = ({ getMin }: { getMin: any }) => {
	return (v: string) => !v || parseFloatNumber(v) >= getMin() || i18n.t("validation.minNumber", { number: getMin() });
};


export const prepareMaxDateRule = ({ getMax, format }: { getMax: any, format: string }) => {
	return (v: string) => !getMax() || !v || parse(v, format, new Date()) <= getMax() ||
		i18n.t("validation.maxDate", { number: formatDate(getMax(), format) });
};

export const prepareMinDateRule = ({ getMin, format }: { getMin: any, format: string }) => {
	return (v: string) => !getMin() || !v || parse(v, format, new Date()) >= getMin() ||
		i18n.t("validation.minDate", { number: formatDate(getMin(), format) });
};

export const prepareMaxTimeRule = ({ getCurrentDate, getMaxDateTime, format }: { getCurrentDate: any, getMaxDateTime: any, format: string }) => {
	return (v: string) => !getMaxDateTime() || !getCurrentDate() || !v || parse(v, format, getCurrentDate()) <= getMaxDateTime() ||
		i18n.t("validation.maxDate", { number: formatDate(getMaxDateTime(), format) });
};

export const prepareMinTimeRule = ({ getCurrentDate, getMinDateTime, format }: { getCurrentDate: any, getMinDateTime: any, format: string }) => {
	return (v: string) => !getMinDateTime() || !getCurrentDate() || !v || parse(v, format, getCurrentDate()) >= getMinDateTime() ||
		i18n.t("validation.minDate", { number: formatDate(getMinDateTime(), format) });
};

export const prepareYearRule = () => {
	return (v: number) => !v || (v > 0 && v <= getYear(new Date())) || i18n.tc("validation.year");
};

export const prepareIMORule = () => {
	return (v: string) => !v || validateIMO(v) || i18n.tc("validation.imo");
};

export const prepareMMSIRule = () => {
	return (v: string) => !v || validateMMSI(v) || i18n.tc("validation.mmsi");
};

export const prepareCalculationObjectCodeRule = () => {
	return (v: string) => !v || /^K[A-Z](\d){9}$/.test(v) || i18n.tc("validation.calculationObjectCode");
};

export const prepareEmailRule = () => {
	return (v: string) => !v || /.+@.+\..+/i.test(v) || i18n.t("validation.validEmail");
};

export const prepareLastnameRule = () => {
	return (v: string) => !v || LASTNAME_REGEXP.test(v) || i18n.t("validation.validLastname");
};

export const prepareFirstnameRule = () => {
	return (v: string) => !v || FIRSTNAME_REGEXP.test(v) || i18n.t("validation.validFirstname");
};

export const prepareMiddleNameRule = () => {
	return (v: string) => !v || MIDDLE_NAME_REGEXP.test(v) || i18n.t("validation.validMiddleName");
};

export const prepareTimeRule = () => {
	return (v: string) => !v || /^(?:[01]?\d|2[0-3])(?::[0-5]\d){1,2}$/i.test(v) || i18n.t("validation.validTime");
};

export const prepareUniqRule = ({ getValue, message }: { getValue: any, message: string }) => {
	return (v: any) => !v || getValue() !== v || message;
};

export const prepareConfirmedEmailRule = ({ getEmail }: { getEmail: any }) => {
	return (v: any) => !v || getEmail() === v || i18n.t("validation.validConfirmedEmail");
};

export const preparePasswordRule = () => {
	return (v: string) => {
		let result = passwordValidator.validate(v, { details: true }) as { message: string }[];
		return result.length ? result[0].message : true;
	};
};

export const prepareConfirmedPasswordRule = (getPassword: Function) => {
	return (v: string) => !v || !getPassword() || getPassword() === v || "Пароли не совпадают";
};

export const onlyIntegerNumbersRule = () => {
	return (v: string) => !v || /^[-\d]+$/.test(v) || i18n.t("validation.validOnlyIntegerNumbers");
};

export const onlyNumbersRule = () => {
	return (v: string) => !v || /^[0-9]+([.,]?\d+)*$/.test(v) || i18n.t("validation.validOnlyNumbers");
};

export const onlyMultipleOfHalfRule = () => {
	return (v: string) => !v || validateMultipleOfHalf(v) || i18n.t("validation.validOnlyMultipleOfHalfNumbers");
};

export const onlyCurrencyRule = () => {
	return (v: string) => !v || /^\d+(\.\d{1,2})?$/.test(v) || i18n.t("validation.validOnlyCurrency");
};

export const phoneNumberRule = ({ getCountry }: { getCountry: any }) => {
	return (v: string) => !v || (isPhoneValid({ value: v, country: getCountry() }) && /^[-\d\-\s+]+$/.test(v)) ||
		i18n.t("validation.validPhone");
};

export const onlyLatinRule = () => {
	return (v: string) => !v || /^[a-zA-Z\s]+$/.test(v) || i18n.t("validation.validOnlyLatin");
};

/* Временные проверки */
export const passwordRule = () => {
	return (v: string | string[]) => !v || v.includes("test") || i18n.t("validation.validPassword");
};
