import { capitalizeFirstLetter } from "@rmp/core/utils/formatting";

export const getCertificateOwnerFullName = (firstAnePatronymicNames: string, lastName: string) => {
	const firstAndPatronymicNames = firstAnePatronymicNames.toLowerCase().split(" ");
	const fullName = [
		lastName.toLowerCase(),
		firstAndPatronymicNames[0],
		firstAndPatronymicNames[1]
	];
	
	return fullName.map(x => capitalizeFirstLetter(x)).join(" ");
};
