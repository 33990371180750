import { RawLocation, VueRouter } from "vue-router/types/router";

export const refreshRoute = (router: VueRouter) => {
	router.go(0);
};

export const openRouteInNewTab = async (router: VueRouter, route: RawLocation) => {
	const { href } = router.resolve(route);
	window.open(href, "_blank");
};

export const openRouteWithoutRouter = async (router: VueRouter, route: RawLocation) => {
	const { href } = router.resolve(route);
	//@ts-ignore
	location = href;
};
