import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { DocumentsState, useDocumentsStore } from "@rmp/counterparty/stores/composables/baseDocuments";
import { AgreementController } from "@rmp/counterparty/api/agreement";
import { AgreementDocument, AgreementDocumentMapper } from "@rmp/core/types/agreement/agreementDocument";
import { useAgreementDetailsStore } from "@rmp/counterparty/stores/agreementDetails";
import {
	useAgreementDetailsDocumentsBreadcrumb
} from "@rmp/counterparty/stores/agreementDetails/documents/composables/useAgreementDetailsDocumentsBreadcrumb";

const abortService = new AbortService();
const page = usePageStore(abortService);
const agreementController = new AgreementController(abortService);

const documentsStore = useDocumentsStore({ controller: agreementController, mapper: AgreementDocumentMapper });

export interface AgreementDetailsDocumentsState extends PageState, DocumentsState<AgreementDocument> {
}

const getDefaultState = (): AgreementDetailsDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...documentsStore.getDefaultState()
	};
};

export const useAgreementDetailsDocumentsStore = defineStore({
	id: "agreement-details-documents",
	state: (): AgreementDetailsDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...documentsStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useAgreementDetailsStore();
			return [...breadcrumbs, useAgreementDetailsDocumentsBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...documentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			const { title } = useAgreementDetailsStore();
			
			this.id = id;
			
			await this.initializeDocumentsStore(title);
		}
	}
});
