import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { useDepartmentStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/department";
import { useServiceStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/service";
import AbortService from "@rmp/core/services/abortService";
import { useDocumentsStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/documents";
import { ApiCreateServiceDocument } from "@rmp/core/api/types/service/document/apiCreateServiceDocument";
import { ServiceApplicationStepState } from "@rmp/counterparty/stores/serviceApplication/steps/shared/serviceApplicationState";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import { usePortStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/port";
import { CounterpartyController } from "@rmp/core/api/counterparty";
import ApiLegalEntityCounterpartyPersisted from "@rmp/core/api/types/counterparty/apiLegalEntityCounterpartyPersisted";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";
import { useUserStore } from "@rmp/core/stores/user";
import ServiceApplicationApiFacade from "@rmp/counterparty/api/service/facade";
import { ApiCreateServiceApplicationBase } from "@rmp/core/api/types/service/base/apiCreateServiceApplicationBase";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import router from "@rmp/counterparty/router";
import { RouteNames } from "@rmp/counterparty/router/routes";
import { ApiBaseServiceTransitionCodeEnum } from "@rmp/core/api/types/service/base/ApiInformationServiceTransitionCodeEnum";
import { ApiCreateFloatingCraneApplication } from "@rmp/core/api/types/service/floatingCrane/apiCreateFloatingCraneApplication";
import { useFloatingCraneStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/floatingCrane";
import { useServiceDocuments } from "@rmp/counterparty/composables/useServiceDocuments";

const abortService = new AbortService();

const counterpartyController = new CounterpartyController(abortService);
const serviceApplicationApiFacade = new ServiceApplicationApiFacade(abortService);

const page = usePageStore(abortService);

export interface ConfirmationStepState extends ServiceApplicationStepState, PageState {
	isSaving: boolean;
	isDraftSaving: boolean;
}

const getDefaultState = (): ConfirmationStepState => {
	return {
		...page.getDefaultPageState(),
		isSaving: false,
		isDraftSaving: false,
		stepType: ServiceApplicationStepTypeEnum.CONFIRMATION
	};
};

export const useConfirmationStepStore = defineStore({
	id: "service-application-confirmation-step",
	state: (): ConfirmationStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return state.initialized;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			const { selectedServiceType } = useServiceStepStore();
			serviceApplicationApiFacade.configure(selectedServiceType);
		},
		async prepareServiceApplication() {
			const { selectedServiceType } = useServiceStepStore();
			const { profile } = useUserStore();
			const { selectedDepartmentId } = useDepartmentStepStore();
			const { selectedPortId } = usePortStepStore();
			
			const counterparty = await counterpartyController.getCounterparty("",
				ApiLegalEntityCounterpartyPersisted,
				AuthorizationScopeType.OWN);
			
			const application = {
				initiatorCounterpartyEmployeeId: profile!.externalId,
				initiatorCounterpartyId: counterparty.id,
				divisionId: selectedDepartmentId,
				portId: selectedPortId
			} as ApiCreateServiceApplicationBase;
			
			if(selectedServiceType === ServiceTypeEnum.FLOATING_CRANE_SERVICE) {
				const { selectedFloatingCraneIds } = useFloatingCraneStepStore();
				(application as ApiCreateFloatingCraneApplication).requestedFloatingcranes = selectedFloatingCraneIds.map(x => ({
					floatingcraneId: x
				}));
			}
			
			return application;
		},
		async save() {
			this.isSaving = true;
			
			try {
				const { selectedServiceType } = useServiceStepStore();
				const { documents: uploadedDocuments } = useDocumentsStepStore();
				const { isApplicableServiceTypeForFetchConstitutionalDocuments } = useServiceDocuments();
				
				const serviceApplication = await this.prepareServiceApplication();
				
				const documents: ApiCreateServiceDocument[] = uploadedDocuments.map(x => ({
					documentKind: x.documentKind,
					tempFileId: x.id,
					title: x.name
				} as ApiCreateServiceDocument));
				
				const { id } = await serviceApplicationApiFacade.createApplication(serviceApplication);
				
				const tasks: Promise<any>[] = [];
				
				// сохраняем документы для применимого типа документов.
				if(documents.length && isApplicableServiceTypeForFetchConstitutionalDocuments(selectedServiceType))
					tasks.push(serviceApplicationApiFacade.saveDocuments(id, documents));
				
				await Promise.all(tasks);
				
				// TODO
				await serviceApplicationApiFacade.updateStatusByTransition(id, ApiBaseServiceTransitionCodeEnum.CREATE);
				await serviceApplicationApiFacade.updateStatusByTransition(id, ApiBaseServiceTransitionCodeEnum.SEND);
				
				alertService.addSuccess(AlertKeys.SERVICE_APPLICATION_CREATED);
				
				const serviceTypeToRouteMap = new Map([
					[ServiceTypeEnum.DREDGING_WORKS_SERVICE, RouteNames.DREDGING_SERVICE],
					[ServiceTypeEnum.GEODETIC_HYDROGRAPHIC_SERVICE, RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE],
					[ServiceTypeEnum.SNO_INSTALLATION_SERVICE, RouteNames.SNO_INSTALLATION_SERVICE],
					[ServiceTypeEnum.FLOATING_CRANE_SERVICE, RouteNames.FLOATING_CRANE_SERVICE]
				]);
				
				await router.push({ name: serviceTypeToRouteMap.get(selectedServiceType)!, params: { id } });
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.isSaving = false;
			}
		}
	}
});
