import { prepareMaxLengthRule } from "@rmp/core/utils/validation";
import { maxLengths } from "@rmp/core/constants/validation";
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import BatchService from "@rmp/core/services/batchService";
import { RegisterStoreDefinition } from "@rmp/core/stores/composables/register/useRegisterStore";

export interface SearchOptions {
	useStore: RegisterStoreDefinition;
}

export function useRegisterSearch(options: SearchOptions) {
	const setSearchQueryBatchService = new BatchService(({ interval: 700 }));
	
	const store = options.useStore();
	const { search } = storeToRefs(store);
	
	const searchFormValid = ref(false);
	const searchValidation = {
		searchQuery: [prepareMaxLengthRule({ maxLength: maxLengths.search })]
	};
	
	const searchQuery = computed({
		get() {
			return search.value.query;
		},
		set(value: string) {
			if(searchFormValid) {
				setSearchQueryBatchService.push(async () => {
					await store.setSearchQuery(value);
				});
			}
		}
	});
	
	
	return {
		search, searchFormValid, searchQuery, searchValidation
	};
}
