import {
	ServiceApplicationStepState
} from "@rmp/counterparty/stores/serviceApplication/steps/shared/serviceApplicationState";
import { defineStore } from "pinia";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import AbortService from "@rmp/core/services/abortService";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { PierServiceController } from "@rmp/counterparty/api/service/pier";
import { ServiceApplicationPier, ServiceApplicationPierMapper } from "@rmp/core/types/services/serviceApplicationPier";
import { useDepartmentStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/department";
import { useServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication";
import { PierServiceApplication } from "@rmp/core/types/services/pier/pierServiceApplication";
import { uniq } from "lodash";

const abortService = new AbortService();
const page = usePageStore(abortService);

const pierServiceController = new PierServiceController(abortService);

export interface PierStepState extends ServiceApplicationStepState, PageState {
	piers: ServiceApplicationPier[];
	selectedPierIds: string[];
}

const getDefaultState = (): PierStepState => {
	return {
		...page.getDefaultPageState(),
		piers: [],
		selectedPierIds: [],
		stepType: ServiceApplicationStepTypeEnum.PIER
	};
};

export const usePierStepStore = defineStore({
	id: "pier-step",
	state: (): PierStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return state.initialized;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			await this.fetch();
			
			this.trySetDefaultPier();
		},
		async fetch() {
			try {
				const { selectedDepartmentId } = useDepartmentStepStore();
				
				let apiPiers = await pierServiceController.getPiers(selectedDepartmentId);
				
				this.piers = apiPiers.map(x => ServiceApplicationPierMapper.map(x));
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		trySetDefaultPier() {
			if(!this.piers.length) return;
			
			const { copiedService } = useServiceApplicationStore() as { copiedService: PierServiceApplication };
			
			let requestedTariffs = copiedService.requestedPiers || [];

			if(requestedTariffs.length) {
				// Берем уникальные значения, так как объекты повторяются, если выбрано несколько тарифов
				let copiedPierIds = uniq(copiedService.requestedPiers.map(x => x.pierId));

				// Оставляем только те причалы, которые доступны пользователю
				this.selectedPierIds = copiedPierIds.filter(x => this.piers.find(y => y.id === x));
			} else {
				let [pier] = this.piers;
				this.selectedPierIds = [pier.id];
			}
		}
	}
});
