import Vue, { getCurrentInstance } from "vue";
import Vuetify from "vuetify";
// @ts-ignore
import ru from "vuetify/es5/locale/ru";
import "@rmp/core/plugins/vuetify/style.scss";
import "@rmp/core/scss/overrides.scss";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export const useVuetify = () => {
	const instance = getCurrentInstance();
	if(!instance) {
		throw new Error(`useVuetify should be called in setup().`);
	}
	return instance.proxy.$vuetify;
};

export default new Vuetify({
	theme: {
		options: {
			customProperties: true
		},
		themes: {
			light: {
				primary: {
					base: "#00B7C5",
					lighten1: "#33C5D1",
					lighten2: "#66D4DC",
					lighten3: "#99E2E8",
					lighten4: "#CCF1F3",
					darken1: "#00C8D8",
					darken2: "#00A9C2",
					darken3: "#063E78"
				},
				secondary: {
					base: "#003D7C",
					lighten1: "#336496",
					lighten2: "#668BB0",
					lighten3: "#99B1CB",
					lighten4: "#CCD8E5",
					darken1: "#003265"
				},
				grey: {
					base: "#f5f5f5",
					lighten2: "#F7F9FC",
					lighten3: "#CBD2DD",
					darken1: "#8D8D8F",
					darken4: "#1A1A1F",
				},
				purple: {
					base: "#6750A3"
				},
				accent: "#A989EE",
				error: "#B60505",
				success: "#14884E",
				warning: "#FF8B41",
				white: {
					base: "#FFFFFF",
					lighten1: "#F5F5F5",
					lighten2: "#F6F7F9",
					lighten3: "#e9e9e9",
					lighten4: "#F1F1F1",
					darken1: "#e9ecef"
				},
				orange: {
					base: "#FFA800"
				},
				red: {
					lighten4: "#FFE7E7"
				}
			}
		}
	},
	lang: {
		locales: { ru },
		current: "ru"
	}
});
