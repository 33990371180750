import { ApiCertificate } from "@rmp/core/api/types/signature/apiCertificate";
import { isString } from "lodash";
import { convertIsoToNumber, formatDate } from "@rmp/core/utils/dates";
import { getCertificateOwnerFullName } from "@rmp/core/utils/getCertificateOwnerFullName";
import { OID_CP_FIRST_AND_PARTONYMIC_NAMES, OID_CP_ISSUER_NAME, OID_CP_LAST_NAME } from "@rmp/core/constants/signatureIdentifiers";
import { friendlyDateFormat } from "@rmp/core/utils/formats";

export interface CertificateInfo {
	owner: string;
	issuedBy: string;
	thumbprint: string;
	createdDate: string;
	expireDate: string;
}

export class CertificateInfoMapper {
	constructor() {
	}
	
	static map(source: ApiCertificate): CertificateInfo {
		const notAfter = isString(source.notAfter) ? convertIsoToNumber(source.notAfter) : source.notAfter
		const createdDate = isString(source.notBefore) ? convertIsoToNumber(source.notBefore) : source.notBefore
		
		return {
			owner: getCertificateOwnerFullName(source.subjectAttributesByOid[OID_CP_FIRST_AND_PARTONYMIC_NAMES], source.subjectAttributesByOid[OID_CP_LAST_NAME]),
			issuedBy: source.issuerAttributesByOid[OID_CP_ISSUER_NAME],
			thumbprint: source.thumbprint,
			expireDate: formatDate(notAfter, friendlyDateFormat) as string,
			createdDate: formatDate(createdDate, friendlyDateFormat) as string
		};
	}
}
