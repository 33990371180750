import { stringifyQuery } from "@rmp/core/utils/query";
import RmpInternalServerError from "@rmp/core/pages/errors/RmpInternalServerError.vue";
import RmpAccessDeniedError from "@rmp/core/pages/errors/RmpAccessDeniedError.vue";
import RmpPageNotFound from "@rmp/core/pages/errors/RmpPageNotFound.vue";
import RmpConfirmEmail from "@rmp/core/pages/confirmation/RmpConfirmEmail.vue";
import RmpConfirmPhone from "@rmp/core/pages/confirmation/RmpConfirmPhone.vue";
import RmpSilentRenew from "@rmp/counterparty/views/RmpSilentRenew.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import RmpLayout from "@rmp/counterparty/views/layouts/RmpLayout.vue";
import RmpEmptyLayout from "@rmp/core/components/layouts/RmpEmptyLayout.vue";
import RmpCallback from "@rmp/counterparty/views/RmpCallback.vue";
import { RouteNames } from "@rmp/counterparty/router/routes";
import { configureMiddleware } from "@rmp/counterparty/router/middleware";
import RmpCounterpartyDocuments from "@rmp/counterparty/views/counterpartyDocuments/RmpCounterpartyDocuments.vue";
import RmpBoatApplication from "@rmp/counterparty/views/boatApplication/RmpBoatApplication.vue";
import RmpBoatApplications from "@rmp/counterparty/views/boatApplications/RmpBoatApplications.vue";
import RmpBoatApplicationDetails from "@rmp/counterparty/views/boatApplicationDetails/RmpBoatApplicationDetails.vue";
import RmpBoatApplicationDetailsGeneral from "@rmp/counterparty/views/boatApplicationDetails/sections/RmpBoatApplicationDetailsGeneral.vue";
import RmpBoatApplicationDetailsDocuments
	from "@rmp/counterparty/views/boatApplicationDetails/sections/RmpBoatApplicationDetailsDocuments.vue";
import RmpBoatApplicationDetailsHistory from "@rmp/counterparty/views/boatApplicationDetails/sections/RmpBoatApplicationDetailsHistory.vue";
import RmpBoatApplicationDetailsChat from "@rmp/counterparty/views/boatApplicationDetails/sections/RmpBoatApplicationDetailsChat.vue";
import RmpAgreementDetails from "@rmp/counterparty/views/agreementDetails/RmpAgreementDetails.vue";
import RmpAgreementDetailsGeneral from "@rmp/counterparty/views/agreementDetails/sections/RmpAgreementDetailsGeneral.vue";
import RmpAgreementDetailsDocuments from "@rmp/counterparty/views/agreementDetails/sections/RmpAgreementDetailsDocuments.vue";
import RmpAgreementDetailsHistory from "@rmp/counterparty/views/agreementDetails/sections/RmpAgreementDetailsHistory.vue";
import RmpAgreementDetailsChat from "@rmp/counterparty/views/agreementDetails/sections/RmpAgreementDetailsChat.vue";
import RmpAgreementApplication from "@rmp/counterparty/views/agreementApplication/RmpAgreementApplication.vue";
import RmpServiceApplication from "@rmp/counterparty/views/serviceApplication/RmpServiceApplication.vue";
import RmpServices from "@rmp/counterparty/views/services/RmpServices.vue";
import RmpPierService from "@rmp/counterparty/views/serviceDetails/pier/RmpPierService.vue";
import RmpPierServiceGeneral from "@rmp/counterparty/views/serviceDetails/pier/sections/RmpPierServiceGeneral.vue";
import RmpPierServiceDocuments from "@rmp/counterparty/views/serviceDetails/pier/sections/RmpPierServiceDocuments.vue";
import RmpPierServiceChat from "@rmp/counterparty/views/serviceDetails/pier/sections/RmpPierServiceChat.vue";
import RmpPierServiceFeedback from "@rmp/counterparty/views/serviceDetails/pier/sections/RmpPierServiceFeedback.vue";
import RmpPierServiceHistory from "@rmp/counterparty/views/serviceDetails/pier/sections/RmpPierServiceHistory.vue";
import RmpTugboatService from "@rmp/counterparty/views/serviceDetails/tugboat/RmpTugboatService.vue";
import RmpTugboatServiceGeneral from "@rmp/counterparty/views/serviceDetails/tugboat/sections/RmpTugboatServiceGeneral.vue";
import RmpTugboatServiceDocuments from "@rmp/counterparty/views/serviceDetails/tugboat/sections/RmpTugboatServiceDocuments.vue";
import RmpTugboatServiceChat from "@rmp/counterparty/views/serviceDetails/tugboat/sections/RmpTugboatServiceChat.vue";
import RmpTugboatServiceFeedback from "@rmp/counterparty/views/serviceDetails/tugboat/sections/RmpTugboatServiceFeedback.vue";
import RmpTugboatServiceHistory from "@rmp/counterparty/views/serviceDetails/tugboat/sections/RmpTugboatServiceHistory.vue";
import RmpCrewboatService from "@rmp/counterparty/views/serviceDetails/crewboat/RmpCrewboatService.vue";
import RmpCrewboatServiceGeneral from "@rmp/counterparty/views/serviceDetails/crewboat/sections/RmpCrewboatServiceGeneral.vue";
import RmpCrewboatServiceDocuments from "@rmp/counterparty/views/serviceDetails/crewboat/sections/RmpCrewboatServiceDocuments.vue";
import RmpCrewboatServiceChat from "@rmp/counterparty/views/serviceDetails/crewboat/sections/RmpCrewboatServiceChat.vue";
import RmpCrewboatServiceFeedback from "@rmp/counterparty/views/serviceDetails/crewboat/sections/RmpCrewboatServiceFeedback.vue";
import RmpCrewboatServiceHistory from "@rmp/counterparty/views/serviceDetails/crewboat/sections/RmpCrewboatServiceHistory.vue";
import RmpUploadTugboatServiceDocuments from "@rmp/counterparty/views/uploadServiceDocuments/tugboat/RmpUploadTugboatServiceDocuments.vue";
import RmpAgreements from "@rmp/counterparty/views/agreements/RmpAgreements.vue";
import RmpUploadCrewboatServiceDocuments
	from "@rmp/counterparty/views/uploadServiceDocuments/crewboat/RmpUploadCrewboatServiceDocuments.vue";
import RmpUploadPierServiceDocuments from "@rmp/counterparty/views/uploadServiceDocuments/pier/RmpUploadPierServiceDocuments.vue";
import RmpOuterLayout from "@rmp/core/components/layouts/RmpOuterLayout.vue";
import RmpResetPassword from "@rmp/core/pages/resetPassword/RmpResetPassword.vue";
import RmpSignAgreementDocuments from "@rmp/counterparty/views/signAgreementDocuments/RmpSignAgreementDocuments.vue";
import RmpSignDredgingServiceDocuments from "@rmp/counterparty/views/uploadServiceDocuments/dredging/RmpSignDredgingServiceDocuments.vue";
import RmpDredgingService from "@rmp/counterparty/views/serviceDetails/dredging/RmpDredgingService.vue";
import RmpDredgingServiceGeneral from "@rmp/counterparty/views/serviceDetails/dredging/sections/RmpDredgingServiceGeneral.vue";
import RmpDredgingServiceDocuments from "@rmp/counterparty/views/serviceDetails/dredging/sections/RmpDredgingServiceDocuments.vue";
import RmpDredgingServiceHistory from "@rmp/counterparty/views/serviceDetails/dredging/sections/RmpDredgingServiceHistory.vue";
import RmpDredgingServiceChat from "@rmp/counterparty/views/serviceDetails/dredging/sections/RmpDredgingServiceChat.vue";
import RmpDredgingServiceFeedback from "@rmp/counterparty/views/serviceDetails/dredging/sections/RmpDredgingServiceFeedback.vue";
import RmpGeodeticHydrographicService from "@rmp/counterparty/views/serviceDetails/geodeticHydrographic/RmpGeodeticHydrographicService.vue";
import RmpGeodeticHydrographicServiceGeneral from "@rmp/counterparty/views/serviceDetails/geodeticHydrographic/sections/RmpGeodeticHydrographicServiceGeneral.vue";
import RmpGeodeticHydrographicServiceDocuments from "@rmp/counterparty/views/serviceDetails/geodeticHydrographic/sections/RmpGeodeticHydrographicServiceDocuments.vue";
import RmpGeodeticHydrographicServiceHistory from "@rmp/counterparty/views/serviceDetails/geodeticHydrographic/sections/RmpGeodeticHydrographicServiceHistory.vue";
import RmpGeodeticHydrographicServiceChat from "@rmp/counterparty/views/serviceDetails/geodeticHydrographic/sections/RmpGeodeticHydrographicServiceChat.vue";
import RmpGeodeticHydrographicServiceFeedback from "@rmp/counterparty/views/serviceDetails/geodeticHydrographic/sections/RmpGeodeticHydrographicServiceFeedback.vue";
import RmpSignGeodeticHydrographicServiceDocuments from "@rmp/counterparty/views/uploadServiceDocuments/geodeticHydrographic/RmpSignGeodeticHydrographicServiceDocuments.vue";
import RmpSnoInstallationServiceGeneral
	from "@rmp/counterparty/views/serviceDetails/snoInstallation/sections/RmpSnoInstallationServiceGeneral.vue";
import RmpSignSnoInstallationServiceDocuments
	from "@rmp/counterparty/views/uploadServiceDocuments/snoInstallation/RmpSignSnoInstallationServiceDocuments.vue";
import RmpSnoInstallationService from "@rmp/counterparty/views/serviceDetails/snoInstallation/RmpSnoInstallationService.vue";
import RmpSnoInstallationServiceDocuments
	from "@rmp/counterparty/views/serviceDetails/snoInstallation/sections/RmpSnoInstallationServiceDocuments.vue";
import RmpSnoInstallationServiceHistory
	from "@rmp/counterparty/views/serviceDetails/snoInstallation/sections/RmpSnoInstallationServiceHistory.vue";
import RmpSnoInstallationServiceChat
	from "@rmp/counterparty/views/serviceDetails/snoInstallation/sections/RmpSnoInstallationServiceChat.vue";
import RmpSnoInstallationServiceFeedback
	from "@rmp/counterparty/views/serviceDetails/snoInstallation/sections/RmpSnoInstallationServiceFeedback.vue";
import RmpInformationService from "@rmp/counterparty/views/serviceDetails/information/RmpInformationService.vue";
import RmpInformationServiceGeneral from "@rmp/counterparty/views/serviceDetails/information/sections/RmpInformationServiceGeneral.vue";
import RmpInformationServiceDocuments from "@rmp/counterparty/views/serviceDetails/information/sections/RmpInformationServiceDocuments.vue";
import RmpInformationServiceHistory from "@rmp/counterparty/views/serviceDetails/information/sections/RmpInformationServiceHistory.vue";
import RmpInformationServiceChat from "@rmp/counterparty/views/serviceDetails/information/sections/RmpInformationServiceChat.vue";
import RmpInformationServiceFeedback from "@rmp/counterparty/views/serviceDetails/information/sections/RmpInformationServiceFeedback.vue";
import RmpSignInformationServiceDocuments
	from "@rmp/counterparty/views/uploadServiceDocuments/information/RmpSignInformationServiceDocuments.vue";
import RmpFloatingCraneService from "@rmp/counterparty/views/serviceDetails/floatingCrane/RmpFloatingCraneService.vue";
import RmpFloatingCraneServiceGeneral
	from "@rmp/counterparty/views/serviceDetails/floatingCrane/sections/RmpFloatingCraneServiceGeneral.vue";
import RmpFloatingCraneServiceDocuments
	from "@rmp/counterparty/views/serviceDetails/floatingCrane/sections/RmpFloatingCraneServiceDocuments.vue";
import RmpFloatingCraneServiceHistory
	from "@rmp/counterparty/views/serviceDetails/floatingCrane/sections/RmpFloatingCraneServiceHistory.vue";
import RmpFloatingCraneServiceChat from "@rmp/counterparty/views/serviceDetails/floatingCrane/sections/RmpFloatingCraneServiceChat.vue";
import RmpFloatingCraneServiceFeedback
	from "@rmp/counterparty/views/serviceDetails/floatingCrane/sections/RmpFloatingCraneServiceFeedback.vue";
import RmpSignFloatingCraneServiceDocuments
	from "@rmp/counterparty/views/uploadServiceDocuments/floatingCrane/RmpSignFloatingCraneServiceDocuments.vue";
import RmpAgreementDetailsServices from "@rmp/counterparty/views/agreementDetails/sections/RmpAgreementDetailsServices.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/callback.html",
		component: RmpCallback,
		meta: { public: true },
		name: RouteNames.CALLBACK
	},
	{
		path: "/silent-renew.html",
		component: RmpSilentRenew,
		meta: { public: true },
		name: RouteNames.SILENT_RENEW
	},
	{
		path: "/",
		component: RmpLayout,
		name: RouteNames.ROOT,
		redirect: { name: RouteNames.SERVICES },
		children: [
			{
				path: "counterparty-documents",
				name: RouteNames.COUNTERPARTY_DOCUMENTS,
				component: RmpCounterpartyDocuments
			},
			{
				path: "boat-applications",
				name: RouteNames.BOAT_APPLICATIONS,
				component: RmpBoatApplications
			},
			{
				path: "boat-application",
				name: RouteNames.BOAT_APPLICATION_CREATE,
				query: ["copy"],
				component: RmpBoatApplication
			},
			{
				path: "boat-application-details/:id",
				name: RouteNames.BOAT_APPLICATION_DETAILS,
				redirect: { name: RouteNames.BOAT_APPLICATION_DETAILS_GENERAL },
				component: RmpBoatApplicationDetails,
				children: [
					{
						path: "",
						meta: { key: "boat-application-details" },
						name: RouteNames.BOAT_APPLICATION_DETAILS_GENERAL,
						component: RmpBoatApplicationDetailsGeneral
					},
					{
						path: "documents",
						meta: { key: "boat-application-details" },
						name: RouteNames.BOAT_APPLICATION_DETAILS_DOCUMENTS,
						component: RmpBoatApplicationDetailsDocuments
					},
					{
						path: "history",
						meta: { key: "boat-application-details" },
						name: RouteNames.BOAT_APPLICATION_DETAILS_HISTORY,
						component: RmpBoatApplicationDetailsHistory
					},
					{
						path: "comments",
						meta: { key: "boat-application-details" },
						name: RouteNames.BOAT_APPLICATION_DETAILS_COMMENTS,
						component: RmpBoatApplicationDetailsChat
					}
				]
			},
			{
				path: "agreements",
				name: RouteNames.AGREEMENTS,
				component: RmpAgreements
			},
			{
				path: "agreement-application",
				name: RouteNames.CREATE_AGREEMENT_APPLICATION,
				component: RmpAgreementApplication
			},
			{
				path: "agreement-details/:id",
				name: RouteNames.AGREEMENT_DETAILS,
				redirect: { name: RouteNames.AGREEMENT_DETAILS_GENERAL },
				component: RmpAgreementDetails,
				children: [
					{
						path: "",
						meta: { key: "agreement-details" },
						name: RouteNames.AGREEMENT_DETAILS_GENERAL,
						component: RmpAgreementDetailsGeneral
					},
					{
						path: "documents",
						meta: { key: "agreement-details" },
						name: RouteNames.AGREEMENT_DETAILS_DOCUMENTS,
						component: RmpAgreementDetailsDocuments
					},
					{
						path: "history",
						meta: { key: "agreement-details" },
						name: RouteNames.AGREEMENT_DETAILS_HISTORY,
						component: RmpAgreementDetailsHistory
					},
					{
						path: "comments",
						meta: { key: "agreement-details" },
						name: RouteNames.AGREEMENT_DETAILS_COMMENTS,
						component: RmpAgreementDetailsChat
					},
					{
						path: "services",
						meta: { key: "agreement-details" },
						name: RouteNames.AGREEMENT_DETAILS_SERVICES,
						component: RmpAgreementDetailsServices
					}
				]
			},
			{
				path: "agreement-details/:id/sign-documents",
				name: RouteNames.AGREEMENT_SIGN_DOCUMENTS,
				component: RmpSignAgreementDocuments
			},
			{
				path: "services",
				name: RouteNames.SERVICES,
				component: RmpServices
			},
			{
				path: "service-application",
				name: RouteNames.CREATE_SERVICE_APPLICATION,
				query: ["copy"],
				component: RmpServiceApplication
			},
			{
				path: "services/pier/:id/upload-documents",
				name: RouteNames.PIER_SERVICE_UPLOAD_DOCUMENTS,
				component: RmpUploadPierServiceDocuments
			},
			{
				path: "services/pier/:id",
				name: RouteNames.PIER_SERVICE,
				redirect: { name: RouteNames.PIER_SERVICE_GENERAL },
				component: RmpPierService,
				children: [
					{
						path: "",
						meta: { key: "pier-service" },
						name: RouteNames.PIER_SERVICE_GENERAL,
						component: RmpPierServiceGeneral
					},
					{
						path: "documents",
						meta: { key: "pier-service" },
						name: RouteNames.PIER_SERVICE_DOCUMENTS,
						component: RmpPierServiceDocuments
					},
					{
						path: "history",
						meta: { key: "pier-service" },
						name: RouteNames.PIER_SERVICE_HISTORY,
						component: RmpPierServiceHistory
					},
					{
						path: "comments",
						meta: { key: "pier-service" },
						name: RouteNames.PIER_SERVICE_COMMENTS,
						component: RmpPierServiceChat
					},
					{
						path: "feedback",
						meta: { key: "pier-service" },
						name: RouteNames.PIER_SERVICE_FEEDBACK,
						component: RmpPierServiceFeedback
					}
				]
			},
			{
				path: "services/information/:id",
				name: RouteNames.INFORMATION_SERVICE,
				redirect: { name: RouteNames.INFORMATION_SERVICE_GENERAL },
				component: RmpInformationService,
				children: [
					{
						path: "",
						meta: { key: "information-service" },
						name: RouteNames.INFORMATION_SERVICE_GENERAL,
						component: RmpInformationServiceGeneral
					},
					{
						path: "documents",
						meta: { key: "information-service" },
						name: RouteNames.INFORMATION_SERVICE_DOCUMENTS,
						component: RmpInformationServiceDocuments
					},
					{
						path: "history",
						meta: { key: "information-service" },
						name: RouteNames.INFORMATION_SERVICE_HISTORY,
						component: RmpInformationServiceHistory
					},
					{
						path: "comments",
						meta: { key: "information-service" },
						name: RouteNames.INFORMATION_SERVICE_COMMENTS,
						component: RmpInformationServiceChat
					},
					{
						path: "feedback",
						meta: { key: "information-service" },
						name: RouteNames.INFORMATION_SERVICE_FEEDBACK,
						component: RmpInformationServiceFeedback
					}
				]
			},
			{
				path: "services/information/:id/upload-documents",
				name: RouteNames.INFORMATION_SERVICE_SIGN_DOCUMENTS,
				component: RmpSignInformationServiceDocuments
			},
			{
				path: "services/tugboat/:id",
				name: RouteNames.TUGBOAT_SERVICE,
				redirect: { name: RouteNames.TUGBOAT_SERVICE_GENERAL },
				component: RmpTugboatService,
				children: [
					{
						path: "",
						meta: { key: "tugboat-service" },
						name: RouteNames.TUGBOAT_SERVICE_GENERAL,
						component: RmpTugboatServiceGeneral
					},
					{
						path: "documents",
						meta: { key: "tugboat-service" },
						name: RouteNames.TUGBOAT_SERVICE_DOCUMENTS,
						component: RmpTugboatServiceDocuments
					},
					{
						path: "history",
						meta: { key: "tugboat-service" },
						name: RouteNames.TUGBOAT_SERVICE_HISTORY,
						component: RmpTugboatServiceHistory
					},
					{
						path: "comments",
						meta: { key: "tugboat-service" },
						name: RouteNames.TUGBOAT_SERVICE_COMMENTS,
						component: RmpTugboatServiceChat
					},
					{
						path: "feedback",
						meta: { key: "tugboat-service" },
						name: RouteNames.TUGBOAT_SERVICE_FEEDBACK,
						component: RmpTugboatServiceFeedback
					}
				]
			},
			{
				path: "services/tugboat/:id/upload-documents",
				name: RouteNames.TUGBOAT_SERVICE_UPLOAD_DOCUMENTS,
				component: RmpUploadTugboatServiceDocuments
			},
			{
				path: "services/crewboat/:id/upload-documents",
				name: RouteNames.CREWBOAT_SERVICE_UPLOAD_DOCUMENTS,
				component: RmpUploadCrewboatServiceDocuments
			},
			{
				path: "services/crewboat/:id",
				name: RouteNames.CREWBOAT_SERVICE,
				redirect: { name: RouteNames.CREWBOAT_SERVICE_GENERAL },
				component: RmpCrewboatService,
				children: [
					{
						path: "",
						meta: { key: "crewboat-service" },
						name: RouteNames.CREWBOAT_SERVICE_GENERAL,
						component: RmpCrewboatServiceGeneral
					},
					{
						path: "documents",
						meta: { key: "crewboat-service" },
						name: RouteNames.CREWBOAT_SERVICE_DOCUMENTS,
						component: RmpCrewboatServiceDocuments
					},
					{
						path: "history",
						meta: { key: "crewboat-service" },
						name: RouteNames.CREWBOAT_SERVICE_HISTORY,
						component: RmpCrewboatServiceHistory
					},
					{
						path: "comments",
						meta: { key: "crewboat-service" },
						name: RouteNames.CREWBOAT_SERVICE_COMMENTS,
						component: RmpCrewboatServiceChat
					},
					{
						path: "feedback",
						meta: { key: "crewboat-service" },
						name: RouteNames.CREWBOAT_SERVICE_FEEDBACK,
						component: RmpCrewboatServiceFeedback
					}
				]
			},
			{
				path: "services/dredging/:id/sign-documents",
				name: RouteNames.DREDGING_SERVICE_SIGN_DOCUMENTS,
				component: RmpSignDredgingServiceDocuments
			},
			{
				path: "services/dredging/:id",
				name: RouteNames.DREDGING_SERVICE,
				redirect: { name: RouteNames.DREDGING_SERVICE_GENERAL },
				component: RmpDredgingService,
				children: [
					{
						path: "",
						meta: { key: "dredging-service" },
						name: RouteNames.DREDGING_SERVICE_GENERAL,
						component: RmpDredgingServiceGeneral
					},
					{
						path: "documents",
						meta: { key: "dredging-service" },
						name: RouteNames.DREDGING_SERVICE_DOCUMENTS,
						component: RmpDredgingServiceDocuments
					},
					{
						path: "history",
						meta: { key: "dredging-service" },
						name: RouteNames.DREDGING_SERVICE_HISTORY,
						component: RmpDredgingServiceHistory
					},
					{
						path: "comments",
						meta: { key: "dredging-service" },
						name: RouteNames.DREDGING_SERVICE_COMMENTS,
						component: RmpDredgingServiceChat
					},
					{
						path: "feedback",
						meta: { key: "dredging-service" },
						name: RouteNames.DREDGING_SERVICE_FEEDBACK,
						component: RmpDredgingServiceFeedback
					}
				]
			},
			{
				path: "services/floating-crane/:id/sign-documents",
				name: RouteNames.FLOATING_CRANE_SERVICE_SIGN_DOCUMENTS,
				component: RmpSignFloatingCraneServiceDocuments
			},
			{
				path: "services/floating-crane/:id",
				name: RouteNames.FLOATING_CRANE_SERVICE,
				redirect: { name: RouteNames.FLOATING_CRANE_SERVICE_GENERAL },
				component: RmpFloatingCraneService,
				children: [
					{
						path: "",
						meta: { key: "floating-crane-service" },
						name: RouteNames.FLOATING_CRANE_SERVICE_GENERAL,
						component: RmpFloatingCraneServiceGeneral
					},
					{
						path: "documents",
						meta: { key: "floating-crane-service" },
						name: RouteNames.FLOATING_CRANE_SERVICE_DOCUMENTS,
						component: RmpFloatingCraneServiceDocuments
					},
					{
						path: "history",
						meta: { key: "floating-crane-service" },
						name: RouteNames.FLOATING_CRANE_SERVICE_HISTORY,
						component: RmpFloatingCraneServiceHistory
					},
					{
						path: "comments",
						meta: { key: "floating-crane-service" },
						name: RouteNames.FLOATING_CRANE_SERVICE_COMMENTS,
						component: RmpFloatingCraneServiceChat
					},
					{
						path: "feedback",
						meta: { key: "floating-crane-service" },
						name: RouteNames.FLOATING_CRANE_SERVICE_FEEDBACK,
						component: RmpFloatingCraneServiceFeedback
					}
				]
			},
			{
				path: "services/sno-installation/:id/sign-documents",
				name: RouteNames.SNO_INSTALLATION_SERVICE_SIGN_DOCUMENTS,
				component: RmpSignSnoInstallationServiceDocuments
			},
			{
				path: "services/sno-installation/:id",
				name: RouteNames.SNO_INSTALLATION_SERVICE,
				redirect: { name: RouteNames.SNO_INSTALLATION_SERVICE_GENERAL },
				component: RmpSnoInstallationService,
				children: [
					{
						path: "",
						meta: { key: "sno-installation-service" },
						name: RouteNames.SNO_INSTALLATION_SERVICE_GENERAL,
						component: RmpSnoInstallationServiceGeneral
					},
					{
						path: "documents",
						meta: { key: "sno-installation-service" },
						name: RouteNames.SNO_INSTALLATION_SERVICE_DOCUMENTS,
						component: RmpSnoInstallationServiceDocuments
					},
					{
						path: "history",
						meta: { key: "sno-installation-service" },
						name: RouteNames.SNO_INSTALLATION_SERVICE_HISTORY,
						component: RmpSnoInstallationServiceHistory
					},
					{
						path: "comments",
						meta: { key: "sno-installation-service" },
						name: RouteNames.SNO_INSTALLATION_SERVICE_COMMENTS,
						component: RmpSnoInstallationServiceChat
					},
					{
						path: "feedback",
						meta: { key: "sno-installation-service" },
						name: RouteNames.SNO_INSTALLATION_SERVICE_FEEDBACK,
						component: RmpSnoInstallationServiceFeedback
					}
				]
			},
			{
				path: "services/geodetic-hydrographic/:id/sign-documents",
				name: RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_SIGN_DOCUMENTS,
				component: RmpSignGeodeticHydrographicServiceDocuments
			},
			{
				path: "services/geodetic-hydrographic/:id",
				name: RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE,
				redirect: { name: RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_GENERAL },
				component: RmpGeodeticHydrographicService,
				children: [
					{
						path: "",
						meta: { key: "geodetic-hydrographic-service" },
						name: RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_GENERAL,
						component: RmpGeodeticHydrographicServiceGeneral
					},
					{
						path: "documents",
						meta: { key: "geodetic-hydrographic-service" },
						name: RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_DOCUMENTS,
						component: RmpGeodeticHydrographicServiceDocuments
					},
					{
						path: "history",
						meta: { key: "geodetic-hydrographic-service" },
						name: RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_HISTORY,
						component: RmpGeodeticHydrographicServiceHistory
					},
					{
						path: "comments",
						meta: { key: "geodetic-hydrographic-service" },
						name: RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_COMMENTS,
						component: RmpGeodeticHydrographicServiceChat
					},
					{
						path: "feedback",
						meta: { key: "geodetic-hydrographic-service" },
						name: RouteNames.GEODETIC_HYDROGRAPHIC_SERVICE_FEEDBACK,
						component: RmpGeodeticHydrographicServiceFeedback
					}
				]
			}
		]
	},
	{
		path: "/",
		component: RmpEmptyLayout,
		children: [
			{
				path: "",
				component: RmpOuterLayout,
				children: [
					{
						path: "confirm-email",
						component: RmpConfirmEmail,
						name: RouteNames.CONFIRM_EMAIL
					},
					{
						path: "confirm-phone",
						component: RmpConfirmPhone,
						name: RouteNames.CONFIRM_PHONE
					},
					{
						path: "reset-password",
						component: RmpResetPassword,
						name: RouteNames.RESET_PASSWORD
					}
				]
			}
		]
	},
	{
		path: "/",
		component: RmpEmptyLayout,
		meta: { public: true },
		children: [
			{
				path: "internal-server-error",
				component: RmpInternalServerError,
				name: RouteNames.ERROR_INTERNAL_SERVER_ERROR
			},
			{
				path: "access-denied",
				component: RmpAccessDeniedError,
				name: RouteNames.ERROR_ACCESS_DENIED
			},
			{
				path: "*",
				component: RmpPageNotFound,
				name: RouteNames.ERROR_PAGE_NOT_FOUND
			}
		]
	}
];

const router = new VueRouter({
	routes,
	mode: "history",
	stringifyQuery: query => {
		// @ts-ignore
		let result = stringifyQuery(query);
		return result ? ("?" + result) : "";
	}
});

configureMiddleware(router);

export default router;
