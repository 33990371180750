import ApiCounterpartyBoatDocumentPackage from "@rmp/core/api/types/counterparty/boatApplication/apiCounterpartyBoatDocumentPackage";
import { ApiCounterpartyBoatApplication } from "@rmp/core/api/types/counterpartyBoatApplication/apiCounterpartyBoatApplication";

export interface BoatApplicationDetails {
	name: string;
	callSign: string;
	imo: string;
	mmsi?: string;
	flag: string;
	type: string;
	grt: number;
	exclusionGRT?: number;
	nrt: number;
	deadWeight: number;
	length: number;
	width: number;
	height: number;
	power: number;
	portOfRegistry: string;
	numberOfRegistry: string;
	yearOfBuilding?: number;
	status: string;
	shipowner: string;
	documentsPackage: ApiCounterpartyBoatDocumentPackage;
	counterpartyId: string;
	calculatedGRT?: number;
	precipitationMaxValue?: number;
}

export class BoatApplicationDetailsMapper {
	static map(source: ApiCounterpartyBoatApplication): BoatApplicationDetails {
		return {
			...source,
			...source.applicationBoat
		};
	}
}
