import { ApiCertificate } from "@rmp/core/api/types/signature/apiCertificate";

export class ApiFileMetaSignature {
	constructor(
		public signature: string = "",
		public certificates: ApiCertificate[],
		public createdAt: string
	)
	{
	}
}

export default class ApiFileMeta {
	constructor(
		public id: string = "",
		public name: string = "",
		public mimeType: string = "",
		public namespace: string = "",
		public signatures: ApiFileMetaSignature[])
	{
	}
}
