import ApiLegalEntityCounterpartyPersisted from "@rmp/core/api/types/counterparty/apiLegalEntityCounterpartyPersisted";
import {
    LegalEntityCounterpartyHead,
    LegalEntityCounterpartyHeadMapper
} from "@rmp/core/types/counterparty/legalEntity/legalEntityCounterpartyHead";
import { ApiLegalEntity } from "@rmp/core/api/types/legalEntity/apiLegalEntity";
import { parseISODate } from "@rmp/core/utils/dates";

export interface LegalEntityCounterparty {
    inn: string;
    ogrn: string;
    kpp: string;
    opf: string;
    longName: string;
    shortName: string;
    legalAddress: string;
    registrationDate: Date | null;
    description: string;
    heads: LegalEntityCounterpartyHead[];
}

export class LegalEntityCounterpartyMapper {
    static map(source: ApiLegalEntityCounterpartyPersisted): LegalEntityCounterparty {
        return {
            ...source.counterparty,
            registrationDate: parseISODate(source.counterparty.registrationDate),
            heads: source.counterparty.heads.length ? source.counterparty.heads.map((x, index) => ({
                ...LegalEntityCounterpartyHeadMapper.map(x), id: String(index)
            })) : []
        };
    }

    static mapFromApiLegalEntity(source: ApiLegalEntity): LegalEntityCounterparty {
        return {
            ...source,
            registrationDate: parseISODate(source.registrationDate),
            description: "",
            heads: source.heads.length ? source.heads.map((x, index) => ({
                ...LegalEntityCounterpartyHeadMapper.map(x), id: String(index)
            })) : []
        };
    }

    static getEmpty(): LegalEntityCounterparty {
        return {
            inn: "",
            ogrn: "",
            kpp: "",
            opf: "",
            longName: "",
            shortName: "",
            legalAddress: "",
            registrationDate: null,
            description: "",
            heads: []
        };
    }
}
