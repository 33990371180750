import { render, staticRenderFns } from "./RmpDetailsItem.vue?vue&type=template&id=9e20e40c&scoped=true&"
import script from "./RmpDetailsItem.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RmpDetailsItem.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./RmpDetailsItem.vue?vue&type=style&index=0&id=9e20e40c&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e20e40c",
  null
  
)

export default component.exports