// @ts-ignore
import { checkINN, checkSnils, checkOgrn, checkOgrnip } from "ru-validation-codes";
// @ts-ignore
import isKpp from "is-kpp-js";
import { KPP_FORMAT, LEGAL_ENTITY_INN_FORMAT, OGRN_FORMAT, OGRNIP_FORMAT, PERSON_INN_FORMAT } from "@rmp/core/utils/formats";
import { unmask } from "@rmp/core/utils/formatting";
import { isPhoneValid } from "@rmp/core/utils/phone";
import { isBIK, isCorrespondentAccount, isPaymentAccount } from "@utkonos/entrepreneur";
import { parseFloatNumber } from "@rmp/core/utils/number";

export const validatePersonInn = (value: string) => {
	if(!value) return false;
	
	return value.length === 12 && checkINN(value);
};

export const validatePersonMaskedInn = (value: string) => {
	if(!value) return false;
	
	let raw = unmask(value, PERSON_INN_FORMAT);
	
	return validatePersonInn(raw);
};

export const validateLegalEntityInn = (value: string) => {
	if(!value) return false;
	
	return value.length === 10 && checkINN(value);
};

export const validateLegalEntityMaskedInn = (value: string) => {
	if(!value) return false;
	
	let raw = unmask(value, LEGAL_ENTITY_INN_FORMAT);
	
	return validateLegalEntityInn(raw);
};

export const validateOgrn = (value: string) => {
	if(!value) return false;
	
	return checkOgrn(value);
};

export const validateOgrnip = (value: string) => {
	if(!value) return false;
	
	return checkOgrnip(value);
};

export const validateMaskedOgrn = (value: string) => {
	if(!value) return false;
	
	let raw = unmask(value, OGRN_FORMAT);
	
	return validateOgrn(raw);
};

export const validateMaskedOgrnip = (value: string) => {
	if(!value) return false;
	
	let raw = unmask(value, OGRNIP_FORMAT);
	
	return validateOgrnip(raw);
};

export const validateKpp = (value: string) => {
	if(!value) return false;
	
	return isKpp(value);
};

export const validateMaskedKpp = (value: string) => {
	if(!value) return false;
	
	let raw = unmask(value, KPP_FORMAT);
	
	return validateKpp(raw);
};

export const validateBankAccountNumber = (bankAccount: string, bankIdentificationCode: string) => {
	if(!bankAccount) return false;
	if(!bankIdentificationCode) return false;
	
	return isPaymentAccount(bankAccount, bankIdentificationCode);
};

export const validateCorrespondentAccount = (correspondentAccount: string, bankIdentificationCode: string) => {
	if(!correspondentAccount) return false;
	if(!bankIdentificationCode) return false;
	
	return isCorrespondentAccount(correspondentAccount, bankIdentificationCode);
};

export const validateBankIdentificationCode = (value: string) => {
	if(!value) return false;
	
	return isBIK(value);
};

export const validateEmail = (value: string) => {
	if(!value) return false;
	
	return /.+@.+\..+/i.test(value);
};

export const validatePhone = (value: string, country: string) => {
	if(!value) return false;
	
	return isPhoneValid({ value, country });
};

export const validateIMO = (value: string) => {
	return value.length === 6 || value.length === 7;
};
export const validateMMSI = (value: string) => {
	return value.length === 9;
};
export const validateMultipleOfHalf = (value: string) => {
	return /^[0-9]+([.,]?\d+)*$/.test(value) && parseFloatNumber(value) % 0.5 === 0;
};
