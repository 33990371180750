import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { BoatApplicationController } from "@rmp/counterparty/api/boatApplication";
import { useBoatApplicationsBreadcrumb } from "@rmp/counterparty/stores/boatApplications/composables/useBoatApplicationsBreadcrumb";
import { useBoatApplicationDetailsBreadcrumb } from "@rmp/counterparty/stores/boatApplicationDetails/composables/useBoatApplicationDetailsBreadcrumb";
import { BoatApplicationDetails, BoatApplicationDetailsMapper } from "@rmp/core/types/boatApplication/boatApplicationDetails";

const abortService = new AbortService();
const page = usePageStore(abortService);

const boatApplicationController = new BoatApplicationController(abortService);

export interface BoatApplicationDetailsState extends PageState {
	application: BoatApplicationDetails;
	id: string;
}

const getDefaultState = (): BoatApplicationDetailsState => {
	return {
		...page.getDefaultPageState(),
		application: {} as BoatApplicationDetails,
		id: ""
	};
};

export const useBoatApplicationDetailsStore = defineStore({
	id: "boat-application-details",
	state: (): BoatApplicationDetailsState => getDefaultState(),
	getters: {
		...page.getters,
		breadcrumbs(state: BoatApplicationDetailsState) {
			return [
				useBoatApplicationsBreadcrumb(),
				useBoatApplicationDetailsBreadcrumb(this.application.name)
			];
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.fetch();
		},
		async fetch() {
			try {
				let { counterpartyBoatApplication } = await boatApplicationController.getBoatApplication(this.id!);
				this.application = BoatApplicationDetailsMapper.map(counterpartyBoatApplication);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		}
	}
});
