import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { usePierServiceStore } from "@rmp/counterparty/stores/serviceDetails/pier";
import { usePierServiceDocumentsBreadcrumb } from "@rmp/counterparty/stores/serviceDetails/pier/documents/composables/usePierServiceDocumentsBreadcrumb";
import { ServiceController } from "@rmp/counterparty/api/service";
import { DocumentsState, useDocumentsStore } from "@rmp/counterparty/stores/composables/baseDocuments";
import { ServiceDocument, ServiceDocumentMapper } from "@rmp/core/types/services/serviceDocument";

const abortService = new AbortService();
const page = usePageStore(abortService);
const serviceController = new ServiceController(abortService);

const documentsStore = useDocumentsStore({ controller: serviceController, mapper: ServiceDocumentMapper });

export interface PierServiceDocumentsState extends PageState, DocumentsState<ServiceDocument> {
}

const getDefaultState = (): PierServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...documentsStore.getDefaultState()
	};
};

export const usePierServiceDocumentsStore = defineStore({
	id: "pier-service-documents",
	state: (): PierServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...documentsStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = usePierServiceStore();
			return [...breadcrumbs, usePierServiceDocumentsBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...documentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			const { title } = usePierServiceStore();
			
			this.id = id;
			
			await this.initializeDocumentsStore(title);
		}
	}
});
