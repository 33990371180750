export enum ApiServiceTariffsEnum {
	PER_MONTH = "PerMonth",
	PER_DAY = "PerDay",
	PER_HOUR = "PerHour",
	PER_CUBIC_METER = "PerCubicMeter"
}


export enum ApiSortedServiceTariffsEnum {
	PerMonth = 0,
	PerDay = 1,
	PerHour = 2,
	PerCubicMeter = 3
}
