import { ServiceApplicationAgreement } from "@rmp/counterparty/stores/serviceApplication/steps/agreement/types/serviceApplicationAgreement";
import { ApiAgreementPersisted } from "@rmp/core/api/types/agreement/apiAgreement";
import { AgreementDurationMapper } from "@rmp/core/types/agreement/agreementDuration";
import { convertToTimestamp } from "@rmp/core/utils/dates";

export default class ServiceApplicationAgreementMapper {
	mapAgreement(source: ApiAgreementPersisted): ServiceApplicationAgreement {
		return {
			id: source.id,
			agreementNumber: source.agreement.agreementNumber,
			agreementDuration: source.agreement.agreementDuration && AgreementDurationMapper.map(source.agreement.agreementDuration),
			balance: source.agreement.amount,
			minAdvanceBalance: source.agreement.minAdvanceBalance,
			state: source.agreement.state,
			createdAt: convertToTimestamp(source.createdAt) as number,
		};
	}
}
