import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useServicesBreadcrumb } from "@rmp/counterparty/stores/services/composables/useServicesBreadcrumb";
import { BaseServiceState, useBaseServiceStore } from "@rmp/counterparty/stores/composables/baseService";
import { InformationServiceController } from "@rmp/counterparty/api/service/information";
import {
	InformationServiceApplication,
	InformationServiceApplicationMapper
} from "@rmp/core/types/services/information/informationServiceApplication";
import { useInformationServiceBreadcrumb } from "@rmp/counterparty/stores/serviceDetails/information/composables/useInformationServiceBreadcrumb";
import { formatServiceTitle } from "@rmp/core/utils/formatting";

const abortService = new AbortService();
const page = usePageStore(abortService);
const baseServiceStore = useBaseServiceStore({ abortService });

const informationServiceController = new InformationServiceController(abortService);

export interface InformationServiceState extends PageState, BaseServiceState {
	service: InformationServiceApplication;
}

const getDefaultState = (): InformationServiceState => {
	return {
		...page.getDefaultPageState(),
		...baseServiceStore.getDefaultState(),
		service: {} as InformationServiceApplication
	};
};

export const useInformationServiceStore = defineStore({
	id: "information-service",
	state: (): InformationServiceState => getDefaultState(),
	getters: {
		...page.getters,
		...baseServiceStore.getters,
		breadcrumbs(state: InformationServiceState) {
			return [
				useServicesBreadcrumb(),
				useInformationServiceBreadcrumb(state.title)
			];
		}
	},
	actions: {
		...page.actions,
		...baseServiceStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.fetch();
			
			await this.initializeBaseServiceStore(this.service.type);
		},
		async fetch() {
			try {
				let serviceApplication = await informationServiceController.getService(this.id!);
				
				this.service = InformationServiceApplicationMapper.map(serviceApplication);
				this.title = formatServiceTitle(this.service.type, this.service.applicationNumber);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		}
	}
});
