import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useAppStore } from "@rmp/core/stores/appStore";
import { cloneDeep } from "lodash";
import { ApiDepartment } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { ApiPort } from "@rmp/core/api/types/divisions/port/apiPort";
import { DivisionController } from "@rmp/counterparty/api/division";
import { DredgingServiceApplication } from "@rmp/core/types/services/dredging/dredgingServiceApplication";
import { useDredgingServiceStore } from "@rmp/counterparty/stores/serviceDetails/dredging";
import { ServiceDocument, ServiceDocumentMapper } from "@rmp/core/types/services/serviceDocument";
import { ServiceController } from "@rmp/counterparty/api/service";

const abortService = new AbortService();
const page = usePageStore(abortService);

const divisionController = new DivisionController(abortService);
const serviceController = new ServiceController(abortService);

export interface ServiceDetailsGeneralState extends PageState {
	id: string | null;
	service: DredgingServiceApplication;
	port: ApiPort;
	department: ApiDepartment;
	documents: ServiceDocument[];
}

const getDefaultState = (): ServiceDetailsGeneralState => {
	return {
		...page.getDefaultPageState(),
		id: null,
		service: {} as DredgingServiceApplication,
		department: {} as ApiDepartment,
		port: {} as ApiPort,
		documents: []
	};
};

export const useDredgingServiceGeneralStore = defineStore({
	id: "dredging-service-details-general",
	state: (): ServiceDetailsGeneralState => getDefaultState(),
	getters: {
		...page.getters,
		breadcrumbs() {
			const { breadcrumbs } = useDredgingServiceStore();
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.fetch();
		},
		async fetch() {
			try {
				let { service, title } = useDredgingServiceStore();
				
				const [{ division: department }, { division: port }, { documents }] = await Promise.all(
					[
						divisionController.getDepartment(service.divisionId),
						divisionController.getPort(service.portId),
						serviceController.getDocuments(service.id)
					]);				
								
				this.department = department;
				this.port = port;
				this.documents = documents.map(x => ServiceDocumentMapper.map(x, title));
				this.service = cloneDeep(service);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		}
	}
});
