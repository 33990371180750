import { forOwn, isArray, isObject } from "lodash";
import TreeModel from "tree-model";
import urlcat from "urlcat";

export const processApiPayload = (payload: any) => {
	for (let property in payload) {
		if(payload.hasOwnProperty(property)) {
			let value = payload[property];
			if(value === "") {
				delete payload[property];
			} else if(isObject(value)) {
				processApiPayload(value);
			} else if(isArray(value)) {
				value.forEach((el) => {
					if(isObject(el)) {
						processApiPayload(el);
					}
				});
			}
		}
	}
};

export const filterApiPayload = (payload: any, values: any[]) => {
	for (let property in payload) {
		if(payload.hasOwnProperty(property)) {
			let value = payload[property];
			if(values.includes(value)) {
				delete payload[property];
			} else if(isObject(value)) {
				processApiPayload(value);
			} else if(isArray(value)) {
				value.forEach((el) => {
					if(isObject(el)) {
						processApiPayload(el);
					}
				});
			}
		}
	}
};


export interface Node {
	pointer: object;
	children: Node[];
}

export type ApiSegment = {
	path: string;
}

export const traverse = (obj: object, node: Node) => {
	forOwn(obj, (val: object, key) => {
		if(key !== "path")
		{
			const branch: Node = {
				pointer: val,
				children: []
			};
			
			node.children.push(branch);
			
			traverse(val, branch);
		}
	});
};

export const createUrlsTree: (urls: object) => TreeModel.Node<Node> = (urls: object) => {
	const root: Node = {
		pointer: { path: "" },
		children: []
	};
	
	traverse(urls, root);
	
	const tree = new TreeModel();
	return tree.parse<Node>(root);
};


export const resolveUrl = (baseUrl: string, urlsTree: TreeModel.Node<Node>, pointer: object, params: Record<string, any>) => {
	const portNode = urlsTree.first(x => x.model.pointer === pointer)!;
	
	const path = portNode.getPath().map(x => x.model.pointer.path).join("/");
	
	return urlcat(baseUrl, path, params);
};
