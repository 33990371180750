import { storeToRefs } from "pinia";
import { computed } from "vue";
import { RegisterStoreDefinition } from "@rmp/core/stores/composables/register/useRegisterStore";


export interface PaginationOptions {
	useStore: RegisterStoreDefinition;
}

export function useRegisterPagination(options: PaginationOptions) {
	const store = options.useStore();
	const { paging } = storeToRefs(store);
	
	const page = computed({
		get() {
			return paging.value.page;
		},
		set(value: number) {
			store.setPagingPage(value);
		}
	});
	
	const footerOptions = {
		itemsPerPageOptions: [
			5,
			10,
			15,
			50,
			-1
		]
	};
	
	return {
		paging, page, footerOptions
	};
}
