import { ApiAgreementDocument } from "@rmp/core/api/types/agreement/apiAgreementDocument";
import AgreementDocumentKindMapper, { AgreementDocumentKindEnum } from "@rmp/core/types/agreement/agreementDocumentKindEnum";
import { i18n } from "@rmp/core/plugins";
import { BaseDocument } from "@rmp/core/types/services/baseDocument";

export interface AgreementDocument extends BaseDocument {
	documentKind: AgreementDocumentKindEnum;
}

export class AgreementDocumentMapper {
	static map(source: ApiAgreementDocument, titleFirstPart: string): AgreementDocument {
		const documentKind = AgreementDocumentKindMapper.mapAgreementDocumentKind(source.documentKind);
		
		return {
			...source,
			isSigning: false,
			documentKind,
			fileName: `${titleFirstPart} - ${i18n.t(`agreement.document.${documentKind}`)}`
		}
	}
}
