import InvalidArgumentException from "@rmp/core/exceptions/invalidArgumentException";
import { AgreementDocumentKindEnum } from "@rmp/core/types/agreement/agreementDocumentKindEnum";

export enum ApiAgreementDocumentKindEnum {
	// Устав организации
	ORGANIZATION_CHARTER = "OrganizationCharter",
	
	// Приказ о назначении руководителя
	HEAD_APPOINTMENTS_ORDER = "HeadAppointmentOrder",
	
	// Учредительный договор
	FOUNDATION_AGREEMENT = "FoundationAgreement",
	
	// Договор
	AGREEMENT = "Agreement",
	
	// Лист согласования
	AGREEMENT_NEGOTIATION_LIST = "AgreementNegotiationList"
}

export default class ApiAgreementDocumentKindMapper {
	static mapApiAgreementDocumentKind(source: AgreementDocumentKindEnum): ApiAgreementDocumentKindEnum {
		switch (source) {
			case AgreementDocumentKindEnum.ORGANIZATION_CHARTER:
				return ApiAgreementDocumentKindEnum.ORGANIZATION_CHARTER;
			case AgreementDocumentKindEnum.HEAD_APPOINTMENTS_ORDER:
				return ApiAgreementDocumentKindEnum.HEAD_APPOINTMENTS_ORDER;
			case AgreementDocumentKindEnum.FOUNDATION_AGREEMENT:
				return ApiAgreementDocumentKindEnum.FOUNDATION_AGREEMENT;
			case AgreementDocumentKindEnum.AGREEMENT_NEGOTIATION_LIST:
				return ApiAgreementDocumentKindEnum.AGREEMENT_NEGOTIATION_LIST;
			case AgreementDocumentKindEnum.AGREEMENT:
				return ApiAgreementDocumentKindEnum.AGREEMENT;
			default:
				throw new InvalidArgumentException("source", source);
		}
	}
}
