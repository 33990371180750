import { render, staticRenderFns } from "./RmpTabs.vue?vue&type=template&id=53b8d718&scoped=true&"
import script from "./RmpTabs.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RmpTabs.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53b8d718",
  null
  
)

export default component.exports