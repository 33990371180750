import { ApiPersistedBase } from "@rmp/core/api/types/base/apiPersistedBase";
import ApiCounterpartyPersistedBase from "@rmp/core/api/types/counterparty/apiCounterpartyPersistedBase";
import ApiCounterpartyBoatDocumentPackage from "@rmp/core/api/types/counterparty/boatApplication/apiCounterpartyBoatDocumentPackage";
import { BoatApplication } from "@rmp/core/types/boatApplication/boatApplication";
import { parseFloatNumber } from "@rmp/core/utils/number";

export interface ApiApplicationBoat {
	name: string;
	callSign: string;
	imo: string;
	mmsi?: string;
	flag: string;
	grt: number;
	exclusionGRT?: number;
	nrt: number;
	deadWeight: number;
	length: number;
	width: number;
	height: number;
	power: number;
	portOfRegistry: string;
	numberOfRegistry: string;
	yearOfBuilding?: number;
	precipitationMaxValue?: number;
	calculatedGRT?: number;
	type: string;
	shipowner: string;
	documentsPackage: ApiCounterpartyBoatDocumentPackage;
}

export class ApiApplicationBoatMapper {
	static map(source: BoatApplication): ApiApplicationBoat {
		return {
			...source,
			grt: parseFloatNumber(source.grt),
			power: parseFloatNumber(source.power),
			exclusionGRT: source.exclusionGRT ? parseFloatNumber(source.exclusionGRT) : undefined,
			deadWeight: parseFloatNumber(source.deadWeight),
			length: parseFloatNumber(source.length),
			width: parseFloatNumber(source.width),
			height: parseFloatNumber(source.height),
			yearOfBuilding: source.yearOfBuilding ? +source.yearOfBuilding : undefined,
			nrt: parseFloatNumber(source.nrt),
			calculatedGRT: source.calculatedGRT ? parseFloatNumber(source.calculatedGRT) : undefined,
			precipitationMaxValue: source.precipitationMaxValue ? parseFloatNumber(source.precipitationMaxValue) : undefined
		};
	}
}

export interface ApiCounterpartyBoatApplication {
	status: string;
	applicationBoat: ApiApplicationBoat;
	counterpartyId: string;
	counterparty: ApiCounterpartyPersistedBase;
}

export interface ApiCounterpartyBoatApplicationPersisted extends ApiPersistedBase {
	counterpartyBoatApplication: ApiCounterpartyBoatApplication;
}
