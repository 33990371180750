import Vue from "vue";
// @ts-ignore
import ruLang from "vuetify/es5/locale/ru";
import coreLocales from "@rmp/core/plugins/i18n/locales";
import pluralizationRules from "@rmp/core/plugins/i18n/rules/pluralization";
import deepmerge from "deepmerge";

import VueI18n from "vue-i18n";
import { createI18n } from "vue-i18n-composable";

Vue.use(VueI18n);

const RU_LOCALE_ALIAS = "ru";
const EN_LOCALE_ALIAS = "en";

const createVueI18nInstance = (locales: any) => {
	let mergedLocales = deepmerge(coreLocales, locales);
	
	const messages = {
		ru: Object.assign({}, mergedLocales[RU_LOCALE_ALIAS], ruLang)
	};
	
	return createI18n({
		locale: RU_LOCALE_ALIAS,
		fallbackLocale: EN_LOCALE_ALIAS,
		messages: messages,
		pluralizationRules
	});
};

export default createVueI18nInstance;
