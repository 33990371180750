import { render, staticRenderFns } from "./RmpMonthsTariff.vue?vue&type=template&id=0dc05419&scoped=true&"
import script from "./RmpMonthsTariff.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RmpMonthsTariff.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dc05419",
  null
  
)

export default component.exports