import { useAlertsStore } from "@rmp/core/stores/alerts";

export enum AlertKeys {
	OFFLINE_REQUEST_ERROR,
	SERVER_UNAVAILABLE_ERROR,
	REQUEST_UNKNOWN_ERROR,
	SUCCESS_CREATED_INFO,
	SUCCESS_UPDATED_INFO,
	PROFILE_ALREADY_ACTIVATED,
	PROFILE_ACTIVATED,
	PROFILE_DEACTIVATED,
	ACCOUNT_ACTIVATED,
	ACCOUNT_DEACTIVATED,
	MASTER_ACCOUNT_ACCESS_APPLICATION_APPROVED,
	MASTER_ACCOUNT_ACCESS_APPLICATION_REJECTED,
	BOAT_APPLICATION_APPROVED,
	BOAT_APPLICATION_DECLINED,
	EMPLOYEE_JOIN_APPLICATION_CREATED,
	EMPLOYEE_JOIN_APPLICATION_APPROVED,
	EMPLOYEE_JOIN_APPLICATION_DECLINED,
	TRUST_ACCEPTED,
	TRUST_DECLINED,
	TRUST_ACCEPT_CANCELED,
	EMPLOYEE_PROFILE_ALREADY_ACTIVATED,
	PASSWORD_RESET_SUCCESSFULLY,
	EMPLOYEE_SUCCESS_CREATED,
	EMPLOYEE_SUCCESS_INVITED,
	COUNTERPARTY_SUCCESS_UPDATED_VIA_FNS,
	DRAFT_SUCCESS_CREATED,
	DRAFT_SUCCESS_SAVED,
	VERSION_SUCCESS_ACTIVATED,
	VERSION_SUCCESS_CREATED,
	TRANCHE_SUCCESS_CREATED,
	TRANCHE_SUCCESS_DELETED,
	TRANCHES_SUCCESS_SAVED,
	OVERDUE_SUCCESS_CREATED,
	OVERDUE_SUCCESS_DELETED,
	OVERDUES_SUCCESS_SAVED,
	SCHEDULE_ITEM_SUCCESS_CREATED,
	SCHEDULE_ITEM_SUCCESS_DELETED,
	SCHEDULE_ITEMS_SUCCESS_SAVED,
	OVERDUE_INTEREST_SUCCESS_CREATED,
	OVERDUE_INTEREST_SUCCESS_DELETED,
	OVERDUE_INTEREST_SUCCESS_SAVED,
	PENALTY_INTEREST_SUCCESS_CREATED,
	PENALTY_INTEREST_SUCCESS_DELETED,
	PENALTY_INTEREST_SUCCESS_SAVED,
	ACCRUED_INTEREST_ITEM_SUCCESS_CREATED,
	ACCRUED_INTEREST_ITEM_SUCCESS_DELETED,
	ACCRUED_INTEREST_ITEM_SUCCESS_SAVED,
	ACCRUED_INTEREST_ITEMS_SUCCESS_UPDATED,
	CASH_FLOW_ITEM_SUCCESS_CREATED,
	CASH_FLOW_ITEM_SUCCESS_DELETED,
	CASH_FLOW_ITEM_SUCCESS_UPDATED,
	SNILS_RECOGNIZE_SUCCESS,
	SNILS_RECOGNIZE_ERROR,
	PHONE_CONFIRMATION_REQUEST_SUCCESS_CREATED,
	CHANGES_SUCCESS_SAVED,
	EXTRA_RATE_SUCCESS_CHARGED,
	UPLOAD_TREASURY_STATEMENT_SUCCESS,
	HANDLE_TREASURY_STATEMENT_SUCCESS,
	NOTE_SUCCESS_CREATED,
	NOTE_SUCCESS_UPDATED,
	NOTE_SUCCESS_DELETED,
	TREASURY_STATEMENT_IMPORT_CANCELLED,
	PROJECT_SUCCESS_UPDATED,
	DEBTOR_SUCCESS_CREATED,
	DEBTOR_SUCCESS_DELETED,
	STATUS_SUCCESS_UPDATED,
	COMMENT_SUCCESS_UPDATED,
	LOAN_PERMISSIONS_NOT_FOUND,
	SUCCESS_COPIED_LINK,
	COPY_LINK_ERROR,
	UPDATE_DOCUMENT_REQUEST_CREATED,
	UNPUBLISH_DOCUMENT_REQUEST_CREATED,
	CREATE_DOCUMENT_REQUEST_CREATED,
	REQUEST_ACCEPTED,
	REQUEST_REJECTED,
	ACCOUNT_NOT_FOUND_ERROR,
	CHAT_MESSAGE_SUCCESS_CREATED,
	SERVICE_APPROVED,
	SERVICE_DECLINED,
	SERVICE_APPLICATION_CREATED,
	FEEDBACK_SENT,
	TUGBOATS_ASSIGNED,
	CREWBOATS_ASSIGNED,
	FLOATING_CRANES_ASSIGNED,
	TARIFFS_ASSIGNED,
	PIERS_ASSIGNED,
	DOCUMENTS_SAVED,
	DOCUMENTS_SENT,
	SERVICE_COMPLETED,
	SERVICE_STATUS_UPDATED,
	BRANCH_STATUS_UPDATED,
	DEPARTMENT_STATUS_UPDATED,
	PORT_STATUS_UPDATED,
	AGREEMENT_CREATED,
	AGREEMENT_STATUS_UPDATED,
	AGREEMENT_DECLINED,
	PAGE_NOT_FOUND,
	TARIFFS_CHANGED,
	ACCESS_FORBIDDEN,
	WRONG_CODE
}

const alertTextMap = {
	[AlertKeys.REQUEST_UNKNOWN_ERROR]: "Возникла непредвиденная ошибка.",
	[AlertKeys.SERVER_UNAVAILABLE_ERROR]: "Сервис временно недоступен. Повторите попытку позже.",
	[AlertKeys.OFFLINE_REQUEST_ERROR]: "Отсутствует интернет-соединение. Проверьте подключение и повторите попытку.",
	[AlertKeys.SUCCESS_CREATED_INFO]: "Данные успешно сохранены.",
	[AlertKeys.SUCCESS_UPDATED_INFO]: "Данные успешно обновлены",
	[AlertKeys.PROFILE_ALREADY_ACTIVATED]: "Не удалось активировать профиль. Профиль был активирован ранее.",
	[AlertKeys.PROFILE_ACTIVATED]: "Профиль успешно активирован.",
	[AlertKeys.PROFILE_DEACTIVATED]: "Профиль успешно деактивирован.",
	[AlertKeys.ACCOUNT_ACTIVATED]: "Аккаунт успешно активирован.",
	[AlertKeys.ACCOUNT_DEACTIVATED]: "Аккаунт успешно деактивирован.",
	[AlertKeys.MASTER_ACCOUNT_ACCESS_APPLICATION_APPROVED]: "Заявка на получение доступа к мастер-аккаунту успешно одобрена.",
	[AlertKeys.MASTER_ACCOUNT_ACCESS_APPLICATION_REJECTED]: "Заявка на получение доступа к мастер-аккаунту успешно отклонена.",
	[AlertKeys.BOAT_APPLICATION_APPROVED]: "Право на распоряжение судном успешно одобрено.",
	[AlertKeys.BOAT_APPLICATION_DECLINED]: "Право на распоряжение судном успешно отклонено.",
	[AlertKeys.EMPLOYEE_JOIN_APPLICATION_CREATED]: "Заявка на присоединение к организации отправлена.",
	[AlertKeys.EMPLOYEE_JOIN_APPLICATION_APPROVED]: "Заявка на присоединение к организации успешно одобрена.",
	[AlertKeys.EMPLOYEE_JOIN_APPLICATION_DECLINED]: "Заявка на присоединение к организации успешно отклонена.",
	[AlertKeys.TRUST_ACCEPTED]: "Доверенность акцептована.",
	[AlertKeys.TRUST_DECLINED]: "Доверенность отклонена.",
	[AlertKeys.TRUST_ACCEPT_CANCELED]: "Акцепт с доверенности снят.",
	[AlertKeys.EMPLOYEE_PROFILE_ALREADY_ACTIVATED]: "Профиль сотрудника уже был активирован.",
	[AlertKeys.PASSWORD_RESET_SUCCESSFULLY]: "Пароль успешно сброшен.",
	[AlertKeys.EMPLOYEE_SUCCESS_CREATED]: "Сотрудник был успешно добавлен.",
	[AlertKeys.EMPLOYEE_SUCCESS_INVITED]: "Письмо с приглашением направлено на указанную почту.",
	[AlertKeys.COUNTERPARTY_SUCCESS_UPDATED_VIA_FNS]: "Данные успешно обновлены из Контур.Фокус.",
	[AlertKeys.DRAFT_SUCCESS_CREATED]: "Черновик был успешно создан.",
	[AlertKeys.DRAFT_SUCCESS_SAVED]: "Черновик успешно сохранен.",
	[AlertKeys.VERSION_SUCCESS_ACTIVATED]: "Версия успешно активирована.",
	[AlertKeys.VERSION_SUCCESS_CREATED]: "Версия успешно создана.",
	[AlertKeys.TRANCHE_SUCCESS_CREATED]: "Новая запись блока Траншевое финансирование успешно добавлена.",
	[AlertKeys.TRANCHE_SUCCESS_DELETED]: "Запись блока Траншевое финансирование успешно удалена.",
	[AlertKeys.TRANCHES_SUCCESS_SAVED]: "Данные блока Траншевое финансирование успешно сохранены.",
	[AlertKeys.OVERDUE_SUCCESS_CREATED]: "Новая запись блока Просроченная задолженность успешно добавлена.",
	[AlertKeys.OVERDUE_SUCCESS_DELETED]: "Запись блока Просроченная задолженность успешно удалена.",
	[AlertKeys.OVERDUES_SUCCESS_SAVED]: "Данные блока Просроченная задолженность успешно сохранены.",
	[AlertKeys.SCHEDULE_ITEM_SUCCESS_CREATED]: "Новая запись блока График возврата займа успешно добавлена.",
	[AlertKeys.SCHEDULE_ITEM_SUCCESS_DELETED]: "Запись блока График возврата займа успешно удалена.",
	[AlertKeys.SCHEDULE_ITEMS_SUCCESS_SAVED]: "Данные блока График возврата займа успешно сохранены.",
	[AlertKeys.OVERDUE_INTEREST_SUCCESS_CREATED]: "Новая запись блока Просроченная задолженность по процентам успешно добавлена.",
	[AlertKeys.OVERDUE_INTEREST_SUCCESS_DELETED]: "Запись блока Просроченная задолженность по процентам успешно удалена.",
	[AlertKeys.OVERDUE_INTEREST_SUCCESS_SAVED]: "Данные блока Просроченная задолженность по процентам успешно сохранены.",
	[AlertKeys.PENALTY_INTEREST_SUCCESS_CREATED]: "Новая запись блока Реестр начисленных штрафов/пеней успешно добавлена.",
	[AlertKeys.PENALTY_INTEREST_SUCCESS_DELETED]: "Запись блока Реестр начисленных штрафов/пеней успешно удалена.",
	[AlertKeys.PENALTY_INTEREST_SUCCESS_SAVED]: "Данные блока Реестр начисленных штрафов/пеней успешно сохранены.",
	[AlertKeys.ACCRUED_INTEREST_ITEM_SUCCESS_CREATED]: "Новая запись блока Реестр начисленных процентов успешно добавлена.",
	[AlertKeys.ACCRUED_INTEREST_ITEM_SUCCESS_DELETED]: "Запись блока Реестр начисленных процентов успешно удалена.",
	[AlertKeys.ACCRUED_INTEREST_ITEM_SUCCESS_SAVED]: "Запись блока Реестр начисленных процентов успешно сохранена.",
	[AlertKeys.ACCRUED_INTEREST_ITEMS_SUCCESS_UPDATED]: "Массовое редактирование блока Реестр начисленных процентов выполнено успешно.",
	[AlertKeys.CASH_FLOW_ITEM_SUCCESS_CREATED]: "Новая запись блока Реестр движения денежных средств успешно добавлена.",
	[AlertKeys.CASH_FLOW_ITEM_SUCCESS_DELETED]: "Запись блока Реестр движения денежных средств успешно удалена.",
	[AlertKeys.CASH_FLOW_ITEM_SUCCESS_UPDATED]: "Данные блока Реестр движения денежных средств по процентам успешно сохранены.",
	[AlertKeys.SNILS_RECOGNIZE_SUCCESS]: "Распознавание СНИЛС прошло успешно.",
	[AlertKeys.SNILS_RECOGNIZE_ERROR]: "Не удалось распознать СНИЛС.",
	[AlertKeys.PHONE_CONFIRMATION_REQUEST_SUCCESS_CREATED]: "Запрос на подтверждение номера телефона успешно сформирован.",
	[AlertKeys.CHANGES_SUCCESS_SAVED]: "Изменения успешно сохранены.",
	[AlertKeys.EXTRA_RATE_SUCCESS_CHARGED]: "Дополнительные проценты начислены.",
	[AlertKeys.UPLOAD_TREASURY_STATEMENT_SUCCESS]: "Импортирование выписки прошло успешно.",
	[AlertKeys.HANDLE_TREASURY_STATEMENT_SUCCESS]: "Обработка выписки прошла успешно.",
	[AlertKeys.NOTE_SUCCESS_CREATED]: "Запись успешно создана.",
	[AlertKeys.NOTE_SUCCESS_UPDATED]: "Запись успешно сохранена.",
	[AlertKeys.NOTE_SUCCESS_DELETED]: "Запись успешно удалена.",
	[AlertKeys.TREASURY_STATEMENT_IMPORT_CANCELLED]: "Запрос на импорт выписки УФК отменен.",
	[AlertKeys.PROJECT_SUCCESS_UPDATED]: "Проект успешно изменен.",
	[AlertKeys.DEBTOR_SUCCESS_CREATED]: "Должник успешно создан.",
	[AlertKeys.DEBTOR_SUCCESS_DELETED]: "Должник успешно удален.",
	[AlertKeys.STATUS_SUCCESS_UPDATED]: "Статус успешно изменен.",
	[AlertKeys.COMMENT_SUCCESS_UPDATED]: "Комментарий успешно изменен.",
	[AlertKeys.LOAN_PERMISSIONS_NOT_FOUND]: "Не удалось получить список прав доступа.",
	[AlertKeys.SUCCESS_COPIED_LINK]: "Ссылка успешно скопирована.",
	[AlertKeys.COPY_LINK_ERROR]: "Не удалось скопировать ссылку.",
	[AlertKeys.UPDATE_DOCUMENT_REQUEST_CREATED]: "Заявка на изменение документа отправлена.",
	[AlertKeys.UNPUBLISH_DOCUMENT_REQUEST_CREATED]: "Заявка на снятие документа с публикации отправлена.",
	[AlertKeys.CREATE_DOCUMENT_REQUEST_CREATED]: "Заявка на создание документа отправлена.",
	[AlertKeys.REQUEST_ACCEPTED]: "Заявка успешно одобрена.",
	[AlertKeys.REQUEST_REJECTED]: "Заявка успешно отклонена.",
	[AlertKeys.ACCOUNT_NOT_FOUND_ERROR]: "Неверный логин или пароль. Повторите попытку или нажмите на ссылку \"Восстановить доступ\"",
	[AlertKeys.CHAT_MESSAGE_SUCCESS_CREATED]: "Сообщение успешно добавлено.",
	[AlertKeys.SERVICE_APPROVED]: "Услуга успешно одобрена.",
	[AlertKeys.SERVICE_DECLINED]: "Услуга успешно отклонена.",
	[AlertKeys.SERVICE_APPLICATION_CREATED]: "Заявка на оказание услуг успешно создана.",
	[AlertKeys.FEEDBACK_SENT]: "Отзыв успешно отправлен.",
	[AlertKeys.TUGBOATS_ASSIGNED]: "Буксиры успешно назначены.",
	[AlertKeys.CREWBOATS_ASSIGNED]: "Разъездные суда успешно назначены.",
	[AlertKeys.FLOATING_CRANES_ASSIGNED]: "Плавкраны успешно назначены.",
	[AlertKeys.PIERS_ASSIGNED]: "Причалы успешно назначены.",
	[AlertKeys.TARIFFS_ASSIGNED]: "Тарифы успешно назначены.",
	[AlertKeys.DOCUMENTS_SAVED]: "Документы успешно сохранены.",
	[AlertKeys.DOCUMENTS_SENT]: "Документы успешно отправлены.",
	[AlertKeys.SERVICE_COMPLETED]: "Услуги завершены.",
	[AlertKeys.SERVICE_STATUS_UPDATED]: "Статус услуги успешно изменен.",
	[AlertKeys.BRANCH_STATUS_UPDATED]: "Статус филиала успешно изменен.",
	[AlertKeys.DEPARTMENT_STATUS_UPDATED]: "Статус управления успешно изменен.",
	[AlertKeys.PORT_STATUS_UPDATED]: "Статус порта успешно изменен.",
	[AlertKeys.AGREEMENT_CREATED]: "Договор успешно создан.",
	[AlertKeys.AGREEMENT_STATUS_UPDATED]: "Статус договора успешно изменен.",
	[AlertKeys.AGREEMENT_DECLINED]: "Договор успешно отклонен.",
	[AlertKeys.PAGE_NOT_FOUND]: "Запрашиваемый вами ресурс не существует или у вас нет к нему доступа.",
	[AlertKeys.TARIFFS_CHANGED]: "Тарифы успешно изменены.",
	[AlertKeys.ACCESS_FORBIDDEN]: "Отсутствуют права доступа для выполнения операции.",
	[AlertKeys.WRONG_CODE]: "Код был введен неверно"
};


class AlertService {
	
	addError(key: AlertKeys) {
		const alertsStore = useAlertsStore();
		alertsStore.addErrorAlert(alertTextMap[key]);
	}

	addSuccess(key: AlertKeys) {
		const alertsStore = useAlertsStore();
		alertsStore.addSuccessAlert(alertTextMap[key]);
	}

	addInfo(key: AlertKeys) {
		const alertsStore = useAlertsStore();
		alertsStore.addInfoAlert(alertTextMap[key]);
	}

	addCustomError(text: string) {
		const alertsStore = useAlertsStore();
		alertsStore.addErrorAlert(text);
	}

	addCustomSuccess(text: string) {
		const alertsStore = useAlertsStore();
		alertsStore.addSuccessAlert(text);
	}

	addCustomInfo(text: string) {
		const alertsStore = useAlertsStore();
		alertsStore.addInfoAlert(text);
	}
}

export default new AlertService();
