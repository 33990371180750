import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useServicesBreadcrumb } from "@rmp/counterparty/stores/services/composables/useServicesBreadcrumb";
import { useTugboatServiceBreadcrumb } from "@rmp/counterparty/stores/serviceDetails/tugboat/composables/useTugboatServiceBreadcrumb";
import {
	useUploadTugboatServiceDocumentsBreadcrumb
} from "@rmp/counterparty/stores/uploadServiceDocuments/tugboat/composables/useUploadTugboatServiceDocumentsBreadcrumb";
import { TugboatServiceController } from "@rmp/counterparty/api/service/tugboat";
import { ServiceWorkflowStateType } from "@rmp/core/types/services/ServiceWorkflowStateType";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/counterparty/router";
import { RouteNames } from "@rmp/counterparty/router/routes";
import { TugboatServiceApplication, TugboatServiceApplicationMapper } from "@rmp/core/types/services/tugboat/tugboatServiceApplication";
import { ApiPropertyServiceTransitionCodeEnum } from "@rmp/core/api/types/service/ApiPropertyServiceTransitionCodeEnum";
import TugboatServiceApplicationWorkflowService from "@rmp/core/services/serviceApplication/tugboatServiceApplicationWorkflowService";
import {
	BaseSignServiceDocumentsState,
	useBaseSignServiceDocumentsStore
} from "@rmp/counterparty/stores/composables/baseSignServiceDocuments";
import { formatServiceTitle } from "@rmp/core/utils/formatting";
import { ServiceController } from "@rmp/counterparty/api/service";
import { ServiceDocument, ServiceDocumentMapper } from "@rmp/core/types/services/serviceDocument";

const abortService = new AbortService();
const page = usePageStore(abortService);

const tugboatServiceController = new TugboatServiceController(abortService);
const serviceController = new ServiceController(abortService);

const baseSignServiceDocumentsStore = useBaseSignServiceDocumentsStore<ServiceDocument>({
	abortService,
	controller: serviceController,
	mapper: ServiceDocumentMapper
});

const tugboatWorkflowService = new TugboatServiceApplicationWorkflowService();

export interface UploadTugboatServiceDocumentsState extends PageState, BaseSignServiceDocumentsState<ServiceDocument> {
	service: TugboatServiceApplication;
}

const getDefaultState = (): UploadTugboatServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...baseSignServiceDocumentsStore.getDefaultState(),
		service: {} as TugboatServiceApplication
	};
};

export const useUploadTugboatServiceDocumentsStore = defineStore({
	id: "upload-tugboat-service-documents",
	state: (): UploadTugboatServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...baseSignServiceDocumentsStore.getters,
		breadcrumbs(state: UploadTugboatServiceDocumentsState) {
			return [
				useServicesBreadcrumb(),
				useTugboatServiceBreadcrumb(state.title, { id: state.service.id }),
				useUploadTugboatServiceDocumentsBreadcrumb()
			];
		}
	},
	actions: {
		...page.actions,
		...baseSignServiceDocumentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			
			await Promise.all([
				this.fetchServiceApplication(id),
				this.fetchDocuments(id),
				this.verifySigningAuthority()
			]);
		},
		async fetchServiceApplication(serviceId: string) {
			try {
				let serviceApplication = await tugboatServiceController.getService(serviceId);
				
				this.service = TugboatServiceApplicationMapper.map(serviceApplication);
				this.title = formatServiceTitle(this.service.type, this.service.applicationNumber);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async approve() {
			this.approving = true;
			
			try {
				await tugboatServiceController.updateStatusByTransition(this.id!,
					tugboatWorkflowService.getTransition(this.service.workflowState.alias,
						ServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY) as ApiPropertyServiceTransitionCodeEnum);
				
				this.service.workflowState.alias = ServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY;
				
				alertService.addSuccess(AlertKeys.DOCUMENTS_SENT);
				
				await router.push({ name: RouteNames.TUGBOAT_SERVICE, params: { id: this.id } });
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.approving = false;
			}
		}
	}
});
