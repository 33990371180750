import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/counterparty/router";
import { RouteNames } from "@rmp/counterparty/router/routes";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { BoatApplicationController } from "@rmp/counterparty/api/boatApplication";
import { BoatApplication, BoatApplicationMapper } from "@rmp/core/types/boatApplication/boatApplication";
import { BoatKindItem } from "@rmp/counterparty/stores/boatApplication/types/boatKindItem";
import {
	CounterpartyBoatDocumentPackage,
	CounterpartyBoatDocumentPackageMapper
} from "@rmp/core/types/boatApplication/counterpartyBoatDocumentPackage";
import { ApiApplicationBoatMapper } from "@rmp/core/api/types/counterpartyBoatApplication/apiCounterpartyBoatApplication";
import { ApiCounterpartyBoatDocumentPackageMapper } from "@rmp/core/api/types/counterparty/boatApplication/apiCounterpartyBoatDocumentPackage";
import { BoatApplicationDocumentType } from "@rmp/core/types/boatApplicationDocumentType";
import ApiFileMeta from "@rmp/core/api/types/storage/apiFileMeta";
import { FileMetaHelper } from "@rmp/core/types/storage/fileMeta";
import { i18n } from "@rmp/counterparty/plugins";
import { useBoatApplicationBreadcrumb } from "@rmp/counterparty/stores/boatApplication/composables/useBoatApplicationBreadcrumb";
import { useBoatApplicationsBreadcrumb } from "@rmp/counterparty/stores/boatApplications/composables/useBoatApplicationsBreadcrumb";
import NotDefinedOrNullException from "@rmp/core/exceptions/notDefinedOrNullException";
import { StorageController } from "@rmp/core/api/storage";

const abortService = new AbortService();
const boatApplicationController = new BoatApplicationController(abortService);
const storageController = new StorageController(abortService);

const page = usePageStore(abortService);
const form = useFormStore();
const snapshotStore = useSnapshotStore([
	{ key: SnapshotKeysEnum.LAST_SAVED, fields: ["application", "documentsPackage"] }
]);

export interface BoatApplicationState extends PageState, SnapshotState, FormState {
	title: string;
	application: BoatApplication;
	boatKindItems: BoatKindItem[];
	documentsPackage: CounterpartyBoatDocumentPackage;
}

const getDefaultState = (): BoatApplicationState => {
	return {
		...page.getDefaultPageState(),
		...form.getDefaultState(),
		...snapshotStore.getDefaultState(),
		title: "",
		application: BoatApplicationMapper.getEmpty(),
		boatKindItems: [],
		documentsPackage: CounterpartyBoatDocumentPackageMapper.getEmpty()
	};
};

export const useBoatApplicationStore = defineStore({
	id: "boat-application",
	state: (): BoatApplicationState => getDefaultState(),
	getters: {
		...page.getters,
		...form.getters,
		...snapshotStore.getters,
		breadcrumbs(state: BoatApplicationState) {
			return [
				useBoatApplicationsBreadcrumb(),
				useBoatApplicationBreadcrumb(state.title)
			];
		},
		formattedBoatKindItems(state: BoatApplicationState) {
			return state.boatKindItems.map(x => {
				return {
					text: x.description,
					value: x.code
				};
			});
		}
	},
	actions: {
		...page.actions,
		...form.actions,
		...snapshotStore.actions,
		async beforeInitialized() {
			this.setStateSnapshot();
			await this.fetchBoatKindItems();
			
			const copyId = router.currentRoute.query.copy;
			
			if(copyId) {
				await this.fetchCopy(copyId as string);
			} else {
				this.title = i18n.t("titles.boatCreate") as string;
			}
			
			this.setStateSnapshot();
		},
		async fetchBoatKindItems() {
			this.formStateLoading = true;
			
			try {
				this.boatKindItems = await boatApplicationController.getBoatKind();
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
				this.formDisabled = true;
			} finally {
				this.formStateLoading = false;
			}
		},
		async fetchCopy(copyId: string) {
			this.formStateLoading = true;
			
			try {
				let { counterpartyBoatApplication } = await boatApplicationController.getBoatApplication(copyId);
				this.application = BoatApplicationMapper.map(counterpartyBoatApplication);
				
				const boatTypeOption = this.boatKindItems.find(x => x.code === this.application.type);
				if(!boatTypeOption)
					throw new NotDefinedOrNullException("boatTypeOption");
				
				this.application.type = boatTypeOption.code;
				
				this.title = counterpartyBoatApplication.applicationBoat.name;
				
				// TODO зря структуру документов храним в виде объекта, нужно переделать на массив
				const documentsPackage = this.application.documentsPackage;
				const documents = [
					...documentsPackage.customDocuments.map(x => ({
						type: BoatApplicationDocumentType.customDocuments,
						id: x.tempFileId
					})),
					{
						type: BoatApplicationDocumentType.ownershipCertificate,
						id: documentsPackage.ownershipCertificateTempFileId
					},
					{
						type: BoatApplicationDocumentType.internationalMeasuringCertificate,
						id: documentsPackage.internationalMeasuringCertificateTempFileId
					},
					{
						type: BoatApplicationDocumentType.classificationCertificate,
						id: documentsPackage.classificationCertificateTempFileId
					},
					{
						type: BoatApplicationDocumentType.internationalOilPollutionPreventionCertificate,
						id: documentsPackage.internationalOilPollutionPreventionCertificateTempFileId
					}
				];
				
				// Грузим существующие файлы повторно 
				for (const item of documents.filter(x => !!x.id)) {
					const clonedFileMeta = await storageController.clone(item.id);
					
					this.handleAddDocument({
						type: item.type,
						meta: clonedFileMeta
					});
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
				this.formDisabled = true;
			} finally {
				this.formStateLoading = false;
			}
		},
		async save() {
			this.formSaving = true;
			
			const payload = ApiApplicationBoatMapper.map({
				...this.application,
				documentsPackage: ApiCounterpartyBoatDocumentPackageMapper.map(this.documentsPackage)
			});
			
			try {
				let { id } = await boatApplicationController.createBoatApplication(payload);
				
				this.setStateSnapshot();
				
				alertService.addSuccess(AlertKeys.SUCCESS_CREATED_INFO);
				
				await router.push({ name: RouteNames.BOAT_APPLICATION_DETAILS, params: { id } });
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.formSaving = false;
			}
		},
		handleAddDocument({ type, meta }: { type: BoatApplicationDocumentType, meta: ApiFileMeta }) {
			try {
				switch (type) {
					case BoatApplicationDocumentType.ownershipCertificate:
						this.documentsPackage.ownershipCertificate = FileMetaHelper.map(meta);
						break;
					case BoatApplicationDocumentType.internationalMeasuringCertificate:
						this.documentsPackage.internationalMeasuringCertificate = FileMetaHelper.map(meta);
						break;
					case BoatApplicationDocumentType.classificationCertificate:
						this.documentsPackage.classificationCertificate = FileMetaHelper.map(meta);
						break;
					case BoatApplicationDocumentType.internationalOilPollutionPreventionCertificate:
						this.documentsPackage.internationalOilPollutionPreventionCertificate = FileMetaHelper.map(meta);
						break;
					case BoatApplicationDocumentType.customDocuments:
						this.documentsPackage.customDocuments.push(FileMetaHelper.map(meta));
						break;
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		removeCustomDocument(index: number) {
			this.documentsPackage.customDocuments.splice(index, 1);
		}
	}
});
