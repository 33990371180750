// TODO: удалить, когда все документы-объекты будут заменены на массивы

export interface DocumentsObjectState<PackageType> {
	documentsPackage: PackageType;
}

export interface DocumentsObjectParams<PackageType> {
	documentsPackage: PackageType;
}

export const useDocumentsObjectStore = <PackageType>() => {
	const getDefaultState = (): DocumentsObjectState<PackageType> => {
		return {
			documentsPackage: {} as PackageType
		};
	};
	
	const getters = {};
	
	const actions = {
		initializeDocumentsStore(this: DocumentsObjectState<PackageType>, params: DocumentsObjectParams<PackageType>) {
			this.documentsPackage = params.documentsPackage;
		}
	};
	
	return { getters, actions, getDefaultState };
};
