import { ApiServiceTypeEnum } from "@rmp/core/types/services/ApiServiceTypeEnum";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import InvalidArgumentException from "@rmp/core/exceptions/invalidArgumentException";

export default class ServiceMapperProfile {
	static mapServiceType(source: ApiServiceTypeEnum): ServiceTypeEnum {
		switch (source) {
			case ApiServiceTypeEnum.UNDEFINED_SERVICE:
				return ServiceTypeEnum.UNDEFINED_SERVICE;
			case ApiServiceTypeEnum.TUGBOAT_SERVICE:
				return ServiceTypeEnum.TUGBOAT_SERVICE;
			case ApiServiceTypeEnum.CREWBOAT_SERVICE:
				return ServiceTypeEnum.CREWBOAT_SERVICE;
			case ApiServiceTypeEnum.INFORMATION_SERVICE:
				return ServiceTypeEnum.INFORMATION_SERVICE;
			case ApiServiceTypeEnum.FLOATING_CRANE_SERVICE:
				return ServiceTypeEnum.FLOATING_CRANE_SERVICE;
			case ApiServiceTypeEnum.PIER_SERVICE:
				return ServiceTypeEnum.PIER_SERVICE;
			case ApiServiceTypeEnum.GEODETIC_HYDROGRAPHIC_SERVICE:
				return ServiceTypeEnum.GEODETIC_HYDROGRAPHIC_SERVICE;
			case ApiServiceTypeEnum.SNO_INSTALLATION_SERVICE:
				return ServiceTypeEnum.SNO_INSTALLATION_SERVICE;
			case ApiServiceTypeEnum.DREDGING_WORKS_SERVICE:
				return ServiceTypeEnum.DREDGING_WORKS_SERVICE;
			default:
				throw new InvalidArgumentException("source", source);
		}
	}
	
	static mapApiServiceType(source: ServiceTypeEnum): ApiServiceTypeEnum {
		switch (source) {
			case ServiceTypeEnum.UNDEFINED_SERVICE:
				return ApiServiceTypeEnum.UNDEFINED_SERVICE;
			case ServiceTypeEnum.TUGBOAT_SERVICE:
				return ApiServiceTypeEnum.TUGBOAT_SERVICE;
			case ServiceTypeEnum.CREWBOAT_SERVICE:
				return ApiServiceTypeEnum.CREWBOAT_SERVICE;
			case ServiceTypeEnum.INFORMATION_SERVICE:
				return ApiServiceTypeEnum.INFORMATION_SERVICE;
			case ServiceTypeEnum.FLOATING_CRANE_SERVICE:
				return ApiServiceTypeEnum.FLOATING_CRANE_SERVICE;
			case ServiceTypeEnum.PIER_SERVICE:
				return ApiServiceTypeEnum.PIER_SERVICE;
			case ServiceTypeEnum.GEODETIC_HYDROGRAPHIC_SERVICE:
				return ApiServiceTypeEnum.GEODETIC_HYDROGRAPHIC_SERVICE;
			case ServiceTypeEnum.SNO_INSTALLATION_SERVICE:
				return ApiServiceTypeEnum.SNO_INSTALLATION_SERVICE;
			case ServiceTypeEnum.DREDGING_WORKS_SERVICE:
				return ApiServiceTypeEnum.DREDGING_WORKS_SERVICE;
			default:
				throw new InvalidArgumentException("source", source);
		}
	}
}
