import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useHistoryStore } from "@rmp/core/stores/composables/history";
import { AgreementController } from "@rmp/counterparty/api/agreement";
import AgreementHistoryMapperProfile, { AgreementHistoryEntry } from "@rmp/core/types/agreement/agreementHistoryEntry";
import { useAgreementDetailsStore } from "@rmp/counterparty/stores/agreementDetails";
import { useAgreementDetailsHistoryBreadcrumb } from "@rmp/counterparty/stores/agreementDetails/history/composables/useAgreementDetailsHistoryBreadcrumb";

const abortService = new AbortService();
const page = usePageStore(abortService);

const agreementController = new AgreementController(abortService);

const mapper = new AgreementHistoryMapperProfile();
const historyStore = useHistoryStore({ controller: agreementController, mapper });

export interface AgreementDetailsHistoryState extends PageState {
	id: string | null;
	history: AgreementHistoryEntry[];
}

const getDefaultState = (): AgreementDetailsHistoryState => {
	return {
		...page.getDefaultPageState(),
		...historyStore.getDefaultState(),
		id: null,
		history: []
	};
};

export const useAgreementDetailsHistoryStore = defineStore({
	id: "agreement-details-history",
	state: (): AgreementDetailsHistoryState => getDefaultState(),
	getters: {
		...page.getters,
		...historyStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useAgreementDetailsStore();
			return [...breadcrumbs, useAgreementDetailsHistoryBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...historyStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			await this.initializeHistoryStore();
		}
	}
});
