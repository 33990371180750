import { defineStore } from "pinia";
import { ServiceApplicationStateBase } from "@rmp/counterparty/stores/serviceApplication/services/base/useServiceApplicationStoreBase";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import { useUserStore } from "@rmp/core/stores/user";


export interface FloatingCraneServiceApplicationState extends ServiceApplicationStateBase {
	// currentStep (title, index, alias)
}

const getDefaultState = (): FloatingCraneServiceApplicationState => {
	return {};
};

export const useFloatingCraneServiceApplicationStore = defineStore({
	id: "floating-crane-service-application",
	state: (): FloatingCraneServiceApplicationState => getDefaultState(),
	getters: {
		steps: state => {
			const { isEntrepreneurEmployee } = useUserStore();
			
			let steps = [
				ServiceApplicationStepTypeEnum.SERVICE,
				ServiceApplicationStepTypeEnum.DEPARTMENT,
				ServiceApplicationStepTypeEnum.PORT,
				ServiceApplicationStepTypeEnum.FLOATING_CRANE,
				ServiceApplicationStepTypeEnum.DOCUMENTS,
				ServiceApplicationStepTypeEnum.CONFIRMATION
			];
			
			if(isEntrepreneurEmployee)
				steps = steps.filter(x => x !== ServiceApplicationStepTypeEnum.DOCUMENTS);
			
			return steps;
		}
	},
	actions: {}
});
