import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import { Permissions } from "@rmp/core/constants/permissions";

interface AgreementPermissions {
	create: Permissions;
	send: Permissions;
	negotiate: Permissions;
	signByEnterprise: Permissions;
	signByCounterparty: Permissions;
	start: Permissions;
	finish: Permissions;
	cancel: Permissions;
	reject: Permissions;
}

export default class AgreementPermissionsService {
	permissionsMap = new Map<ServiceTypeEnum, AgreementPermissions>([
		[ServiceTypeEnum.TUGBOAT_SERVICE, {
			create: Permissions.OWN_TUGBOAT_AGREEMENT_CREATE,
			send: Permissions.OWN_TUGBOAT_AGREEMENT_SEND,
			negotiate: Permissions.OWN_TUGBOAT_AGREEMENT_NEGOTIATE,
			signByEnterprise: Permissions.OWN_TUGBOAT_AGREEMENT_FINALLY_SIGN_BY_ENTERPRISE,
			signByCounterparty: Permissions.OWN_TUGBOAT_AGREEMENT_FINALLY_SIGN_BY_COUNTERPARTY,
			start: Permissions.OWN_TUGBOAT_AGREEMENT_START,
			finish: Permissions.OWN_TUGBOAT_AGREEMENT_FINISH,
			cancel: Permissions.OWN_TUGBOAT_AGREEMENT_CANCEL,
			reject: Permissions.OWN_TUGBOAT_AGREEMENT_REJECT
		}],
		[ServiceTypeEnum.CREWBOAT_SERVICE, {
			create: Permissions.OWN_CREWBOAT_AGREEMENT_CREATE,
			send: Permissions.OWN_CREWBOAT_AGREEMENT_SEND,
			negotiate: Permissions.OWN_CREWBOAT_AGREEMENT_NEGOTIATE,
			signByEnterprise: Permissions.OWN_CREWBOAT_AGREEMENT_FINALLY_SIGN_BY_ENTERPRISE,
			signByCounterparty: Permissions.OWN_CREWBOAT_AGREEMENT_FINALLY_SIGN_BY_COUNTERPARTY,
			start: Permissions.OWN_CREWBOAT_AGREEMENT_START,
			finish: Permissions.OWN_CREWBOAT_AGREEMENT_FINISH,
			cancel: Permissions.OWN_CREWBOAT_AGREEMENT_CANCEL,
			reject: Permissions.OWN_CREWBOAT_AGREEMENT_REJECT
		}],
		[ServiceTypeEnum.PIER_SERVICE, {
			create: Permissions.OWN_PIER_AGREEMENT_CREATE,
			send: Permissions.OWN_PIER_AGREEMENT_SEND,
			negotiate: Permissions.OWN_PIER_AGREEMENT_NEGOTIATE,
			signByEnterprise: Permissions.OWN_PIER_AGREEMENT_FINALLY_SIGN_BY_ENTERPRISE,
			signByCounterparty: Permissions.OWN_PIER_AGREEMENT_FINALLY_SIGN_BY_COUNTERPARTY,
			start: Permissions.OWN_PIER_AGREEMENT_START,
			finish: Permissions.OWN_PIER_AGREEMENT_FINISH,
			cancel: Permissions.OWN_PIER_AGREEMENT_CANCEL,
			reject: Permissions.OWN_PIER_AGREEMENT_REJECT
		}],
		[ServiceTypeEnum.INFORMATION_SERVICE, {
			create: Permissions.OWN_INFORMATION_AGREEMENT_CREATE,
			send: Permissions.OWN_INFORMATION_AGREEMENT_SEND,
			negotiate: Permissions.OWN_INFORMATION_AGREEMENT_NEGOTIATE,
			signByEnterprise: Permissions.OWN_INFORMATION_AGREEMENT_FINALLY_SIGN_BY_ENTERPRISE,
			signByCounterparty: Permissions.OWN_INFORMATION_AGREEMENT_FINALLY_SIGN_BY_COUNTERPARTY,
			start: Permissions.OWN_INFORMATION_AGREEMENT_START,
			finish: Permissions.OWN_INFORMATION_AGREEMENT_FINISH,
			cancel: Permissions.OWN_INFORMATION_AGREEMENT_CANCEL,
			reject: Permissions.OWN_INFORMATION_AGREEMENT_REJECT
		}]
	])
	
	getPermissions(serviceType: ServiceTypeEnum) {
		const permissions = this.permissionsMap.get(serviceType);
		
		if(!permissions)
			throw new Error();
		
		return permissions;
	}
}
