import { ApiAgreementPersisted } from "@rmp/core/api/types/agreement/apiAgreement";
import { parseISO } from "date-fns";
import { ApiAgreementWorkflowState } from "@rmp/core/api/types/agreement/apiAgreementWorkflowState";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";
import { AgreementDuration, AgreementDurationMapper } from "@rmp/core/types/agreement/agreementDuration";
import { ApiAgreementPaymentMethodEnum } from "@rmp/core/api/types/agreement/ApiAgreementPaymentMethodEnum";

export interface Agreement {
    id: string;
    agreementNumber: number;
    counterpartyId: string;
    divisionId: string;
    serviceKind: ServiceTypeEnum;
    limit: number;
    amountLimit: number;
    attorneyRMPNumber: string;
    attorneyRMPDate: Date;
    attorneyCounterpartyNumber: string;
    attorneyCounterpartyDate: Date;
    target: number;
    amount: number | null;
    isCounterpartySigned: boolean;
    isRMPSigned: boolean;
    agreementDate?: Date;
    state: ApiAgreementWorkflowState;
    agreementDuration: AgreementDuration | null;
    rejectReason: string | null;
	paymentMethod: ApiAgreementPaymentMethodEnum;
	isArchived: boolean;
}

export class AgreementMapper {
    static map(source: ApiAgreementPersisted): Agreement {
        return {
            id: source.id,
            ...source.agreement,
            attorneyRMPDate: parseISO(source.agreement.attorneyRMPDate),
            attorneyCounterpartyDate: parseISO(source.agreement.attorneyCounterpartyDate),
            agreementDate: source.agreement.agreementDate ? parseISO(source.agreement.agreementDate) : undefined,
            serviceKind: ServiceMapperProfile.mapServiceType(source.agreement.serviceKind),
            agreementDuration: source.agreement.agreementDuration && AgreementDurationMapper.map(source.agreement.agreementDuration)
        };
    }
}
