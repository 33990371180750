import BaseServiceApplicationWorkflowService from "@rmp/core/services/serviceApplication/baseServiceApplicationWorkflowService";
import { ServiceWorkflowStateType } from "@rmp/core/types/services/ServiceWorkflowStateType";
import { ApiPropertyServiceTransitionCodeEnum } from "@rmp/core/api/types/service/ApiPropertyServiceTransitionCodeEnum";

export default class TugboatServiceApplicationWorkflowService extends BaseServiceApplicationWorkflowService {
	transitions = new Map<ServiceWorkflowStateType, ServiceWorkflowStateType[]>([
		[ServiceWorkflowStateType.NEW, [ServiceWorkflowStateType.DRAFT]],
		[ServiceWorkflowStateType.DRAFT, [ServiceWorkflowStateType.SENT, ServiceWorkflowStateType.CANCELED]],
		[
			ServiceWorkflowStateType.SENT,
			[ServiceWorkflowStateType.APPROVED_BY_DISPATCHER, ServiceWorkflowStateType.REJECTED, ServiceWorkflowStateType.CANCELED]
		],
		[ServiceWorkflowStateType.APPROVED_BY_DISPATCHER, [ServiceWorkflowStateType.APPROVED_BY_PILOT, ServiceWorkflowStateType.REJECTED]],
		[ServiceWorkflowStateType.APPROVED_BY_PILOT, [ServiceWorkflowStateType.PROVIDING, ServiceWorkflowStateType.REJECTED]],
		[ServiceWorkflowStateType.PROVIDING, [ServiceWorkflowStateType.PROVIDED]],
		[ServiceWorkflowStateType.PROVIDED, [ServiceWorkflowStateType.APPROVED_BY_FINANSIST]],
		[ServiceWorkflowStateType.APPROVED_BY_FINANSIST, [ServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED]],
		[
			ServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED,
			[ServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE, ServiceWorkflowStateType.APPROVED_BY_FINANSIST]
		],
		[
			ServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE,
			[ServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY, ServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED]
		],
		[ServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY, [ServiceWorkflowStateType.COMPLETED]]
	]);
	
	transitionNames = new Map<string, ApiPropertyServiceTransitionCodeEnum>([
		[
			[ServiceWorkflowStateType.NEW, ServiceWorkflowStateType.DRAFT].join(),
			ApiPropertyServiceTransitionCodeEnum.CREATE
		],
		[
			[ServiceWorkflowStateType.DRAFT, ServiceWorkflowStateType.SENT].join(), ApiPropertyServiceTransitionCodeEnum.SEND
		],
		[
			[ServiceWorkflowStateType.SENT, ServiceWorkflowStateType.APPROVED_BY_DISPATCHER].join(),
			ApiPropertyServiceTransitionCodeEnum.APPROVE
		],
		[
			[ServiceWorkflowStateType.APPROVED_BY_DISPATCHER, ServiceWorkflowStateType.APPROVED_BY_PILOT].join(),
			ApiPropertyServiceTransitionCodeEnum.APPROVE
		],
		[
			[ServiceWorkflowStateType.APPROVED_BY_PILOT, ServiceWorkflowStateType.PROVIDING].join(),
			ApiPropertyServiceTransitionCodeEnum.START
		],
		[
			[ServiceWorkflowStateType.PROVIDING, ServiceWorkflowStateType.PROVIDED].join(), ApiPropertyServiceTransitionCodeEnum.FINISH
		],
		[
			[ServiceWorkflowStateType.PROVIDED, ServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE].join(),
			ApiPropertyServiceTransitionCodeEnum.FINALLY_SIGN
		],
		[
			[ServiceWorkflowStateType.PROVIDED, ServiceWorkflowStateType.APPROVED_BY_FINANSIST].join(),
			ApiPropertyServiceTransitionCodeEnum.APPROVE
		],
		[
			[ServiceWorkflowStateType.APPROVED_BY_FINANSIST, ServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED].join(),
			ApiPropertyServiceTransitionCodeEnum.ATTACH
		],
		[
			[ServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED, ServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE].join(),
			ApiPropertyServiceTransitionCodeEnum.FINALLY_SIGN
		],
		[
			[
				ServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE,
				ServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY
			].join(), ApiPropertyServiceTransitionCodeEnum.FINALLY_SIGN
		],
		[
			[ServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY, ServiceWorkflowStateType.COMPLETED].join(),
			ApiPropertyServiceTransitionCodeEnum.COMPLETE
		],
		[
			[ServiceWorkflowStateType.DRAFT, ServiceWorkflowStateType.CANCELED].join(), ApiPropertyServiceTransitionCodeEnum.CANCEL
		],
		[
			[ServiceWorkflowStateType.SENT, ServiceWorkflowStateType.REJECTED].join(), ApiPropertyServiceTransitionCodeEnum.REJECT
		],
		[
			[ServiceWorkflowStateType.APPROVED_BY_DISPATCHER, ServiceWorkflowStateType.REJECTED].join(),
			ApiPropertyServiceTransitionCodeEnum.REJECT
		],
		[
			[ServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE, ServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED].join(),
			ApiPropertyServiceTransitionCodeEnum.RESET
		],
		[
			[ServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED, ServiceWorkflowStateType.APPROVED_BY_FINANSIST].join(),
			ApiPropertyServiceTransitionCodeEnum.RESET
		]
	]);
}
