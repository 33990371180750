export const urls = {
	agreement: {
		path: "agreement",
		all: {
			path: "all"
		},
		$id: {
			path: ":id",
			remainingLimit: {
				path: "remaining-limit"
			},
			documents: {
				path: "documents"
			},
			chat: {
				path: "chat",
				message: {
					path: "message"
				}
			},
			history: {
				path: "history"
			},
			services: {
				path: "service"
			},
			transition: {
				path: "transition",
				$code: {
					path: ":code"
				}
			},
			paymentInvoice: {
				path: "payment-invoice"
			},
			archive: {
				path: "archive"
			},
			unarchive: {
				path: "unarchive"
			}
		},
		definitions: {
			path: "definitions"
		}
	},
	boatApplication: {
		path: "boat-application",
		$boatApplicationId: {
			path: ":boatApplicationId",
			chat: {
				path: "chat",
				message: {
					path: "message"
				}
			},
			history: {
				path: "history"
			}
		},
		kind: {
			path: "kind"
		}
	},
	boat: {
		path: "boat",
		$id: {
			path: ":id"
		}
	},
	service: {
		path: "service",
		$serviceId: {
			path: ":serviceId",
			$code: {
				path: ":code"
			}
		},
		$serviceType: {
			path: ":serviceType",
			tariff: {
				path: "tariff"
			}
		},
		definition: {
			path: "definition"
		},
		$id: {
			path: ":id",
			history: {
				path: "history"
			},
			feedback: {
				path: "feedback"
			}
		},
		pier: {
			path: "pier",
			$pierServiceId: {
				path: ":pierServiceId",
				$code: {
					path: ":code"
				}
			},
			draft: {
				path: "draft"
			},
			department: {
				path: "department",
				$departmentId: {
					path: ":departmentId",
					agreement: {
						path: "agreement",
						$agreementId: {
							path: ":agreementId"
						}
					},
					port: {
						path: "port"
					},
					pier: {
						path: "pier",
						$pierId: {
							path: ":pierId",
							tariff: {
								path: "tariff",
								$tariffId: {
									path: ":tariffId",
									calculate: {
										path: "calculate"
									}
								}
							}
						}
					}
				}
			},
			expectedCost: {
				path: "expected-cost",
				calculate: {
					path: "calculate"
				}
			}
		},
		floatingCrane: {
			path: "floatingcrane",
			$serviceId: {
				path: ":serviceId",
				document: {
					path: "document"
				},
				$code: {
					path: ":code"
				}
			},
			department: {
				path: "department",
				$departmentId: {
					path: ":departmentId",
					port: {
						path: "port"
					},
					floatingCrane: {
						path: "floatingcrane"
					}
				}
			}
		},
		dredging: {
			path: "dredging",
			$serviceId: {
				path: ":serviceId",
				document: {
					path: "document"
				},
				$code: {
					path: ":code"
				}
			},
			department: {
				path: "department",
				$departmentId: {
					path: ":departmentId",
					port: {
						path: "port"
					}
				}
			}
		},
		snoInstallation: {
			path: "sno-installation",
			$serviceId: {
				path: ":serviceId",
				document: {
					path: "document"
				},
				$code: {
					path: ":code"
				}
			},
			department: {
				path: "department",
				$departmentId: {
					path: ":departmentId",
					port: {
						path: "port"
					}
				}
			}
		},
		geodetichydrographic: {
			path: "geodetichydrographic",
			$serviceId: {
				path: ":serviceId",
				document: {
					path: "document"
				},
				$code: {
					path: ":code"
				}
			},
			department: {
				path: "department",
				$departmentId: {
					path: ":departmentId",
					port: {
						path: "port"
					}
				}
			}
		},
		tugboat: {
			path: "tugboat",
			draft: {
				path: "draft"
			},
			$tugboatId: {
				path: ":tugboatId",
				copy: {
					path: "copy"
				}
			},
			$tugboatServiceId: {
				path: ":tugboatServiceId",
				$code: {
					path: ":code"
				},
				copy: {
					path: "copy"
				}
			},
			department: {
				path: "department",
				$departmentId: {
					path: ":departmentId",
					port: {
						path: "port",
						$portId: {
							path: ":portId",
							tugboat: {
								path: "tugboat",
								$tugboat: {
									path: ":tugboatId",
									tariff: {
										path: "tariff",
										$tariffId: {
											path: ":tariffId",
											calculate: {
												path: "calculate"
											}
										}
									}
								}
							}
						}
					},
					tugboat: {
						path: "tugboat"
					},
					agreement: {
						path: "agreement"
					}
				}
			},
			expectedCost: {
				path: "expected-cost",
				calculate: {
					path: "calculate"
				}
			}
		},
		information: {
			path: "information",
			$serviceId: {
				path: ":serviceId",
				$code: {
					path: ":code"
				}
			},
			department: {
				path: "department",
				$departmentId: {
					path: ":departmentId",
					port: {
						path: "port"
					},
					tariff: {
						path: "tariff",
						$tariffId: {
							path: ":tariffId",
							calculate: {
								path: "calculate"
							}
						}
					},
					agreement: {
						path: "agreement",
						$agreementId: {
							path: ":agreementId"
						}
					}
				}
			},
			expectedCost: {
				path: "expected-cost",
				calculate: {
					path: "calculate"
				}
			}
		},
		crewboat: {
			path: "crewboat",
			draft: {
				path: "draft"
			},
			$crewboatServiceId: {
				path: ":crewboatServiceId",
				copy: {
					path: "copy"
				},
				$code: {
					path: ":code"
				}
			},
			department: {
				path: "department",
				$departmentId: {
					path: ":departmentId",
					port: {
						path: "port",
						$portId: {
							path: ":portId",
							crewboat: {
								path: "crewboat",
								$crewboat: {
									path: ":crewboatId",
									tariff: {
										path: "tariff",
										$tariffId: {
											path: ":tariffId",
											calculate: {
												path: "calculate"
											}
										}
									}
								}
							}
						}
					},
					crewboat: {
						path: "crewboat"
					},
					agreement: {
						path: "agreement"
					}
				}
			},
			expectedCost: {
				path: "expected-cost",
				calculate: {
					path: "calculate"
				}
			}
		},
		$serviceApplicationId: {
			path: ":serviceApplicationId",
			chat: {
				path: "chat",
				message: {
					path: "message"
				}
			},
			document: {
				path: "document"
			},
			archive: {
				path: "archive"
			},
			unarchive: {
				path: "unarchive"
			}
		}
	},
	enterprise: {
		path: "enterprise",
		division: {
			path: "division",
			department: {
				path: "department",
				$id: {
					path: ":id"
				}
			},
			port: {
				path: "port",
				$id: {
					path: ":id",
					destination: {
						path: "destination",
						$destinationId: {
							path: ":destinationId"
						}
					}
				}
			}
		},
		property: {
			path: "property",
			tugboat: {
				path: "tugboat",
				$id: {
					path: ":id"
				}
			},
			pier: {
				path: "pier",
				$id: {
					path: ":id"
				}
			},
			floatingCrane: {
				path: "floating-crane",
				$id: {
					path: ":id"
				}
			},
			crewboat: {
				path: "crewboat",
				$id: {
					path: ":id"
				}
			}
		}
	}
};
