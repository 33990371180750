import { ApiServiceApplicationTugboat } from "@rmp/core/api/types/service/tugboat/apiServiceApplicationTugboat";
import {
	ServiceApplicationPropertyBase,
	ServiceApplicationPropertyBaseMapper
} from "@rmp/core/types/services/base/serviceApplicationPropertyBase";

export interface ServiceApplicationTugboat extends ServiceApplicationPropertyBase {
	tugboatId: string;
	tugServiceApplicationId: string;
}

export class ServiceApplicationTugboatMapper {
	static map(source: ApiServiceApplicationTugboat): ServiceApplicationTugboat {
		return {
			...ServiceApplicationPropertyBaseMapper.map(source),
			tugboatId: source.tugBoatId,
			tugServiceApplicationId: source.tugServiceApplicationId,
		};
	}
}
