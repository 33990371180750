import ApiLegalEntityCounterparty from "@rmp/core/api/types/counterparty/apiLegalEntityCounterparty";
import { Type } from "class-transformer";
import ApiCounterpartyBase from "@rmp/core/api/types/counterparty/apiCounterpartyBase";
import { CounterpartyTypeEnum } from "@rmp/core/types/CounterpartyTypeEnum";
import ApiPhysicalPersonCounterparty from "@rmp/core/api/types/counterparty/apiPhysicalPersonCounterparty";
import ApiForeignOrganizationCounterparty from "@rmp/core/api/types/counterparty/apiForeignOrganizationCounterparty";
import ApiLegalPersonCounterparty from "@rmp/core/api/types/counterparty/apiLegalPersonCounterparty";

type Counterparty = ApiLegalEntityCounterparty | ApiLegalPersonCounterparty | ApiCounterpartyBase

export default class ApiCounterpartyPersistedBase {
	id: string;
	createdAt: string;
	updatedAt: string;
	
	@Type(() => ApiCounterpartyBase, {
		keepDiscriminatorProperty: true,
		discriminator: {
			property: "type",
			subTypes: [
				{ value: ApiLegalPersonCounterparty, name: CounterpartyTypeEnum.LEGAL_PERSON },
				{ value: ApiLegalEntityCounterparty, name: CounterpartyTypeEnum.LEGAL_ENTITY }
			]
		}
	})
	counterparty: Counterparty;
	
	
	constructor(id: string = "",
		createdAt: string = "",
		updatedAt: string = "",
		counterparty: Counterparty = new ApiCounterpartyBase())
	{
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.counterparty = counterparty;
	}
}
