import { ServiceApplicationStepState } from "@rmp/counterparty/stores/serviceApplication/steps/shared/serviceApplicationState";
import { defineStore } from "pinia";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/counterparty/router";
import { RouteNames } from "@rmp/counterparty/router/routes";
import { useDepartmentStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/department";
import { useAgreementStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/agreement";
import { usePortStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/port";
import { useUserStore } from "@rmp/core/stores/user";
import { CounterpartyController } from "@rmp/core/api/counterparty";
import ApiLegalEntityCounterpartyPersisted from "@rmp/core/api/types/counterparty/apiLegalEntityCounterpartyPersisted";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";
import { ApiCreateInformationApplication } from "@rmp/core/api/types/service/information/apiCreateInformationApplication";
import { useInformationTariffStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/informationTariff";
import { InformationServiceController } from "@rmp/counterparty/api/service/information";
import { ApiInformationServiceTransitionCodeEnum } from "@rmp/core/api/types/service/information/ApiInformationServiceTransitionCodeEnum";

const abortService = new AbortService();

const informationServiceController = new InformationServiceController(abortService);
const counterpartyController = new CounterpartyController(abortService);

const page = usePageStore(abortService);

export interface InformationConfirmationStepState extends ServiceApplicationStepState, PageState {
	isSaving: boolean;
	isDraftSaving: boolean;
}

const getDefaultState = (): InformationConfirmationStepState => {
	return {
		...page.getDefaultPageState(),
		isSaving: false,
		isDraftSaving: false,
		stepType: ServiceApplicationStepTypeEnum.INFORMATION_CONFIRMATION
	};
};

export const useInformationConfirmationStepStore = defineStore({
	id: "information-confirmation-step",
	state: (): InformationConfirmationStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return state.initialized;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
		},
		async prepareServiceApplication(): Promise<ApiCreateInformationApplication> {
			const { profile } = useUserStore();
			const { selectedDepartmentId } = useDepartmentStepStore();
			const { selectedAgreementId, filteredOrderedAgreements } = useAgreementStepStore();
			const { selectedPortId } = usePortStepStore();
			const { requestedTariff } = useInformationTariffStepStore();
			
			const counterparty = await counterpartyController.getCounterparty("",
				ApiLegalEntityCounterpartyPersisted,
				AuthorizationScopeType.OWN);
			
			return {
				agreementId: selectedAgreementId,
				initiatorCounterpartyEmployeeId: profile!.externalId,
				initiatorCounterpartyId: counterparty.id,
				divisionId: selectedDepartmentId,
				portId: selectedPortId,
				requestedTariff: requestedTariff!
			};
		},
		async save() {
			this.isSaving = true;
			
			try {
				const serviceApplication = await this.prepareServiceApplication();
				
				const { id } = await informationServiceController.createApplication(serviceApplication);
				
				
				// TODO Будет перенесено на бек, пока за перевод статусов фронт отвечает
				await informationServiceController.updateStatusByTransition(id, ApiInformationServiceTransitionCodeEnum.CREATE);
				
				await informationServiceController.updateStatusByTransition(id, ApiInformationServiceTransitionCodeEnum.SEND);
				
				await router.push({ name: RouteNames.INFORMATION_SERVICE, params: { id } });
				
				alertService.addSuccess(AlertKeys.SERVICE_APPLICATION_CREATED);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.isSaving = false;
			}
		}
	}
});
