import {
	ServiceApplicationStepState
} from "@rmp/counterparty/stores/serviceApplication/steps/shared/serviceApplicationState";
import { defineStore } from "pinia";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import AbortService from "@rmp/core/services/abortService";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useDepartmentStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/department";
import { useServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication";
import { uniq } from "lodash";
import { FloatingCraneServiceController } from "@rmp/counterparty/api/service/floatingCrane";
import { FloatingCraneServiceApplication } from "@rmp/core/types/services/floatingCrane/floatingCraneServiceApplication";
import { ApiFloatingCranePersisted } from "@rmp/core/api/types/enterpriseProperty/apiFloatingCrane";

const abortService = new AbortService();
const page = usePageStore(abortService);

const floatingCraneServiceController = new FloatingCraneServiceController(abortService);

export interface FloatingCraneStepState extends ServiceApplicationStepState, PageState {
	floatingCranes: ApiFloatingCranePersisted[];
	selectedFloatingCraneIds: string[];
}

const getDefaultState = (): FloatingCraneStepState => {
	return {
		...page.getDefaultPageState(),
		floatingCranes: [],
		selectedFloatingCraneIds: [],
		stepType: ServiceApplicationStepTypeEnum.FLOATING_CRANE
	};
};

export const useFloatingCraneStepStore = defineStore({
	id: "floating-crane-step",
	state: (): FloatingCraneStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return state.initialized;
		},
		selectedFloatingCranes(state) {
			return state.floatingCranes.filter(x => state.selectedFloatingCraneIds.includes(x.id));
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			await this.fetch();
			
			this.trySetDefaultFloatingCrane();
		},
		async fetch() {
			try {
				const { selectedDepartmentId } = useDepartmentStepStore();
				
				this.floatingCranes = await floatingCraneServiceController.getFloatingCranes(selectedDepartmentId);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		trySetDefaultFloatingCrane() {
			if(!this.floatingCranes.length) return;
			
			const { copiedService } = useServiceApplicationStore() as { copiedService: FloatingCraneServiceApplication };
			
			let requestedTariffs = copiedService.requestedFloatingcranes || [];
			
			if(requestedTariffs.length) {
				// Берем уникальные значения, так как объекты повторяются, если на буксир выбрано несколько тарифов
				let copiedFloatingCraneIds = uniq(copiedService.requestedFloatingcranes.map(x => x.floatingcraneId));
				
				// Оставляем только те плавкраны, которые доступны пользователю
				this.selectedFloatingCraneIds = copiedFloatingCraneIds.filter(x => this.floatingCranes.find(y => y.id === x));
			} else {
				let [floatingCrane] = this.floatingCranes;
				this.selectedFloatingCraneIds = [floatingCrane.id];
			}
		}
	}
});
