import { ApiAccountProfileBase } from "@rmp/core/api/types/account/profile/apiAccountProfileBase";
import { ProfileType } from "@rmp/core/types/ProfileType";

export default class ApiForeignOrganizationProfile extends ApiAccountProfileBase {
	longName: string;
	
	constructor(
		type = ProfileType.MASTER_ACCOUNT_FOREIGN_ORGANIZATION,
		accountId: string = "",
		title: string = "",
		email: string = "",
		phone: string = "",
		isActive: boolean = false,
		longName: string = ""
	)
	{
		super(type, accountId, "", title, email, phone, isActive);
		
		this.longName = longName;
	}
}
