export const masks = {
	legalEntityInn: `##########`,
	inn: `############`,
	kpp: `####XX###`,
	ogrn: `#############`,
	ogrnip: `###############`,
	snils: `###-###-###-##`,
	date: `##.##.####`,
	time: `##:##`,
	dateRange: `##.##.####-##.##.####`,
	bankIdentificationCode: `#########`,
	bankAccountNumber: `####################`,
	correspondentAccount: `####################`,
	year: `####`,
	imo: `#######`,
	mmsi: `#########`,
	floatNumber: `FFFFFFFFFF`,
	calculationObjectCode: `XX#########`,
	harborDuesIdentifier: `##########`
}
