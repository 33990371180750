import { defineStore } from "pinia";
import { PageModeType } from "@rmp/core/stores/appStore/types/pageModeType";
import Breadcrumb from "@rmp/core/stores/composables/page/types/breadcrumb";
import RequestCancelledException from "@rmp/core/exceptions/requestCancelledException";
import OfflineException from "@rmp/core/exceptions/offlineException";
import ServerUnavailableException from "@rmp/core/exceptions/serverUnavailableException";
import BadRequestException from "@rmp/core/exceptions/badRequestException";
import InvalidBusinessOperationException from "@rmp/core/exceptions/invalidBusinessOperationException";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";
import AccessForbiddenException from "@rmp/core/exceptions/accessForbiddenException";

export interface AppState {
	pageMode: PageModeType,
	middlewareExecuted: boolean,
	breadcrumbs: Breadcrumb[];
	breadcrumbsLoading: boolean;
}

// TODO за хлебные крошки и mode должна отвечать сама страница
const getDefaultState = (): AppState => {
	return {
		pageMode: PageModeType.OK, // TODO убрать однажды
		middlewareExecuted: true,
		breadcrumbs: [], // TODO убрать однажды
		breadcrumbsLoading: false
	};
};

export const useAppStore = defineStore({
	id: "app",
	state: (): AppState => getDefaultState(),
	actions: {
		setMiddlewareExecuted(value: boolean) {
			this.middlewareExecuted = value;
		},
		setPageMode(value: PageModeType) {
			this.pageMode = value;
		},
		resetPageMode() {
			this.pageMode = PageModeType.OK;
		},
		setPageModeNotFound() {
			this.pageMode = PageModeType.PAGE_NOT_FOUND;
		},
		setPageModeAccessForbidden() {
			this.pageMode = PageModeType.ACCESS_DENIED;
		},
		// TODO Переименовать на handleServerError
		handleInternalServerError(ex: Error) {
			switch (ex.constructor) {
				case HttpNotFoundException:
					this.pageMode = PageModeType.PAGE_NOT_FOUND;
					break;
				case RequestCancelledException:
				case OfflineException:
				case InvalidBusinessOperationException:
				case AccessForbiddenException:
					break;
				case ServerUnavailableException:
				case BadRequestException:
				default:
					this.pageMode = PageModeType.INTERNAL_SERVER_ERROR;
					break;
			}
		},
		setBreadcrumbs(value: Breadcrumb[]) {
			this.breadcrumbs = value;
		},
		setBreadcrumbsLoading(value: boolean) {
			this.breadcrumbsLoading = value;
		}
	}
});

