export enum ServiceWorkflowStateType {
	NEW = "New",
	CANCELED = "Canceled",
	REJECTED = "Rejected",
	DRAFT = "Draft",
	SENT = "Sent",
	APPROVED_BY_DISPATCHER = "ApprovedByDispatcher",
	APPROVED_BY_PILOT = "ApprovedByPilot",
	PROVIDING = "Providing",
	PROVIDED = "Provided",
	APPROVED_BY_FINANSIST = "ApprovedByFinansist",
	ACCOUNTING_DOCUMENTS_ATTACHED = "AccountingDocumentsAttached",
	FINALLY_SIGNED_BY_ENTERPRISE = "FinallySignedByEnterprise",
	FINALLY_SIGNED_BY_COUNTERPARTY = "FinallySignedByCounterparty",
	COMPLETED = "Completed"
}
