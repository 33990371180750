import { ApiAgreementDuration } from "@rmp/core/api/types/agreement/apiAgreementDuration";
import { parseISODate } from "@rmp/core/utils/dates";

export interface AgreementDuration {
	start: Date | null;
	end: Date | null;
}

export class AgreementDurationMapper {
	static map(source: ApiAgreementDuration): AgreementDuration {
		return {
			start: parseISODate(source.start),
			end:  source.end ? parseISODate(source.end) : null
		};
	}
}
