import AbortService from "@rmp/core/services/abortService";
import { urls } from "@rmp/counterparty/api/config/urls";
import BaseCounterpartyController from "@rmp/counterparty/api/base/baseCounterpartyController";
import { ApiDepartmentPersisted } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { ApiAgreementPersisted } from "@rmp/core/api/types/agreement/apiAgreement";
import { ApiPortPersisted } from "@rmp/core/api/types/divisions/port/apiPort";
import { ApiGetTariffTotalAmountRequest } from "@rmp/core/api/types/service/apiGetTariffTotalAmountRequest";
import { ServiceControllerBase } from "@rmp/counterparty/api/service/serviceControllerBase";
import { ApiTariff } from "@rmp/core/api/types/service/tariffs/apiTariff";
import { ApiTariffTotalAmount } from "@rmp/core/api/types/service/apiTariffTotalAmount";
import { ApiCreatePropertyServiceApplicationBase } from "@rmp/core/api/types/service/base/apiCreatePropertyServiceApplicationBase";
import { ApiCrewboatServiceApplication } from "@rmp/core/api/types/service/crewboat/apiCrewboatServiceApplication";
import { ApiCrewboatPersisted } from "@rmp/core/api/types/enterpriseProperty/apiCrewboat";
import { ApiExpectedCost } from "@rmp/core/api/types/service/apiExpectedCost";
import { ApiCrewboatExpectedCostRequest } from "@rmp/core/api/types/service/crewboat/apiCrewboatExpectedCostRequest";
import NotImplementedException from "@rmp/core/exceptions/notImplementedException";
import { ApiPropertyServiceTransitionCodeEnum } from "@rmp/core/api/types/service/ApiPropertyServiceTransitionCodeEnum";


export class CrewboatServiceController extends BaseCounterpartyController implements ServiceControllerBase {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getService = async (crewboatServiceId: string) => {
		const { serviceApplication } = await this.get<{ serviceApplication: ApiCrewboatServiceApplication }>(urls.service.crewboat.$crewboatServiceId,
			{ crewboatServiceId });
		return serviceApplication;
	};
	
	getAgreements = async (departmentId: string) => {
		return await this.get<ApiAgreementPersisted[]>(urls.service.crewboat.department.$departmentId.agreement, { departmentId });
	};
	
	getDepartments = async () => {
		return await this.get<ApiDepartmentPersisted[]>(urls.service.crewboat.department);
	};
	
	getDepartmentPorts = async (departmentId: string) => {
		return await this.get<ApiPortPersisted[]>(urls.service.crewboat.department.$departmentId.port, { departmentId });
	};
	
	getTariffs = async (departmentId: string, portId: string, crewboatId: string) => {
		return await this.get<ApiTariff[]>(urls.service.crewboat.department.$departmentId.port.$portId.crewboat.$crewboat.tariff,
			{ departmentId, portId, crewboatId });
	};
	
	calculateTariffCost = async (departmentId: string,
		portId: string,
		crewboatId: string,
		tariffId: string,
		payload: ApiGetTariffTotalAmountRequest) => {
		// TODO: массив временный
		const [data] = await this.post<ApiTariffTotalAmount[]>(urls.service.crewboat.department.$departmentId.port.$portId.crewboat.$crewboat.tariff.$tariffId.calculate,
			{ departmentId, portId, crewboatId, tariffId }, payload);
		return data;
	};
	
	getCrewboats = async (departmentId: string) => {
		return await this.get<ApiCrewboatPersisted[]>(urls.service.crewboat.department.$departmentId.crewboat, { departmentId });
	};
	
	createApplication = async (request: ApiCreatePropertyServiceApplicationBase) => {
		return await this.post<{ id: string }>(urls.service.crewboat, {}, request);
	};
	
	createDraftApplication = async (request: ApiCreatePropertyServiceApplicationBase) => {
		return await this.post<{ id: string }>(urls.service.crewboat.draft, {}, request);
	};
	
	calculateExpectedCost = async (payload: ApiCrewboatExpectedCostRequest) => {
		return await this.post<ApiExpectedCost>(urls.service.crewboat.expectedCost.calculate, {}, payload);
	};
	
	updateStatusByTransition = async (tugboatServiceId: string, code: ApiPropertyServiceTransitionCodeEnum, reason?: string) => {
		return await this.post<void>(urls.service.tugboat.$tugboatServiceId.$code,
			{ tugboatServiceId, code },
			{ rejectionTransition: { reason } });
	};
}
