import { IMiddleware } from "@rmp/core/types/core/middleware";
import { Route } from "vue-router/types/router";
import SecurityService from "@rmp/core/services/auth/securityService";
import { AccountManagementRouteNames } from "@rmp/core/router/routeNames";
import jwtDecode from "jwt-decode";

const securityService = new SecurityService();

export default class CheckPasswordMiddleware implements IMiddleware {
	constructor() {
		
	}
	
	async invoke(to: Route, from: Route) {
		const freeRoutes = [AccountManagementRouteNames.RESET_PASSWORD.toString()];
		if(freeRoutes.includes(to.name || ""))
			return;
		
		try {
			let user = await securityService.getUser();

			if(user.isTemporaryPassword)
				return { name: AccountManagementRouteNames.RESET_PASSWORD };
		} catch (e) {
			console.error(e);
		}
	}
}
