import { ApiChatMessage } from "@rmp/core/api/types/chat/apiChatMessage";
import { ChatMessage } from "@rmp/core/types/chat/chatMessage";
import { convertToTimestamp } from "@rmp/core/utils/dates";

export default class ChatMapperProfile {
	mapToChatMessage(source: ApiChatMessage): ChatMessage {
		return {
			...source,
			dateTime: convertToTimestamp(source.dateTime) as number
		};
	}
}
