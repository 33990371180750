export const dateFormat = "dd.MM.yyyy";
export const friendlyDateFormat = "dd LLLL yyyy";
export const friendlyFormat = "dd MMMM yyyy";
export const friendlyDateFormatWithSeconds = "dd MMMM yyyy HH:mm:ss";
export const friendlyDateFormatWithoutYear = "dd LLLL";
export const dateFormatUpperCase = "DD.MM.YYYY";
export const dateTimeFormat = "dd.MM.yy HH:mm";
export const dateWithSecondsFormat = "dd.MM.yyyy HH:mm:ss";
export const isDateWithSecondsFormatValid = "DD.MM.YYYY HH:mm:ss";
export const isoDateFormat = "yyyy-MM-dd";
export const isoDate = "yyyy-MM-dd'T'HH:mm:ss";
export const isoZonedDate = "yyyy-MM-dd'T'HH:mm:ssXXX";
export const timeFormat = "HH:mm";
export const rawTimeFormat = "HHmm";
export const timeFormatWithSeconds = "HH:mm:ss";
export const timeFormatMinutesWithSeconds = "mm:ss";
export const isMyDateValidDateFormat = "DD.MM.YYYY";
export const dayOfWeek = "EEEEEE";
export const numberDayOfWeek = "i";
export const SNILS_FORMAT = "###-###-### ##";
export const PERSON_INN_FORMAT = "############";
export const LEGAL_ENTITY_INN_FORMAT = "##########";
export const OGRN_FORMAT = "#############";
export const OGRNIP_FORMAT = "###############";
export const KPP_FORMAT = "####NN###";
