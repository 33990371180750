import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useServicesBreadcrumb } from "@rmp/counterparty/stores/services/composables/useServicesBreadcrumb";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/counterparty/router";
import { RouteNames } from "@rmp/counterparty/router/routes";
import FloatingCraneServiceApplicationWorkflowService
	from "@rmp/core/services/serviceApplication/floatingCraneServiceApplicationWorkflowService";
import { FloatingCraneServiceController } from "@rmp/counterparty/api/service/floatingCrane";
import { FloatingCraneServiceWorkflowStateType } from "@rmp/core/types/services/floatingCrane/FloatingCraneServiceWorkflowStateType";
import {
	FloatingCraneServiceApplication,
	FloatingCraneServiceApplicationMapper
} from "@rmp/core/types/services/floatingCrane/floatingCraneServiceApplication";
import {
	useFloatingCraneServiceBreadcrumb
} from "@rmp/counterparty/stores/serviceDetails/floatingCrane/composables/useFloatingCraneServiceBreadcrumb";
import {
	useSignFloatingCraneServiceDocumentsBreadcrumb
} from "@rmp/counterparty/stores/uploadServiceDocuments/floatingCrane/composables/useSignFloatingCraneServiceDocumentsBreadcrumb";
import {
	ApiFloatingCraneServiceTransitionCodeEnum
} from "@rmp/core/api/types/service/floatingCrane/ApiFloatingCraneServiceTransitionCodeEnum";
import {
	BaseSignServiceDocumentsState,
	useBaseSignServiceDocumentsStore
} from "@rmp/counterparty/stores/composables/baseSignServiceDocuments";
import { formatServiceTitle } from "@rmp/core/utils/formatting";
import { ServiceController } from "@rmp/counterparty/api/service";
import { ServiceDocument, ServiceDocumentMapper } from "@rmp/core/types/services/serviceDocument";

const abortService = new AbortService();
const page = usePageStore(abortService);

const floatingCraneServiceController = new FloatingCraneServiceController(abortService);
const serviceController = new ServiceController(abortService);

const baseSignServiceDocumentsStore = useBaseSignServiceDocumentsStore<ServiceDocument>({
	abortService,
	controller: serviceController,
	mapper: ServiceDocumentMapper
});

const floatingCraneWorkflowService = new FloatingCraneServiceApplicationWorkflowService();

export interface SignFloatingCraneServiceDocumentsState extends PageState, BaseSignServiceDocumentsState<ServiceDocument> {
	service: FloatingCraneServiceApplication;
}

const getDefaultState = (): SignFloatingCraneServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...baseSignServiceDocumentsStore.getDefaultState(),
		service: {} as FloatingCraneServiceApplication
	};
};

export const useSignFloatingCraneServiceDocumentsStore = defineStore({
	id: "sign-floating-crane-service-documents",
	state: (): SignFloatingCraneServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...baseSignServiceDocumentsStore.getters,
		breadcrumbs(state: SignFloatingCraneServiceDocumentsState) {
			return [
				useServicesBreadcrumb(),
				useFloatingCraneServiceBreadcrumb(state.title, { id: state.service.id }),
				useSignFloatingCraneServiceDocumentsBreadcrumb()
			];
		},
		targetState(state: SignFloatingCraneServiceDocumentsState) {
			if(!state.service.workflowState) return;
			
			switch (state.service.workflowState.alias) {
				case FloatingCraneServiceWorkflowStateType.INITIALLY_SIGNED_BY_ENTERPRISE:
					return FloatingCraneServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY;
				case FloatingCraneServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE:
					return FloatingCraneServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY;
				case FloatingCraneServiceWorkflowStateType.INITIALLY_SIGNED_BY_COUNTERPARTY:
				case FloatingCraneServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY:
					return;
				default:
					throw new Error();
			}
		}
	},
	actions: {
		...page.actions,
		...baseSignServiceDocumentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			
			await Promise.all([
				this.fetchServiceApplication(id),
				this.fetchDocuments(id),
				this.verifySigningAuthority()
			]);
		},
		async fetchServiceApplication(serviceId: string) {
			try {
				let serviceApplication = await floatingCraneServiceController.getService(serviceId);
				
				this.service = FloatingCraneServiceApplicationMapper.map(serviceApplication);
				this.title = formatServiceTitle(this.service.type, this.service.applicationNumber);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		async approve() {
			this.approving = true;
			
			try {
				await floatingCraneServiceController.updateStatusByTransition(this.id!,
					floatingCraneWorkflowService.getTransition(this.service.workflowState.alias,
						this.targetState!) as ApiFloatingCraneServiceTransitionCodeEnum);
				
				this.service.workflowState.alias = this.targetState!;
				
				alertService.addSuccess(AlertKeys.DOCUMENTS_SENT);
				
				await router.push({ name: RouteNames.FLOATING_CRANE_SERVICE, params: { id: this.id } });
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.approving = false;
			}
		}
	}
});
