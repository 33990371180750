
export enum Permissions {
	GLOBAL_COUNTERPARTY_READ_LIST = "global:counterparty:read-list",
	GLOBAL_COUNTERPARTY_READ = "global:counterparty:read",
	OWN_COUNTERPARTY_READ = "own:counterparty:read",
	GLOBAL_COUNTERPARTY_CREATE = "global:counterparty:create",
	GLOBAL_COUNTERPARTY_UPDATE = "global:counterparty:update",
	GLOBAL_COUNTERPARTY_HEAD_READ_LIST = "global:counterparty-head:read-list",
	OWN_COUNTERPARTY_HEAD_READ_LIST = "own:counterparty-head:read-list",
	OWN_COUNTERPARTY_HEAD_READ = "own:counterparty-head:read",
	GLOBAL_COUNTERPARTY_HEAD_READ = "global:counterparty-head:read",
	GLOBAL_COUNTERPARTY_EMPLOYEE_READ_LIST = "global:counterparty-employee:read-list",
	OWN_COUNTERPARTY_EMPLOYEE_READ_LIST = "own:counterparty-employee:read-list",
	GLOBAL_COUNTERPARTY_EMPLOYEE_READ = "global:counterparty-employee:read",
	OWN_COUNTERPARTY_EMPLOYEE_READ = "own:counterparty-employee:read",
	GLOBAL_COUNTERPARTY_EMPLOYEE_CREATE = "global:counterparty-employee:create",
	OWN_COUNTERPARTY_EMPLOYEE_CREATE = "own:counterparty-employee:create",
	GLOBAL_COUNTERPARTY_EMPLOYEE_UPDATE = "global:counterparty-employee:update",
	OWN_COUNTERPARTY_EMPLOYEE_UPDATE = "own:counterparty-employee:update",
	GLOBAL_COUNTERPARTY_FNS_UPDATE = "global:counterparty-fns:update",
	GLOBAL_COUNTERPARTY_FNS_READ = "global:counterparty-fns:read",
	GLOBAL_COUNTERPARTY_MASTER_ACCOUNT_UPDATE = "global:counterparty-master-account:update",
	GLOBAL_COUNTERPARTY_MASTER_ACCOUNT_CREATE = "global:counterparty-master-account:create",
	GLOBAL_COUNTERPARTY_MASTER_ACCOUNT_READ = "global:counterparty-master-account:read",
	GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_READ_LIST = "global:counterparty-employee-trust:read-list",
	OWN_COUNTERPARTY_EMPLOYEE_TRUST_READ_LIST = "own:counterparty-employee-trust:read-list",
	GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_READ = "global:counterparty-employee-trust:read",
	OWN_COUNTERPARTY_EMPLOYEE_TRUST_READ = "own:counterparty-employee-trust:read",
	GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_CREATE = "global:counterparty-employee-trust:create",
	OWN_COUNTERPARTY_EMPLOYEE_TRUST_CREATE = "own:counterparty-employee-trust:create",
	GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_ACCEPT = "global:counterparty-employee-trust:accept",
	GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_DECLINE = "global:counterparty-employee-trust:decline",
	GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_CANCEL = "global:counterparty-employee-trust:cancel",
	GLOBAL_COUNTERPARTY_EMPLOYEE_PROFILE_READ = "global:counterparty-employee-profile:read",
	OWN_COUNTERPARTY_EMPLOYEE_PROFILE_READ = "own:counterparty-employee-profile:read",
	GLOBAL_ACCOUNT_READ_LIST = "global:account:read-list",
	GLOBAL_ACCOUNT_UPDATE = "global:account:update",
	GLOBAL_ACCOUNT_READ = "global:account:read",
	GLOBAL_ACCOUNT_PROFILE_READ_LIST = "global:account-profile:read-list",
	GLOBAL_ACCOUNT_PROFILE_READ = "global:account-profile:read",
	GLOBAL_ACCOUNT_PROFILE_UPDATE = "global:account-profile:update",
	GLOBAL_MASTER_ACCOUNT_APPLICATION_READ_LIST = "global:master-account-application:read-list",
	GLOBAL_MASTER_ACCOUNT_APPLICATION_READ = "global:master-account-application:read",
	GLOBAL_MASTER_ACCOUNT_APPLICATION_UPDATE = "global:master-account-application:update",
	GLOBAL_SERVICE_READ_LIST = "global:service:read-list",
	OWN_SERVICE_READ_LIST = "own:service:read-list",
	GLOBAL_SERVICE_READ = "global:service:read",
	OWN_SERVICE_READ = "own:service:read",
	GLOBAL_SERVICE_UPDATE = "global:service:update",
	OWN_SERVICE_UPDATE = "own:service:update",
	OWN_SERVICE_CREATE = "own:service:create",
	GLOBAL_AGREEMENT_READ_LIST = "global:agreement:read-list",
	OWN_AGREEMENT_READ_LIST = "own:agreement:read-list",
	GLOBAL_AGREEMENT_READ = "global:agreement:read",
	OWN_AGREEMENT_READ = "own:agreement:read",
	GLOBAL_AGREEMENT_UPDATE = "global:agreement:update",
	OWN_AGREEMENT_UPDATE = "own:agreement:update",
	OWN_AGREEMENT_CREATE = "own:agreement:create",
	OWN_COUNTERPARTY_DOCUMENT_READ_LIST = "own:counterparty-document:read-list",
	OWN_COUNTERPARTY_DOCUMENT_READ = "own:counterparty-document:read",
	OWN_COUNTERPARTY_DOCUMENT_UPDATE = "own:counterparty-document:update",
	OWN_COUNTERPARTY_DOCUMENT_CREATE = "own:counterparty-document:create",
	GLOBAL_COUNTERPARTY_PROPERTY_READ_LIST = "global:counterparty-property:read-list",
	OWN_COUNTERPARTY_PROPERTY_READ_LIST = "own:counterparty-property:read-list",
	GLOBAL_COUNTERPARTY_PROPERTY_READ = "global:counterparty-property:read",
	OWN_COUNTERPARTY_PROPERTY_READ = "own:counterparty-property:read",
	OWN_COUNTERPARTY_PROPERTY_CREATE = "own:counterparty-property:create",
	GLOBAL_COUNTERPARTY_PROPERTY_ACCEPT = "global:counterparty-property:accept",
	GLOBAL_COUNTERPARTY_PROPERTY_DECLINE = "global:counterparty-property:decline",
	GLOBAL_OFFICE_READ_LIST = "global:office:read-list",
	GLOBAL_OFFICE_READ = "global:office:read",
	OWN_OFFICE_READ = "own:office:read",
	GLOBAL_OFFICE_CREATE = "global:office:create",
	GLOBAL_OFFICE_UPDATE = "global:office:update",
	GLOBAL_OFFICE_EMPLOYEE_READ_LIST = "global:office-employee:read-list",
	OWN_OFFICE_EMPLOYEE_READ_LIST = "own:office-employee:read-list",
	GLOBAL_OFFICE_EMPLOYEE_READ = "global:office-employee:read",
	OWN_OFFICE_EMPLOYEE_READ = "own:office-employee:read",
	GLOBAL_OFFICE_EMPLOYEE_CREATE = "global:office-employee:create",
	OWN_OFFICE_EMPLOYEE_CREATE = "own:office-employee:create",
	GLOBAL_OFFICE_EMPLOYEE_UPDATE = "global:office-employee:update",
	OWN_OFFICE_EMPLOYEE_UPDATE = "own:office-employee:update",
	GLOBAL_OFFICE_EMPLOYEE_TRUST_READ_LIST = "global:office-employee-trust:read-list",
	OWN_OFFICE_EMPLOYEE_TRUST_READ_LIST = "own:office-employee-trust:read-list",
	GLOBAL_OFFICE_EMPLOYEE_TRUST_READ = "global:office-employee-trust:read",
	OWN_OFFICE_EMPLOYEE_TRUST_READ = "own:office-employee-trust:read",
	GLOBAL_OFFICE_EMPLOYEE_TRUST_CREATE = "global:office-employee-trust:create",
	OWN_OFFICE_EMPLOYEE_TRUST_CREATE = "own:office-employee-trust:create",
	GLOBAL_OFFICE_EMPLOYEE_TRUST_ACCEPT = "global:office-employee-trust:accept",
	GLOBAL_OFFICE_EMPLOYEE_TRUST_DECLINE = "global:office-employee-trust:decline",
	GLOBAL_OFFICE_EMPLOYEE_TRUST_CANCEL = "global:office-employee-trust:cancel",
	GLOBAL_OFFICE_EMPLOYEE_PROFILE_READ = "global:office-employee-profile:read",
	OWN_OFFICE_EMPLOYEE_PROFILE_READ = "own:office-employee-profile:read",
	GLOBAL_OFFICE_PROPERTY_READ_LIST = "global:office-property:read-list",
	OWN_OFFICE_PROPERTY_READ_LIST = "own:office-property:read-list",
	GLOBAL_OFFICE_PROPERTY_READ = "global:office-property:read",
	OWN_OFFICE_PROPERTY_READ = "own:office-property:read",
	OWN_OFFICE_PROPERTY_CREATE = "own:office-property:create",
	GLOBAL_OFFICE_PROPERTY_CREATE = "global:office-property:create",
	GLOBAL_OFFICE_PROPERTY_ACCEPT = "global:office-property:accept",
	GLOBAL_OFFICE_PROPERTY_DECLINE = "global:office-property:decline",
	OWN_TUGBOAT_SERVICE_CREATE = "own:tugboat-service:create",
	OWN_TUGBOAT_SERVICE_UPDATE = "own:tugboat-service:update",
	OWN_TUGBOAT_SERVICE_SEND = "own:tugboat-service:send",
	OWN_TUGBOAT_SERVICE_APPROVE_BY_DISPATCHER = "own:tugboat-service:approve-by-dispatcher",
	OWN_TUGBOAT_SERVICE_APPROVE_BY_FINANSIST = "own:tugboat-service:approve-by-finansist",
	OWN_TUGBOAT_SERVICE_APPROVE_BY_PILOT = "own:tugboat-service:approve-by-pilot",
	OWN_TUGBOAT_SERVICE_START = "own:tugboat-service:start",
	OWN_TUGBOAT_SERVICE_FINISH = "own:tugboat-service:finish",
	OWN_TUGBOAT_SERVICE_FINALLY_SIGN_BY_ENTERPRISE = "own:tugboat-service:finally-sign-by-enterprise",
	OWN_TUGBOAT_SERVICE_FINALLY_SIGN_BY_COUNTERPARTY = "own:tugboat-service:finally-sign-by-counterparty",
	OWN_TUGBOAT_SERVICE_COMPLETE = "own:tugboat-service:complete",
	OWN_TUGBOAT_SERVICE_CANCEL = "own:tugboat-service:cancel",
	OWN_TUGBOAT_SERVICE_REJECT = "own:tugboat-service:reject",
	OWN_CREWBOAT_SERVICE_CREATE = "own:crewboat-service:create",
	OWN_CREWBOAT_SERVICE_UPDATE = "own:crewboat-service:update",
	OWN_CREWBOAT_SERVICE_SEND = "own:crewboat-service:send",
	OWN_CREWBOAT_SERVICE_APPROVE_BY_DISPATCHER = "own:crewboat-service:approve-by-dispatcher",
	OWN_CREWBOAT_SERVICE_APPROVE_BY_FINANSIST = "own:crewboat-service:approve-by-finansist",
	OWN_CREWBOAT_SERVICE_START = "own:crewboat-service:start",
	OWN_CREWBOAT_SERVICE_FINISH = "own:crewboat-service:finish",
	OWN_CREWBOAT_SERVICE_FINALLY_SIGN_BY_ENTERPRISE = "own:crewboat-service:finally-sign-by-enterprise",
	OWN_CREWBOAT_SERVICE_FINALLY_SIGN_BY_COUNTERPARTY = "own:crewboat-service:finally-sign-by-counterparty",
	OWN_CREWBOAT_SERVICE_COMPLETE = "own:crewboat-service:complete",
	OWN_CREWBOAT_SERVICE_CANCEL = "own:crewboat-service:cancel",
	OWN_CREWBOAT_SERVICE_REJECT = "own:crewboat-service:reject",
	OWN_PIER_SERVICE_CREATE = "own:pier-service:create",
	OWN_PIER_SERVICE_UPDATE = "own:pier-service:update",
	OWN_PIER_SERVICE_SEND = "own:pier-service:send",
	OWN_PIER_SERVICE_APPROVE_BY_DISPATCHER = "own:pier-service:approve-by-dispatcher",
	OWN_PIER_SERVICE_APPROVE_BY_FINANSIST = "own:pier-service:approve-by-finansist",
	OWN_PIER_SERVICE_START = "own:pier-service:start",
	OWN_PIER_SERVICE_FINISH = "own:pier-service:finish",
	OWN_PIER_SERVICE_FINALLY_SIGN_BY_ENTERPRISE = "own:pier-service:finally-sign-by-enterprise",
	OWN_PIER_SERVICE_FINALLY_SIGN_BY_COUNTERPARTY = "own:pier-service:finally-sign-by-counterparty",
	OWN_PIER_SERVICE_COMPLETE = "own:pier-service:complete",
	OWN_PIER_SERVICE_CANCEL = "own:pier-service:cancel",
	OWN_PIER_SERVICE_REJECT = "own:pier-service:reject",
	OWN_GEODETIC_HYDROGRAPHIC_SERVICE_CREATE = "own:geodetic-hydrographic-service:create",
	OWN_GEODETIC_HYDROGRAPHIC_SERVICE_UPDATE = "own:geodetic-hydrographic-service:update",
	OWN_GEODETIC_HYDROGRAPHIC_SERVICE_SEND = "own:geodetic-hydrographic-service:send",
	OWN_GEODETIC_HYDROGRAPHIC_SERVICE_APPROVE_BY_NGO_SERVICE_EMPLOYEE = "own:geodetic-hydrographic-service:approve-by-ngo-service-employee",
	OWN_GEODETIC_HYDROGRAPHIC_SERVICE_APPROVE_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE = "own:geodetic-hydrographic-service:approve-by-fleet-operations-service-employee",
	OWN_GEODETIC_HYDROGRAPHIC_SERVICE_INITIALLY_SIGN_BY_ENTERPRISE = "own:geodetic-hydrographic-service:initially-sign-by-enterprise",
	OWN_GEODETIC_HYDROGRAPHIC_SERVICE_INITIALLY_SIGN_BY_COUNTERPARTY = "own:geodetic-hydrographic-service:initially-sign-by-counterparty",
	OWN_GEODETIC_HYDROGRAPHIC_SERVICE_START = "own:geodetic-hydrographic-service:start",
	OWN_GEODETIC_HYDROGRAPHIC_SERVICE_FINISH = "own:geodetic-hydrographic-service:finish",
	OWN_GEODETIC_HYDROGRAPHIC_SERVICE_FINALLY_SIGN_BY_ENTERPRISE = "own:geodetic-hydrographic-service:finally-sign-by-enterprise",
	OWN_GEODETIC_HYDROGRAPHIC_SERVICE_FINALLY_SIGN_BY_COUNTERPARTY = "own:geodetic-hydrographic-service:finally-sign-by-counterparty",
	OWN_GEODETIC_HYDROGRAPHIC_SERVICE_COMPLETE = "own:geodetic-hydrographic-service:complete",
	OWN_GEODETIC_HYDROGRAPHIC_SERVICE_CANCEL = "own:geodetic-hydrographic-service:cancel",
	OWN_GEODETIC_HYDROGRAPHIC_SERVICE_REJECT = "own:geodetic-hydrographic-service:reject",
	OWN_INFORMATION_SERVICE_CREATE = "own:information-service:create",
	OWN_INFORMATION_SERVICE_UPDATE = "own:information-service:update",
	OWN_INFORMATION_SERVICE_SEND = "own:information-service:send",
	OWN_INFORMATION_SERVICE_APPROVE_BY_DISPATCHER = "own:information-service:approve-by-dispatcher",
	OWN_INFORMATION_SERVICE_APPROVE_BY_FINANSIST = "own:information-service:approve-by-finansist",
	OWN_INFORMATION_SERVICE_START = "own:information-service:start",
	OWN_INFORMATION_SERVICE_FINISH = "own:information-service:finish",
	OWN_INFORMATION_SERVICE_FINALLY_SIGN_BY_ENTERPRISE = "own:information-service:finally-sign-by-enterprise",
	OWN_INFORMATION_SERVICE_FINALLY_SIGN_BY_COUNTERPARTY = "own:information-service:finally-sign-by-counterparty",
	OWN_INFORMATION_SERVICE_COMPLETE = "own:information-service:complete",
	OWN_INFORMATION_SERVICE_CANCEL = "own:information-service:cancel",
	OWN_INFORMATION_SERVICE_REJECT = "own:information-service:reject",
	OWN_FLOATING_CRANE_SERVICE_CREATE = "own:floating-crane-service:create",
	OWN_FLOATING_CRANE_SERVICE_UPDATE = "own:floating-crane-service:update",
	OWN_FLOATING_CRANE_SERVICE_SEND = "own:floating-crane-service:send",
	OWN_FLOATING_CRANE_SERVICE_INITIALLY_SIGN_BY_ENTERPRISE = "own:floating-crane-service:initially-sign-by-enterprise",
	OWN_FLOATING_CRANE_SERVICE_INITIALLY_SIGN_BY_COUNTERPARTY = "own:floating-crane-service:initially-sign-by-counterparty",
	OWN_FLOATING_CRANE_SERVICE_START = "own:floating-crane-service:start",
	OWN_FLOATING_CRANE_SERVICE_FINISH = "own:floating-crane-service:finish",
	OWN_FLOATING_CRANE_SERVICE_FINALLY_SIGN_BY_ENTERPRISE = "own:floating-crane-service:finally-sign-by-enterprise",
	OWN_FLOATING_CRANE_SERVICE_FINALLY_SIGN_BY_COUNTERPARTY = "own:floating-crane-service:finally-sign-by-counterparty",
	OWN_FLOATING_CRANE_SERVICE_COMPLETE = "own:floating-crane-service:complete",
	OWN_FLOATING_CRANE_SERVICE_CANCEL = "own:floating-crane-service:cancel",
	OWN_FLOATING_CRANE_SERVICE_REJECT = "own:floating-crane-service:reject",
	OWN_SNO_INSTALLATION_SERVICE_CREATE = "own:sno-installation-service:create",
	OWN_SNO_INSTALLATION_SERVICE_UPDATE = "own:sno-installation-service:update",
	OWN_SNO_INSTALLATION_SERVICE_SEND = "own:sno-installation-service:send",
	OWN_SNO_INSTALLATION_SERVICE_APPROVE_BY_NGO_SERVICE_EMPLOYEE = "own:sno-installation-service:approve-by-ngo-service-employee",
	OWN_SNO_INSTALLATION_SERVICE_APPROVE_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE = "own:sno-installation-service:approve-by-fleet-operations-service-employee",
	OWN_SNO_INSTALLATION_SERVICE_INITIALLY_SIGN_BY_ENTERPRISE = "own:sno-installation-service:initially-sign-by-enterprise",
	OWN_SNO_INSTALLATION_SERVICE_INITIALLY_SIGN_BY_COUNTERPARTY = "own:sno-installation-service:initially-sign-by-counterparty",
	OWN_SNO_INSTALLATION_SERVICE_START = "own:sno-installation-service:start",
	OWN_SNO_INSTALLATION_SERVICE_FINISH = "own:sno-installation-service:finish",
	OWN_SNO_INSTALLATION_SERVICE_FINALLY_SIGN_BY_ENTERPRISE = "own:sno-installation-service:finally-sign-by-enterprise",
	OWN_SNO_INSTALLATION_SERVICE_FINALLY_SIGN_BY_COUNTERPARTY = "own:sno-installation-service:finally-sign-by-counterparty",
	OWN_SNO_INSTALLATION_SERVICE_COMPLETE = "own:sno-installation-service:complete",
	OWN_SNO_INSTALLATION_SERVICE_CANCEL = "own:sno-installation-service:cancel",
	OWN_SNO_INSTALLATION_SERVICE_REJECT = "own:sno-installation-service:reject",
	OWN_DREDGING_SERVICE_CREATE = "own:dredging-service:create",
	OWN_DREDGING_SERVICE_UPDATE = "own:dredging-service:update",
	OWN_DREDGING_SERVICE_SEND = "own:dredging-service:send",
	OWN_DREDGING_SERVICE_APPROVE_BY_FLEET_OPERATIONS_SERVICE_EMPLOYEE = "own:dredging-service:approve-by-fleet-operations-service-employee",
	OWN_DREDGING_SERVICE_APPROVE_BY_FLEET_OPERATIONS_HEAD_OFFICE_EMPLOYEE = "own:dredging-service:approve-by-fleet-operations-head-office-employee",
	OWN_DREDGING_SERVICE_INITIALLY_SIGN_BY_ENTERPRISE = "own:dredging-service:initially-sign-by-enterprise",
	OWN_DREDGING_SERVICE_INITIALLY_SIGN_BY_COUNTERPARTY = "own:dredging-service:initially-sign-by-counterparty",
	OWN_DREDGING_SERVICE_START = "own:dredging-service:start",
	OWN_DREDGING_SERVICE_FINISH = "own:dredging-service:finish",
	OWN_DREDGING_SERVICE_FINALLY_SIGN_BY_ENTERPRISE = "own:dredging-service:finally-sign-by-enterprise",
	OWN_DREDGING_SERVICE_FINALLY_SIGN_BY_COUNTERPARTY = "own:dredging-service:finally-sign-by-counterparty",
	OWN_DREDGING_SERVICE_COMPLETE = "own:dredging-service:complete",
	OWN_DREDGING_SERVICE_CANCEL = "own:dredging-service:cancel",
	OWN_DREDGING_SERVICE_REJECT = "own:dredging-service:reject",
	OWN_TUGBOAT_AGREEMENT_CREATE = "own:tugboat-agreement:create",
	OWN_TUGBOAT_AGREEMENT_SEND = "own:tugboat-agreement:send",
	OWN_TUGBOAT_AGREEMENT_NEGOTIATE = "own:tugboat-agreement:negotiate",
	OWN_TUGBOAT_AGREEMENT_FINALLY_SIGN_BY_ENTERPRISE = "own:tugboat-agreement:finally-sign-by-enterprise",
	OWN_TUGBOAT_AGREEMENT_FINALLY_SIGN_BY_COUNTERPARTY = "own:tugboat-agreement:finally-sign-by-counterparty",
	OWN_TUGBOAT_AGREEMENT_START = "own:tugboat-agreement:start",
	OWN_TUGBOAT_AGREEMENT_FINISH = "own:tugboat-agreement:finish",
	OWN_TUGBOAT_AGREEMENT_CANCEL = "own:tugboat-agreement:cancel",
	OWN_TUGBOAT_AGREEMENT_REJECT = "own:tugboat-agreement:reject",
	OWN_CREWBOAT_AGREEMENT_CREATE = "own:crewboat-agreement:create",
	OWN_CREWBOAT_AGREEMENT_SEND = "own:crewboat-agreement:send",
	OWN_CREWBOAT_AGREEMENT_NEGOTIATE = "own:crewboat-agreement:negotiate",
	OWN_CREWBOAT_AGREEMENT_FINALLY_SIGN_BY_ENTERPRISE = "own:crewboat-agreement:finally-sign-by-enterprise",
	OWN_CREWBOAT_AGREEMENT_FINALLY_SIGN_BY_COUNTERPARTY = "own:crewboat-agreement:finally-sign-by-counterparty",
	OWN_CREWBOAT_AGREEMENT_START = "own:crewboat-agreement:start",
	OWN_CREWBOAT_AGREEMENT_FINISH = "own:crewboat-agreement:finish",
	OWN_CREWBOAT_AGREEMENT_CANCEL = "own:crewboat-agreement:cancel",
	OWN_CREWBOAT_AGREEMENT_REJECT = "own:crewboat-agreement:reject",
	OWN_PIER_AGREEMENT_CREATE = "own:pier-agreement:create",
	OWN_PIER_AGREEMENT_SEND = "own:pier-agreement:send",
	OWN_PIER_AGREEMENT_NEGOTIATE = "own:pier-agreement:negotiate",
	OWN_PIER_AGREEMENT_FINALLY_SIGN_BY_ENTERPRISE = "own:pier-agreement:finally-sign-by-enterprise",
	OWN_PIER_AGREEMENT_FINALLY_SIGN_BY_COUNTERPARTY = "own:pier-agreement:finally-sign-by-counterparty",
	OWN_PIER_AGREEMENT_START = "own:pier-agreement:start",
	OWN_PIER_AGREEMENT_FINISH = "own:pier-agreement:finish",
	OWN_PIER_AGREEMENT_CANCEL = "own:pier-agreement:cancel",
	OWN_PIER_AGREEMENT_REJECT = "own:pier-agreement:reject",
	OWN_INFORMATION_AGREEMENT_CREATE = "own:information-agreement:create",
	OWN_INFORMATION_AGREEMENT_SEND = "own:information-agreement:send",
	OWN_INFORMATION_AGREEMENT_NEGOTIATE = "own:information-agreement:negotiate",
	OWN_INFORMATION_AGREEMENT_FINALLY_SIGN_BY_ENTERPRISE = "own:information-agreement:finally-sign-by-enterprise",
	OWN_INFORMATION_AGREEMENT_FINALLY_SIGN_BY_COUNTERPARTY = "own:information-agreement:finally-sign-by-counterparty",
	OWN_INFORMATION_AGREEMENT_START = "own:information-agreement:start",
	OWN_INFORMATION_AGREEMENT_FINISH = "own:information-agreement:finish",
	OWN_INFORMATION_AGREEMENT_CANCEL = "own:information-agreement:cancel",
	OWN_INFORMATION_AGREEMENT_REJECT = "own:information-agreement:reject",
	OWN_DESTINATION_UPDATE = 'own:destination:update'
}
