import InvalidArgumentException from "@rmp/core/exceptions/invalidArgumentException";
import { ApiAgreementDocumentKindEnum } from "@rmp/core/api/types/agreement/ApiAgreementDocumentKindEnum";

export enum AgreementDocumentKindEnum {
	// Устав организации
	ORGANIZATION_CHARTER = "ORGANIZATION_CHARTER",
	
	// Приказ о назначении руководителя
	HEAD_APPOINTMENTS_ORDER = "HEAD_APPOINTMENTS_ORDER",
	
	// Учредительный договор
	FOUNDATION_AGREEMENT = "FOUNDATION_AGREEMENT",
	
	// Договор
	AGREEMENT = "AGREEMENT",
	
	// Лист согласования
	AGREEMENT_NEGOTIATION_LIST = "AGREEMENT_NEGOTIATION_LIST"
}

export default class AgreementDocumentKindMapper {
	static mapAgreementDocumentKind(source: ApiAgreementDocumentKindEnum): AgreementDocumentKindEnum {
		switch (source) {
			case ApiAgreementDocumentKindEnum.ORGANIZATION_CHARTER:
				return AgreementDocumentKindEnum.ORGANIZATION_CHARTER;
			case ApiAgreementDocumentKindEnum.HEAD_APPOINTMENTS_ORDER:
				return AgreementDocumentKindEnum.HEAD_APPOINTMENTS_ORDER;
			case ApiAgreementDocumentKindEnum.FOUNDATION_AGREEMENT:
				return AgreementDocumentKindEnum.FOUNDATION_AGREEMENT;
			case ApiAgreementDocumentKindEnum.AGREEMENT_NEGOTIATION_LIST:
				return AgreementDocumentKindEnum.AGREEMENT_NEGOTIATION_LIST;
			case ApiAgreementDocumentKindEnum.AGREEMENT:
				return AgreementDocumentKindEnum.AGREEMENT;
			default:
				throw new InvalidArgumentException("source", source);
		}
	}
}
