export interface RegisterPaginationState {
	paging: {
		total: number,
		page: number,
		pageSize: number
	};
}

export function useRegisterPaginationStore() {
	const actions = {
		resetPagingPage(this: RegisterPaginationState) {
			this.paging.page = 1;
		},
		setPagingPage(this: RegisterPaginationState, value: number) {
			this.paging.page = value;
		},
		setPagingPageSize(this: RegisterPaginationState, value: number) {
			this.paging.pageSize = value;
		},
		setPagingTotal(this: RegisterPaginationState, value: number) {
			this.paging.total = value;
		}
	};

	return {
		actions
	};
}
