import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { Store } from "pinia";
import AbortService from "@rmp/core/services/abortService";
import { StorageController } from "@rmp/core/api/storage";
import { CounterpartyController } from "@rmp/core/api/counterparty";
import { BaseDocumentsController } from "@rmp/core/api/shared/baseDocumentsController";
import { BaseDocument } from "@rmp/core/types/services/baseDocument";

export interface BaseSignServiceDocumentsState<DocumentType extends BaseDocument> {
	id: string;
	title: string;
	documentsLoading: boolean;
	approving: boolean;
	canSign: boolean;
	documents: DocumentType[];
}

interface DocumentsBaseMapper<DocumentType extends BaseDocument> {
	map: (source: any, titleFirstPart: string) => DocumentType;
}

interface BaseSignServiceDocumentsOptions<DocumentType extends BaseDocument> {
	abortService: AbortService;
	controller: BaseDocumentsController
	mapper: DocumentsBaseMapper<DocumentType>;
}

export const useBaseSignServiceDocumentsStore = <DocumentType extends BaseDocument>(options: BaseSignServiceDocumentsOptions<DocumentType>) => {
	type BaseSignServiceDocumentsStore = Store<string, BaseSignServiceDocumentsState<DocumentType>, {}, {
		[key: string]: any,
		fetchDocuments(serviceId: string): Promise<void>
	}>;
	
	const storageController = new StorageController(options.abortService);
	const counterpartyController = new CounterpartyController(options.abortService);
	
	const getDefaultState = (): BaseSignServiceDocumentsState<DocumentType> => {
		return {
			id: "",
			title: "",
			documentsLoading: false,
			approving: false,
			canSign: false,
			documents: []
		};
	};
	
	const getters = {};
	
	const actions = {
		async fetchDocuments(this: BaseSignServiceDocumentsState<DocumentType>, serviceId: string) {
			this.documentsLoading = true;
			
			try {
				const { documents } = await options.controller.getDocuments(serviceId);
				
				this.documents = documents.map(x => options.mapper.map(x, this.title));
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.documentsLoading = false;
			}
		},
		async trySaveSignature(this: BaseSignServiceDocumentsStore, id: string, signature: string): Promise<boolean> {
			let document = this.documents.find(x => x.fileId === id)!;
			document.isSigning = true;
			
			try {
				await storageController.signFile(document.fileId, signature);
				
				this.documents = [];
				await this.fetchDocuments(this.id);
				
				return true;
			} catch (ex) {
				console.error(ex);
				AlertHelper.handleGeneralRequestErrors(ex);
				
				return false;
			} finally {
				document.isSigning = false;
			}
		},
		async verifySigningAuthority(this: BaseSignServiceDocumentsStore) {
			try {
				const { verified } = await counterpartyController.verifySigningAuthority();
				
				this.canSign = verified;
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		}
	};
	
	return { getters, actions, getDefaultState };
};
