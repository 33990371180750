export enum ApiServiceDocumentKindEnum {
	UNDEFINED = "Undefined",
	
	// Счёт-фактура
	FACTURE_INVOICE = "FactureInvoice",
	
	// Счёт
	INVOICE = "Invoice",
	
	// Акт об оказании услуг
	SERVICE_ACCEPTANCE_CERTIFICATE = "ServiceAcceptanceCertificate",
	
	// Графическое приложение
	GRAPHIC_ANNEX = "GraphicAnnex",
	
	// Разрешение на строительство
	BUILDING_PERMIT = "BuildingPermit",
	
	// Разрешение Росприроднадзора на захоронение донного грунта, извлеченного при производстве работ по реализации указанного проекта
	NATURE_INSPECTOR_RESOLUTION = "NatureInspectorateResolution",
	
	// Главгосэкспертиза о ГЭЭ
	CHIEF_STATE_EXPERTISE = "ChiefStateExpertise",
	
	// Решение о предоставлении водного объекта (свалка грунта) в пользование Заказчика
	PROVIDING_WATER_FACILITY_DECISION = "ProvidingWaterFacilityDecision",
	
	// Заключение Росрыболовства о согласовании осуществления деятельности в рамках реализации указанного проекта
	ROSFISHING_CONCLUSION = "RosfishingConclusion",
	
	// Договор на оказание услуг
	AGREEMENT = "Agreement",
	
	// Устав организации
	ORGANIZATION_CHARTER = "OrganizationCharter",
	
	// Учредительный договор
	FOUNDATION_AGREEMENT = "FoundationAgreement",
	
	// Наряд-задание
	WORK_ORDER = "WorkOrder",
	// Приказ-задание
	ORDER_ASSIGNMENT = "OrderAssignment"
}
