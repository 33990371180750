import { defineStore } from "pinia";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import PermissionsResolver from "@rmp/core/api/authorization/permissionsResolver";
import InvalidOperationException from "@rmp/core/exceptions/invalidOperationException";
import { Permissions } from "@rmp/core/constants/permissions";
import { ProfileType } from "@rmp/core/types/ProfileType";
import User from "@rmp/core/stores/user/types/user";
import AbortService from "@rmp/core/services/abortService";
import { AccountController } from "@rmp/core/api/account";
import { plainToInstance } from "class-transformer";
import ApiLegalEntityProfile from "@rmp/core/api/types/account/profile/apiLegalEntityProfile";
import ApiEntrepreneurProfile from "@rmp/core/api/types/account/profile/apiEntrepreneurProfile";
import ApiForeignOrganizationProfile from "@rmp/core/api/types/account/profile/apiForeignOrganizationProfile";
import { ApiAccountProfileBase } from "@rmp/core/api/types/account/profile/apiAccountProfileBase";
import SecurityService from "@rmp/core/services/auth/securityService";
import { AccountProfileBase } from "@rmp/core/stores/user/types/accountProfileBase";
import { addDays, differenceInDays } from "date-fns";

const securityService = new SecurityService();
const abortService = new AbortService();
const accountController = new AccountController(abortService);

const PASSWORD_EXPIRY_NOTIFICATION_START_TIME = addDays(new Date(), 10).getTime();

export interface UserState {
	permissions: string[],
	initialized: boolean,
	user: User
}

const getDefaultState = (): UserState => {
	return {
		permissions: [],
		initialized: false,
		user: new User()
	};
};

const permissionsResolver = new PermissionsResolver();

export const useUserStore = defineStore({
	id: "user",
	state: (): UserState => getDefaultState(),
	getters: {
		profile(state: UserState) {
			return state.user.profile;
		},
		isMasterAccount(state: UserState) {
			if(state.user.profile) {
				switch (state.user.profile.type) {
					case ProfileType.MASTER_ACCOUNT_LEGAL_ENTITY:
					case ProfileType.MASTER_ACCOUNT_ENTREPRENEUR:
					case ProfileType.MASTER_ACCOUNT_FOREIGN_ORGANIZATION:
					case ProfileType.MASTER_ACCOUNT_OFFICE:
						return true;
					default:
						return false;
				}
			}
		},
		passwordExpired(state: UserState) {
			return Date.now() <= state.user.passwordExpiresAt;
		},
		passwordCloseExpiration(state: UserState) {
			return state.user.passwordExpiresAt <= PASSWORD_EXPIRY_NOTIFICATION_START_TIME;
		},
		passwordExpirationRemainingDays(state: UserState) {
			return differenceInDays(state.user.passwordExpiresAt, new Date());
		},
		isCounterpartyEmployee(state: UserState) {
			if(state.user.profile) {
				switch (state.user.profile.type) {
					case ProfileType.COUNTERPARTY_LEGAL_ENTITY_EMPLOYEE:
					case ProfileType.COUNTERPARTY_ENTREPRENEUR_EMPLOYEE:
					case ProfileType.COUNTERPARTY_PHYSICAL_PERSON_EMPLOYEE:
					case ProfileType.COUNTERPARTY_FOREIGN_ORGANIZATION_EMPLOYEE:
						return true;
					default:
						return false;
				}
			}
		},
		isEntrepreneurEmployee(state: UserState) {
			if(!state.user.profile) return;
			
			return state.user.profile.type === ProfileType.COUNTERPARTY_ENTREPRENEUR_EMPLOYEE;
		},
		isLegalEntityEmployee(state: UserState) {
			if(!state.user.profile) return;
			
			return state.user.profile.type === ProfileType.COUNTERPARTY_LEGAL_ENTITY_EMPLOYEE;
		},
		isOfficeEmployee(state: UserState) {
			if(!state.user.profile)
				return;
			
			return state.user.profile.type === ProfileType.OFFICE_EMPLOYEE;
		}
	},
	actions: {
		async initialize() {
			if(this.initialized)
				throw new InvalidOperationException();
			
			abortService.initialize();
			
			try {
				await Promise.all([
					this.fetchUserinfo(),
					this.fetchPermissions(),
					this.fetchProfiles()
				]);
				
				this.initialized = true;
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		destroy() {
			abortService.abort();
		},
		async fetchPermissions() {
			try {
				this.permissions = await permissionsResolver.resolve();
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		check(requiredPermissions: Permissions[], any = true): boolean {
			if(!this.initialized)
				throw new InvalidOperationException();
			
			if(!requiredPermissions.length)
				return true;
			
			if(any) {
				return requiredPermissions.some(x => this.permissions.includes(x));
			} else {
				return requiredPermissions.every(x => this.permissions.includes(x));
			}
		},
		async fetchProfiles() {
			this.user.isProfilesLoading = true;
			
			try {
				let profiles = await accountController.getAccountProfiles();
				this.user.profiles = profiles.map((x) => {
					return plainToInstance(AccountProfileBase, {
						...x,
						...x.profile
					});
				});
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.user.isProfilesLoading = false;
			}
		},
		async fetchUserinfo() {
			this.user.isUserinfoLoading = true;
			
			try {
				let { account, profile, passwordExpiresAt } = await securityService.getUser();
				
				this.user.account = account;
				this.user.passwordExpiresAt = passwordExpiresAt;
				
				if(profile) {
					switch (profile.type) {
						case ProfileType.MASTER_ACCOUNT_LEGAL_ENTITY:
							this.user.profile = plainToInstance(ApiLegalEntityProfile, profile);
							break;
						case ProfileType.MASTER_ACCOUNT_ENTREPRENEUR:
							this.user.profile = plainToInstance(ApiEntrepreneurProfile, profile);
							break;
						case ProfileType.MASTER_ACCOUNT_FOREIGN_ORGANIZATION:
							this.user.profile = plainToInstance(ApiForeignOrganizationProfile, profile);
							break;
						default:
							this.user.profile = plainToInstance(ApiAccountProfileBase, profile);
					}
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.user.isUserinfoLoading = false;
			}
		}
	}
});

