import { ApiTariffTotalAmount } from "@rmp/core/api/types/service/apiTariffTotalAmount";
import { ApiServiceApplicationPropertyBase } from "@rmp/core/api/types/service/base/apiServiceApplicationPropertyBase";

export interface ServiceApplicationPropertyBase {
	workingTime?: string;
	tariffId?: string;
	totalAmount?: ApiTariffTotalAmount;
}

export class ServiceApplicationPropertyBaseMapper {
	static map(source: ApiServiceApplicationPropertyBase): ServiceApplicationPropertyBase {
		return {
			...source
		};
	}
}
