import {
	ApiLegalEntityCounterpartyHead,
	ApiLegalEntityCounterpartyHeadPersisted
} from "@rmp/core/api/types/counterparty/apiLegalEntityCounterpartyHead";

export interface LegalEntityCounterpartyHead {
	id: string;
	inn: string;
	snils: string;
	fullName: string;
	position: string;
	description: string;
}


export class LegalEntityCounterpartyHeadMapper {
	static mapFromPersisted(source: ApiLegalEntityCounterpartyHeadPersisted): LegalEntityCounterpartyHead {
		return {
			id: source.id,
			...source.legalEntityHead
		};
	}

	static map(source: ApiLegalEntityCounterpartyHead): LegalEntityCounterpartyHead {
		return {
			id: "",
			...source
		};
	}
	
	static getEmpty(): LegalEntityCounterpartyHead {
		return {
			id: "",
			inn: "",
			snils: "",
			fullName: "",
			position: "",
			description: ""
		};
	}
}
