import { ServiceApplicationStepState } from "@rmp/counterparty/stores/serviceApplication/steps/shared/serviceApplicationState";
import { defineStore } from "pinia";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import AbortService from "@rmp/core/services/abortService";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { usePortStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/port";
import { useDepartmentStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/department";
import { useTugboatStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/tugboat";
import { useServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication";
import ServiceApplicationApiFacade from "@rmp/counterparty/api/service/facade";
import { useServiceStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/service";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import { useCrewboatStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/crewboat";
import { usePierStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/pier";
import {
	NewServiceApplicationPropertyTariff
} from "@rmp/core/types/services/newServiceApplicationPropertyTariff";
import { NewServiceApplicationTariffMapper, NewServiceApplicationTariff } from "@rmp/core/types/services/newServiceApplicationTariff";
import { ApiRequestedTugboat } from "@rmp/core/api/types/service/tugboat/apiRequestedTugboat";
import { ApiServiceApplicationCrewboat } from "@rmp/core/api/types/service/crewboat/apiServiceApplicationCrewboat";
import { ApiServiceApplicationPier } from "@rmp/core/api/types/service/pier/apiServiceApplicationPier";
import { formatWorkingTime } from "@rmp/core/utils/dates";
import { TugboatServiceApplication } from "@rmp/core/types/services/tugboat/tugboatServiceApplication";
import { PierServiceApplication } from "@rmp/core/types/services/pier/pierServiceApplication";
import { CrewboatServiceApplication } from "@rmp/core/types/services/crewboat/crewboatServiceApplication";
import { useBoatStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/boat";
import { ApiVatEnum } from "@rmp/core/api/types/vat/ApiVatEnum";
import { ApiSortedServiceTariffsEnum } from "@rmp/core/api/types/service/tariffs/ApiServiceTariffsEnum";
import { sortBy } from "lodash";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceApplicationApiFacade = new ServiceApplicationApiFacade(abortService);

export interface TariffStepState extends ServiceApplicationStepState, PageState {
	formValid: boolean;
	propertyTariffs: NewServiceApplicationPropertyTariff[];
	requested: (ApiRequestedTugboat | ApiServiceApplicationCrewboat | ApiServiceApplicationPier)[];
}

const getDefaultState = (): TariffStepState => {
	return {
		...page.getDefaultPageState(),
		formValid: true,
		stepType: ServiceApplicationStepTypeEnum.TARIFF,
		propertyTariffs: [],
		requested: []
	};
};

export const useTariffStepStore = defineStore({
	id: "tariff-step",
	state: (): TariffStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return state.initialized;
		},
		requestedProperties(state) {
			const { selectedServiceType } = useServiceStepStore();
			const requestedProperties: any[] = [];
			
			state.propertyTariffs.forEach(propertyTariff => {
				const property: any = {};
				
				if(selectedServiceType === ServiceTypeEnum.TUGBOAT_SERVICE)
					property.tugBoatId = propertyTariff.propertyId;
				else if(selectedServiceType === ServiceTypeEnum.CREWBOAT_SERVICE)
					property.crewBoatId = propertyTariff.propertyId;
				else if(selectedServiceType === ServiceTypeEnum.PIER_SERVICE)
					property.pierId = propertyTariff.propertyId;
				else
					throw new Error();
				
				const tariffs = propertyTariff.tariffs.map(x => ({
					...property,
					workingTime: formatWorkingTime(+x.value),
					tariffId: x.id
				})).filter(x => propertyTariff.selectedTariffIds.includes(x.tariffId));
				requestedProperties.push(...tariffs);
			});
			
			return requestedProperties;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			const { selectedServiceType } = useServiceStepStore();
			serviceApplicationApiFacade.configure(selectedServiceType);
			
			await this.fetch();
			this.selectDefaultTariffs();
		},
		getProperty() {
			const { selectedServiceType } = useServiceStepStore();
			
			switch (selectedServiceType) {
				case ServiceTypeEnum.TUGBOAT_SERVICE:
				{
					const { selectedTugboatIds, tugboats } = useTugboatStepStore();
					return { properties: tugboats, selectedPropertyIds: selectedTugboatIds };
				}
				case ServiceTypeEnum.CREWBOAT_SERVICE:
				{
					const { selectedCrewboatIds, crewboats } = useCrewboatStepStore();
					// TODO
					return { properties: crewboats.map(x => ({ ...x, ...x.crewboat })), selectedPropertyIds: selectedCrewboatIds };
				}
				case ServiceTypeEnum.PIER_SERVICE:
				{
					const { selectedPierIds, piers } = usePierStepStore();
					return { properties: piers, selectedPropertyIds: selectedPierIds };
				}
				default:
					throw new Error();
			}
		},
		async fetch() {
			try {
				const { stepType } = useServiceStepStore();
				const { selectedPortId } = usePortStepStore();
				const { selectedDepartmentId } = useDepartmentStepStore();
				const { selectedBoatId } = useBoatStepStore();
				
				let { properties, selectedPropertyIds } = this.getProperty();
				
				if(selectedBoatId && selectedPropertyIds.length) {
					for (const propertyId of selectedPropertyIds) {
						let apiTariffs = await serviceApplicationApiFacade.getTariffs(selectedDepartmentId, selectedPortId, propertyId);
						
						let property = properties.find(y => y.id === propertyId)!;
						
						let tariffs = apiTariffs.map(x => NewServiceApplicationTariffMapper.map(x));

						let sortedTariffs = sortBy(tariffs, [
							x => x.name,
							x => x.vat === ApiVatEnum.NoVat,
							x => x.price
						]);
						
						this.propertyTariffs.push({
							propertyId: propertyId,
							propertyName: property.name,
							selectedTariffIds: [],
							tariffs: sortedTariffs
						});
					}
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		selectDefaultTariffs() {
			const { copiedService } = useServiceApplicationStore();
			
			this.propertyTariffs.forEach((x) => {
				if(!x.tariffs.length) return;
				
				let copiedTariffs: { tariffId?: string }[] = [];
				
				switch (copiedService.type) {
					case ServiceTypeEnum.TUGBOAT_SERVICE:
						copiedTariffs =
							((copiedService as TugboatServiceApplication).requestedTugBoats || []).filter(y => y.tugboatId ===
								x.propertyId);
						break;
					case ServiceTypeEnum.CREWBOAT_SERVICE:
						copiedTariffs =
							((copiedService as CrewboatServiceApplication).requestedCrewBoats || []).filter(y => y.crewboatId ===
								x.propertyId);
						break;
					case ServiceTypeEnum.PIER_SERVICE:
						copiedTariffs =
							((copiedService as PierServiceApplication).requestedPiers || []).filter(y => y.pierId === x.propertyId);
						break;
				}
				
				if(copiedTariffs.length) {
					x.selectedTariffIds = copiedTariffs.filter(x => x.tariffId).map(x => x.tariffId!);
				} else {
					let [firstTariff] = x.tariffs;
					x.selectedTariffIds = [firstTariff.id];
				}
			});
		}
	}
});
