import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useTugboatServiceStore } from "@rmp/counterparty/stores/serviceDetails/tugboat";
import { useTugboatServiceDocumentsBreadcrumb } from "@rmp/counterparty/stores/serviceDetails/tugboat/documents/composables/useTugboatServiceDocumentsBreadcrumb";
import { ServiceController } from "@rmp/counterparty/api/service";
import { DocumentsState, useDocumentsStore } from "@rmp/counterparty/stores/composables/baseDocuments";
import { ServiceDocument, ServiceDocumentMapper } from "@rmp/core/types/services/serviceDocument";

const abortService = new AbortService();
const page = usePageStore(abortService);
const serviceController = new ServiceController(abortService);

const documentsStore = useDocumentsStore({ controller: serviceController, mapper: ServiceDocumentMapper });

export interface TugboatServiceDocumentsState extends PageState, DocumentsState<ServiceDocument> {
}

const getDefaultState = (): TugboatServiceDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...documentsStore.getDefaultState()
	};
};

export const useTugboatServiceDocumentsStore = defineStore({
	id: "tugboat-service-documents",
	state: (): TugboatServiceDocumentsState => getDefaultState(),
	getters: {
		...page.getters,
		...documentsStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useTugboatServiceStore();
			return [...breadcrumbs, useTugboatServiceDocumentsBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...documentsStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			const { title } = useTugboatServiceStore();
			
			this.id = id;
			
			await this.initializeDocumentsStore(title);
		}
	}
});
