import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { ServiceController } from "@rmp/counterparty/api/service";
import { usePierServiceStore } from "@rmp/counterparty/stores/serviceDetails/pier";
import { usePierServiceFeedbackBreadcrumb } from "@rmp/counterparty/stores/serviceDetails/pier/feedback/composables/usePierServiceFeedbackBreadcrumb";
import { FeedbackState, useFeedbackStore } from "@rmp/core/stores/composables/feedback";

const abortService = new AbortService();
const page = usePageStore(abortService);

const serviceController = new ServiceController(abortService);
const feedbackStore = useFeedbackStore({ controller: serviceController });

export interface PierServiceFeedbackState extends PageState, FeedbackState {
}

const getDefaultState = (): PierServiceFeedbackState => {
	return {
		...page.getDefaultPageState(),
		...feedbackStore.getDefaultState()
	};
};

export const usePierServiceFeedbackStore = defineStore({
	id: "pier-service-feedback",
	state: (): PierServiceFeedbackState => getDefaultState(),
	getters: {
		...page.getters,
		...feedbackStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = usePierServiceStore();
			return [...breadcrumbs, usePierServiceFeedbackBreadcrumb()];
		}
	},
	actions: {
		...page.actions,
		...feedbackStore.actions,
		async beforeInitialized(this: any, { id }: { id: string }) {
			this.id = id;
			await this.initializeFeedbackStore();
		}
	}
});
