import AbortService from "@rmp/core/services/abortService";
import ApiCreateCounterpartyDocumentRequest from "@rmp/core/api/types/documents/apiCreateCounterpartyDocumentRequest";
import { ApiGetCounterpartyDocumentsParameters } from "@rmp/core/api/types/documents/apiGetCounterpartyDocumentsParameters";
import { urls } from "@rmp/core/api/config/organizationUrls";
import BaseOrganizationController from "@rmp/core/api/base/baseOrganiztionController";
import { ApiCounterpartyDocumentPersisted } from "@rmp/core/api/types/documents/apiCounterpartyDocument";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";

export class CounterpartyDocumentsController extends BaseOrganizationController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getDocuments = async (id: string,
		parameters: ApiGetCounterpartyDocumentsParameters,
		scope = AuthorizationScopeType.GLOBAL) => {
		const url = scope === AuthorizationScopeType.GLOBAL ? urls.counterparty.$id.document : urls.own.counterparty.document;
		
		return await this.get<{ documents: ApiCounterpartyDocumentPersisted[], totalCount: number }>(url,
			{ id, ...parameters });
	};
	
	updateDocumentStatus = async (id: string, isArchived: boolean) => {
		return await this.post<ApiCounterpartyDocumentPersisted>(urls.own.counterparty.document.$id.toggleArchived, { id }, { isArchived });
	};
	
	createDocument = async (document: ApiCreateCounterpartyDocumentRequest) => {
		return await this.post<ApiCounterpartyDocumentPersisted>(urls.own.counterparty.document, {}, document);
	};
}
