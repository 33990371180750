import ApiCounterpartyBoatDocumentPackage from "@rmp/core/api/types/counterparty/boatApplication/apiCounterpartyBoatDocumentPackage";
import { parseISO } from "date-fns";
import { formatCounterpartyTitle } from "@rmp/core/utils/formatting";
import { ApiCounterpartyBoatApplicationPersisted } from "@rmp/core/api/types/counterpartyBoatApplication/apiCounterpartyBoatApplication";
import { plainToInstance } from "class-transformer";
import ApiCounterpartyPersistedBase from "@rmp/core/api/types/counterparty/apiCounterpartyPersistedBase";

export interface BoatApplicationsItem {
	id: string;
	createdAt: Date;
	name: string;
	callSign: string;
	imo: string;
	mmsi?: string;
	flag: string;
	type: string;
	grt: number;
	exclusionGRT?: number;
	nrt: number;
	deadWeight: number;
	length: number;
	width: number;
	height: number;
	power: number;
	portOfRegistry: string;
	numberOfRegistry: string;
	yearOfBuilding?: number;
	status: string;
	shipowner: string;
	documentsPackage: ApiCounterpartyBoatDocumentPackage;
	counterparty: string;
}

export class BoatApplicationsItemMapper {
	static map(source: ApiCounterpartyBoatApplicationPersisted): BoatApplicationsItem {
		const counterparty = plainToInstance(ApiCounterpartyPersistedBase, source.counterpartyBoatApplication.counterparty).counterparty;
		return {
			...source,
			...source.counterpartyBoatApplication,
			...source.counterpartyBoatApplication.applicationBoat,
			createdAt: parseISO(source.createdAt),
			counterparty: formatCounterpartyTitle(counterparty)
		};
	}
}
