import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";

export const agreementBasedService = (kind: ServiceTypeEnum) => {
	const agreementBasedServices = [
		ServiceTypeEnum.TUGBOAT_SERVICE,
		ServiceTypeEnum.CREWBOAT_SERVICE,
		ServiceTypeEnum.INFORMATION_SERVICE,
		ServiceTypeEnum.PIER_SERVICE
	];
	
	return agreementBasedServices.includes(kind);
};
