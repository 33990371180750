import { ApiTariffTotalAmount } from "@rmp/core/api/types/service/apiTariffTotalAmount";
import { ApiServiceApplicationPier } from "@rmp/core/api/types/service/pier/apiServiceApplicationPier";
import {
	ServiceApplicationPropertyBase,
	ServiceApplicationPropertyBaseMapper
} from "@rmp/core/types/services/base/serviceApplicationPropertyBase";

export interface ServiceApplicationPier extends ServiceApplicationPropertyBase {
	pierId: string;
	pierServiceApplicationId: string;
}

export class ServiceApplicationPierMapper {
	static map(source: ApiServiceApplicationPier): ServiceApplicationPier {
		return {
			...ServiceApplicationPropertyBaseMapper.map(source),
			pierId: source.pierId,
			pierServiceApplicationId: source.pierServiceApplicationId
		};
	}
}
