import PasswordValidator from "password-validator";
import { i18n } from "@rmp/core/plugins";
import { isNil } from "lodash";

const {
	VUE_APP_PASSWORD_STRENGTH_MIN_LENGTH,
	VUE_APP_PASSWORD_STRENGTH_MAX_LENGTH,
	VUE_APP_PASSWORD_STRENGTH_MIN_UNIQUE_CHARS,
	VUE_APP_PASSWORD_STRENGTH_REQUIRE_LOWERCASE,
	VUE_APP_PASSWORD_STRENGTH_REQUIRE_UPPERCASE,
	VUE_APP_PASSWORD_STRENGTH_REQUIRE_DIGIT,
	VUE_APP_PASSWORD_STRENGTH_REQUIRE_NON_ALPHANUMERIC
} = process.env;

const defaultValidatorParams = {
	minSymbols: 8,
	maxSymbols: 100,
	lowercase: 1,
	uppercase: 1,
	minDigits: 1,
	minSpecialSymbols: 1,
	minUniqSymbols: 5
};

const passwordValidator = new PasswordValidator();

// Проверяем, есть ли у нас значение из env, если есть, то преобразуем в число, если нет, то подставляем дефолт (чтобы не возникало ошибок при преобразовании несуществующей переменной, которую задали, в число)
const minLength = isNil(VUE_APP_PASSWORD_STRENGTH_MIN_LENGTH) ? defaultValidatorParams.minSymbols : +VUE_APP_PASSWORD_STRENGTH_MIN_LENGTH;
const maxLength = isNil(VUE_APP_PASSWORD_STRENGTH_MAX_LENGTH) ? defaultValidatorParams.maxSymbols : +VUE_APP_PASSWORD_STRENGTH_MAX_LENGTH;
const minUniqueChars = isNil(VUE_APP_PASSWORD_STRENGTH_MIN_UNIQUE_CHARS) ? defaultValidatorParams.minUniqSymbols : +VUE_APP_PASSWORD_STRENGTH_MIN_UNIQUE_CHARS;

// Берем значение из env и парсим его в boolean
const requiredLowercase = VUE_APP_PASSWORD_STRENGTH_REQUIRE_LOWERCASE && JSON.parse(VUE_APP_PASSWORD_STRENGTH_REQUIRE_LOWERCASE);
const requiredUppercase = VUE_APP_PASSWORD_STRENGTH_REQUIRE_UPPERCASE && JSON.parse(VUE_APP_PASSWORD_STRENGTH_REQUIRE_UPPERCASE);
const requiredDigit = VUE_APP_PASSWORD_STRENGTH_REQUIRE_DIGIT && JSON.parse(VUE_APP_PASSWORD_STRENGTH_REQUIRE_DIGIT);
const requiredSpecialSymbols = VUE_APP_PASSWORD_STRENGTH_REQUIRE_NON_ALPHANUMERIC && JSON.parse(VUE_APP_PASSWORD_STRENGTH_REQUIRE_NON_ALPHANUMERIC);

passwordValidator
.is().min(minLength, i18n.tc("validation.passwordMinSymbols", minLength))
.is().max(maxLength, i18n.tc("validation.passwordMaxSymbols", maxLength))
.has().not().spaces(0, i18n.t("validation.passwordNoSpaces") as string);

if(requiredLowercase) {
	passwordValidator.has().lowercase(defaultValidatorParams.lowercase, i18n.tc("validation.passwordMinLowercaseSymbols", defaultValidatorParams.lowercase));
}

if(requiredUppercase) {
	passwordValidator.has().uppercase(defaultValidatorParams.uppercase, i18n.tc("validation.passwordMinUppercaseSymbols", defaultValidatorParams.uppercase));
}

if(requiredDigit) {
	passwordValidator.has().digits(defaultValidatorParams.minDigits, i18n.tc("validation.passwordMinDigits", defaultValidatorParams.minDigits));
}

if(requiredSpecialSymbols) {
	passwordValidator.has().symbols(defaultValidatorParams.minSpecialSymbols, i18n.tc("validation.passwordMinSpecialSymbols", defaultValidatorParams.minSpecialSymbols));
}

passwordValidator.usingPlugin((password: string) => checkPasswordForNumberUniqueChars(password, minUniqueChars), i18n.t("validation.passwordMinUniqChars", { num: minUniqueChars }) as string);

function checkPasswordForNumberUniqueChars(password: string, minUniqSymbols: number) {
	if(!password) return false;
	
	const splittedPassword = password.split("");
	const uniqueItemsArray = [...new Set(splittedPassword)];
	
	return uniqueItemsArray.length >= minUniqSymbols;
}

export default passwordValidator;
