export enum ServiceTypeEnum {
	UNDEFINED_SERVICE = "UNDEFINED_SERVICE",

	// Буксирные услуги
	TUGBOAT_SERVICE = "TUGBOAT_SERVICE",
	
	// Услуги по предоставлению разъездных судов
	CREWBOAT_SERVICE = "CREWBOAT_SERVICE",
	
	// Информационные услуги
	INFORMATION_SERVICE = "INFORMATION_SERVICE",
	
	// Услуги плавкрана
	FLOATING_CRANE_SERVICE = "FLOATING_CRANE_SERVICE",
	
	// Услуги по предоставлению причалов для стоянки судов
	PIER_SERVICE = "PIER_SERVICE",
	
	// Геодезические и гидрографические услуги
	GEODETIC_HYDROGRAPHIC_SERVICE = "GEODETIC_HYDROGRAPHIC_SERVICE",
	
	// Услуги по установке и обслуживанию СНО
	SNO_INSTALLATION_SERVICE = "SNO_INSTALLATION_SERVICE",
	
	// Дноуглубительные услуги
	DREDGING_WORKS_SERVICE = "DREDGING_WORKS_SERVICE"
}
