import { StoreDefinition, storeToRefs } from "pinia";
import { useI18n } from "vue-i18n-composable";
import { onBeforeRouteUpdate } from "vue-router/composables";
import { isNil, isString } from "lodash";
import { RegisterStoreDefinition } from "@rmp/core/stores/composables/register/useRegisterStore";

interface TableHeader {
	value: string;
	sortable?: boolean;
	width?: string;
	text?: string;
	class?: string;
}

export interface RegisterItemsOptions {
	useStore: RegisterStoreDefinition;
	headers: string[] | TableHeader[];
}

const createTableHeaders = (items: string[] | TableHeader[]) => {
	const { t } = useI18n();
	
	return items.map(x => {
		if(isString(x))
			return {
				text: t(`tables.${x}`), value: x
			};
		else return {
			...x,
			text: !isNil(x.text) ? x.text : t(`tables.${x.value}`)
		};
	});
};

export function useRegisterItems(options: RegisterItemsOptions) {
	const store = options.useStore();
	const { listing, filteredItems, isListingEmpty } = storeToRefs(store);
	
	const TABLE_MOBILE_BREAKPOINT = 880;
	
	const headers = createTableHeaders(options.headers);
	
	onBeforeRouteUpdate(async (to, from, next) => {
		await next();
		await store.syncStateWithRoute();
	});
	
	return {
		listing, isListingEmpty, headers, TABLE_MOBILE_BREAKPOINT, filteredItems
	};
}
