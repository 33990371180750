import { IsString, IsInt, Min, IsIn, Max, Length, IsEnum } from "class-validator";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { ApplicationFilterStatusType } from "@rmp/core/types/ApplicationFilterStatusType";
import { AVAILABLE_SORTS } from "@rmp/counterparty/stores/boatApplications/constants";
import RegisterRouteQuery from "@rmp/core/stores/composables/register/types/registerRouteQuery";

export default class BoatApplicationsRouteQuery implements RegisterRouteQuery {
	@IsInt()
	@Min(0)
	@Max(1000)
	page: number;

	@IsString()
	@IsIn(AVAILABLE_SORTS)
	sort: string;

	@IsString()
	@IsIn([SortingOrderTypeEnum.DESCENDING, SortingOrderTypeEnum.ASCENDING])
	sortDirection: string;

	@IsString()
	@Length(0, 100)
	query: string;

	@IsString()
	@IsEnum(ApplicationFilterStatusType)
	status: ApplicationFilterStatusType;

	constructor(page: number, sort: string, sortDirection: string, query: string, status: ApplicationFilterStatusType) {
		this.page = page;
		this.sort = sort;
		this.sortDirection = sortDirection;
		this.query = query;
		this.status = status;
	}
}
