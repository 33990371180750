import AbortService from "@rmp/core/services/abortService";
import { urls } from "@rmp/counterparty/api/config/urls";
import BaseCounterpartyController from "@rmp/counterparty/api/base/baseCounterpartyController";
import { ApiDepartmentPersisted } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { ApiPortPersisted } from "@rmp/core/api/types/divisions/port/apiPort";

export class DivisionController extends BaseCounterpartyController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getDepartment = async (id: string) => {
		return await this.get<ApiDepartmentPersisted>(urls.enterprise.division.department.$id, { id });
	};
	
	getPort = async (id: string) => {
		return await this.get<ApiPortPersisted>(urls.enterprise.division.port.$id, { id });
	};
}
