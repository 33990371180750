import BaseServiceApplicationWorkflowService from "@rmp/core/services/serviceApplication/baseServiceApplicationWorkflowService";
import { InformationServiceWorkflowStateType } from "@rmp/core/types/services/information/InformationServiceWorkflowStateType";
import { ApiInformationServiceTransitionCodeEnum } from "@rmp/core/api/types/service/information/ApiInformationServiceTransitionCodeEnum";

export default class InformationServiceApplicationWorkflowService extends BaseServiceApplicationWorkflowService {
	transitions = new Map<InformationServiceWorkflowStateType, InformationServiceWorkflowStateType[]>([
		[InformationServiceWorkflowStateType.NEW, [InformationServiceWorkflowStateType.DRAFT]],
		[
			InformationServiceWorkflowStateType.DRAFT,
			[InformationServiceWorkflowStateType.SENT, InformationServiceWorkflowStateType.CANCELED]
		],
		[
			InformationServiceWorkflowStateType.SENT,
			[
				InformationServiceWorkflowStateType.APPROVED_BY_DISPATCHER, InformationServiceWorkflowStateType.REJECTED,
				InformationServiceWorkflowStateType.CANCELED
			]
		],
		[
			InformationServiceWorkflowStateType.APPROVED_BY_DISPATCHER,
			[InformationServiceWorkflowStateType.PROVIDING, InformationServiceWorkflowStateType.REJECTED]
		],
		[InformationServiceWorkflowStateType.PROVIDING, [InformationServiceWorkflowStateType.PROVIDED]],
		[InformationServiceWorkflowStateType.PROVIDED, [InformationServiceWorkflowStateType.APPROVED_BY_FINANSIST]],
		[InformationServiceWorkflowStateType.APPROVED_BY_FINANSIST, [InformationServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED]],
		[
			InformationServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED,
			[InformationServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE, InformationServiceWorkflowStateType.APPROVED_BY_FINANSIST]
		],
		[
			InformationServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE,
			[
				InformationServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY,
				InformationServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED
			]
		],
		[InformationServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY, [InformationServiceWorkflowStateType.COMPLETED]]
	]);
	
	transitionNames = new Map<string, ApiInformationServiceTransitionCodeEnum>([
		[
			[InformationServiceWorkflowStateType.NEW, InformationServiceWorkflowStateType.DRAFT].join(),
			ApiInformationServiceTransitionCodeEnum.CREATE
		],
		[
			[InformationServiceWorkflowStateType.DRAFT, InformationServiceWorkflowStateType.SENT].join(),
			ApiInformationServiceTransitionCodeEnum.SEND
		],
		[
			[InformationServiceWorkflowStateType.SENT, InformationServiceWorkflowStateType.APPROVED_BY_DISPATCHER].join(),
			ApiInformationServiceTransitionCodeEnum.APPROVE
		],
		[
			[InformationServiceWorkflowStateType.APPROVED_BY_DISPATCHER, InformationServiceWorkflowStateType.PROVIDING].join(),
			ApiInformationServiceTransitionCodeEnum.START
		],
		[
			[InformationServiceWorkflowStateType.PROVIDING, InformationServiceWorkflowStateType.PROVIDED].join(),
			ApiInformationServiceTransitionCodeEnum.FINISH
		],
		[
			[
				InformationServiceWorkflowStateType.PROVIDED, InformationServiceWorkflowStateType.APPROVED_BY_FINANSIST
			].join(), ApiInformationServiceTransitionCodeEnum.APPROVE
		],
		[
			[
				InformationServiceWorkflowStateType.APPROVED_BY_FINANSIST,
				InformationServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED
			].join(), ApiInformationServiceTransitionCodeEnum.ATTACH
		],
		[
			[
				InformationServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED,
				InformationServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE
			].join(), ApiInformationServiceTransitionCodeEnum.FINALLY_SIGN
		],
		[
			[
				InformationServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE,
				InformationServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY
			].join(), ApiInformationServiceTransitionCodeEnum.FINALLY_SIGN
		],
		[
			[
				InformationServiceWorkflowStateType.FINALLY_SIGNED_BY_COUNTERPARTY,
				InformationServiceWorkflowStateType.COMPLETED
			].join(), ApiInformationServiceTransitionCodeEnum.COMPLETE
		],
		[
			[InformationServiceWorkflowStateType.DRAFT, InformationServiceWorkflowStateType.CANCELED].join(),
			ApiInformationServiceTransitionCodeEnum.CANCEL
		],
		[
			[InformationServiceWorkflowStateType.SENT, InformationServiceWorkflowStateType.REJECTED].join(),
			ApiInformationServiceTransitionCodeEnum.REJECT
		],
		[
			[InformationServiceWorkflowStateType.APPROVED_BY_DISPATCHER, InformationServiceWorkflowStateType.REJECTED].join(),
			ApiInformationServiceTransitionCodeEnum.REJECT
		],
		[
			[
				InformationServiceWorkflowStateType.FINALLY_SIGNED_BY_ENTERPRISE,
				InformationServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED
			].join(),
			ApiInformationServiceTransitionCodeEnum.RESET
		],
		[
			[
				InformationServiceWorkflowStateType.ACCOUNTING_DOCUMENTS_ATTACHED, InformationServiceWorkflowStateType.APPROVED_BY_FINANSIST
			].join(),
			ApiInformationServiceTransitionCodeEnum.RESET
		]
	]);
}
