import { RouteNames } from "@rmp/counterparty/router/routes";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import routeToPermissionsMap from "@rmp/counterparty/router/routeToPermissionsMap";
import { i18n } from "@rmp/counterparty/plugins";

export function useSignAgreementDocumentsBreadcrumb() {
	return useBreadcrumb({
		route: {
			name: RouteNames.AGREEMENT_SIGN_DOCUMENTS
		},
		text: i18n.t(`navigation.breadcrumbs.${RouteNames.AGREEMENT_SIGN_DOCUMENTS}`).toString(),
		routeToPermissionsMap
	});
}
