import AbortService from "@rmp/core/services/abortService";
import { urls } from "@rmp/counterparty/api/config/urls";
import BaseCounterpartyController from "@rmp/counterparty/api/base/baseCounterpartyController";
import { ApiServiceTypeEnum } from "@rmp/core/types/services/ApiServiceTypeEnum";
import { ApiChatMessage } from "@rmp/core/api/types/chat/apiChatMessage";
import { ApiServiceFeedback } from "@rmp/core/api/types/service/apiServiceFeedback";
import { ApiServiceHistoryEntryPersisted } from "@rmp/core/api/types/service/apiServiceHistoryEntry";
import { ApiServicesItem } from "@rmp/core/api/types/service/apiServicesItem";
import { ApiService } from "@rmp/core/api/types/service/apiService";
import { ApiServiceDocument } from "@rmp/core/api/types/service/document/apiServiceDocument";
import { ApiGetServicesParameters } from "@rmp/core/api/types/service/apiGetServicesParameters";
import { ApiTariff } from "@rmp/core/api/types/service/tariffs/apiTariff";
import { ApiGetServiceFeedbackResponsePersisted } from "@rmp/core/api/types/service/apiGetServiceFeedbackResponse";
import { BaseHistoryController } from "@rmp/core/api/shared/baseHistoryController";

export class ServiceController extends BaseCounterpartyController implements BaseHistoryController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getDefinitions = async () => {
		return await this.get<{ name: string, enum: ApiServiceTypeEnum, available: boolean }[]>(urls.service.definition);
	};
	
	getServices = async (parameters: ApiGetServicesParameters) => {
		return await this.get<{ items: ApiServicesItem[], totalCount: number }>(urls.service, parameters);
	};
	
	getService = async (tugboatServiceId: string) => {
		return await this.get<{ serviceApplication: ApiService }>(urls.service.tugboat.$tugboatServiceId, { tugboatServiceId });
	};
	
	getHistory = async (id: string) => {
		return await this.get<{ history: ApiServiceHistoryEntryPersisted[] }>(urls.service.$id.history, { id });
	};
	
	getChatMessages = async (serviceApplicationId: string) => {
		return await this.get<{ messages: ApiChatMessage[] }>(urls.service.$serviceApplicationId.chat.message, { serviceApplicationId });
	};
	
	createChatMessage = async (serviceApplicationId: string, message: string): Promise<ApiChatMessage> => {
		return await this.post(urls.service.$serviceApplicationId.chat.message, { serviceApplicationId }, { message });
	};
	
	getFeedback = async (id: string) => {
		return await this.get<ApiGetServiceFeedbackResponsePersisted | null>(urls.service.$id.feedback, { id });
	};
	
	sendFeedback = async (id: string, feedback: ApiServiceFeedback) => {
		return await this.post<{ serviceApplicationReviewPersisted: ApiGetServiceFeedbackResponsePersisted }>(urls.service.$id.feedback,
			{ id },
			feedback);
	};
	
	getDocuments = async (serviceApplicationId: string) => {
		return await this.get<{ documents: ApiServiceDocument[] }>(urls.service.$serviceApplicationId.document, { serviceApplicationId });
	};
	
	getTariffs = async (serviceType: string, archived?: boolean) => {
		return await this.get<{ tariffs: ApiTariff[] }>(urls.service.$serviceType.tariff, { serviceType, isArchived: archived });
	};
	
	archive = async (serviceApplicationId: string) => {
		return await this.post(urls.service.$serviceApplicationId.archive, { serviceApplicationId }, {});
	};
	
	unarchive = async (serviceApplicationId: string) => {
		return await this.post(urls.service.$serviceApplicationId.unarchive, { serviceApplicationId }, {});
	};
}
