import { ApiAccountProfileBase } from "@rmp/core/api/types/account/profile/apiAccountProfileBase";
import { ProfileType } from "@rmp/core/types/ProfileType";

export default class ApiEntrepreneurProfile extends ApiAccountProfileBase {
	ogrnIp: string;
	inn: string;
	
	constructor(
		type = ProfileType.MASTER_ACCOUNT_ENTREPRENEUR,
		accountId: string = "",
		title: string = "",
		email: string = "",
		phone: string = "",
		inn: string = "",
		isActive: boolean = false,
		ogrnIp: string = ""
	)
	{
		super(type, accountId, "", title, email, phone, isActive);
		
		this.inn = inn;
		this.ogrnIp = ogrnIp;
	}
}
