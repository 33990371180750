import { RouteNames } from "@rmp/counterparty/router/routes";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import { i18n } from "@rmp/counterparty/plugins";
import routeToPermissionsMap from "@rmp/counterparty/router/routeToPermissionsMap";

export function useDredgingServiceChatBreadcrumb() {
	return useBreadcrumb({
		route: {
			name: RouteNames.DREDGING_SERVICE_COMMENTS
		},
		text: i18n.t(`navigation.breadcrumbs.${RouteNames.DREDGING_SERVICE_COMMENTS}`).toString(),
		routeToPermissionsMap
	});
}
