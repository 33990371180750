import { ApiServiceApplicationCrewboat } from "@rmp/core/api/types/service/crewboat/apiServiceApplicationCrewboat";
import {
	ServiceApplicationPropertyBase,
	ServiceApplicationPropertyBaseMapper
} from "@rmp/core/types/services/base/serviceApplicationPropertyBase";

export interface ServiceApplicationCrewboat extends ServiceApplicationPropertyBase {
	crewboatId: string;
	crewboatServiceApplicationId: string;
}

export class ServiceApplicationCrewboatMapper {
	static map(source: ApiServiceApplicationCrewboat): ServiceApplicationCrewboat {
		return {
			...ServiceApplicationPropertyBaseMapper.map(source),
			crewboatId: source.crewBoatId,
			crewboatServiceApplicationId: source.crewboatServiceApplicationId
		};
	}
}
