import urls from "@rmp/core/api/config/urls";
import { prepareUrl } from "@rmp/core/utils/prepareUrlQuery";
import BaseController from "@rmp/core/api/shared/baseController";
import AbortService from "@rmp/core/services/abortService";

export class AuthController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	signInWithProfile = async (payload: any, query: string): Promise<any> => {
		let { redirectUri } = await this.client.post<any>(prepareUrl(`${urls.auth.signInWithProfile}${query}`), payload, {
			withCredentials: true
		});

		return redirectUri;
	};
}
