import { defineStore } from "pinia";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { AuthorizationController } from "@rmp/core/api/authorization";
import { useUser } from "@rmp/core/composables/useUser";
import { ConfirmEmailModeType } from "@rmp/core/stores/confirmation/email/types/confirmEmailModeType";
import { Route } from "vue-router";
import { i18n } from "@rmp/core/plugins";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";


const abortService = new AbortService();
const authorizationController = new AuthorizationController(abortService);

const page = usePageStore(abortService);
const form = useFormStore();

export interface ConfirmEmailState extends PageState, FormState {
	email: string;
	code: string;
	codeError: string;
	mode: ConfirmEmailModeType;
}

const getDefaultState = (): ConfirmEmailState => {
	return {
		...page.getDefaultPageState(),
		...form.getDefaultState(),
		email: "",
		code: "",
		codeError: "",
		mode: ConfirmEmailModeType.INITIAL
	};
};

export const useConfirmEmailStore = defineStore({
	id: "confirm-email",
	state: (): ConfirmEmailState => getDefaultState(),
	getters: {
		...page.getters,
		...form.getters
	},
	actions: {
		...page.actions,
		...form.actions,
		async beforeInitialized({ route }: { route: Route }) {
			if(route.query.code)
				this.code = route.query.code as string;
		},
		async confirmEmail() {
			this.formSaving = true;
			
			try {
				const { signInSilent } = useUser();
				
				await authorizationController.confirmEmail(this.code);
				
				this.mode = ConfirmEmailModeType.SUCCESS;
				
				await signInSilent();
			} catch (ex) {
				console.error(ex);
				alertService.addError(AlertKeys.WRONG_CODE);
				this.codeError = i18n.tc("common.wrongCode");
				
			} finally {
				this.formSaving = false;
			}
		},
		async getNewCode() {
			try {
				await authorizationController.getNewConfirmEmailCode();
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
			}
		}
	}
});

