import { ApiDepartmentPersisted } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { AgreementApplicationDepartment } from "@rmp/counterparty/stores/agreementApplication/steps/department/types/agreementApplicationDepartment";

export default class AgreementApplicationDepartmentMapper {
	mapDepartment(source: ApiDepartmentPersisted, agreementsNumber: number): AgreementApplicationDepartment {
		return {
			id: source.id,
			name: source.division.shortName,
			agreementsNumber
		};
	}
}
