import { ApiDepartmentPersisted } from "@rmp/core/api/types/divisions/department/apiDepartment";
import { ServiceApplicationDepartment } from "@rmp/counterparty/stores/serviceApplication/steps/department/types/serviceApplicationDepartment";

export default class ServiceApplicationDepartmentMapper {
	mapDepartment(source: ApiDepartmentPersisted, agreementsNumber: number): ServiceApplicationDepartment {
		return {
			id: source.id,
			name: source.division.shortName,
			agreementsNumber
		};
	}
}
