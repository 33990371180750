import { ServiceApplicationStepState } from "@rmp/counterparty/stores/serviceApplication/steps/shared/serviceApplicationState";
import { defineStore } from "pinia";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useTariffStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/tariff";
import { sum } from "lodash";
import ServiceApplicationApiFacade from "@rmp/counterparty/api/service/facade";
import { useServiceStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/service";
import { usePortStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/port";
import { useBoatStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/boat";
import { useDepartmentStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/department";
import { ServiceTypeEnum } from "@rmp/core/types/services/ServiceTypeEnum";
import { ApiTariffTotalAmount } from "@rmp/core/api/types/service/apiTariffTotalAmount";
import { InformationServiceController } from "@rmp/counterparty/api/service/information";
import { useInformationTariffStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/informationTariff";

const abortService = new AbortService();
const page = usePageStore(abortService);

const informationServiceController = new InformationServiceController(abortService);

export interface InformationCostStepState extends ServiceApplicationStepState, PageState {
	totalAmounts: ApiTariffTotalAmount[];
}

const getDefaultState = (): InformationCostStepState => {
	return {
		...page.getDefaultPageState(),
		stepType: ServiceApplicationStepTypeEnum.INFORMATION_COST,
		totalAmounts: []
	};
};

export const useInformationCostStepStore = defineStore({
	id: "information-cost-step",
	state: (): InformationCostStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return state.initialized;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			await this.fetch();
		},
		async fetch() {
			const { selectedServiceType } = useServiceStepStore();
			const { selectedPortId } = usePortStepStore();
			const { selectedTariff } = useInformationTariffStepStore();
			const { selectedDepartmentId } = useDepartmentStepStore();
			
			if(!selectedTariff)
				return;
			
			try {				
				const { totalAmounts } = await informationServiceController.calculateExpectedCost({
					departmentId: selectedDepartmentId,
					tariffId: selectedTariff.id,
					quantity: +selectedTariff.value
				});
				
				this.totalAmounts = totalAmounts;
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		}
	}
});
