import {
	ServiceApplicationStepState
} from "@rmp/counterparty/stores/serviceApplication/steps/shared/serviceApplicationState";
import { defineStore } from "pinia";
import { ServiceApplicationStepTypeEnum } from "@rmp/counterparty/stores/serviceApplication/steps/shared/ServiceApplicationStepTypeEnum";
import { CrewboatServiceController } from "@rmp/counterparty/api/service/crewboat";
import AbortService from "@rmp/core/services/abortService";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useDepartmentStepStore } from "@rmp/counterparty/stores/serviceApplication/steps/department";
import {ApiCrewboatPersisted} from "@rmp/core/api/types/enterpriseProperty/apiCrewboat";
import { useServiceApplicationStore } from "@rmp/counterparty/stores/serviceApplication";
import { CrewboatServiceApplication } from "@rmp/core/types/services/crewboat/crewboatServiceApplication";
import { uniq } from "lodash";

const abortService = new AbortService();
const page = usePageStore(abortService);

const crewboatServiceController = new CrewboatServiceController(abortService);

export interface CrewboatStepState extends ServiceApplicationStepState, PageState {
	crewboats: ApiCrewboatPersisted[];
	selectedCrewboatIds: string[];
}

const getDefaultState = (): CrewboatStepState => {
	return {
		...page.getDefaultPageState(),
		crewboats: [],
		selectedCrewboatIds: [],
		stepType: ServiceApplicationStepTypeEnum.CREWBOAT
	};
};

export const useCrewboatStepStore = defineStore({
	id: "crewboat-step",
	state: (): CrewboatStepState => getDefaultState(),
	getters: {
		...page.getters,
		completed(state) {
			return state.initialized;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			await this.fetch();
			
			this.trySetDefaultCrewboat();
		},
		async fetch() {
			try {
				const { selectedDepartmentId } = useDepartmentStepStore();
				
				this.crewboats = await crewboatServiceController.getCrewboats(selectedDepartmentId);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		trySetDefaultCrewboat() {
			if(!this.crewboats.length) return;
			
			const { copiedService } = useServiceApplicationStore() as { copiedService: CrewboatServiceApplication };
			
			let requestedTariffs = copiedService.requestedCrewBoats || [];

			if(requestedTariffs.length) {
				// Берем уникальные значения, так как объекты повторяются, если выбрано несколько тарифов
				let copiedCrewboatIds = uniq(copiedService.requestedCrewBoats.map(x => x.crewboatId));

				// Оставляем только те судна, которые доступны пользователю
				this.selectedCrewboatIds = copiedCrewboatIds.filter(x => this.crewboats.find(y => y.id === x));
			} else {
				let [crewboat] = this.crewboats;
				this.selectedCrewboatIds = [crewboat.id];
			}
		}
	}
});
