import { ApiPortPersisted } from "@rmp/core/api/types/divisions/port/apiPort";
import { ServiceApplicationPort } from "@rmp/counterparty/stores/serviceApplication/steps/port/types/serviceApplicationPort";

export default class ServiceApplicationPortMapper {
	mapPort(source: ApiPortPersisted): ServiceApplicationPort {
		return {
			name: source.division.fullName,
			id: source.id,
			isArchived: source.division.isArchived
		};
	}
}
