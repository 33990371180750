import { render, staticRenderFns } from "./RmpUploadCrewboatServiceDocumentsContentLoader.vue?vue&type=template&id=c0b49a9e&"
import script from "./RmpUploadCrewboatServiceDocumentsContentLoader.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RmpUploadCrewboatServiceDocumentsContentLoader.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports