import { parseISO } from "date-fns";
import ServiceMapperProfile from "@rmp/core/api/mappers/service";
import { ServiceApplicationBase } from "@rmp/core/types/services/base/serviceApplicationBase";
import { ApiDredgingServiceApplication } from "@rmp/core/api/types/service/dredging/apiDredgingServiceApplication";
import { ApiDredgingServiceWorkflowState } from "@rmp/core/api/types/service/dredging/apiDredgingServiceWorkflowState";

export interface DredgingServiceApplication extends ServiceApplicationBase {
	workflowState: ApiDredgingServiceWorkflowState;
}

export class DredgingServiceApplicationMapper {
	static map(source: ApiDredgingServiceApplication): DredgingServiceApplication {
		return {
			...source,
			createdAt: parseISO(source.createdAt),
			updatedAt: parseISO(source.updatedAt),
			type: ServiceMapperProfile.mapServiceType(source.type)
		};
	}
}
