export enum ApiAgreementTransitionCodeType {
	CREATE = "Create",
	UPDATE = "Update",
	SEND = "Send",
	NEGOTIATE = "Negotiate",
	FINALLY_SIGN = "FinallySign",
	START = "Start",
	FINISH = "Finish",
	CANCEL = "Cancel",
	REJECT = "Reject"
}
