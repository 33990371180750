import { defineStore } from "pinia";
import { Alert } from "@rmp/core/stores/alerts/types/alert";

export interface AlertsState {
	errorAlerts: Alert[];
	infoAlerts: Alert[];
	successAlerts: Alert[];
}

const getDefaultState = (): AlertsState => {
	return {
		errorAlerts: [],
		infoAlerts: [],
		successAlerts: []
	};
};

export const useAlertsStore = defineStore({
	id: "alerts",
	state: (): AlertsState => getDefaultState(),
	actions: {
		addErrorAlert(value: string) {
			this.errorAlerts.push({ text: value, timeout: 8000 });
		},
		removeErrorAlert(index: number) {
			this.errorAlerts.splice(index, 1);
		},
		addSuccessAlert(value: string) {
			this.successAlerts.push({ text: value, timeout: 3000 });
		},
		removeSuccessAlert(index: number) {
			this.successAlerts.splice(index, 1);
		},
		addInfoAlert(value: string) {
			this.infoAlerts.push({ text: value, timeout: 3000 });
		},
		removeInfoAlert(index: number) {
			this.infoAlerts.splice(index, 1);
		}
	}
});

