import Vue from "vue";
// @ts-ignore
import VueMask from "v-mask";

export const customTokens = {
	N: { pattern: /[a-zA-Z\d]/ },
	F: { pattern: /[\d.,]/ }
};

const placeholders = Object.fromEntries(Object.entries(customTokens)
											  .map(([key, { pattern }]) => [key, pattern]));

Vue.use(VueMask, {
	placeholders
});
