import ApiGetBoatApplicationsParameters from "@rmp/core/api/types/counterparty/boatApplication/apiGetBoatApplicationsParameters";
import { BoatApplicationsState } from "@rmp/counterparty/stores/boatApplications/index";
import { ApplicationFilterStatusType } from "@rmp/core/types/ApplicationFilterStatusType";

export default class BoatApplicationsMapperProfile {
	constructor() {
	}
	
	mapToApiGetBoatApplicationsParameters(source: BoatApplicationsState): ApiGetBoatApplicationsParameters {
		return {
			take: source.paging.pageSize,
			skip: source.paging.pageSize * (source.paging.page - 1),
			query: source.search.query,
			sortField: source.sorting.type,
			sortingDirection: source.sorting.order,
			statuses: ApplicationFilterStatusType.ALL === source.filter.status ? [] : [source.filter.status]
		}
	}
}
